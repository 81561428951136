// From: https://www.joshwcomeau.com/snippets/react-components/in-portal/
import React from 'react'
import ReactDOM from 'react-dom'

interface InPortalProps {
  id: string
}

export const InPortal: React.FC<InPortalProps> = props => {
  const { id, children } = props

  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return ReactDOM.createPortal(children, document.querySelector(`#${id}`)!)
}
