import { sentenceCase } from 'change-case'
import { DesignApp } from '../generated/graphql'

const designAppMap: { [key: string]: string } = {
  [DesignApp.Figma]: 'Figma',
  [DesignApp.Sketch]: 'Sketch',
  [DesignApp.Xd]: 'Adobe XD',
  [DesignApp.Photoshop]: 'Adobe Photoshop',
  [DesignApp.Framer]: 'Framer',
  [DesignApp.Other]: 'Other',
}

export const formatDesignApp = (designApp?: DesignApp | null): string => {
  if (!designApp) return ''
  return designAppMap[designApp] || sentenceCase(designApp)
}
