import React from 'react'
import { Button } from './Button'

export interface EditSelectedProps {
  selected: React.ReactNode
  editText: string
  onEdit: () => void
}

export const EditSelected: React.FC<EditSelectedProps> = props => {
  return (
    <div className="flex justify-between items-center leading-6">
      <div>{props.selected}</div>
      <div>
        <Button styleType="link" size="sm" onClick={props.onEdit}>
          {props.editText}
        </Button>
      </div>
    </div>
  )
}
