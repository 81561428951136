import { gql } from '@apollo/client'
import { Field, Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../components/Button'
import { Card } from '../components/Card'
import { CustomTextAreaComponent } from '../components/FormikCustom'
import { FormItem } from '../components/FormItem'
import { useNotification } from '../components/Notification'
import { PageLayout } from '../components/PageLayout'
import { UpdateUserHeardAboutInput, UserWithHeardAboutDocument, useUpdateUserHeardAboutMutation, useUserWithHeardAboutQuery } from '../generated/graphql'
import { trackEvent } from '../utils/analytics'
import { formatGraphQLError } from '../utils/error'

gql`
  query UserWithHeardAbout {
    getUser {
      id
      heardAbout
    }
  }
`

gql`
  mutation UpdateUserHeardAbout($data: UpdateUserHeardAboutInput!){
    updateUserHeardAbout(data: $data){
      id
      heardAbout
    }
  }
`

interface DeveloperSourceProps { }

export const DeveloperSource: React.FC<DeveloperSourceProps> = props => {
  const { data } = useUserWithHeardAboutQuery();
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const [updateUserHeardAbout] = useUpdateUserHeardAboutMutation()
  const history = useHistory()

  return (
    <PageLayout title="Welcome" headerDark headerLarge>
      <Card>
        <Formik<UpdateUserHeardAboutInput>
          initialValues={{
            heardAbout: data?.getUser.heardAbout || '',
          }}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            console.log('onSubmit', values)
            try {
              await updateUserHeardAbout({
                variables: { data: values }, refetchQueries: [
                  { query: UserWithHeardAboutDocument }
                ]
              })
              trackEvent('Submitted heard about', values)
              history.goBack()
              showSuccessNotification({ description: 'Successfully submitted! Thanks!' })
            } catch (error) {
              console.error(error)
              showErrorNotification({ description: formatGraphQLError(error) })
            }
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, handleSubmit, handleReset, values }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <Field name="heardAbout" label="How did you hear about FrontWork?" component={CustomTextAreaComponent} rows={2} />

              <div className="text-right">
                <FormItem>
                  <Button
                    styleType="primary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </FormItem>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </PageLayout>
  )
}
