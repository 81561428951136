import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from './Button'
import { formatDateForCalendar, formatDateLong } from '../utils/date'
import { serverAmountToUSD } from '../utils/currency'

interface ProjectItemProps {
  projectId: string
  firstItem: boolean
  name: string
  technologies: string[]
  projectDeadline?: Date
  bidAmount?: number
  bidDeliveryDate?: Date
  imageUrl?: string
  onClick?: () => void
}

export const ProjectItem: React.FC<ProjectItemProps> = props => {
  const history = useHistory()
  const projectPath = `/project/${props.projectId}`

  return (
    <li className="border-t border-gray-200">
      <Link
        to={projectPath}
        className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            {/* {props.imageUrl && (
              <div className="flex-shrink-0">
                <img className="h-12 w-16 rounded" src={props.imageUrl} alt="" />
              </div>
            )} */}
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                  {props.name}
                </div>
                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                  <svg
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path>
                  </svg>
                  <span className="truncate">{props.technologies.join(', ')}</span>
                </div>
              </div>

              <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-gray-900">
                    {props.projectDeadline && (
                      <>
                        Project Deadline:{' '}
                        <time dateTime={formatDateForCalendar(props.projectDeadline)}>
                          {formatDateLong(props.projectDeadline)}
                        </time>
                      </>
                    )}
                  </div>
                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                    {props.bidAmount ? (
                      <>
                        <svg
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Your bid: {serverAmountToUSD(props.bidAmount)}{' '}
                        {props.bidDeliveryDate && (
                          <>
                            by&nbsp;
                            <time dateTime={formatDateForCalendar(props.bidDeliveryDate)}>
                              {formatDateLong(props.bidDeliveryDate)}
                            </time>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div> */}

          <div className="w-24 flex justify-end">
            <Button styleType="primary" onClick={() => history.push(projectPath)}>
              {props.bidAmount ? 'Edit Bid' : 'Bid'}
            </Button>
          </div>
        </div>
      </Link>
    </li>
  )
}
