import React from 'react'

export interface AvatarProps {
  srcUrl?: string
  letters?: string
  color?: 'bg-gray-500' | 'bg-indigo-500' | 'bg-green-500' | 'bg-blue-500'
  online?: boolean
}

export const Avatar: React.FC<AvatarProps> = props => {
  return (
    <span className="inline-block relative">
      {props.srcUrl ? (
        <img className="h-10 w-10 rounded-full" src={props.srcUrl} alt={props.letters} />
      ) : (
        <span
          className={`inline-flex items-center justify-center h-10 w-10 rounded-full ${
            props.color || 'bg-blue-500'
          }`}
        >
          <span className="font-medium leading-none text-white">
            {props.letters?.toUpperCase()}
          </span>
        </span>
      )}

      {props.online && (
        <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
      )}
    </span>
  )
}
