import React from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { LoadingSmall } from './Loading'

export interface ButtonProps {
  styleType?: 'primary' | 'secondary' | 'danger' | 'link' | 'linkWhite' | 'white' | 'black'
  icon?: React.ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'icon'
  block?: boolean
  disabled?: boolean
  loading?: boolean
  padding?: string
  onDark?: boolean
}

export type FullButtonProps = ButtonProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Button: React.FC<FullButtonProps> = ({
  styleType = 'primary',
  size = 'md',
  block,
  loading,
  disabled,
  padding,
  children,
  icon,
  onDark,
  ...buttonProps
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'inline-flex items-center justify-center border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
          'text-white bg-indigo-600 hover:bg-indigo-500 focus:ring-indigo-500':
            styleType === 'primary' && !onDark,
          'text-white bg-indigo-500 hover:bg-indigo-400 focus:ring-indigo-400':
            styleType === 'primary' && onDark,
          'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500':
            styleType === 'secondary',
          'text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500': styleType === 'danger',
          'text-indigo-700 hover:text-indigo-500 focus:ring-indigo-500': styleType === 'link',
          'text-white hover:text-gray-200 focus:ring-gray-200': styleType === 'linkWhite',
          'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500':
            styleType === 'white',

          'text-white bg-gray-800 hover:bg-gray-900 focus:ring-gray-900': styleType === 'black',

          'px-2.5 py-1.5 text-xs leading-4 rounded': size === 'xs',
          'px-3 py-2 text-sm leading-4 rounded-md': size === 'sm',
          'px-4 py-2 text-sm leading-5 rounded-md': size === 'md',
          'px-4 py-2 text-base leading-6 rounded-md': size === 'lg',
          'px-6 py-3 text-base leading-6 rounded-md': size === 'xl',
          'px-2 py-2': size === 'icon',
          'inline-flex rounded-md shadow-sm': styleType !== 'link',
          'w-full': block,

          'opacity-50 cursor-not-allowed': disabled,
        }
      )}
      disabled={disabled}
      {...buttonProps}
    >
      {loading ? (
        <div className="flex justify-center mr-3">
          <LoadingSmall color="currentColor" />
        </div>
      ) : null}
      {icon && <span className="-ml-1 mr-2 h-5 w-5">{icon}</span>}
      {children}
    </button>
  )
}

interface ButtonLinkProps extends ButtonProps {
  to: string
  external?: boolean
}

// TODO will be better to make this an actual link in many cases
// better for accessibility (even simple things like opening in new window on option-click don't work for example)
export const ButtonLink: React.FC<ButtonLinkProps> = props => {
  const history = useHistory()
  return (
    <Button
      {...props}
      onClick={() => {
        if (props.external) window.location.href = props.to
        else history.push(props.to)
      }}
    />
  )
}
