import React from 'react'
import classNames from 'classnames'
import { Transition } from '@headlessui/react'

interface PageHeaderProps {
  title: string
  description?: React.ReactNode
  dark?: boolean
}

export const PageHeaderWrapper: React.FC<PageHeaderProps> = props => {
  return (
    <header className={`${props.dark ? `bg-gray-800` : `bg-white`} shadow`}>
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">{props.children}</div>
    </header>
  )
}

export const PageHeader: React.FC<PageHeaderProps> = props => {
  return (
    <PageHeaderWrapper {...props}>
      <h1
        className={`text-3xl font-bold leading-tight ${
          props.dark ? `text-white` : `text-gray-800`
        }`}
      >
        {props.title}
      </h1>
      {props.description ? (
        <div className={`pt-2 text-sm leading-5 ${props.dark ? `text-white` : `text-gray-500`}`}>
          {props.description}
        </div>
      ) : null}
    </PageHeaderWrapper>
  )
}

interface DesktopButtonProps {
  text: string
  icon?: React.ReactNode
  type: 'primary' | 'secondary'
  onClick?: () => void
}

export const DesktopButton: React.FC<DesktopButtonProps> = props => {
  return (
    <span className="shadow-sm rounded-md">
      <button
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md focus:outline-none transition',
          {
            'text-white bg-indigo-500 hover:bg-indigo-400 focus:ring-indigo focus:border-indigo-600 active:bg-indigo-600':
              props.type === 'primary',
            'text-white bg-gray-600 hover:bg-gray-500 focus:ring-gray focus:border-gray-700 active:bg-gray-700':
              props.type === 'secondary',
          }
        )}
        onClick={props.onClick}
      >
        {props.icon && <span className="-ml-1 mr-2 h-5 w-5">{props.icon}</span>}
        {props.text}
      </button>
    </span>
  )
}

export type HeaderButton = {
  text: string
  icon?: React.ReactNode
  type: 'primary' | 'secondary'
  hideOnMobile: boolean
  onClick: () => void
}

export interface PageHeaderExtraProps extends PageHeaderProps {
  description?: React.ReactNode
  rightContent?: React.ReactNode
  buttons: HeaderButton[]
}

export const PageHeaderExtra: React.FC<PageHeaderExtraProps> = props => {
  return (
    <PageHeaderWrapper {...props}>
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
            {props.title}
          </h2>
          {props.description ? props.description : null}
        </div>

        <div className="mt-5 flex md:mt-0 md:ml-4 items-center">
          {props.rightContent}
          {props.buttons.map((button, i) => {
            return (
              <span
                key={button.text}
                className={classNames({
                  'hidden sm:block': button.hideOnMobile,
                  'ml-3': i > 0,
                })}
              >
                <DesktopButton
                  type={button.type}
                  text={button.text}
                  icon={button.icon}
                  onClick={button.onClick}
                />
              </span>
            )
          })}

          <span className="ml-3">
            <Dropdown {...props} />
          </span>
        </div>
      </div>
    </PageHeaderWrapper>
  )
}

export const Dropdown: React.FC<PageHeaderExtraProps> = props => {
  const [open, setOpen] = React.useState(false)
  const toggleOpen = () => setOpen(!open)
  const secondaryButtons = props.buttons.filter(button => button.hideOnMobile)

  return (
    <span className="relative shadow-sm rounded-md sm:hidden">
      {secondaryButtons.length ? (
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-gray focus:border-gray-800 transition"
          id="mobile-menu"
          aria-haspopup="true"
          onClick={toggleOpen}
        >
          More
          <svg className="-mr-1 ml-2 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      ) : null}

      <Transition
        show={open}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="origin-top-left absolute left-0 mt-2 -ml-1 w-48 rounded-md shadow-lg"
          aria-labelledby="mobile-menu"
          role="menu"
        >
          <div className="py-1 rounded-md bg-white focus:ringring-1 ring-black ring-opacity-5">
            {secondaryButtons.map(button => {
              return (
                <a
                  key={button.text}
                  href="#"
                  className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition"
                  role="menuitem"
                  onClick={button.onClick}
                >
                  {button.text}
                </a>
              )
            })}
          </div>
        </div>
      </Transition>
    </span>
  )
}
