import classNames from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'

export interface PageHeaderTabBarProps {
  tabs: Array<{ label: string; to: string }>
}

export const PageHeaderTabBar: React.FC<PageHeaderTabBarProps> = props => {
  return (
    <div className="flex pt-6 space-x-6 text-gray-300">
      {props.tabs.map(tab => {
        return (
          <NavLink
            key={tab.label}
            to={tab.to}
            exact
            className={classNames(
              'inline-flex flex-shrink-0 items-center pb-3 px-3 pt-1 border-b-4',
              'border-transparent hover:border-gray-300',
              'transition duration-150 ease-in-out focus:outline-none'
            )}
            activeClassName="border-indigo-400 hover:border-indigo-400 text-white hover:text-white"
          >
            {tab.label}
          </NavLink>
        )
      })}
    </div>
  )
}

interface PageHeaderTabBarWrapperProps {}

export const PageHeaderTabBarWrapper: React.FC<PageHeaderTabBarWrapperProps> = props => {
  return (
    <header className="bg-gray-800 shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{props.children}</div>
    </header>
  )
}
