import React from 'react'
import styled from 'styled-components'

export interface RadioProps {
  id: string
  name: string
  label: string
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
}

export const Radio: React.FC<RadioProps> = props => {
  return (
    <div className="flex items-center">
      <input
        {...props}
        id={props.id}
        name={props.name}
        type="radio"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
      />
      <label htmlFor={props.id} className="ml-3 block text-sm font-medium text-gray-700">
        {props.label}
      </label>
    </div>
  )
}

export const RadioGroupWrapper = styled.div.attrs({
  className: 'mt-4 space-y-4',
})``
