import React from 'react'
import { Loading } from './Loading'

const Markdown = React.lazy(() => import('./Markdown'))

export const MarkdownLazy: React.FC<{ text: string }> = props => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Markdown>{props.text}</Markdown>
    </React.Suspense>
  )
}
