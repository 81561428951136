import { format, parse, addDays as addDaysDateFns, formatDistanceToNow } from 'date-fns'

export const formatDate = (date: Date) => format(date, 'dd/MM/yy')
export const formatDateLong = (date: Date) => format(date, 'MMMM d, yyyy')
export const formatDateMedium = (date: Date) => format(date, 'MMM d, yyyy')
export const formatDateForCalendar = (date: Date) => format(date, 'yyyy-MM-dd')
export const formatDateAgo = (date: Date) => formatDistanceToNow(date, { addSuffix: true })
export const parseCalendarDate = (dateString?: string) =>
  dateString ? parse(dateString, 'yyyy-MM-dd', new Date()) : undefined
export const addDays = addDaysDateFns
