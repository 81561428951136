export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL || 'https://api.frontwork.dev/graphql'
export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://api.frontwork.dev'
export const NEXT_APP_URL = process.env.REACT_APP_NEXT_APP_URL || 'https://frontwork.dev'

export const ZEPLIN_CLIENT_ID = process.env.REACT_APP_ZEPLIN_CLIENT_ID as string
export const ZEPLIN_REDIRECT_URI = process.env.REACT_APP_ZEPLIN_REDIRECT_URI as string

export const FIGMA_CLIENT_ID = process.env.REACT_APP_FIGMA_CLIENT_ID as string
export const FIGMA_REDIRECT_URI = process.env.REACT_APP_FIGMA_REDIRECT_URI as string

export const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID as string
export const GITHUB_REDIRECT_URI = process.env.REACT_APP_GITHUB_REDIRECT_URI as string

export const GITHUB_APP_INSTALL_URL = process.env.REACT_APP_GITHUB_APP_INSTALL_URL as string

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT

export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

export const GTM_ID = process.env.REACT_APP_GTM_ID

export const COHERE_API_KEY = process.env.REACT_APP_COHERE_API_KEY

export const HOTJAR_SITE_ID =
  process.env.REACT_APP_HOTJAR_SITE_ID && parseInt(process.env.REACT_APP_HOTJAR_SITE_ID)
export const HOTJAR_SNIPPET_VERSION =
  process.env.REACT_APP_HOTJAR_SNIPPET_VERSION &&
  parseInt(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION)

export const BLUESNAP_MERCHANT_ID = process.env.REACT_APP_BLUESNAP_MERCHANT_ID as string
export const BLUESNAP_SDK_URL = process.env.REACT_APP_BLUESNAP_SDK_URL as string

export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID as string

export const STANDARD_SUBSCRIPTION_AMOUNT = 30
export const UNLIMITED_SUBSCRIPTION_AMOUNT = 60
export const STANDARD_SUBSCRIPTION_CREDITS = 20

export const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID

export const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true'

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
