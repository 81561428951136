import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { Field, Formik } from 'formik'
import React from 'react'
import { Button } from '../../components/Button'
import {
  CustomChooseCardComponent,
  CustomInputComponent,
  CustomInputCurrencyComponent,
  CustomTextAreaComponent,
} from '../../components/FormikCustom'
import { FormItem, FormItemsWrapper } from '../../components/FormItem'
import { FormSection } from '../../components/FormSection'
import { Label } from '../../components/Label'
import { useNotification } from '../../components/Notification'
import { PageLayout } from '../../components/PageLayout'
import {
  DeveloperComponentsDocument,
  FrontendFramework,
  ProgrammingLanguage,
  StylingLibrary,
  ComponentFragment,
  useComponentQuery,
  useCreateComponentMutation,
  useEditComponentMutation,
} from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import { useCloudinary } from '../../utils/cloudinary'
import { formatGraphQLError } from '../../utils/error'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../../utils/skills'
import { CreateComponentInput } from '../../generated/graphql'
import { Card, CardsWrapper } from '../../components/Card'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from '../../components/Loading'
import { ErrorPage } from '../../components/ErrorPage'
import { ComponentScreenshots } from '../../components/ComponentScreenshots'
import { amountToServerAmount, serverAmountToAmount } from '../../utils/currency'
import { ComponentCategoryField } from '../../components/ComponentCategoryField'
import { UploadComponentGitHub } from './UploadComponentGitHub'
import { CustomLinkExternal, Paragraph } from '../../components/Typography'
import { NEXT_APP_URL } from '../../utils/config'

gql`
  mutation CreateComponent($data: CreateComponentInput!) {
    createComponent(data: $data) {
      ...Component
    }
  }
`

gql`
  mutation EditComponent($data: EditComponentInput!) {
    editComponent(data: $data) {
      ...Component
    }
  }
`

const DeliveryMethod = {
  GITHUB: 'GITHUB',
  TAILWIND: 'TAILWIND',
  CODEPEN: 'CODEPEN',
  OTHER: 'OTHER',
}

export const EditUploadComponent: React.FC<{}> = props => {
  const { componentId } = useParams<{ componentId: string }>()

  const { data, loading, error } = useComponentQuery({ variables: { id: componentId } })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  return <UploadComponent component={data.component} />
}

interface UploadComponentProps {
  component?: ComponentFragment
}

export const UploadComponent: React.FC<UploadComponentProps> = props => {
  const { component } = props
  const { openUploadWidget } = useCloudinary()
  const [createComponent] = useCreateComponentMutation()
  const [editComponent] = useEditComponentMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const history = useHistory()
  const [screenshots, setScreenshots] = React.useState<{ [key: string]: { url: string } }>(
    Object.fromEntries(
      component?.componentScreenshots?.map(s => {
        return [s.url, { url: s.url }]
      }) || []
    )
  )
  const screenshotValues = Object.values(screenshots)

  const [thumbnailUrl, setThumbnailUrl] = React.useState(
    component?.thumbnail || screenshotValues[0]?.url
  )

  // const [githubFiles, setGithubFiles] = React.useState<string[]>([])
  const [selectedRepo, setSelectedRepo] = React.useState<string | undefined>(
    component?.gitHubRepo || undefined
  )
  // TODO initial value based off of: component?.files
  const [selectedFiles, setSelectedFiles] = React.useState<{ [key: string]: boolean }>({})
  const [deliverAllFiles, setDeliverAllFiles] = React.useState(true)

  const getDefaultDeliveryMethod = () => {
    if (!component?.deliveryUrl) return DeliveryMethod.GITHUB
    if (component?.deliveryUrl.includes('play.tailwindcss.com')) return DeliveryMethod.TAILWIND
    if (component?.deliveryUrl.includes('codepen.io')) return DeliveryMethod.CODEPEN

    return DeliveryMethod.OTHER
  }

  return (
    <PageLayout title="Upload Component" headerDark headerLarge>
      <Formik<
        CreateComponentInput & {
          componentCategories?: Array<{ value: string; label: string }>
          deliveryMethod?: string
        }
      >
        initialValues={{
          name: component?.name || '',
          description: component?.description || '',
          demoUrl: component?.demoUrl || '',
          price: component?.price ? serverAmountToAmount(component.price) : 0,
          daysToDeliver: component?.daysToDeliver || 0,
          frontendFrameworks: component?.frontendFrameworks || [],
          programmingLanguages: component?.programmingLanguages || [],
          stylingLibraries: component?.stylingLibraries || [],
          componentScreenshots: component?.componentScreenshots || [],
          categories: [],
          // have support for future of multiple categories but sticking to one for now
          componentCategories: component?.categories.map(({ name }) => ({
            value: name,
            label: name,
          })),
          deliveryMethod: getDefaultDeliveryMethod(),
          deliveryUrl: component?.deliveryUrl,
          // these aren't actually used
          gitHubRepo: component?.gitHubRepo || '',
          files: component?.files?.map(f => f.path) || [],
          allGitHubRepoFiles: component?.allGitHubRepoFiles ?? true,
          thumbnailUrl,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const data = {
              ...values,
              price: amountToServerAmount(values.price),
              componentScreenshots: Object.values(screenshots).map(s => ({ url: s.url })),
              categories: values.componentCategories
                ? values.componentCategories.map(c => c.value)
                : [],
              gitHubRepo: selectedRepo,
              files: Object.keys(selectedFiles),
              allGitHubRepoFiles: deliverAllFiles,
              thumbnailUrl,
            }
            delete data['componentCategories']
            delete data['deliveryMethod']
            console.log('onSubmit', data)

            let componentId = component?.id

            if (component) {
              await editComponent({
                variables: {
                  data: {
                    id: component.id,
                    ...data,
                  },
                },
                refetchQueries: [{ query: DeveloperComponentsDocument }],
              })
              trackEvent('Edit component', values)
              showSuccessNotification({
                description: 'Successfully edited component',
              })
            } else {
              const res = await createComponent({
                variables: { data },
                refetchQueries: [{ query: DeveloperComponentsDocument }],
              })
              componentId = res.data?.createComponent.id
              trackEvent('Create component', values)
              showSuccessNotification({
                description: 'Successfully published component for sale',
              })
            }
            history.push(`/component/${componentId}`)
          } catch (error) {
            console.error(error)
            const description = formatGraphQLError(error)
            showErrorNotification({
              description: description.map((d: string) => <div key={d}>{d}</div>),
            })
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <CardsWrapper>
              <Card>
                <FormItemsWrapper>
                  <Field name="name" label="Name" component={CustomInputComponent} />

                  <Field
                    name="price"
                    label="Price (suggested price is $1-$10 per component)"
                    component={CustomInputCurrencyComponent}
                    type="number"
                    step={0.01}
                    min={1}
                    max={20}
                  />

                  <ComponentCategoryField />

                  <div>
                    <div className="mb-2">
                      <Label>Screenshots (supports GIFs)</Label>
                    </div>

                    <ComponentScreenshots
                      screenshots={screenshotValues}
                      thumbnail={thumbnailUrl || screenshotValues[0]?.url}
                      onScreenshotClick={url => {
                        setThumbnailUrl(url)
                      }}
                      onScreenshotDelete={url => {
                        console.log('🚀 ~ file: UploadComponent.tsx ~ line 312 ~ url', url)

                        setScreenshots(screenshots => {
                          const newScreenshots = { ...screenshots }
                          delete newScreenshots[url]

                          return newScreenshots
                        })

                        if (url === thumbnailUrl) setThumbnailUrl(screenshots?.[0]?.url)
                      }}
                    />

                    <div className="mt-2">
                      <Button
                        onClick={() => {
                          openUploadWidget(({ error, event, url, filename, result }) => {
                            if (error) {
                              console.error(error)
                              return
                            }

                            if (event === 'success') {
                              setScreenshots(screenshots => {
                                return {
                                  ...screenshots,
                                  [url]: {
                                    url,
                                    filename,
                                  },
                                }
                              })
                            }
                          })
                        }}
                      >
                        Upload Screenshots
                      </Button>
                    </div>
                  </div>

                  <Field
                    name="demoUrl"
                    label="Demo URL (a viewable demo or Storybook hosted on Vercel, Netlify or similar)"
                    component={CustomInputComponent}
                    type="url"
                  />
                </FormItemsWrapper>
              </Card>

              <Card title="Optional">
                <FormItemsWrapper>
                  <Field
                    name="description"
                    label="Additional Information (shown when someone clicks on your component in the marketplace)"
                    type="textarea"
                    component={CustomTextAreaComponent}
                    rows={2}
                  />
                </FormItemsWrapper>
              </Card>

              {/*
              copied pasted from dev skillset. mostly fine though.
              these forms have very different purposes and can diverge in the future
            */}
              <Card title="Available in">
                <FormSection title="Languages" fullWidth>
                  <Field
                    name="programmingLanguages"
                    component={CustomChooseCardComponent}
                    options={Object.values(ProgrammingLanguage).map(language => ({
                      value: language,
                      label: formatProgrammingLanguage(language),
                    }))}
                    multiselect
                    maxColumns={3}
                  />
                </FormSection>

                <FormSection title="Frameworks" fullWidth>
                  <Field
                    name="frontendFrameworks"
                    component={CustomChooseCardComponent}
                    options={Object.values(FrontendFramework).map(framework => ({
                      value: framework,
                      label: formatFrontendFramework(framework),
                    }))}
                    multiselect
                    maxColumns={3}
                  />
                </FormSection>

                <FormSection title="Styling" fullWidth>
                  <Field
                    name="stylingLibraries"
                    component={CustomChooseCardComponent}
                    options={Object.values(StylingLibrary).map(styling => ({
                      value: styling,
                      label: formatStylingLibrary(styling),
                    }))}
                    multiselect
                    maxColumns={3}
                  />
                </FormSection>
              </Card>

              <Card
                title="Delivery"
                description="How would you like to deliver your files?"
                // title="GitHub"
                // description="These files are delivered to the client when they make a purchase."
              >
                <div className="space-y-6">
                  <Field
                    name="deliveryMethod"
                    component={CustomChooseCardComponent}
                    options={[
                      { value: DeliveryMethod.GITHUB, label: 'GitHub Repo' },
                      { value: DeliveryMethod.TAILWIND, label: 'Tailwind Playground' },
                      { value: DeliveryMethod.CODEPEN, label: 'CodePen' },
                      { value: DeliveryMethod.OTHER, label: 'Other' },
                    ]}
                    maxColumns={4}
                  />

                  {values.deliveryMethod === DeliveryMethod.GITHUB ? (
                    <UploadComponentGitHub
                      selectedRepo={selectedRepo}
                      setSelectedRepo={setSelectedRepo}
                      selectedFiles={selectedFiles}
                      toggleSelectedFile={file => {
                        setSelectedFiles(files => {
                          return {
                            ...files,
                            [file.path]: !files[file.path],
                          }
                        })
                      }}
                      deliverAllFiles={deliverAllFiles}
                      toggleDeliverAllFiles={() => setDeliverAllFiles(!deliverAllFiles)}
                    />
                  ) : (
                    <Field
                      name="deliveryUrl"
                      label="URL (must be publicly accessible. Sent to user upon purchase)"
                      type="url"
                      component={CustomInputComponent}
                    />
                  )}
                </div>
              </Card>
            </CardsWrapper>

            <div className="text-right">
              <FormItem>
                <div className="space-x-2 flex items-center">
                  <div className="text-left flex-1">
                    <Paragraph>
                      By publishing you agree to our{' '}
                      <CustomLinkExternal
                        href={`${NEXT_APP_URL}/component-marketplace/how-it-works`}
                        target="_blank"
                      >
                        Terms and Conditions
                      </CustomLinkExternal>
                    </Paragraph>
                  </div>
                  {component ? (
                    <Button
                      styleType="white"
                      type="button"
                      onClick={() => history.push(`/component/${component.id}`)}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    styleType="primary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Publish
                  </Button>
                </div>
              </FormItem>
            </div>
          </form>
        )}
      </Formik>
    </PageLayout>
  )
}
