import React from 'react'
import { Card } from '../../components/Card'
import { PageLayout } from '../../components/PageLayout'
import { useSearchQuery } from '../../utils/location'

export const StripePaymentSuccess: React.FC<{}> = props => {
  const query = useSearchQuery()
  const sessionId = query.get("session_id")

  React.useEffect(() => {
    const opener: any = window.opener
    opener?.onPurchaseCompleted?.()
  }, [])

  React.useEffect(() => {
    const opener: any = window.opener
    opener?.onSubscriptionCompleted?.(sessionId)
  }, [sessionId])

  return (
    <PageLayout title={'Payment Successful'} headerDark headerLarge>
      <Card>Thank you for your purchase :)</Card>
    </PageLayout>
  )
}

export const StripePaymentFailure: React.FC<{}> = props => {
  return (
    <PageLayout title={'Payment Fail'} headerDark headerLarge>
      <Card>There was an error completing the payment.</Card>
    </PageLayout>
  )
}
