import { useScript } from './use-script'

interface CloudinaryImage {
  secure_url: string
  original_filename: string
}

export const useCloudinary = () => {
  useScript('https://widget.cloudinary.com/v2.0/global/all.js')

  const openUploadWidget = (
    onUpload: (options: {
      event: 'success'
      url: string
      filename: string
      result: any
      error: any
    }) => void
  ) => {
    // @ts-ignore
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'elie-tech',
        uploadPreset: 'frontwork',
        folder: 'frontwork-local',
        sources: ['local', 'url', 'dropbox', 'google_drive'],
      },
      (
        error: any,
        result: {
          event: 'success'
          info: CloudinaryImage
        }
      ) => {
        onUpload({
          event: result.event,
          url: result.info.secure_url,
          filename: result.info.original_filename,
          result,
          error,
        })
      }
    )

    widget.open()
  }

  return { openUploadWidget }
}
