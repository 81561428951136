import React from 'react'
import produce from 'immer'
import gql from 'graphql-tag'
import { Field, Formik } from 'formik'
import {
  useCreateProjectZeplinMutation,
  CreateProjectZeplinInput,
  ProjectFragment,
  ZeplinScreenInput,
  ComponentType,
} from '../generated/graphql'
import { CustomChooseCardComponent } from '../components/FormikCustom'
import { FormItem } from '../components/FormItem'
import { StepComponentProps, useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { Pagination } from '../components/Pagination'
import { Button } from '../components/Button'
import { useZeplin, connectToZeplin, ZeplinProject, ZeplinScreen } from '../utils/zeplin'
import { Loading } from '../components/Loading'
import { PROJECT_FRAGMENT } from './Projects'
import { ConnectTo } from '../components/ConnectTo'
import { notUndefined } from '../utils/common'
import { useStorage } from '../utils/useStorage'
import { trackEvent } from '../utils/analytics'
import { SelectComponents } from '../components/SelectComponents'
import { DEV_MODE } from '../utils/config'

gql`
  mutation CreateProjectZeplin($projectId: ID!, $data: CreateProjectZeplinInput!) {
    createProjectZeplin(projectId: $projectId, data: $data) {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`

interface NewProjectZeplinProps extends StepComponentProps {
  project?: ProjectFragment
}

export const NewProjectZeplin: React.FC<NewProjectZeplinProps> = props => {
  const { project } = props
  const query = useSearchQuery()
  const projectId = query.get('projectId')
  const {
    authenticated,
    loadingAuth,
    selectedProjectScreens,
    selectedProjectComponents,
    connect,
  } = useZeplin()
  const callback = React.useCallback(() => {
    if (!authenticated) connect()
  }, [authenticated, connect])
  useStorage(callback)

  const [createProjectZeplin] = useCreateProjectZeplinMutation()

  return (
    <Card title="Zeplin">
      <Formik<Partial<CreateProjectZeplinInput & { zeplinScreenIds: { [key: string]: boolean } }>>
        enableReinitialize
        initialValues={{
          zeplinProjectId: project?.zeplinProjectId || undefined,
          zeplinScreens: project?.zeplinScreens || undefined,
          zeplinScreenIds: Object.fromEntries(
            project?.zeplinScreens?.map(screen => [screen.zeplinId, true]) || []
          ),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)
          if (!projectId) {
            console.error(`No project id`)
            return
          }
          if ((!values.zeplinProjectId || !values.zeplinScreenIds) && !DEV_MODE) {
            console.log(`No zeplin project id or screens`)
            return
          }

          const variables = {
            projectId,
            data: {
              zeplinProjectId: values.zeplinProjectId || '',
              zeplinScreens: Object.entries(values.zeplinScreenIds || {})
                .filter(([screenId, value]) => value)
                .map(([screenId]) => {
                  let screen: ZeplinScreen | undefined
                  let componentType = ComponentType.Screen
                  screen = selectedProjectScreens?.find(s => s.id === screenId)

                  if (!screen) {
                    screen = selectedProjectComponents?.find(s => s.id === screenId)
                    componentType = ComponentType.Component
                  }
                  if (!screen) return null

                  const input: ZeplinScreenInput = {
                    zeplinId: screen.id,
                    name: screen.name,
                    zeplinCreated: screen.created,
                    zeplinUpdated: screen.updated,
                    numberOfVersions: screen.number_of_versions,
                    numberOfNotes: screen.number_of_notes,
                    width: screen.image.width,
                    height: screen.image.height,
                    zeplinOriginalUrl: screen.image.original_url,
                    componentType,
                  }

                  return input
                })
                .filter(notUndefined),
            },
          }

          await createProjectZeplin({ variables })
          trackEvent('Create project - Zeplin', variables)
          // setSubmitting(false)
          props.increaseStep?.()
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, setFieldValue, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            {loadingAuth ? (
              <Loading />
            ) : authenticated ? (
              <SelectScreensContainer project={project} setFieldValue={setFieldValue} />
            ) : (
              <ConnectTo
                name="Zeplin"
                onClick={() => {
                  trackEvent('Click connect to Zeplin')
                  connectToZeplin()
                }}
              />
            )}
            <Pagination
              isSubmitting={isSubmitting}
              onNextClick={props.lastStep ? undefined : () => handleSubmit()}
              onPreviousClick={props.firstStep ? undefined : props.decreaseStep}
              disabled={(!values.zeplinProjectId || !values.zeplinScreenIds) && !DEV_MODE}
            />
          </form>
        )}
      </Formik>
    </Card>
  )
}

interface SelectScreensContainerProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  project?: ProjectFragment
}

export const SelectScreensContainer: React.FC<SelectScreensContainerProps> = React.memo(props => {
  const { getProjects, projects, loadingProjects } = useZeplin()

  React.useEffect(() => {
    getProjects()
  }, [getProjects])

  if (loadingProjects) return <Loading />
  if (!projects?.length) {
    return (
      <div className="mt-4">
        {projects
          ? 'No Zeplin Projects found.'
          : 'Error connecting to Zeplin. If this error persists please try refreshing the page.'}
        <ConnectTo name="Zeplin" onClick={connectToZeplin} />
      </div>
    )
  }

  return <SelectScreens {...props} zeplinProjects={projects} />
})

const ZEPLIN_SCREEN_IDS_FIELD = 'zeplinScreenIds'

interface SelectScreensProps extends SelectScreensContainerProps {
  project?: ProjectFragment
  zeplinProjects: ZeplinProject[]
}

export const SelectScreens: React.FC<SelectScreensProps> = props => {
  const { project, zeplinProjects } = props
  const {
    getProjectScreens,
    getProjectComponents,
    selectedProjectScreens,
    selectedProjectComponents,
    disconnect,
  } = useZeplin()
  const [state, setState] = React.useState<{
    zeplinProjectId?: string
    selectedScreens: { [key: string]: boolean }
  }>({
    // easier during development:
    // zeplinProjectId: zeplinProjects[0]?.id,
    zeplinProjectId: project?.zeplinProjectId || undefined,
    selectedScreens: Object.fromEntries(
      project?.zeplinScreens?.map(screen => [screen.zeplinId, true]) || []
    ),
  })
  const { zeplinProjectId } = state
  const screens = selectedProjectScreens || []
  const components = selectedProjectComponents || []

  React.useEffect(() => {
    if (zeplinProjectId) {
      getProjectScreens(zeplinProjectId)
      getProjectComponents(zeplinProjectId)
    }
  }, [getProjectScreens, getProjectComponents, zeplinProjectId])

  const selectProject = (value: string) => {
    setState(
      produce(state, state => {
        state.zeplinProjectId = value
        state.selectedScreens = {}
      })
    )
    props.setFieldValue(ZEPLIN_SCREEN_IDS_FIELD, {})
  }

  const selectAll = () => {
    setState(
      produce(state, state => {
        const selectedScreens = state.selectedScreens
        screens.forEach(screen => {
          selectedScreens[screen.id] = true
        })
        props.setFieldValue(ZEPLIN_SCREEN_IDS_FIELD, { ...selectedScreens })
      })
    )
  }

  const selectAllComponents = () => {
    setState(
      produce(state, state => {
        const selectedScreens = state.selectedScreens
        components.forEach(screen => {
          selectedScreens[screen.id] = true
        })
        props.setFieldValue(ZEPLIN_SCREEN_IDS_FIELD, { ...selectedScreens })
      })
    )
  }

  const unselectAll = () => {
    setState(
      produce(state, state => {
        const selectedScreens = state.selectedScreens
        screens.forEach(screen => {
          selectedScreens[screen.id] = false
        })
        props.setFieldValue(ZEPLIN_SCREEN_IDS_FIELD, { ...selectedScreens })
      })
    )
  }

  const unselectAllComponents = () => {
    setState(
      produce(state, state => {
        const selectedScreens = state.selectedScreens
        components.forEach(screen => {
          selectedScreens[screen.id] = false
        })
        props.setFieldValue(ZEPLIN_SCREEN_IDS_FIELD, { ...selectedScreens })
      })
    )
  }

  const onClickComponent = (screenId: string) => {
    setState(
      produce(state, state => {
        state.selectedScreens[screenId] = !state.selectedScreens[screenId]
        props.setFieldValue(ZEPLIN_SCREEN_IDS_FIELD, { ...state.selectedScreens })
      })
    )
  }

  return (
    <>
      <FormItem>
        <Field
          name="zeplinProjectId"
          label={
            <div className="flex justify-between items-center">
              Which project would you like to use?
              <Button
                styleType="link"
                size="sm"
                onClick={() => {
                  disconnect()
                }}
              >
                Switch Zeplin Account
              </Button>
            </div>
          }
          component={CustomChooseCardComponent}
          options={zeplinProjects.map(project => ({
            value: project.id,
            label: `${project.name}${project.status === 'archived' ? ' (Archived)' : ''}`,
          }))}
          onChange={selectProject}
        />
      </FormItem>

      <SelectComponents
        label="Which screens would you like to be developed?"
        screens={screens}
        selectAll={selectAll}
        unselectAll={unselectAll}
        selectedScreens={state.selectedScreens}
        onClickComponent={onClickComponent}
        getImageUrl={screen => screen.image.original_url}
        getExternalUrl={screen =>
          `https://app.zeplin.io/project/${state.zeplinProjectId}/screen/${screen.id}`
        }
        readonly={false}
        showNotes={false}
      />
      <SelectComponents
        label="Which components would you like to be developed?"
        screens={components}
        selectAll={selectAllComponents}
        unselectAll={unselectAllComponents}
        selectedScreens={state.selectedScreens}
        onClickComponent={onClickComponent}
        getImageUrl={screen => screen.image.original_url}
        getExternalUrl={screen =>
          `https://app.zeplin.io/project/${state.zeplinProjectId}/styleguide/components?coid=${screen.id}`
        }
        readonly={false}
        showNotes={false}
      />
    </>
  )
}
