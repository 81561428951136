import React from 'react'
import { useFigma, getLocalStorageFigmaState, clearLocalStorageFigmaState } from '../utils/figma'
import { useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { PageLayout } from '../components/PageLayout'
import { Button } from '../components/Button'
import { Loading } from '../components/Loading'

interface FigmaProps {}

export const Figma: React.FC<FigmaProps> = props => {
  const { getFigmaToken, authenticated, loadingAuth } = useFigma()
  const query = useSearchQuery()
  const code = query.get('code')
  const state = query.get('state')

  React.useEffect(() => {
    if (code) {
      const stateLocal = getLocalStorageFigmaState()
      if (state === stateLocal) {
        getFigmaToken(code).then(() => {
          window.close()
        })
        clearLocalStorageFigmaState()
      } else {
        console.error('State is wrong')
      }
    }
  }, [code, state, getFigmaToken])

  return (
    <PageLayout title="Figma" headerDark headerLarge>
      <Card title="Connected">
        {loadingAuth ? (
          <Loading />
        ) : authenticated ? (
          <Button
            styleType="primary"
            onClick={() => {
              window.close()
            }}
          >
            Continue
          </Button>
        ) : (
          <div>There was an error connecting.</div>
        )}
      </Card>
    </PageLayout>
  )
}
