import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { useUserContext } from '../auth/userContext'
import { CustomInputComponent } from '../components/FormikCustom'
import { FormItem } from '../components/FormItem'
import { SignUpInput, LogInInput, SendMagicLogInInput } from '../generated/graphql'
import { useNotification } from '../components/Notification'
import { Button, FullButtonProps } from '../components/Button'
import { formatGraphQLError } from '../utils/error'
import { ReactComponent as LogoIcon } from '../images/logo-icon.svg'
import { useSearchQuery } from '../utils/location'

const Header: React.FC<{ text: string }> = props => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <LogoIcon className="mx-auto h-12 w-auto text-indigo-600" />
      <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
        {props.text}
      </h2>
    </div>
  )
}

const FormWrapper: React.FC<{}> = props => {
  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{props.children}</div>
    </div>
  )
}

const ExtraLinksWrapper: React.FC<{}> = props => {
  return <div className="mt-6 flex items-center justify-between">{props.children}</div>
}

const ExtraLink: React.FC<{ text: string; to: string }> = props => {
  return (
    <div className="text-sm leading-5">
      <Link
        to={props.to}
        className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition"
      >
        {props.text}
      </Link>
    </div>
  )
}

const SubmitButton: React.FC<FullButtonProps & { text: string }> = props => {
  return (
    <div className="mt-6">
      <Button block {...props}>
        {props.text}
      </Button>
    </div>
  )
}

const Wrapper: React.FC<{}> = props => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {props.children}
    </div>
  )
}

const SplitScreen: React.FC<{
  left: React.ReactNode
  right: React.ReactNode
}> = props => {
  return (
    <div className="flex">
      <div className="flex-1">{props.left}</div>
      <div className="hidden lg:flex flex-1 bg-gradient-to-b from-indigo-500 to-indigo-700 items-center justify-center">
        {props.right}
      </div>
    </div>
  )
}

export const LogIn: React.FC<{}> = props => {
  return (
    <Wrapper>
      <Header text="Sign in to your account" />

      <LogInForm />
    </Wrapper>
  )
}

export const SignUp: React.FC<{}> = props => {
  const { hash, pathname } = useLocation()
  const developer = hash.includes('developer')
  const searchQuery = useSearchQuery()
  const isPurchase = searchQuery.get('buy')

  const text = isPurchase
    ? 'Sign up to complete purchase'
    : `Register ${developer ? 'a developer' : 'an'} account`

  const signUp = (
    <Wrapper>
      <Header text={text} />

      <SignUpForm developer={isPurchase ? false : developer} singleButton={!!isPurchase} />
    </Wrapper>
  )

  let right: React.ReactNode

  if (isPurchase) right = <PurchaseComponentText />
  else if (pathname.includes('upload-component')) right = <UploadComponentText />

  if (right) return <SplitScreen left={signUp} right={right} />

  return signUp
}

export const ForgotPassword: React.FC<{}> = props => {
  return (
    <Wrapper>
      <Header text="Reset your password" />

      <ForgotPasswordForm />
    </Wrapper>
  )
}

const usePostSubmit = () => {
  const history = useHistory()
  const search = new URLSearchParams(history.location.search)
  const redirect = search.get('redirect')

  return {
    postSubmit: () => {
      if (redirect) history.push(redirect)
    },
  }
}

const LogInForm: React.FC<{}> = props => {
  const { logIn } = useUserContext()
  const { showErrorNotification } = useNotification()
  const { postSubmit } = usePostSubmit()

  return (
    <Formik<LogInInput>
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await logIn(values)

          postSubmit()
        } catch (error) {
          console.log('error', error)
          showErrorNotification({
            description: formatGraphQLError(error),
          })
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <FormWrapper>
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <Field
              name="email"
              label="Email"
              type="email"
              id="email"
              required
              component={CustomInputComponent}
            />

            <FormItem>
              <Field
                name="password"
                label="Password"
                type="password"
                id="password"
                required
                component={CustomInputComponent}
              />
            </FormItem>

            <ExtraLinksWrapper>
              <ExtraLink text="Register" to="/sign-up" />
              <ExtraLink text="Forgot your password?" to="/forgot-password" />
            </ExtraLinksWrapper>

            <SubmitButton
              text={'Sign in'}
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
            />
          </form>
        </FormWrapper>
      )}
    </Formik>
  )
}

const SignUpForm: React.FC<{ developer: boolean; singleButton: boolean }> = props => {
  const { developer, singleButton } = props
  const { signUp } = useUserContext()
  const { showErrorNotification } = useNotification()
  const { postSubmit } = usePostSubmit()

  return (
    <Formik<SignUpInput>
      initialValues={{
        email: '',
        password: '',
        developer,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await signUp({ ...values })

          postSubmit()
        } catch (error) {
          showErrorNotification({
            description: formatGraphQLError(error),
          })
          setSubmitting(false)
        }
      }}
    >
      {({ values, isSubmitting, handleSubmit, handleReset, setFieldValue }) => (
        <FormWrapper>
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <FormItem>
              <Field
                name="email"
                label="Email"
                type="email"
                id="email"
                required
                component={CustomInputComponent}
              />
            </FormItem>
            <FormItem>
              <Field
                name="password"
                label="Password"
                type="password"
                id="password"
                required
                component={CustomInputComponent}
              />
            </FormItem>

            <ExtraLinksWrapper>
              <ExtraLink text="Already have an account? Log in" to="/log-in" />
            </ExtraLinksWrapper>

            {singleButton ? (
              <SubmitButton
                text={'Sign up'}
                loading={isSubmitting}
                disabled={isSubmitting}
                type="button"
                styleType="primary"
                onClick={() => {
                  setFieldValue('developer', true)
                  handleSubmit()
                }}
              />
            ) : (
              <div className="grid grid-cols-2 gap-2">
                <SubmitButton
                  text={'Sign up as Developer'}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="button"
                  styleType={values.developer ? 'primary' : 'secondary'}
                  onClick={() => {
                    setFieldValue('developer', true)
                    handleSubmit()
                  }}
                />

                <SubmitButton
                  text={'Sign up as Client'}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="button"
                  styleType={values.developer ? 'secondary' : 'primary'}
                  onClick={() => {
                    setFieldValue('developer', false)
                    handleSubmit()
                  }}
                />
              </div>
            )}
          </form>
        </FormWrapper>
      )}
    </Formik>
  )
}

const ForgotPasswordForm: React.FC<{}> = props => {
  const { sendMagicLogIn } = useUserContext()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <Formik<SendMagicLogInInput>
      initialValues={{
        email: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await sendMagicLogIn(values)

          showSuccessNotification({
            description: 'If you have an account you have been sent a reset password email',
          })
        } catch (error) {
          console.log('error', error)
          showErrorNotification({
            description: formatGraphQLError(error),
          })
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <FormWrapper>
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <Field
              name="email"
              label="Email"
              type="email"
              id="email"
              required
              component={CustomInputComponent}
            />

            <SubmitButton
              text={'Reset Password'}
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
            />

            <ExtraLinksWrapper>
              <ExtraLink text="Register" to="/sign-up" />
              <ExtraLink text="Log in" to="/log-in" />
            </ExtraLinksWrapper>
          </form>
        </FormWrapper>
      )}
    </Formik>
  )
}

const UploadComponentText: React.FC<{}> = props => {
  return (
    <div className="text-white text-3xl font-light leading-relaxed lg:p-16 xl:p-24 space-y-10">
      <p>Publish your frontend components and get paid 70% of every sale.</p>
      <p>
        Write code in your frontend framework of choice. Sell HTML, React, Vue, Angular, Svelte,
        React Native, or Flutter components in the FrontWork marketplace.
      </p>
    </div>
  )
}

const PurchaseComponentText: React.FC<{}> = props => {
  return (
    <section className="text-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
        <svg
          className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
          width={784}
          height={404}
          fill="none"
          viewBox="0 0 784 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
        </svg>

        <div className="relative lg:flex lg:items-center">
          <div className="relative lg:ml-10">
            <svg
              className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 144 144"
              aria-hidden="true"
            >
              <path
                strokeWidth={2}
                d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
              />
            </svg>
            <blockquote className="relative">
              <div className="text-2xl leading-9 font-medium pxx-10">
                <p>
                  Using the FrontWork component marketplace saved us a ton of time on our project.
                  We'll be using it on a consistent basis moving forward.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="flex-shrink-0 lg:hidden">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-4 lg:ml-0 text-indigo-50">
                    <div className="text-base font-medium">Jason Wollicki</div>
                    <div className="text-base font-medium">CTO, CyberX</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  )
}
