import React from 'react'
import ReactCompareImage from 'react-compare-image'

interface ImageCompareProps {}

export const ImageCompare: React.FC<ImageCompareProps> = props => {
  return (
    <div className="p-20">
      <ReactCompareImage
        // leftImage="https://res.cloudinary.com/dz209s6jk/image/upload/q_auto,w_900/Screenshots/cbvlomfcde4y8ihz3uvu.jpg"
        // rightImage="https://res.cloudinary.com/dz209s6jk/image/upload/q_auto,w_900/Challenges/d3zuxyz7uggvmjtk895p.jpg"
        leftImage='https://dl.airtable.com/.attachmentThumbnails/49ea7d84b29bd1a17538874906b550ea/33585c33'
        rightImage='https://res.cloudinary.com/elie-tech/image/upload/v1598020489/frontwork-local/7:10.png'
      />
    </div>
  )
}
