import React from 'react'
import { BidStatus } from '../generated/graphql'

interface BidResultProps {
  status?: BidStatus
}

export const BidResult: React.FC<BidResultProps> = props => {
  if (props.status === BidStatus.Accepted)
    return <div className=" text-green-500">Bid Accepted!</div>

  if (props.status === BidStatus.Rejected) return <div className=" text-red-500">Bid Rejected</div>

  return null
}
