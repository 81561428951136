import React from 'react'
import { useHistory } from 'react-router-dom'
import { MessagesSlideOver } from '../components/Messages'

interface Context {
  setOpen: (otherUserId: string) => void
}

const defaultContextValue: Context = {
  setOpen: () => {},
}

export const MessageContext = React.createContext<Context>(defaultContextValue)

export const useMessageContext = () => React.useContext(MessageContext)

export const MessageProvider: React.FC<{}> = ({ children }) => {
  const [state, setState] = React.useState<{ open: boolean; otherUserId?: string }>({
    open: false,
    otherUserId: undefined,
  })

  const { listen, location } = useHistory()

  React.useEffect(() => {
    const openConversation = (location: { search: string }) => {
      const search = new URLSearchParams(location.search)
      const queryConversationUserId = search.get('conversationUserId')

      if (queryConversationUserId) setState({ open: true, otherUserId: queryConversationUserId })
    }

    // for first page load
    openConversation(location)

    const unlisten = listen(location => {
      openConversation(location)
    })

    return unlisten
  }, [listen, location])

  const setOpen = React.useCallback((otherUserId: string) => {
    setState({ open: true, otherUserId })
  }, [])

  return (
    <MessageContext.Provider value={{ setOpen }}>
      {children}
      <MessagesSlideOver
        open={state.open}
        otherUserId={state.otherUserId || ''}
        onClose={() => {
          setState({ open: false, otherUserId: undefined })
        }}
      />
    </MessageContext.Provider>
  )
}
