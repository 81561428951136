import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string | Date;
};

export type Screen = {
  id: Scalars['ID'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  projectId: Scalars['String'];
  project: Project;
  componentType: ComponentType;
  cloudinaryUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  commentsCount: Scalars['Int'];
};

export enum ComponentType {
  Component = 'COMPONENT',
  Screen = 'SCREEN'
}

export type FigmaScreen = Screen & {
  __typename?: 'FigmaScreen';
  id: Scalars['ID'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  projectId: Scalars['String'];
  project: Project;
  componentType: ComponentType;
  cloudinaryUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  commentsCount: Scalars['Int'];
  figmaId: Scalars['String'];
  figmaPageId: Scalars['String'];
  figmaOriginalUrl: Scalars['String'];
};

export type ZeplinScreen = Screen & {
  __typename?: 'ZeplinScreen';
  id: Scalars['ID'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  projectId: Scalars['String'];
  project: Project;
  componentType: ComponentType;
  cloudinaryUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  commentsCount: Scalars['Int'];
  zeplinCreated: Scalars['Int'];
  zeplinUpdated: Scalars['Int'];
  zeplinNumberOfVersions?: Maybe<Scalars['Int']>;
  zeplinNumberOfNotes?: Maybe<Scalars['Int']>;
  width: Scalars['Int'];
  height: Scalars['Int'];
  zeplinId: Scalars['String'];
  zeplinOriginalUrl: Scalars['String'];
};

export type ImageScreen = Screen & {
  __typename?: 'ImageScreen';
  id: Scalars['ID'];
  name: Scalars['String'];
  originalName: Scalars['String'];
  projectId: Scalars['String'];
  project: Project;
  componentType: ComponentType;
  cloudinaryUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Comment>>;
  commentsCount: Scalars['Int'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  creatorId: Scalars['String'];
  creator: User;
  name: Scalars['String'];
  status: ProjectStatus;
  overview?: Maybe<Scalars['String']>;
  type?: Maybe<ProjectType>;
  designApp?: Maybe<DesignApp>;
  zeplinProjectId?: Maybe<Scalars['String']>;
  zeplinScreens?: Maybe<Array<ZeplinScreen>>;
  figmaFileId?: Maybe<Scalars['String']>;
  figmaScreens?: Maybe<Array<FigmaScreen>>;
  imageScreens?: Maybe<Array<ImageScreen>>;
  designUrl?: Maybe<Scalars['String']>;
  frontendFramework?: Maybe<FrontendFrameworkProject>;
  programmingLanguage?: Maybe<ProgrammingLanguageProject>;
  styling?: Maybe<StylingLibraryProject>;
  deadline?: Maybe<Scalars['DateTime']>;
  expiration?: Maybe<Scalars['DateTime']>;
  contractType: ContractType;
  englishLevelRequired?: Maybe<Scalars['Float']>;
  prettierConfig?: Maybe<Scalars['String']>;
  eslintConfig?: Maybe<Scalars['String']>;
  interviewBidProject?: Maybe<Scalars['Boolean']>;
  interviewDevProject?: Maybe<Scalars['Boolean']>;
  interviewActiveBidProject?: Maybe<Scalars['Boolean']>;
  interviewActiveDevProject?: Maybe<Scalars['Boolean']>;
  projects: Array<DeployedProject>;
  bid?: Maybe<Bid>;
  bids?: Maybe<Array<Bid>>;
  deployedProject?: Maybe<DeployedProject>;
};


export enum ProjectStatus {
  Draft = 'DRAFT',
  Searching = 'SEARCHING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED'
}

export enum ProjectType {
  Storybook = 'STORYBOOK',
  Component = 'COMPONENT',
  LandingPage = 'LANDING_PAGE',
  AppUi = 'APP_UI'
}

export enum DesignApp {
  Figma = 'FIGMA',
  Zeplin = 'ZEPLIN',
  Sketch = 'SKETCH',
  Xd = 'XD',
  Photoshop = 'PHOTOSHOP',
  Framer = 'FRAMER',
  Other = 'OTHER'
}

export enum FrontendFrameworkProject {
  React = 'REACT',
  Vue = 'VUE',
  Angular = 'ANGULAR',
  ReactNative = 'REACT_NATIVE',
  ReactNativeWeb = 'REACT_NATIVE_WEB',
  VanillaHtml = 'VANILLA_HTML',
  Svelte = 'SVELTE',
  Wordpress = 'WORDPRESS',
  Other = 'OTHER',
  DevelopersChoice = 'DEVELOPERS_CHOICE'
}

export enum ProgrammingLanguageProject {
  Javascript = 'JAVASCRIPT',
  Typescript = 'TYPESCRIPT',
  Flow = 'FLOW',
  VanillaHtml = 'VANILLA_HTML',
  DevelopersChoice = 'DEVELOPERS_CHOICE'
}

export enum StylingLibraryProject {
  Css = 'CSS',
  Sass = 'SASS',
  Less = 'LESS',
  StyledComponents = 'STYLED_COMPONENTS',
  Emotion = 'EMOTION',
  Tailwind = 'TAILWIND',
  Other = 'OTHER',
  DevelopersChoice = 'DEVELOPERS_CHOICE'
}

export enum ContractType {
  FixedPrice = 'FIXED_PRICE',
  FixedPriceThenHourly = 'FIXED_PRICE_THEN_HOURLY',
  Hourly = 'HOURLY'
}

export type DeployedProject = {
  __typename?: 'DeployedProject';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  developer: Developer;
  githubUrl?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  githubRepoName?: Maybe<Scalars['String']>;
  developerGithubUsername?: Maybe<Scalars['String']>;
  netlifyUrl?: Maybe<Scalars['String']>;
  clientRating?: Maybe<Scalars['Float']>;
  parentProjectId: Scalars['String'];
  parentProject: Project;
  developerId: Scalars['String'];
  projectType?: Maybe<Scalars['String']>;
  clientRecommendRating?: Maybe<Scalars['Float']>;
  clientCodeRating?: Maybe<Scalars['Float']>;
  clientUiRating?: Maybe<Scalars['Float']>;
  clientCommunicationRating?: Maybe<Scalars['Float']>;
  clientReview?: Maybe<Scalars['String']>;
  clientPublicReview?: Maybe<Scalars['Boolean']>;
  completedDate?: Maybe<Scalars['DateTime']>;
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  frontendFramework?: Maybe<FrontendFrameworkProject>;
  programmingLanguage?: Maybe<ProgrammingLanguageProject>;
  styling?: Maybe<StylingLibraryProject>;
  clientRating?: Maybe<Scalars['Float']>;
  clientReview?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['DateTime']>;
};

export type Bid = {
  __typename?: 'Bid';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  developerAmount: Scalars['Int'];
  clientAmount: Scalars['Int'];
  developerHourlyRate?: Maybe<Scalars['Int']>;
  clientHourlyRate?: Maybe<Scalars['Int']>;
  developerId: Scalars['String'];
  developer: User;
  projectId: Scalars['String'];
  project: Project;
  status: BidStatus;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  interview?: Maybe<Scalars['Boolean']>;
  conversationId: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  amount: Scalars['Float'];
  hourlyRate?: Maybe<Scalars['Float']>;
};

export enum BidStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  user: User;
  read?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  bidId?: Maybe<Scalars['String']>;
  bid?: Maybe<Bid>;
  queryString?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
};

export enum NotificationType {
  Bid = 'BID',
  ScreenComment = 'SCREEN_COMMENT',
  Message = 'MESSAGE'
}

export type CountryFull = {
  __typename?: 'CountryFull';
  name: Scalars['String'];
  enum: Country;
  code?: Maybe<Scalars['String']>;
};

export enum Country {
  Albania = 'ALBANIA',
  Algeria = 'ALGERIA',
  AmericanSamoa = 'AMERICAN_SAMOA',
  Andorra = 'ANDORRA',
  Angola = 'ANGOLA',
  Anguilla = 'ANGUILLA',
  Antarctica = 'ANTARCTICA',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Argentina = 'ARGENTINA',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Australia = 'AUSTRALIA',
  Austria = 'AUSTRIA',
  Azerbaijan = 'AZERBAIJAN',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Barbados = 'BARBADOS',
  Belarus = 'BELARUS',
  Belgium = 'BELGIUM',
  Belize = 'BELIZE',
  Benin = 'BENIN',
  Bermuda = 'BERMUDA',
  Bhutan = 'BHUTAN',
  Bolivia = 'BOLIVIA',
  BosniaHerzegovina = 'BOSNIA_HERZEGOVINA',
  Botswana = 'BOTSWANA',
  BouvetIsland = 'BOUVET_ISLAND',
  Brazil = 'BRAZIL',
  BritishIndianOceanTerritory = 'BRITISH_INDIAN_OCEAN_TERRITORY',
  BruneiDarussalam = 'BRUNEI_DARUSSALAM',
  Bulgaria = 'BULGARIA',
  BurkinaFaso = 'BURKINA_FASO',
  Burundi = 'BURUNDI',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  Canada = 'CANADA',
  CapeVerde = 'CAPE_VERDE',
  CaymanIslands = 'CAYMAN_ISLANDS',
  CentralAfricanRepublic = 'CENTRAL_AFRICAN_REPUBLIC',
  Chad = 'CHAD',
  Chile = 'CHILE',
  China = 'CHINA',
  ChristmasIsland = 'CHRISTMAS_ISLAND',
  CocosKeelingIslands = 'COCOS_KEELING_ISLANDS',
  Comoros = 'COMOROS',
  Congo = 'CONGO',
  CongoBrazzaville = 'CONGO_BRAZZAVILLE',
  CookIslands = 'COOK_ISLANDS',
  CostaRica = 'COSTA_RICA',
  Croatia = 'CROATIA',
  Curacao = 'CURACAO',
  Cyprus = 'CYPRUS',
  CzechRepublic = 'CZECH_REPUBLIC',
  Denmark = 'DENMARK',
  Djibouti = 'DJIBOUTI',
  Dominica = 'DOMINICA',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  EastTimor = 'EAST_TIMOR',
  Ecuador = 'ECUADOR',
  Egypt = 'EGYPT',
  ElSalvador = 'EL_SALVADOR',
  EquatorialGuinea = 'EQUATORIAL_GUINEA',
  Eritrea = 'ERITREA',
  Estonia = 'ESTONIA',
  Ethiopia = 'ETHIOPIA',
  FalklandIslands = 'FALKLAND_ISLANDS',
  FaroeIslands = 'FAROE_ISLANDS',
  Fiji = 'FIJI',
  Finland = 'FINLAND',
  FormerUssr = 'FORMER_USSR',
  France = 'FRANCE',
  FranceEuropeanTerritory = 'FRANCE_EUROPEAN_TERRITORY',
  FrenchGuiana = 'FRENCH_GUIANA',
  FrenchSouthernTerritories = 'FRENCH_SOUTHERN_TERRITORIES',
  Gabon = 'GABON',
  Gambia = 'GAMBIA',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  Ghana = 'GHANA',
  Gibraltar = 'GIBRALTAR',
  GreatBritain = 'GREAT_BRITAIN',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Grenada = 'GRENADA',
  GuadeloupeFrench = 'GUADELOUPE_FRENCH',
  GuamUsa = 'GUAM_USA',
  Guatemala = 'GUATEMALA',
  Guernsey = 'GUERNSEY',
  Guinea = 'GUINEA',
  GuineaBissau = 'GUINEA_BISSAU',
  Guyana = 'GUYANA',
  Haiti = 'HAITI',
  HeardAndMcDonaldIslands = 'HEARD_AND_MC_DONALD_ISLANDS',
  HolySeeVaticanCityState = 'HOLY_SEE_VATICAN_CITY_STATE',
  Honduras = 'HONDURAS',
  HongKong = 'HONG_KONG',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  India = 'INDIA',
  Indonesia = 'INDONESIA',
  Ireland = 'IRELAND',
  IsleOfMan = 'ISLE_OF_MAN',
  Israel = 'ISRAEL',
  Italy = 'ITALY',
  IvoryCoast = 'IVORY_COAST',
  Jamaica = 'JAMAICA',
  Japan = 'JAPAN',
  Jersey = 'JERSEY',
  Jordan = 'JORDAN',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kiribati = 'KIRIBATI',
  Kuwait = 'KUWAIT',
  KyrgyzRepublicKyrgyzstan = 'KYRGYZ_REPUBLIC_KYRGYZSTAN',
  Laos = 'LAOS',
  Latvia = 'LATVIA',
  Lesotho = 'LESOTHO',
  Liberia = 'LIBERIA',
  Liechtenstein = 'LIECHTENSTEIN',
  Lithuania = 'LITHUANIA',
  Luxembourg = 'LUXEMBOURG',
  Macao = 'MACAO',
  Macedonia = 'MACEDONIA',
  Madagascar = 'MADAGASCAR',
  Malawi = 'MALAWI',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Mali = 'MALI',
  Malta = 'MALTA',
  MarshallIslands = 'MARSHALL_ISLANDS',
  MartiniqueFrench = 'MARTINIQUE_FRENCH',
  Mauritania = 'MAURITANIA',
  Mauritius = 'MAURITIUS',
  Mayotte = 'MAYOTTE',
  Mexico = 'MEXICO',
  Micronesia = 'MICRONESIA',
  Moldova = 'MOLDOVA',
  Monaco = 'MONACO',
  Mongolia = 'MONGOLIA',
  Montenegro = 'MONTENEGRO',
  Montserrat = 'MONTSERRAT',
  Morocco = 'MOROCCO',
  Mozambique = 'MOZAMBIQUE',
  Namibia = 'NAMIBIA',
  Nauru = 'NAURU',
  Nepal = 'NEPAL',
  Netherlands = 'NETHERLANDS',
  NetherlandsAntilles = 'NETHERLANDS_ANTILLES',
  NeutralZone = 'NEUTRAL_ZONE',
  NewCaledoniaFrench = 'NEW_CALEDONIA_FRENCH',
  NewZealand = 'NEW_ZEALAND',
  Nicaragua = 'NICARAGUA',
  Niger = 'NIGER',
  Nigeria = 'NIGERIA',
  Niue = 'NIUE',
  NorfolkIsland = 'NORFOLK_ISLAND',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Palau = 'PALAU',
  Palestine = 'PALESTINE',
  Panama = 'PANAMA',
  PapuaNewGuinea = 'PAPUA_NEW_GUINEA',
  Paraguay = 'PARAGUAY',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  PitcairnIsland = 'PITCAIRN_ISLAND',
  Poland = 'POLAND',
  PolynesiaFrench = 'POLYNESIA_FRENCH',
  Portugal = 'PORTUGAL',
  PuertoRico = 'PUERTO_RICO',
  Qatar = 'QATAR',
  ReunionFrench = 'REUNION_FRENCH',
  Romania = 'ROMANIA',
  RussianFederation = 'RUSSIAN_FEDERATION',
  Rwanda = 'RWANDA',
  SGeorgiaSSandwichIsls = 'S_GEORGIA_S_SANDWICH_ISLS',
  SaintHelena = 'SAINT_HELENA',
  SaintKittsNevisAnguilla = 'SAINT_KITTS_NEVIS_ANGUILLA',
  SaintLucia = 'SAINT_LUCIA',
  SaintMartin = 'SAINT_MARTIN',
  SaintPierreAndMiquelon = 'SAINT_PIERRE_AND_MIQUELON',
  SaintTomeSaoTomeAndPrincipe = 'SAINT_TOME_SAO_TOME_AND_PRINCIPE',
  SaintVincentGrenadines = 'SAINT_VINCENT_GRENADINES',
  Samoa = 'SAMOA',
  SanMarino = 'SAN_MARINO',
  SaudiArabia = 'SAUDI_ARABIA',
  Senegal = 'SENEGAL',
  Serbia = 'SERBIA',
  Seychelles = 'SEYCHELLES',
  SierraLeone = 'SIERRA_LEONE',
  Singapore = 'SINGAPORE',
  SintMaarten = 'SINT_MAARTEN',
  SlovakRepublic = 'SLOVAK_REPUBLIC',
  Slovenia = 'SLOVENIA',
  SolomonIslands = 'SOLOMON_ISLANDS',
  Somalia = 'SOMALIA',
  SouthAfrica = 'SOUTH_AFRICA',
  SouthKorea = 'SOUTH_KOREA',
  Spain = 'SPAIN',
  SriLanka = 'SRI_LANKA',
  Suriname = 'SURINAME',
  SvalbardAndJanMayenIslands = 'SVALBARD_AND_JAN_MAYEN_ISLANDS',
  Swaziland = 'SWAZILAND',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Tadjikistan = 'TADJIKISTAN',
  Taiwan = 'TAIWAN',
  Tanzania = 'TANZANIA',
  Thailand = 'THAILAND',
  Togo = 'TOGO',
  Tokelau = 'TOKELAU',
  Tonga = 'TONGA',
  TrinidadAndTobago = 'TRINIDAD_AND_TOBAGO',
  Tunisia = 'TUNISIA',
  Turkey = 'TURKEY',
  Turkmenistan = 'TURKMENISTAN',
  TurksAndCaicosIslands = 'TURKS_AND_CAICOS_ISLANDS',
  Tuvalu = 'TUVALU',
  Uganda = 'UGANDA',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES',
  Uruguay = 'URUGUAY',
  UsaMinorOutlyingIslands = 'USA_MINOR_OUTLYING_ISLANDS',
  Uzbekistan = 'UZBEKISTAN',
  Vanuatu = 'VANUATU',
  Venezuela = 'VENEZUELA',
  Vietnam = 'VIETNAM',
  VirginIslandsBritish = 'VIRGIN_ISLANDS_BRITISH',
  VirginIslandsUsa = 'VIRGIN_ISLANDS_USA',
  WallisAndFutunaIslands = 'WALLIS_AND_FUTUNA_ISLANDS',
  WesternSahara = 'WESTERN_SAHARA',
  Zambia = 'ZAMBIA',
  Zimbabwe = 'ZIMBABWE'
}

export type Interview = {
  __typename?: 'Interview';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  developer: Developer;
  developerId: Scalars['String'];
  bidProjectId: Scalars['String'];
  bidProject: Project;
  devProjectId: Scalars['String'];
  devProject: Project;
  devProjectStatus: InterviewStatus;
  videoResponseStatus: VideoReponseStatus;
  completedBidProject: Scalars['Boolean'];
};

export enum InterviewStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Passed = 'PASSED'
}

export enum VideoReponseStatus {
  NotSubmitted = 'NOT_SUBMITTED',
  Submitted = 'SUBMITTED'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  programmingLanguages?: Maybe<Array<ProgrammingLanguage>>;
  frontendFrameworks?: Maybe<Array<FrontendFramework>>;
  stylingLibraries?: Maybe<Array<StylingLibrary>>;
  country?: Maybe<Country>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  githubUsername?: Maybe<Scalars['String']>;
  githubAppUsername?: Maybe<Scalars['String']>;
  storybookLevel?: Maybe<Scalars['Int']>;
  uiLevel?: Maybe<Scalars['Int']>;
  figmaLevel?: Maybe<Scalars['Int']>;
  hourlyRateUsd?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  developer: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  interviewStatus: InterviewStatus;
  notifications: Array<Notification>;
  accountStatus?: Maybe<AccountStatus>;
  admin?: Maybe<Scalars['Boolean']>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  componentCredits?: Maybe<Scalars['Float']>;
  componentCreditsPerMonth?: Maybe<Scalars['Float']>;
  componentSubscriptionPlan?: Maybe<SubscriptionPlan>;
  nextComponentCreditReset?: Maybe<Scalars['DateTime']>;
  entityType?: Maybe<EntityType>;
  businessName?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vendorPrincipalDateOfBirth?: Maybe<Scalars['String']>;
  vendorPrincipalPersonalIdentificationNumber?: Maybe<Scalars['String']>;
  vendorDriverLicenseNumber?: Maybe<Scalars['String']>;
  vendorPassportNumber?: Maybe<Scalars['String']>;
  vendorAgreementCommissionPercent?: Maybe<Scalars['Float']>;
  payoutType?: Maybe<PayoutType>;
  baseCurrency?: Maybe<Currency>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCountry?: Maybe<Country>;
  bankState?: Maybe<Scalars['String']>;
  bankCity?: Maybe<Scalars['String']>;
  bankZip?: Maybe<Scalars['String']>;
  bankNameOnAccount?: Maybe<Scalars['String']>;
  bankAccountClass?: Maybe<BankAccountClass>;
  bankAccountType?: Maybe<BankAccountType>;
  bankId?: Maybe<Scalars['String']>;
  swiftBic?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  minimalPayoutAmount?: Maybe<Scalars['Float']>;
  refundReserve?: Maybe<Scalars['Float']>;
  blueSnapVendorId?: Maybe<Scalars['String']>;
  testAccount?: Maybe<Scalars['Boolean']>;
  heardAbout?: Maybe<Scalars['String']>;
  introVideoUrl?: Maybe<Scalars['String']>;
  portfolio?: Maybe<Scalars['String']>;
  submittedQuickApply?: Maybe<Scalars['Boolean']>;
  updatedAtYearsOfExperience?: Maybe<Scalars['DateTime']>;
  yearsOfExperience?: Maybe<Scalars['Float']>;
  yearsOfExperienceReact?: Maybe<Scalars['Float']>;
  yearsOfExperienceReactNative?: Maybe<Scalars['Float']>;
  yearsOfExperienceVue?: Maybe<Scalars['Float']>;
  yearsOfExperienceAngular?: Maybe<Scalars['Float']>;
  yearsOfExperienceSvelte?: Maybe<Scalars['Float']>;
  yearsOfExperienceWordpress?: Maybe<Scalars['Float']>;
  yearsOfExperienceJavascript?: Maybe<Scalars['Float']>;
  yearsOfExperienceTypescript?: Maybe<Scalars['Float']>;
  yearsOfExperienceFlow?: Maybe<Scalars['Float']>;
  manualPaymentClient?: Maybe<Scalars['Boolean']>;
  developerProgress?: Maybe<DeveloperProgress>;
  countryCode?: Maybe<Scalars['String']>;
  addressSet?: Maybe<Scalars['Boolean']>;
};

export enum ProgrammingLanguage {
  Javascript = 'JAVASCRIPT',
  Typescript = 'TYPESCRIPT',
  Flow = 'FLOW'
}

export enum FrontendFramework {
  React = 'REACT',
  Vue = 'VUE',
  Angular = 'ANGULAR',
  ReactNative = 'REACT_NATIVE',
  Svelte = 'SVELTE',
  Wordpress = 'WORDPRESS'
}

export enum StylingLibrary {
  Css = 'CSS',
  Sass = 'SASS',
  Less = 'LESS',
  StyledComponents = 'STYLED_COMPONENTS',
  Emotion = 'EMOTION',
  Tailwind = 'TAILWIND',
  Other = 'OTHER'
}

export enum AccountStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export enum SubscriptionPlan {
  Standard = 'STANDARD',
  Unlimited = 'UNLIMITED'
}

export enum EntityType {
  Individual = 'INDIVIDUAL',
  Business = 'BUSINESS'
}

export enum PayoutType {
  Wire = 'WIRE'
}

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Jpy = 'JPY',
  Nok = 'NOK',
  Nzd = 'NZD',
  Sek = 'SEK',
  Usd = 'USD'
}

export enum BankAccountClass {
  Personal = 'PERSONAL',
  Corporate = 'CORPORATE',
  International = 'INTERNATIONAL'
}

export enum BankAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS'
}

export enum DeveloperProgress {
  Welcome = 'WELCOME',
  SkillSet = 'SKILL_SET',
  Project = 'PROJECT',
  Assessment = 'ASSESSMENT',
  Congratulations = 'CONGRATULATIONS',
  Passed = 'PASSED',
  Failed = 'FAILED'
}

export type Developer = {
  __typename?: 'Developer';
  id: Scalars['ID'];
  programmingLanguages?: Maybe<Array<ProgrammingLanguage>>;
  frontendFrameworks?: Maybe<Array<FrontendFramework>>;
  stylingLibraries?: Maybe<Array<StylingLibrary>>;
  username?: Maybe<Scalars['String']>;
  portfolio?: Maybe<Scalars['String']>;
  yearsOfExperience?: Maybe<Scalars['Float']>;
  yearsOfExperienceReact?: Maybe<Scalars['Float']>;
  yearsOfExperienceReactNative?: Maybe<Scalars['Float']>;
  yearsOfExperienceVue?: Maybe<Scalars['Float']>;
  yearsOfExperienceAngular?: Maybe<Scalars['Float']>;
  yearsOfExperienceSvelte?: Maybe<Scalars['Float']>;
  yearsOfExperienceWordpress?: Maybe<Scalars['Float']>;
  yearsOfExperienceJavascript?: Maybe<Scalars['Float']>;
  yearsOfExperienceTypescript?: Maybe<Scalars['Float']>;
  yearsOfExperienceFlow?: Maybe<Scalars['Float']>;
  interviewDeployedProject?: Maybe<DeployedProject>;
  githubUsername?: Maybe<Scalars['String']>;
  githubAppUsername?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
};

export type VendorDetails = {
  __typename?: 'VendorDetails';
  vendorId: Scalars['String'];
  accountStatus: Scalars['String'];
  processingStatus: Scalars['String'];
  payoutStatus: Scalars['String'];
  lastPayoutDate: Scalars['String'];
  payoutCurrency: Scalars['String'];
  lastPayoutAmount: Scalars['String'];
  accountBalance: Scalars['String'];
};

export type GitHubRepo = {
  __typename?: 'GitHubRepo';
  full_name: Scalars['String'];
};

export type GitHubRepoList = {
  __typename?: 'GitHubRepoList';
  repositories: Array<GitHubRepo>;
  totalCount: Scalars['Int'];
};

export type GitHubTreeNode = {
  __typename?: 'GitHubTreeNode';
  url?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  type: Scalars['String'];
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  text: Scalars['String'];
  userId: Scalars['String'];
  user: User;
  conversationId?: Maybe<Scalars['String']>;
  parentCommentId?: Maybe<Scalars['String']>;
  parentComment?: Maybe<Comment>;
  childComments?: Maybe<Array<Comment>>;
  screenId?: Maybe<Scalars['String']>;
  screen?: Maybe<Screen>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  annotationType?: Maybe<AnnotationType>;
  creatorUsername: Scalars['String'];
  isCreator: Scalars['Boolean'];
};

export enum AnnotationType {
  Rectangle = 'RECTANGLE',
  Point = 'POINT',
  Oval = 'OVAL'
}

export type PaypalPayment = {
  __typename?: 'PaypalPayment';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  paypalId: Scalars['String'];
  paypalPayerId: Scalars['String'];
  paypalPayerCountryCode: Scalars['String'];
  paypalStatus: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
  bidId: Scalars['String'];
  bid: Bid;
  userId: Scalars['String'];
  user: User;
};

export type Component = {
  __typename?: 'Component';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  programmingLanguages?: Maybe<Array<ProgrammingLanguage>>;
  frontendFrameworks?: Maybe<Array<FrontendFramework>>;
  stylingLibraries?: Maybe<Array<StylingLibrary>>;
  componentScreenshots?: Maybe<Array<ComponentScreenshot>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  user: User;
  demoUrl?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  daysToDeliver?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  categories: Array<ComponentCategory>;
  allGitHubRepoFiles?: Maybe<Scalars['Boolean']>;
  approvalStatus?: Maybe<ComponentApprovalStatus>;
  rating?: Maybe<Scalars['Float']>;
  thumbnail?: Maybe<Scalars['String']>;
  primaryCategory?: Maybe<ComponentCategory>;
  isCreator: Scalars['Boolean'];
  creatorUsername: Scalars['String'];
  deliveryUrl?: Maybe<Scalars['String']>;
  gitHubInstallationId?: Maybe<Scalars['Int']>;
  gitHubRepo?: Maybe<Scalars['String']>;
  files: Array<ComponentFile>;
  hasAccess: Scalars['Boolean'];
};

export enum ComponentApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ComponentScreenshot = {
  __typename?: 'ComponentScreenshot';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  componentId: Scalars['String'];
  component: Component;
};

export type ComponentCategory = {
  __typename?: 'ComponentCategory';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  components: Array<Component>;
};

export type ComponentFile = {
  __typename?: 'ComponentFile';
  id: Scalars['ID'];
  version?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  path: Scalars['String'];
  componentId: Scalars['String'];
  component: Component;
};

export type Query = {
  __typename?: 'Query';
  getUser: User;
  developer: Developer;
  adminUser: User;
  project: Project;
  projects: Array<Project>;
  openProjects: Array<Project>;
  developerActiveProjects: Array<Project>;
  developerPastProjects: Array<Project>;
  interviewDevProject?: Maybe<Project>;
  interview?: Maybe<Interview>;
  developerBids: Array<Bid>;
  conversationBids: Array<Bid>;
  bidPayment: Scalars['String'];
  repos: GitHubRepoList;
  repoContents: Array<GitHubTreeNode>;
  comments: Array<Comment>;
  messages: Array<Comment>;
  vendorDetails?: Maybe<VendorDetails>;
  getEmbeddedCheckoutToken: Scalars['String'];
  notification: Notification;
  notifications: Array<Notification>;
  publicComponent: Component;
  component: Component;
  publicComponents: Array<Component>;
  components: Array<Component>;
  allComponents: Array<Component>;
  developerComponents: Array<Component>;
  purchasedComponents: Array<Component>;
  componentCategories: Array<ComponentCategory>;
  repoZipLink: Scalars['String'];
  pastPayments: Array<PaypalPayment>;
  countries: Array<CountryFull>;
};


export type QueryDeveloperArgs = {
  id: Scalars['ID'];
};


export type QueryAdminUserArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryConversationBidsArgs = {
  otherUserId: Scalars['ID'];
};


export type QueryBidPaymentArgs = {
  bidId: Scalars['String'];
};


export type QueryReposArgs = {
  page?: Maybe<Scalars['Float']>;
};


export type QueryRepoContentsArgs = {
  fullRepoName: Scalars['String'];
};


export type QueryCommentsArgs = {
  screenId: Scalars['ID'];
};


export type QueryMessagesArgs = {
  otherUserId: Scalars['ID'];
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryPublicComponentArgs = {
  id: Scalars['ID'];
};


export type QueryComponentArgs = {
  id: Scalars['ID'];
};


export type QueryPublicComponentsArgs = {
  minRating?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryAllComponentsArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryRepoZipLinkArgs = {
  componentId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  submitQuickApply: User;
  landingPageSubscribe: Scalars['Boolean'];
  updateUserHeardAbout: User;
  updateIntroVideo: User;
  updatePortfolio: User;
  updateHourlyRate: User;
  updateDeveloperSkills: User;
  updateDeveloperExperience: User;
  updateDeveloperTools: User;
  updateDeveloperPersonal: User;
  updateDeveloperPaymentDetails: User;
  updatePublicProfile: User;
  updatePersonalProfile: User;
  updateGithubUsername: User;
  updateGithubAppUsername: User;
  unsetGithubUsername: User;
  unsetGithubAppUsername: User;
  changeAdminStatus: User;
  changeAccountStatus: User;
  changeInterviewStatus: User;
  inviteToQuickApply: Scalars['Boolean'];
  updateAllAirtableDevelopers: Scalars['Boolean'];
  adminDeleteUser: Scalars['Boolean'];
  gradeInterview: User;
  createProjectOverview: Project;
  createProjectZeplin: Project;
  createProjectFigma: Project;
  createProjectImage: Project;
  createProjectAdvanced: Project;
  updateProject: Project;
  deleteProject: Scalars['Boolean'];
  adminDeleteProject: Scalars['Boolean'];
  publishProject: Project;
  completeProject: Project;
  unpublishProject: Project;
  createInterviewDeployedProject: DeployedProject;
  deleteDeployedProject: Scalars['Boolean'];
  deleteGitHubProject: DeployedProject;
  adminDeleteRemoteProject: Scalars['Boolean'];
  unstartProject: Project;
  createInterview: Interview;
  submitInterview: Interview;
  completeVideoInterview: Interview;
  createBid: Bid;
  editBid: Bid;
  deleteBid: Scalars['Boolean'];
  respondToBid: Bid;
  editScreen: Screen;
  createComment: Comment;
  editComment: Comment;
  deleteComment: Scalars['Boolean'];
  completeTokenizedPayment: Scalars['Boolean'];
  markNotificationAsRead: Notification;
  logIn: User;
  sendMagicLogIn: Scalars['Boolean'];
  magicLogIn: User;
  signUp: User;
  updatePassword: Scalars['Boolean'];
  logOut: Scalars['Boolean'];
  createComponent: Component;
  editComponent: Component;
  deleteComponent: Scalars['Boolean'];
  unlockComponent: Component;
  rateComponent: Component;
  seedComponentCategories: Array<ComponentCategory>;
  adminSetComponentApprovalStatus: Component;
  submitPaypalPayment: PaypalPayment;
  createStripeAccount: Scalars['String'];
  createStripeDashboardLink: Scalars['String'];
  createStripeCheckoutSession: Scalars['String'];
  createCheckoutSubscriptionSession: Scalars['String'];
};


export type MutationLandingPageSubscribeArgs = {
  data: LandingPageSubscribeInput;
};


export type MutationUpdateUserHeardAboutArgs = {
  data: UpdateUserHeardAboutInput;
};


export type MutationUpdateIntroVideoArgs = {
  data: UpdateIntroVideoInput;
};


export type MutationUpdatePortfolioArgs = {
  data: UpdatePortfolioInput;
};


export type MutationUpdateHourlyRateArgs = {
  data: UpdateHourlyRateInput;
};


export type MutationUpdateDeveloperSkillsArgs = {
  data: UpdateDeveloperSkillsInput;
};


export type MutationUpdateDeveloperExperienceArgs = {
  data: UpdateDeveloperExperienceInput;
};


export type MutationUpdateDeveloperToolsArgs = {
  data: UpdateDeveloperToolsInput;
};


export type MutationUpdateDeveloperPersonalArgs = {
  data: UpdateDeveloperPersonalInput;
};


export type MutationUpdateDeveloperPaymentDetailsArgs = {
  data: UpdateDeveloperPaymentDetailsInput;
};


export type MutationUpdatePublicProfileArgs = {
  data: UpdatePublicProfileInput;
};


export type MutationUpdatePersonalProfileArgs = {
  data: UpdatePersonalProfileInput;
};


export type MutationUpdateGithubUsernameArgs = {
  githubAccessToken: Scalars['String'];
};


export type MutationUpdateGithubAppUsernameArgs = {
  githubInstallationId: Scalars['Int'];
  githubAccessToken: Scalars['String'];
};


export type MutationChangeAdminStatusArgs = {
  admin: Scalars['Boolean'];
};


export type MutationChangeAccountStatusArgs = {
  code?: Maybe<Scalars['String']>;
  accountStatus: Scalars['Boolean'];
};


export type MutationChangeInterviewStatusArgs = {
  passed: Scalars['Boolean'];
};


export type MutationAdminDeleteUserArgs = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationGradeInterviewArgs = {
  data: GradeInterviewInput;
};


export type MutationCreateProjectOverviewArgs = {
  data: CreateProjectOverviewInput;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreateProjectZeplinArgs = {
  data: CreateProjectZeplinInput;
  projectId: Scalars['ID'];
};


export type MutationCreateProjectFigmaArgs = {
  data: CreateProjectFigmaInput;
  projectId: Scalars['ID'];
};


export type MutationCreateProjectImageArgs = {
  data: CreateProjectImageInput;
  projectId: Scalars['ID'];
};


export type MutationCreateProjectAdvancedArgs = {
  data: CreateProjectAdvancedInput;
  projectId: Scalars['ID'];
};


export type MutationUpdateProjectArgs = {
  data: UpdateProjectInput;
  projectId: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationAdminDeleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationPublishProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationCompleteProjectArgs = {
  data: CompleteProjectInput;
};


export type MutationUnpublishProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationCreateInterviewDeployedProjectArgs = {
  data: CreateInterviewInput;
};


export type MutationDeleteDeployedProjectArgs = {
  deployedProjectId: Scalars['String'];
};


export type MutationDeleteGitHubProjectArgs = {
  deployedProjectId: Scalars['ID'];
};


export type MutationAdminDeleteRemoteProjectArgs = {
  projectName?: Maybe<Scalars['String']>;
  interview: Scalars['Boolean'];
  projectType: Scalars['String'];
};


export type MutationUnstartProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationCreateBidArgs = {
  data: CreateBidInput;
};


export type MutationEditBidArgs = {
  data: EditBidInput;
};


export type MutationDeleteBidArgs = {
  bidId: Scalars['String'];
};


export type MutationRespondToBidArgs = {
  data: RespondToBidInput;
};


export type MutationEditScreenArgs = {
  data: EditScreenInput;
};


export type MutationCreateCommentArgs = {
  data: CreateCommentInput;
};


export type MutationEditCommentArgs = {
  data: EditCommentInput;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationCompleteTokenizedPaymentArgs = {
  bidId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['ID'];
};


export type MutationLogInArgs = {
  logInInput: LogInInput;
};


export type MutationSendMagicLogInArgs = {
  data: SendMagicLogInInput;
};


export type MutationMagicLogInArgs = {
  data: MagicLogInInput;
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationUpdatePasswordArgs = {
  password: Scalars['String'];
};


export type MutationCreateComponentArgs = {
  data: CreateComponentInput;
};


export type MutationEditComponentArgs = {
  data: EditComponentInput;
};


export type MutationDeleteComponentArgs = {
  componentId: Scalars['ID'];
};


export type MutationUnlockComponentArgs = {
  componentId: Scalars['ID'];
};


export type MutationRateComponentArgs = {
  rating: Scalars['Float'];
  componentId: Scalars['ID'];
};


export type MutationAdminSetComponentApprovalStatusArgs = {
  status: ComponentApprovalStatus;
  id: Scalars['ID'];
};


export type MutationSubmitPaypalPaymentArgs = {
  data: SubmitPaypalPayment;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  data: PurchaseComponentInput;
};


export type MutationCreateCheckoutSubscriptionSessionArgs = {
  plan: SubscriptionPlan;
};

export type LandingPageSubscribeInput = {
  email: Scalars['String'];
};

export type UpdateUserHeardAboutInput = {
  heardAbout: Scalars['String'];
};

export type UpdateIntroVideoInput = {
  url: Scalars['String'];
};

export type UpdatePortfolioInput = {
  text: Scalars['String'];
};

export type UpdateHourlyRateInput = {
  hourlyRateUsd: Scalars['Float'];
};

export type UpdateDeveloperSkillsInput = {
  programmingLanguages: Array<ProgrammingLanguage>;
  frontendFrameworks: Array<FrontendFramework>;
  stylingLibraries: Array<StylingLibrary>;
};

export type UpdateDeveloperExperienceInput = {
  yearsOfExperience?: Maybe<Scalars['Float']>;
  yearsOfExperienceReact?: Maybe<Scalars['Float']>;
  yearsOfExperienceReactNative?: Maybe<Scalars['Float']>;
  yearsOfExperienceVue?: Maybe<Scalars['Float']>;
  yearsOfExperienceAngular?: Maybe<Scalars['Float']>;
  yearsOfExperienceSvelte?: Maybe<Scalars['Float']>;
  yearsOfExperienceWordpress?: Maybe<Scalars['Float']>;
  yearsOfExperienceJavascript?: Maybe<Scalars['Float']>;
  yearsOfExperienceTypescript?: Maybe<Scalars['Float']>;
  yearsOfExperienceFlow?: Maybe<Scalars['Float']>;
};

export type UpdateDeveloperToolsInput = {
  storybookLevel?: Maybe<Scalars['Int']>;
  uiLevel?: Maybe<Scalars['Int']>;
  figmaLevel?: Maybe<Scalars['Int']>;
};

export type UpdateDeveloperPersonalInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: Country;
  street: Scalars['String'];
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type UpdateDeveloperPaymentDetailsInput = {
  entityType?: Maybe<EntityType>;
  businessName?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  vendorPrincipalDateOfBirth?: Maybe<Scalars['String']>;
  vendorPrincipalPersonalIdentificationNumber?: Maybe<Scalars['String']>;
  vendorDriverLicenseNumber?: Maybe<Scalars['String']>;
  vendorPassportNumber?: Maybe<Scalars['String']>;
  baseCurrency?: Maybe<Currency>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCountry?: Maybe<Country>;
  bankState?: Maybe<Scalars['String']>;
  bankCity?: Maybe<Scalars['String']>;
  bankZip?: Maybe<Scalars['String']>;
  bankNameOnAccount?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<BankAccountType>;
  bankId?: Maybe<Scalars['String']>;
  swiftBic?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
};

export type UpdatePublicProfileInput = {
  username?: Maybe<Scalars['String']>;
};

export type UpdatePersonalProfileInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type GradeInterviewInput = {
  developerId: Scalars['ID'];
  result: InterviewStatus;
};

export type CreateProjectOverviewInput = {
  type: ProjectType;
  name: Scalars['String'];
  overview: Scalars['String'];
  frontendFramework: FrontendFrameworkProject;
  designApp: DesignApp;
  deadline?: Maybe<Scalars['DateTime']>;
  bidInterview?: Maybe<Scalars['Boolean']>;
  devInterview?: Maybe<Scalars['Boolean']>;
};

export type CreateProjectZeplinInput = {
  zeplinProjectId: Scalars['String'];
  zeplinScreens: Array<ZeplinScreenInput>;
};

export type ZeplinScreenInput = {
  numberOfVersions?: Maybe<Scalars['Int']>;
  numberOfNotes?: Maybe<Scalars['Int']>;
  zeplinId: Scalars['String'];
  name: Scalars['String'];
  zeplinCreated: Scalars['Float'];
  zeplinUpdated: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  zeplinOriginalUrl: Scalars['String'];
  componentType: ComponentType;
};

export type CreateProjectFigmaInput = {
  figmaFileId: Scalars['String'];
  figmaScreens: Array<FigmaScreenInput>;
};

export type FigmaScreenInput = {
  figmaId: Scalars['String'];
  figmaPageId: Scalars['String'];
  name: Scalars['String'];
  figmaOriginalUrl: Scalars['String'];
};

export type CreateProjectImageInput = {
  screens: Array<ImageScreenInput>;
};

export type ImageScreenInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type CreateProjectAdvancedInput = {
  programmingLanguage?: Maybe<ProgrammingLanguageProject>;
  styling?: Maybe<StylingLibraryProject>;
  contractType?: Maybe<ContractType>;
  englishLevelRequired?: Maybe<Scalars['Float']>;
  prettierConfig?: Maybe<Scalars['String']>;
  eslintConfig?: Maybe<Scalars['String']>;
};

export type UpdateProjectInput = {
  overview?: Maybe<Scalars['String']>;
};

export type CompleteProjectInput = {
  clientRecommendRating: Scalars['Float'];
  clientCodeRating?: Maybe<Scalars['Float']>;
  clientUiRating?: Maybe<Scalars['Float']>;
  clientCommunicationRating: Scalars['Float'];
  clientReview?: Maybe<Scalars['String']>;
  clientPublicReview?: Maybe<Scalars['Boolean']>;
  deployedProjectId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
};

export type CreateInterviewInput = {
  projectType: Scalars['String'];
};

export type CreateBidInput = {
  projectId: Scalars['String'];
  amount: Scalars['Float'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
};

export type EditBidInput = {
  projectId: Scalars['String'];
  amount: Scalars['Float'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RespondToBidInput = {
  id: Scalars['ID'];
  response: BidStatus;
};

export type EditScreenInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateCommentInput = {
  text: Scalars['String'];
  parentCommentId?: Maybe<Scalars['String']>;
  screenId?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  annotationType?: Maybe<AnnotationType>;
  otherUserId?: Maybe<Scalars['String']>;
};

export type EditCommentInput = {
  text: Scalars['String'];
  id: Scalars['ID'];
};

export type LogInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SendMagicLogInInput = {
  email: Scalars['String'];
};

export type MagicLogInInput = {
  token: Scalars['String'];
};

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  developer: Scalars['Boolean'];
  signUpParams?: Maybe<Scalars['String']>;
};

export type CreateComponentInput = {
  programmingLanguages?: Maybe<Array<ProgrammingLanguage>>;
  frontendFrameworks?: Maybe<Array<FrontendFramework>>;
  stylingLibraries?: Maybe<Array<StylingLibrary>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  demoUrl: Scalars['String'];
  price: Scalars['Float'];
  daysToDeliver?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  allGitHubRepoFiles?: Maybe<Scalars['Boolean']>;
  componentScreenshots: Array<ComponentScreenshotInput>;
  categories: Array<Scalars['String']>;
  files?: Maybe<Array<Scalars['String']>>;
  gitHubRepo?: Maybe<Scalars['String']>;
  gitHubInstallationId?: Maybe<Scalars['Float']>;
  deliveryUrl?: Maybe<Scalars['String']>;
};

export type ComponentScreenshotInput = {
  url: Scalars['String'];
};

export type EditComponentInput = {
  programmingLanguages?: Maybe<Array<ProgrammingLanguage>>;
  frontendFrameworks?: Maybe<Array<FrontendFramework>>;
  stylingLibraries?: Maybe<Array<StylingLibrary>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  demoUrl: Scalars['String'];
  price: Scalars['Float'];
  daysToDeliver?: Maybe<Scalars['Float']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  allGitHubRepoFiles?: Maybe<Scalars['Boolean']>;
  componentScreenshots: Array<ComponentScreenshotInput>;
  categories: Array<Scalars['String']>;
  files?: Maybe<Array<Scalars['String']>>;
  gitHubRepo?: Maybe<Scalars['String']>;
  gitHubInstallationId?: Maybe<Scalars['Float']>;
  deliveryUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type SubmitPaypalPayment = {
  paypalOrderId: Scalars['ID'];
  bidId: Scalars['String'];
};

export type PurchaseComponentInput = {
  componentId: Scalars['String'];
};

export type UserInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'developer' | 'interviewStatus' | 'developerProgress' | 'githubUsername' | 'githubAppUsername' | 'addressSet' | 'accountStatus' | 'manualPaymentClient' | 'admin' | 'stripeConnectAccountId' | 'componentCredits' | 'componentCreditsPerMonth' | 'componentSubscriptionPlan'>
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserInfoFragment
  ) }
);

export type LogInMutationVariables = Exact<{
  data: LogInInput;
}>;


export type LogInMutation = (
  { __typename?: 'Mutation' }
  & { user: (
    { __typename?: 'User' }
    & UserInfoFragment
  ) }
);

export type SignUpMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { user: (
    { __typename?: 'User' }
    & UserInfoFragment
  ) }
);

export type LogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logOut'>
);

export type SendMagicLogInMutationVariables = Exact<{
  data: SendMagicLogInInput;
}>;


export type SendMagicLogInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMagicLogIn'>
);

export type MagicLogInMutationVariables = Exact<{
  data: MagicLogInInput;
}>;


export type MagicLogInMutation = (
  { __typename?: 'Mutation' }
  & { user: (
    { __typename?: 'User' }
    & UserInfoFragment
  ) }
);

export type ComponentCategoryFragment = (
  { __typename?: 'ComponentCategory' }
  & Pick<ComponentCategory, 'name'>
);

export type ComponentCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ComponentCategoriesQuery = (
  { __typename?: 'Query' }
  & { componentCategories: Array<(
    { __typename?: 'ComponentCategory' }
    & ComponentCategoryFragment
  )> }
);

export type MessageFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'text' | 'createdAt' | 'creatorUsername' | 'isCreator'>
);

export type BidMessageFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'createdAt' | 'amount' | 'hourlyRate' | 'projectId'>
  & { text: Bid['message'] }
  & { developer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
);

export type ConversationQueryVariables = Exact<{
  otherUserId: Scalars['ID'];
}>;


export type ConversationQuery = (
  { __typename?: 'Query' }
  & { messages: Array<(
    { __typename?: 'Comment' }
    & MessageFragment
  )>, conversationBids: Array<(
    { __typename?: 'Bid' }
    & BidMessageFragment
  )> }
);

export type NotificationFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'id' | 'read' | 'message' | 'bidId' | 'projectId' | 'queryString'>
);

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & NotificationFragment
  )> }
);

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationId: Scalars['ID'];
}>;


export type MarkNotificationAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationAsRead: (
    { __typename?: 'Notification' }
    & NotificationFragment
  ) }
);

export type ChangeAdminStatusMutationVariables = Exact<{
  admin: Scalars['Boolean'];
}>;


export type ChangeAdminStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeAdminStatus: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'admin'>
  ) }
);

export type ChangeAccountStatusMutationVariables = Exact<{
  accountStatus: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
}>;


export type ChangeAccountStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeAccountStatus: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'accountStatus'>
  ) }
);

export type ChangeInterviewStatusMutationVariables = Exact<{
  passed: Scalars['Boolean'];
}>;


export type ChangeInterviewStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeInterviewStatus: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'interviewStatus'>
  ) }
);

export type CommentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'createdAt' | 'updatedAt' | 'text' | 'userId' | 'screenId' | 'parentCommentId' | 'x' | 'y' | 'width' | 'height' | 'annotationType' | 'creatorUsername' | 'isCreator'>
);

export type CommentsQueryVariables = Exact<{
  screenId: Scalars['ID'];
}>;


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & CommentFragment
  )> }
);

export type EditScreenMutationVariables = Exact<{
  data: EditScreenInput;
}>;


export type EditScreenMutation = (
  { __typename?: 'Mutation' }
  & { editScreen: (
    { __typename?: 'FigmaScreen' }
    & Pick<FigmaScreen, 'id' | 'name' | 'notes'>
  ) | (
    { __typename?: 'ZeplinScreen' }
    & Pick<ZeplinScreen, 'id' | 'name' | 'notes'>
  ) | (
    { __typename?: 'ImageScreen' }
    & Pick<ImageScreen, 'id' | 'name' | 'notes'>
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  data: CreateCommentInput;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'Comment' }
    & CommentFragment
  ) }
);

export type EditCommentMutationVariables = Exact<{
  data: EditCommentInput;
}>;


export type EditCommentMutation = (
  { __typename?: 'Mutation' }
  & { editComment: (
    { __typename?: 'Comment' }
    & CommentFragment
  ) }
);

export type DeleteCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteComment'>
);

export type PublicProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
);

export type PersonalProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonalProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'street' | 'city' | 'state' | 'country' | 'countryCode' | 'zip' | 'addressSet'>
  ) }
);

export type UpdatePublicProfileMutationVariables = Exact<{
  data: UpdatePublicProfileInput;
}>;


export type UpdatePublicProfileMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicProfile: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
);

export type UpdatePersonalProfileMutationVariables = Exact<{
  data: UpdatePersonalProfileInput;
}>;


export type UpdatePersonalProfileMutation = (
  { __typename?: 'Mutation' }
  & { updatePersonalProfile: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'country' | 'street' | 'city' | 'state' | 'zip' | 'addressSet'>
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePassword'>
);

export type AllComponentsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type AllComponentsQuery = (
  { __typename?: 'Query' }
  & { components: Array<(
    { __typename?: 'Component' }
    & ComponentFragment
  )> }
);

export type ComponentFragment = (
  { __typename?: 'Component' }
  & Pick<Component, 'id' | 'programmingLanguages' | 'frontendFrameworks' | 'stylingLibraries' | 'name' | 'description' | 'demoUrl' | 'price' | 'daysToDeliver' | 'thumbnail' | 'userId' | 'isCreator' | 'deliveryUrl' | 'gitHubRepo' | 'gitHubInstallationId' | 'allGitHubRepoFiles' | 'hasAccess' | 'approvalStatus' | 'rating'>
  & { categories: Array<(
    { __typename?: 'ComponentCategory' }
    & Pick<ComponentCategory, 'name'>
  )>, componentScreenshots?: Maybe<Array<(
    { __typename?: 'ComponentScreenshot' }
    & Pick<ComponentScreenshot, 'id' | 'url'>
  )>>, files: Array<(
    { __typename?: 'ComponentFile' }
    & Pick<ComponentFile, 'path' | 'url'>
  )> }
);

export type ComponentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ComponentQuery = (
  { __typename?: 'Query' }
  & { component: (
    { __typename?: 'Component' }
    & ComponentFragment
  ) }
);

export type DeleteComponentMutationVariables = Exact<{
  componentId: Scalars['ID'];
}>;


export type DeleteComponentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteComponent'>
);

export type UnlockComponentMutationVariables = Exact<{
  componentId: Scalars['ID'];
}>;


export type UnlockComponentMutation = (
  { __typename?: 'Mutation' }
  & { unlockComponent: (
    { __typename?: 'Component' }
    & ComponentFragment
  ) }
);

export type AdminSetComponentApprovalStatusMutationVariables = Exact<{
  status: ComponentApprovalStatus;
  id: Scalars['ID'];
}>;


export type AdminSetComponentApprovalStatusMutation = (
  { __typename?: 'Mutation' }
  & { adminSetComponentApprovalStatus: (
    { __typename?: 'Component' }
    & Pick<Component, 'id' | 'approvalStatus'>
  ) }
);

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  data: PurchaseComponentInput;
}>;


export type CreateStripeCheckoutSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStripeCheckoutSession'>
);

export type GetRepoDownloadLinkQueryVariables = Exact<{
  componentId: Scalars['String'];
}>;


export type GetRepoDownloadLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'repoZipLink'>
);

export type DeveloperComponentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DeveloperComponentsQuery = (
  { __typename?: 'Query' }
  & { components: Array<(
    { __typename?: 'Component' }
    & ComponentFragment
  )> }
);

export type PurchasedComponentsQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchasedComponentsQuery = (
  { __typename?: 'Query' }
  & { components: Array<(
    { __typename?: 'Component' }
    & ComponentFragment
  )> }
);

export type RateComponentMutationVariables = Exact<{
  componentId: Scalars['ID'];
  rating: Scalars['Float'];
}>;


export type RateComponentMutation = (
  { __typename?: 'Mutation' }
  & { rateComponent: (
    { __typename?: 'Component' }
    & Pick<Component, 'id' | 'rating'>
  ) }
);

export type CreateComponentMutationVariables = Exact<{
  data: CreateComponentInput;
}>;


export type CreateComponentMutation = (
  { __typename?: 'Mutation' }
  & { createComponent: (
    { __typename?: 'Component' }
    & ComponentFragment
  ) }
);

export type EditComponentMutationVariables = Exact<{
  data: EditComponentInput;
}>;


export type EditComponentMutation = (
  { __typename?: 'Mutation' }
  & { editComponent: (
    { __typename?: 'Component' }
    & ComponentFragment
  ) }
);

export type GitHubReposQueryVariables = Exact<{ [key: string]: never; }>;


export type GitHubReposQuery = (
  { __typename?: 'Query' }
  & { repos: (
    { __typename?: 'GitHubRepoList' }
    & Pick<GitHubRepoList, 'totalCount'>
    & { repositories: Array<(
      { __typename?: 'GitHubRepo' }
      & Pick<GitHubRepo, 'full_name'>
    )> }
  ) }
);

export type GetRepoContentsQueryVariables = Exact<{
  fullRepoName: Scalars['String'];
}>;


export type GetRepoContentsQuery = (
  { __typename?: 'Query' }
  & { repoContents: Array<(
    { __typename?: 'GitHubTreeNode' }
    & Pick<GitHubTreeNode, 'path'>
  )> }
);

export type DeveloperBidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'updatedAt' | 'amount' | 'developerId' | 'projectId' | 'status' | 'deliveryDate' | 'message'>
  & { project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'status' | 'type' | 'frontendFramework' | 'programmingLanguage' | 'styling' | 'deadline'>
  ) }
);

export type DeveloperDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DeveloperDashboardQuery = (
  { __typename?: 'Query' }
  & { developerBids: Array<(
    { __typename?: 'Bid' }
    & DeveloperBidFragment
  )> }
);

export type UserWithHeardAboutQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithHeardAboutQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'heardAbout'>
  ) }
);

export type UpdateUserHeardAboutMutationVariables = Exact<{
  data: UpdateUserHeardAboutInput;
}>;


export type UpdateUserHeardAboutMutation = (
  { __typename?: 'Mutation' }
  & { updateUserHeardAbout: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'heardAbout'>
  ) }
);

export type GetDeveloperQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeveloperQuery = (
  { __typename?: 'Query' }
  & { developer: (
    { __typename?: 'User' }
    & DeveloperFragment
  ) }
);

export type InterviewFragment = (
  { __typename?: 'Interview' }
  & Pick<Interview, 'id' | 'videoResponseStatus' | 'bidProjectId' | 'devProjectId' | 'devProjectStatus' | 'completedBidProject'>
);

export type CreateInterviewMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateInterviewMutation = (
  { __typename?: 'Mutation' }
  & { createInterview: (
    { __typename?: 'Interview' }
    & InterviewFragment
  ) }
);

export type UpdateDeveloperExperienceMutationVariables = Exact<{
  data: UpdateDeveloperExperienceInput;
}>;


export type UpdateDeveloperExperienceMutation = (
  { __typename?: 'Mutation' }
  & { updateDeveloperExperience: (
    { __typename?: 'User' }
    & DeveloperFragment
  ) }
);

export type UpdateDeveloperToolsMutationVariables = Exact<{
  data: UpdateDeveloperToolsInput;
}>;


export type UpdateDeveloperToolsMutation = (
  { __typename?: 'Mutation' }
  & { updateDeveloperTools: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'storybookLevel' | 'uiLevel' | 'figmaLevel'>
  ) }
);

export type UpdateDeveloperPersonalMutationVariables = Exact<{
  data: UpdateDeveloperPersonalInput;
}>;


export type UpdateDeveloperPersonalMutation = (
  { __typename?: 'Mutation' }
  & { updateDeveloperPersonal: (
    { __typename?: 'User' }
    & DeveloperFragment
  ) }
);

export type UpdateDeveloperSkillsMutationVariables = Exact<{
  data: UpdateDeveloperSkillsInput;
}>;


export type UpdateDeveloperSkillsMutation = (
  { __typename?: 'Mutation' }
  & { updateDeveloperSkills: (
    { __typename?: 'User' }
    & DeveloperFragment
  ) }
);

export type DeveloperFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'country' | 'city' | 'street' | 'state' | 'zip' | 'programmingLanguages' | 'frontendFrameworks' | 'stylingLibraries' | 'storybookLevel' | 'uiLevel' | 'figmaLevel' | 'yearsOfExperience' | 'yearsOfExperienceReact' | 'yearsOfExperienceReactNative' | 'yearsOfExperienceVue' | 'yearsOfExperienceAngular' | 'yearsOfExperienceSvelte' | 'yearsOfExperienceWordpress' | 'yearsOfExperienceJavascript' | 'yearsOfExperienceTypescript' | 'yearsOfExperienceFlow'>
);

export type DeveloperWithPaymentDetailsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'country' | 'street' | 'city' | 'state' | 'zip' | 'entityType' | 'businessName' | 'taxId' | 'vatId' | 'phone' | 'vendorPrincipalDateOfBirth' | 'vendorPrincipalPersonalIdentificationNumber' | 'vendorDriverLicenseNumber' | 'vendorPassportNumber' | 'vendorAgreementCommissionPercent' | 'payoutType' | 'baseCurrency' | 'bankName' | 'bankAddress' | 'bankCountry' | 'bankState' | 'bankCity' | 'bankZip' | 'bankNameOnAccount' | 'bankAccountType' | 'bankId' | 'swiftBic' | 'bankAccountId' | 'iban'>
);

export type GetDeveloperWithPaymentDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeveloperWithPaymentDetailsQuery = (
  { __typename?: 'Query' }
  & { developer: (
    { __typename?: 'User' }
    & DeveloperWithPaymentDetailsFragment
  ) }
);

export type UpdateDeveloperPaymentDetailsMutationVariables = Exact<{
  data: UpdateDeveloperPaymentDetailsInput;
}>;


export type UpdateDeveloperPaymentDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateDeveloperPaymentDetails: (
    { __typename?: 'User' }
    & DeveloperWithPaymentDetailsFragment
  ) }
);

export type VendorDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorDetailsQuery = (
  { __typename?: 'Query' }
  & { vendorDetails?: Maybe<(
    { __typename?: 'VendorDetails' }
    & Pick<VendorDetails, 'vendorId' | 'accountStatus' | 'processingStatus' | 'payoutStatus' | 'lastPayoutDate' | 'payoutCurrency' | 'lastPayoutAmount' | 'accountBalance'>
  )> }
);

export type ReviewFragment = (
  { __typename?: 'Review' }
  & Pick<Review, 'id' | 'frontendFramework' | 'programmingLanguage' | 'styling' | 'clientRating' | 'clientReview' | 'completedDate'>
);

export type DeveloperProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeveloperProfileQuery = (
  { __typename?: 'Query' }
  & { developer: (
    { __typename?: 'Developer' }
    & Pick<Developer, 'id' | 'username' | 'programmingLanguages' | 'frontendFrameworks' | 'stylingLibraries' | 'portfolio' | 'yearsOfExperience' | 'yearsOfExperienceReact' | 'yearsOfExperienceReactNative' | 'yearsOfExperienceVue' | 'yearsOfExperienceAngular' | 'yearsOfExperienceSvelte' | 'yearsOfExperienceWordpress' | 'yearsOfExperienceJavascript' | 'yearsOfExperienceTypescript' | 'yearsOfExperienceFlow'>
    & { reviews: Array<(
      { __typename?: 'Review' }
      & ReviewFragment
    )>, interviewDeployedProject?: Maybe<(
      { __typename?: 'DeployedProject' }
      & Pick<DeployedProject, 'id' | 'netlifyUrl' | 'githubUrl'>
    )> }
  ) }
);

export type GradeInterviewMutationVariables = Exact<{
  data: GradeInterviewInput;
}>;


export type GradeInterviewMutation = (
  { __typename?: 'Mutation' }
  & { gradeInterview: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'interviewStatus'>
  ) }
);

export type AdminDeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AdminDeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteUser'>
);

export type UserWithPortfolioQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithPortfolioQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'portfolio'>
  ) }
);

export type UpdatePortfolioMutationVariables = Exact<{
  data: UpdatePortfolioInput;
}>;


export type UpdatePortfolioMutation = (
  { __typename?: 'Mutation' }
  & { updatePortfolio: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'portfolio'>
  ) }
);

export type UserWithHourlyRateQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithHourlyRateQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'hourlyRateUsd'>
  ) }
);

export type UpdateHourlyRateMutationVariables = Exact<{
  data: UpdateHourlyRateInput;
}>;


export type UpdateHourlyRateMutation = (
  { __typename?: 'Mutation' }
  & { updateHourlyRate: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'hourlyRateUsd'>
  ) }
);

export type UserWithIntroVideoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithIntroVideoQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'introVideoUrl'>
  ) }
);

export type UpdateIntroVideoMutationVariables = Exact<{
  data: UpdateIntroVideoInput;
}>;


export type UpdateIntroVideoMutation = (
  { __typename?: 'Mutation' }
  & { updateIntroVideo: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'introVideoUrl'>
  ) }
);

export type AdminUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'githubUsername' | 'country' | 'interviewStatus' | 'storybookLevel' | 'uiLevel' | 'figmaLevel' | 'heardAbout' | 'introVideoUrl' | 'hourlyRateUsd' | 'yearsOfExperience'>
);

export type AdminDeveloperProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminDeveloperProfileQuery = (
  { __typename?: 'Query' }
  & { adminUser: (
    { __typename?: 'User' }
    & AdminUserFragment
  ) }
);

export type OpenProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type OpenProjectsQuery = (
  { __typename?: 'Query' }
  & { openProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'programmingLanguage' | 'frontendFramework' | 'styling' | 'deadline'>
    & { bid?: Maybe<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'status' | 'amount' | 'deliveryDate' | 'deleted'>
    )> }
  )> }
);

export type DeveloperQuickApplyFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'hourlyRateUsd' | 'portfolio' | 'introVideoUrl' | 'submittedQuickApply'>
);

export type DeveloperWithQuickApplyQueryVariables = Exact<{ [key: string]: never; }>;


export type DeveloperWithQuickApplyQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & DeveloperQuickApplyFragment
  ) }
);

export type SubmitQuickApplyMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitQuickApplyMutation = (
  { __typename?: 'Mutation' }
  & { submitQuickApply: (
    { __typename?: 'User' }
    & DeveloperQuickApplyFragment
  ) }
);

export type UserWithHeardAboutAndSkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithHeardAboutAndSkillsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'heardAbout' | 'programmingLanguages' | 'frontendFrameworks' | 'stylingLibraries'>
  ) }
);

export type DeployedProjectFragment = (
  { __typename?: 'DeployedProject' }
  & Pick<DeployedProject, 'id' | 'githubUrl' | 'netlifyUrl' | 'developerId'>
);

export type GetInterviewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInterviewQuery = (
  { __typename?: 'Query' }
  & { interview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewFragment
  )> }
);

export type InterviewDevProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewDevProjectQuery = (
  { __typename?: 'Query' }
  & { interviewDevProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { deployedProject?: Maybe<(
      { __typename?: 'DeployedProject' }
      & DeployedProjectFragment
    )> }
  )> }
);

export type CreateInterviewDeployedProjectMutationVariables = Exact<{
  data: CreateInterviewInput;
}>;


export type CreateInterviewDeployedProjectMutation = (
  { __typename?: 'Mutation' }
  & { createInterviewDeployedProject: (
    { __typename?: 'DeployedProject' }
    & DeployedProjectFragment
  ) }
);

export type DeleteDeployedProjectMutationVariables = Exact<{
  deployedProjectId: Scalars['String'];
}>;


export type DeleteDeployedProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDeployedProject'>
);

export type SubmitInterviewMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitInterviewMutation = (
  { __typename?: 'Mutation' }
  & { submitInterview: (
    { __typename?: 'Interview' }
    & Pick<Interview, 'id' | 'devProjectStatus' | 'videoResponseStatus'>
  ) }
);

export type AdminDeleteRemoteProjectMutationVariables = Exact<{
  projectType: Scalars['String'];
  interview: Scalars['Boolean'];
  projectName?: Maybe<Scalars['String']>;
}>;


export type AdminDeleteRemoteProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteRemoteProject'>
);

export type CompleteVideoInterviewMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteVideoInterviewMutation = (
  { __typename?: 'Mutation' }
  & { completeVideoInterview: (
    { __typename?: 'Interview' }
    & InterviewFragment
  ) }
);

export type CreateProjectAdvancedMutationVariables = Exact<{
  projectId: Scalars['ID'];
  data: CreateProjectAdvancedInput;
}>;


export type CreateProjectAdvancedMutation = (
  { __typename?: 'Mutation' }
  & { createProjectAdvanced: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CreateProjectFigmaMutationVariables = Exact<{
  projectId: Scalars['ID'];
  data: CreateProjectFigmaInput;
}>;


export type CreateProjectFigmaMutation = (
  { __typename?: 'Mutation' }
  & { createProjectFigma: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CreateProjectImageMutationVariables = Exact<{
  data: CreateProjectImageInput;
  projectId: Scalars['ID'];
}>;


export type CreateProjectImageMutation = (
  { __typename?: 'Mutation' }
  & { createProjectImage: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CreateProjectOverviewMutationVariables = Exact<{
  projectId?: Maybe<Scalars['ID']>;
  data: CreateProjectOverviewInput;
}>;


export type CreateProjectOverviewMutation = (
  { __typename?: 'Mutation' }
  & { createProjectOverview: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CreateProjectZeplinMutationVariables = Exact<{
  projectId: Scalars['ID'];
  data: CreateProjectZeplinInput;
}>;


export type CreateProjectZeplinMutation = (
  { __typename?: 'Mutation' }
  & { createProjectZeplin: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type BidPaymentQueryVariables = Exact<{
  bidId: Scalars['String'];
}>;


export type BidPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bidPayment'>
);

export type PaymentFragment = (
  { __typename?: 'PaypalPayment' }
  & Pick<PaypalPayment, 'id' | 'createdAt' | 'paypalStatus' | 'amount' | 'currency'>
  & { bid: (
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    ) }
  ) }
);

export type PastPaymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type PastPaymentsQuery = (
  { __typename?: 'Query' }
  & { pastPayments: Array<(
    { __typename?: 'PaypalPayment' }
    & PaymentFragment
  )> }
);

export type RespondToBidMutationVariables = Exact<{
  data: RespondToBidInput;
}>;


export type RespondToBidMutation = (
  { __typename?: 'Mutation' }
  & { respondToBid: (
    { __typename?: 'Bid' }
    & BidFragment
  ) }
);

export type BidFragment = (
  { __typename?: 'Bid' }
  & Pick<Bid, 'id' | 'status' | 'amount' | 'hourlyRate' | 'developerId' | 'projectId' | 'deliveryDate' | 'message' | 'deleted'>
);

export type CreateBidMutationVariables = Exact<{
  data: CreateBidInput;
}>;


export type CreateBidMutation = (
  { __typename?: 'Mutation' }
  & { createBid: (
    { __typename?: 'Bid' }
    & BidFragment
  ) }
);

export type EditBidMutationVariables = Exact<{
  data: EditBidInput;
}>;


export type EditBidMutation = (
  { __typename?: 'Mutation' }
  & { editBid: (
    { __typename?: 'Bid' }
    & BidFragment
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  data: UpdateProjectInput;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type SubmitPaypalPaymentMutationVariables = Exact<{
  data: SubmitPaypalPayment;
}>;


export type SubmitPaypalPaymentMutation = (
  { __typename?: 'Mutation' }
  & { submitPaypalPayment: (
    { __typename?: 'PaypalPayment' }
    & Pick<PaypalPayment, 'id' | 'paypalId' | 'paypalPayerId' | 'paypalPayerCountryCode' | 'paypalStatus' | 'amount' | 'currency'>
  ) }
);

export type BidWithDeveloperFragment = (
  { __typename?: 'Bid' }
  & { developer: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
  & BidFragment
);

export type GetProjectFragment = (
  { __typename?: 'Project' }
  & { bid?: Maybe<(
    { __typename?: 'Bid' }
    & BidFragment
  )>, bids?: Maybe<Array<(
    { __typename?: 'Bid' }
    & BidWithDeveloperFragment
  )>>, deployedProject?: Maybe<(
    { __typename?: 'DeployedProject' }
    & DeployedProjectFragment
  )> }
  & ProjectFragment
);

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & GetProjectFragment
  ) }
);

export type PublishProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type PublishProjectMutation = (
  { __typename?: 'Mutation' }
  & { publishProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type UnpublishProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type UnpublishProjectMutation = (
  { __typename?: 'Mutation' }
  & { unpublishProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type DeleteGitHubProjectMutationVariables = Exact<{
  deployedProjectId: Scalars['ID'];
}>;


export type DeleteGitHubProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteGitHubProject: (
    { __typename?: 'DeployedProject' }
    & DeployedProjectFragment
  ) }
);

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProject'>
);

export type AdminDeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type AdminDeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDeleteProject'>
);

export type UnstartProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type UnstartProjectMutation = (
  { __typename?: 'Mutation' }
  & { unstartProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CompleteProjectMutationVariables = Exact<{
  data: CompleteProjectInput;
}>;


export type CompleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { completeProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'status'>
    & { projects: Array<(
      { __typename?: 'DeployedProject' }
      & Pick<DeployedProject, 'id' | 'clientReview' | 'clientPublicReview' | 'clientCommunicationRating' | 'clientRecommendRating' | 'clientUiRating' | 'clientCodeRating'>
    )> }
  ) }
);

export type ProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'creatorId' | 'name' | 'overview' | 'status' | 'type' | 'designApp' | 'zeplinProjectId' | 'figmaFileId' | 'designUrl' | 'frontendFramework' | 'programmingLanguage' | 'styling' | 'deadline' | 'contractType' | 'englishLevelRequired' | 'prettierConfig' | 'eslintConfig' | 'interviewBidProject' | 'interviewDevProject'>
  & { zeplinScreens?: Maybe<Array<(
    { __typename?: 'ZeplinScreen' }
    & Pick<ZeplinScreen, 'id' | 'name' | 'notes' | 'cloudinaryUrl' | 'componentType' | 'commentsCount' | 'zeplinId' | 'zeplinCreated' | 'zeplinUpdated' | 'zeplinNumberOfVersions' | 'zeplinNumberOfNotes' | 'zeplinOriginalUrl' | 'width' | 'height'>
  )>>, figmaScreens?: Maybe<Array<(
    { __typename?: 'FigmaScreen' }
    & Pick<FigmaScreen, 'id' | 'name' | 'notes' | 'cloudinaryUrl' | 'componentType' | 'commentsCount' | 'figmaId' | 'figmaPageId' | 'figmaOriginalUrl'>
  )>>, imageScreens?: Maybe<Array<(
    { __typename?: 'ImageScreen' }
    & Pick<ImageScreen, 'id' | 'name' | 'notes' | 'cloudinaryUrl' | 'componentType' | 'commentsCount'>
  )>>, bids?: Maybe<Array<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id'>
  )>> }
);

export type GetProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & ProjectFragment
  )> }
);

export type CreateStripeAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeAccountMutation = (
  { __typename?: 'Mutation' }
  & { link: Mutation['createStripeAccount'] }
);

export type CreateStripeDashboardLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeDashboardLinkMutation = (
  { __typename?: 'Mutation' }
  & { link: Mutation['createStripeDashboardLink'] }
);

export type CreateCheckoutSubscriptionSessionMutationVariables = Exact<{
  plan: SubscriptionPlan;
}>;


export type CreateCheckoutSubscriptionSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCheckoutSubscriptionSession'>
);

export type CountryFragment = (
  { __typename?: 'CountryFull' }
  & Pick<CountryFull, 'name' | 'enum'>
);

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'CountryFull' }
    & CountryFragment
  )> }
);

export type UpdateGithubUsernameMutationVariables = Exact<{
  githubAccessToken: Scalars['String'];
}>;


export type UpdateGithubUsernameMutation = (
  { __typename?: 'Mutation' }
  & { updateGithubUsername: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'githubUsername' | 'githubAppUsername'>
  ) }
);

export type UpdateGithubAppUsernameMutationVariables = Exact<{
  githubAccessToken: Scalars['String'];
  githubInstallationId: Scalars['Int'];
}>;


export type UpdateGithubAppUsernameMutation = (
  { __typename?: 'Mutation' }
  & { updateGithubAppUsername: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'githubUsername' | 'githubAppUsername'>
  ) }
);

export type UnsetGithubUsernameMutationVariables = Exact<{ [key: string]: never; }>;


export type UnsetGithubUsernameMutation = (
  { __typename?: 'Mutation' }
  & { unsetGithubUsername: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'githubUsername'>
  ) }
);

export type UnsetGithubAppUsernameMutationVariables = Exact<{ [key: string]: never; }>;


export type UnsetGithubAppUsernameMutation = (
  { __typename?: 'Mutation' }
  & { unsetGithubAppUsername: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'githubAppUsername'>
  ) }
);

export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  email
  firstName
  lastName
  developer
  interviewStatus
  developerProgress
  githubUsername
  githubAppUsername
  addressSet
  accountStatus
  manualPaymentClient
  admin
  stripeConnectAccountId
  componentCredits
  componentCreditsPerMonth
  componentSubscriptionPlan
}
    `;
export const ComponentCategoryFragmentDoc = gql`
    fragment ComponentCategory on ComponentCategory {
  name
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on Comment {
  id
  text
  createdAt
  creatorUsername
  isCreator
}
    `;
export const BidMessageFragmentDoc = gql`
    fragment BidMessage on Bid {
  id
  text: message
  createdAt
  amount
  hourlyRate
  projectId
  developer {
    id
    username
  }
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  id
  read
  message
  bidId
  projectId
  queryString
}
    `;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  createdAt
  updatedAt
  text
  userId
  screenId
  parentCommentId
  x
  y
  width
  height
  annotationType
  creatorUsername
  isCreator
}
    `;
export const ComponentFragmentDoc = gql`
    fragment Component on Component {
  id
  programmingLanguages
  frontendFrameworks
  stylingLibraries
  name
  description
  demoUrl
  price
  categories {
    name
  }
  daysToDeliver
  componentScreenshots {
    id
    url
  }
  thumbnail
  userId
  isCreator
  deliveryUrl
  gitHubRepo
  gitHubInstallationId
  allGitHubRepoFiles
  files {
    path
    url
  }
  hasAccess
  approvalStatus
  rating
}
    `;
export const DeveloperBidFragmentDoc = gql`
    fragment DeveloperBid on Bid {
  id
  updatedAt
  amount
  developerId
  projectId
  project {
    id
    name
    status
    type
    frontendFramework
    programmingLanguage
    styling
    deadline
  }
  status
  deliveryDate
  message
}
    `;
export const InterviewFragmentDoc = gql`
    fragment Interview on Interview {
  id
  videoResponseStatus
  bidProjectId
  devProjectId
  devProjectStatus
  videoResponseStatus
  completedBidProject
}
    `;
export const DeveloperFragmentDoc = gql`
    fragment Developer on User {
  id
  firstName
  lastName
  country
  city
  street
  state
  zip
  programmingLanguages
  frontendFrameworks
  stylingLibraries
  storybookLevel
  uiLevel
  figmaLevel
  yearsOfExperience
  yearsOfExperienceReact
  yearsOfExperienceReactNative
  yearsOfExperienceVue
  yearsOfExperienceAngular
  yearsOfExperienceSvelte
  yearsOfExperienceWordpress
  yearsOfExperienceJavascript
  yearsOfExperienceTypescript
  yearsOfExperienceFlow
}
    `;
export const DeveloperWithPaymentDetailsFragmentDoc = gql`
    fragment DeveloperWithPaymentDetails on User {
  id
  firstName
  lastName
  country
  street
  city
  state
  zip
  entityType
  businessName
  taxId
  vatId
  phone
  vendorPrincipalDateOfBirth
  vendorPrincipalPersonalIdentificationNumber
  vendorDriverLicenseNumber
  vendorPassportNumber
  vendorAgreementCommissionPercent
  payoutType
  baseCurrency
  bankName
  bankAddress
  bankCountry
  bankState
  bankCity
  bankZip
  bankNameOnAccount
  bankAccountType
  bankId
  swiftBic
  bankAccountId
  iban
}
    `;
export const ReviewFragmentDoc = gql`
    fragment Review on Review {
  id
  frontendFramework
  programmingLanguage
  styling
  clientRating
  clientReview
  completedDate
}
    `;
export const AdminUserFragmentDoc = gql`
    fragment AdminUser on User {
  id
  email
  firstName
  lastName
  githubUsername
  country
  interviewStatus
  storybookLevel
  uiLevel
  figmaLevel
  heardAbout
  introVideoUrl
  hourlyRateUsd
  yearsOfExperience
}
    `;
export const DeveloperQuickApplyFragmentDoc = gql`
    fragment DeveloperQuickApply on User {
  id
  hourlyRateUsd
  portfolio
  introVideoUrl
  submittedQuickApply
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on PaypalPayment {
  id
  createdAt
  paypalStatus
  amount
  currency
  bid {
    id
    project {
      id
      name
    }
  }
}
    `;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  id
  creatorId
  name
  overview
  status
  type
  designApp
  zeplinProjectId
  zeplinScreens {
    id
    name
    notes
    cloudinaryUrl
    componentType
    commentsCount
    zeplinId
    zeplinCreated
    zeplinUpdated
    zeplinNumberOfVersions
    zeplinNumberOfNotes
    zeplinOriginalUrl
    width
    height
  }
  figmaFileId
  figmaScreens {
    id
    name
    notes
    cloudinaryUrl
    componentType
    commentsCount
    figmaId
    figmaPageId
    figmaOriginalUrl
  }
  imageScreens {
    id
    name
    notes
    cloudinaryUrl
    componentType
    commentsCount
  }
  designUrl
  frontendFramework
  programmingLanguage
  styling
  deadline
  contractType
  englishLevelRequired
  prettierConfig
  eslintConfig
  interviewBidProject
  interviewDevProject
  bids {
    id
  }
}
    `;
export const BidFragmentDoc = gql`
    fragment Bid on Bid {
  id
  status
  amount
  hourlyRate
  developerId
  projectId
  deliveryDate
  message
  deleted
}
    `;
export const BidWithDeveloperFragmentDoc = gql`
    fragment BidWithDeveloper on Bid {
  ...Bid
  developer {
    id
    username
  }
}
    ${BidFragmentDoc}`;
export const DeployedProjectFragmentDoc = gql`
    fragment DeployedProject on DeployedProject {
  id
  githubUrl
  netlifyUrl
  developerId
}
    `;
export const GetProjectFragmentDoc = gql`
    fragment GetProject on Project {
  ...Project
  bid {
    ...Bid
  }
  bids {
    ...BidWithDeveloper
  }
  deployedProject {
    ...DeployedProject
  }
}
    ${ProjectFragmentDoc}
${BidFragmentDoc}
${BidWithDeveloperFragmentDoc}
${DeployedProjectFragmentDoc}`;
export const CountryFragmentDoc = gql`
    fragment Country on CountryFull {
  name
  enum
}
    `;
export const GetUserDocument = gql`
    query GetUser {
  user: getUser {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LogInDocument = gql`
    mutation LogIn($data: LogInInput!) {
  user: logIn(logInInput: $data) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type LogInMutationFn = Apollo.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, options);
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($data: SignUpInput!) {
  user: signUp(signUpInput: $data) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const LogOutDocument = gql`
    mutation LogOut {
  logOut
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;
export const SendMagicLogInDocument = gql`
    mutation SendMagicLogIn($data: SendMagicLogInInput!) {
  sendMagicLogIn(data: $data)
}
    `;
export type SendMagicLogInMutationFn = Apollo.MutationFunction<SendMagicLogInMutation, SendMagicLogInMutationVariables>;

/**
 * __useSendMagicLogInMutation__
 *
 * To run a mutation, you first call `useSendMagicLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicLogInMutation, { data, loading, error }] = useSendMagicLogInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMagicLogInMutation(baseOptions?: Apollo.MutationHookOptions<SendMagicLogInMutation, SendMagicLogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMagicLogInMutation, SendMagicLogInMutationVariables>(SendMagicLogInDocument, options);
      }
export type SendMagicLogInMutationHookResult = ReturnType<typeof useSendMagicLogInMutation>;
export type SendMagicLogInMutationResult = Apollo.MutationResult<SendMagicLogInMutation>;
export type SendMagicLogInMutationOptions = Apollo.BaseMutationOptions<SendMagicLogInMutation, SendMagicLogInMutationVariables>;
export const MagicLogInDocument = gql`
    mutation MagicLogIn($data: MagicLogInInput!) {
  user: magicLogIn(data: $data) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type MagicLogInMutationFn = Apollo.MutationFunction<MagicLogInMutation, MagicLogInMutationVariables>;

/**
 * __useMagicLogInMutation__
 *
 * To run a mutation, you first call `useMagicLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagicLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magicLogInMutation, { data, loading, error }] = useMagicLogInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMagicLogInMutation(baseOptions?: Apollo.MutationHookOptions<MagicLogInMutation, MagicLogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MagicLogInMutation, MagicLogInMutationVariables>(MagicLogInDocument, options);
      }
export type MagicLogInMutationHookResult = ReturnType<typeof useMagicLogInMutation>;
export type MagicLogInMutationResult = Apollo.MutationResult<MagicLogInMutation>;
export type MagicLogInMutationOptions = Apollo.BaseMutationOptions<MagicLogInMutation, MagicLogInMutationVariables>;
export const ComponentCategoriesDocument = gql`
    query ComponentCategories {
  componentCategories {
    ...ComponentCategory
  }
}
    ${ComponentCategoryFragmentDoc}`;

/**
 * __useComponentCategoriesQuery__
 *
 * To run a query within a React component, call `useComponentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useComponentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComponentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useComponentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ComponentCategoriesQuery, ComponentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComponentCategoriesQuery, ComponentCategoriesQueryVariables>(ComponentCategoriesDocument, options);
      }
export function useComponentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComponentCategoriesQuery, ComponentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComponentCategoriesQuery, ComponentCategoriesQueryVariables>(ComponentCategoriesDocument, options);
        }
export type ComponentCategoriesQueryHookResult = ReturnType<typeof useComponentCategoriesQuery>;
export type ComponentCategoriesLazyQueryHookResult = ReturnType<typeof useComponentCategoriesLazyQuery>;
export type ComponentCategoriesQueryResult = Apollo.QueryResult<ComponentCategoriesQuery, ComponentCategoriesQueryVariables>;
export const ConversationDocument = gql`
    query Conversation($otherUserId: ID!) {
  messages(otherUserId: $otherUserId) {
    ...Message
  }
  conversationBids(otherUserId: $otherUserId) {
    ...BidMessage
  }
}
    ${MessageFragmentDoc}
${BidMessageFragmentDoc}`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      otherUserId: // value for 'otherUserId'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($notificationId: ID!) {
  markNotificationAsRead(notificationId: $notificationId) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, options);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const ChangeAdminStatusDocument = gql`
    mutation ChangeAdminStatus($admin: Boolean!) {
  changeAdminStatus(admin: $admin) {
    id
    admin
  }
}
    `;
export type ChangeAdminStatusMutationFn = Apollo.MutationFunction<ChangeAdminStatusMutation, ChangeAdminStatusMutationVariables>;

/**
 * __useChangeAdminStatusMutation__
 *
 * To run a mutation, you first call `useChangeAdminStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdminStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdminStatusMutation, { data, loading, error }] = useChangeAdminStatusMutation({
 *   variables: {
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useChangeAdminStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAdminStatusMutation, ChangeAdminStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAdminStatusMutation, ChangeAdminStatusMutationVariables>(ChangeAdminStatusDocument, options);
      }
export type ChangeAdminStatusMutationHookResult = ReturnType<typeof useChangeAdminStatusMutation>;
export type ChangeAdminStatusMutationResult = Apollo.MutationResult<ChangeAdminStatusMutation>;
export type ChangeAdminStatusMutationOptions = Apollo.BaseMutationOptions<ChangeAdminStatusMutation, ChangeAdminStatusMutationVariables>;
export const ChangeAccountStatusDocument = gql`
    mutation ChangeAccountStatus($accountStatus: Boolean!, $code: String) {
  changeAccountStatus(accountStatus: $accountStatus, code: $code) {
    id
    accountStatus
  }
}
    `;
export type ChangeAccountStatusMutationFn = Apollo.MutationFunction<ChangeAccountStatusMutation, ChangeAccountStatusMutationVariables>;

/**
 * __useChangeAccountStatusMutation__
 *
 * To run a mutation, you first call `useChangeAccountStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccountStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccountStatusMutation, { data, loading, error }] = useChangeAccountStatusMutation({
 *   variables: {
 *      accountStatus: // value for 'accountStatus'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useChangeAccountStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAccountStatusMutation, ChangeAccountStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAccountStatusMutation, ChangeAccountStatusMutationVariables>(ChangeAccountStatusDocument, options);
      }
export type ChangeAccountStatusMutationHookResult = ReturnType<typeof useChangeAccountStatusMutation>;
export type ChangeAccountStatusMutationResult = Apollo.MutationResult<ChangeAccountStatusMutation>;
export type ChangeAccountStatusMutationOptions = Apollo.BaseMutationOptions<ChangeAccountStatusMutation, ChangeAccountStatusMutationVariables>;
export const ChangeInterviewStatusDocument = gql`
    mutation ChangeInterviewStatus($passed: Boolean!) {
  changeInterviewStatus(passed: $passed) {
    id
    interviewStatus
  }
}
    `;
export type ChangeInterviewStatusMutationFn = Apollo.MutationFunction<ChangeInterviewStatusMutation, ChangeInterviewStatusMutationVariables>;

/**
 * __useChangeInterviewStatusMutation__
 *
 * To run a mutation, you first call `useChangeInterviewStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeInterviewStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeInterviewStatusMutation, { data, loading, error }] = useChangeInterviewStatusMutation({
 *   variables: {
 *      passed: // value for 'passed'
 *   },
 * });
 */
export function useChangeInterviewStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeInterviewStatusMutation, ChangeInterviewStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeInterviewStatusMutation, ChangeInterviewStatusMutationVariables>(ChangeInterviewStatusDocument, options);
      }
export type ChangeInterviewStatusMutationHookResult = ReturnType<typeof useChangeInterviewStatusMutation>;
export type ChangeInterviewStatusMutationResult = Apollo.MutationResult<ChangeInterviewStatusMutation>;
export type ChangeInterviewStatusMutationOptions = Apollo.BaseMutationOptions<ChangeInterviewStatusMutation, ChangeInterviewStatusMutationVariables>;
export const CommentsDocument = gql`
    query Comments($screenId: ID!) {
  comments(screenId: $screenId) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      screenId: // value for 'screenId'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const EditScreenDocument = gql`
    mutation EditScreen($data: EditScreenInput!) {
  editScreen(data: $data) {
    id
    name
    notes
  }
}
    `;
export type EditScreenMutationFn = Apollo.MutationFunction<EditScreenMutation, EditScreenMutationVariables>;

/**
 * __useEditScreenMutation__
 *
 * To run a mutation, you first call `useEditScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScreenMutation, { data, loading, error }] = useEditScreenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditScreenMutation(baseOptions?: Apollo.MutationHookOptions<EditScreenMutation, EditScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditScreenMutation, EditScreenMutationVariables>(EditScreenDocument, options);
      }
export type EditScreenMutationHookResult = ReturnType<typeof useEditScreenMutation>;
export type EditScreenMutationResult = Apollo.MutationResult<EditScreenMutation>;
export type EditScreenMutationOptions = Apollo.BaseMutationOptions<EditScreenMutation, EditScreenMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($data: CreateCommentInput!) {
  createComment(data: $data) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const EditCommentDocument = gql`
    mutation EditComment($data: EditCommentInput!) {
  editComment(data: $data) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type EditCommentMutationFn = Apollo.MutationFunction<EditCommentMutation, EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: Apollo.MutationHookOptions<EditCommentMutation, EditCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCommentMutation, EditCommentMutationVariables>(EditCommentDocument, options);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<EditCommentMutation, EditCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentId: ID!) {
  deleteComment(commentId: $commentId)
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const PublicProfileDocument = gql`
    query PublicProfile {
  user: getUser {
    id
    username
  }
}
    `;

/**
 * __usePublicProfileQuery__
 *
 * To run a query within a React component, call `usePublicProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicProfileQuery(baseOptions?: Apollo.QueryHookOptions<PublicProfileQuery, PublicProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicProfileQuery, PublicProfileQueryVariables>(PublicProfileDocument, options);
      }
export function usePublicProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicProfileQuery, PublicProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicProfileQuery, PublicProfileQueryVariables>(PublicProfileDocument, options);
        }
export type PublicProfileQueryHookResult = ReturnType<typeof usePublicProfileQuery>;
export type PublicProfileLazyQueryHookResult = ReturnType<typeof usePublicProfileLazyQuery>;
export type PublicProfileQueryResult = Apollo.QueryResult<PublicProfileQuery, PublicProfileQueryVariables>;
export const PersonalProfileDocument = gql`
    query PersonalProfile {
  user: getUser {
    id
    firstName
    lastName
    email
    street
    city
    state
    country
    countryCode
    zip
    addressSet
  }
}
    `;

/**
 * __usePersonalProfileQuery__
 *
 * To run a query within a React component, call `usePersonalProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalProfileQuery(baseOptions?: Apollo.QueryHookOptions<PersonalProfileQuery, PersonalProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalProfileQuery, PersonalProfileQueryVariables>(PersonalProfileDocument, options);
      }
export function usePersonalProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalProfileQuery, PersonalProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalProfileQuery, PersonalProfileQueryVariables>(PersonalProfileDocument, options);
        }
export type PersonalProfileQueryHookResult = ReturnType<typeof usePersonalProfileQuery>;
export type PersonalProfileLazyQueryHookResult = ReturnType<typeof usePersonalProfileLazyQuery>;
export type PersonalProfileQueryResult = Apollo.QueryResult<PersonalProfileQuery, PersonalProfileQueryVariables>;
export const UpdatePublicProfileDocument = gql`
    mutation UpdatePublicProfile($data: UpdatePublicProfileInput!) {
  updatePublicProfile(data: $data) {
    id
    username
  }
}
    `;
export type UpdatePublicProfileMutationFn = Apollo.MutationFunction<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables>;

/**
 * __useUpdatePublicProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePublicProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicProfileMutation, { data, loading, error }] = useUpdatePublicProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePublicProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables>(UpdatePublicProfileDocument, options);
      }
export type UpdatePublicProfileMutationHookResult = ReturnType<typeof useUpdatePublicProfileMutation>;
export type UpdatePublicProfileMutationResult = Apollo.MutationResult<UpdatePublicProfileMutation>;
export type UpdatePublicProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePublicProfileMutation, UpdatePublicProfileMutationVariables>;
export const UpdatePersonalProfileDocument = gql`
    mutation UpdatePersonalProfile($data: UpdatePersonalProfileInput!) {
  updatePersonalProfile(data: $data) {
    id
    email
    firstName
    lastName
    country
    street
    city
    state
    zip
    addressSet
  }
}
    `;
export type UpdatePersonalProfileMutationFn = Apollo.MutationFunction<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>;

/**
 * __useUpdatePersonalProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalProfileMutation, { data, loading, error }] = useUpdatePersonalProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePersonalProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>(UpdatePersonalProfileDocument, options);
      }
export type UpdatePersonalProfileMutationHookResult = ReturnType<typeof useUpdatePersonalProfileMutation>;
export type UpdatePersonalProfileMutationResult = Apollo.MutationResult<UpdatePersonalProfileMutation>;
export type UpdatePersonalProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($password: String!) {
  updatePassword(password: $password)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const AllComponentsDocument = gql`
    query AllComponents($filter: String) {
  components: allComponents(filter: $filter) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;

/**
 * __useAllComponentsQuery__
 *
 * To run a query within a React component, call `useAllComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllComponentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllComponentsQuery(baseOptions?: Apollo.QueryHookOptions<AllComponentsQuery, AllComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllComponentsQuery, AllComponentsQueryVariables>(AllComponentsDocument, options);
      }
export function useAllComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllComponentsQuery, AllComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllComponentsQuery, AllComponentsQueryVariables>(AllComponentsDocument, options);
        }
export type AllComponentsQueryHookResult = ReturnType<typeof useAllComponentsQuery>;
export type AllComponentsLazyQueryHookResult = ReturnType<typeof useAllComponentsLazyQuery>;
export type AllComponentsQueryResult = Apollo.QueryResult<AllComponentsQuery, AllComponentsQueryVariables>;
export const ComponentDocument = gql`
    query Component($id: ID!) {
  component(id: $id) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;

/**
 * __useComponentQuery__
 *
 * To run a query within a React component, call `useComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComponentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useComponentQuery(baseOptions: Apollo.QueryHookOptions<ComponentQuery, ComponentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComponentQuery, ComponentQueryVariables>(ComponentDocument, options);
      }
export function useComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComponentQuery, ComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComponentQuery, ComponentQueryVariables>(ComponentDocument, options);
        }
export type ComponentQueryHookResult = ReturnType<typeof useComponentQuery>;
export type ComponentLazyQueryHookResult = ReturnType<typeof useComponentLazyQuery>;
export type ComponentQueryResult = Apollo.QueryResult<ComponentQuery, ComponentQueryVariables>;
export const DeleteComponentDocument = gql`
    mutation DeleteComponent($componentId: ID!) {
  deleteComponent(componentId: $componentId)
}
    `;
export type DeleteComponentMutationFn = Apollo.MutationFunction<DeleteComponentMutation, DeleteComponentMutationVariables>;

/**
 * __useDeleteComponentMutation__
 *
 * To run a mutation, you first call `useDeleteComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComponentMutation, { data, loading, error }] = useDeleteComponentMutation({
 *   variables: {
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useDeleteComponentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteComponentMutation, DeleteComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteComponentMutation, DeleteComponentMutationVariables>(DeleteComponentDocument, options);
      }
export type DeleteComponentMutationHookResult = ReturnType<typeof useDeleteComponentMutation>;
export type DeleteComponentMutationResult = Apollo.MutationResult<DeleteComponentMutation>;
export type DeleteComponentMutationOptions = Apollo.BaseMutationOptions<DeleteComponentMutation, DeleteComponentMutationVariables>;
export const UnlockComponentDocument = gql`
    mutation UnlockComponent($componentId: ID!) {
  unlockComponent(componentId: $componentId) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;
export type UnlockComponentMutationFn = Apollo.MutationFunction<UnlockComponentMutation, UnlockComponentMutationVariables>;

/**
 * __useUnlockComponentMutation__
 *
 * To run a mutation, you first call `useUnlockComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockComponentMutation, { data, loading, error }] = useUnlockComponentMutation({
 *   variables: {
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useUnlockComponentMutation(baseOptions?: Apollo.MutationHookOptions<UnlockComponentMutation, UnlockComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockComponentMutation, UnlockComponentMutationVariables>(UnlockComponentDocument, options);
      }
export type UnlockComponentMutationHookResult = ReturnType<typeof useUnlockComponentMutation>;
export type UnlockComponentMutationResult = Apollo.MutationResult<UnlockComponentMutation>;
export type UnlockComponentMutationOptions = Apollo.BaseMutationOptions<UnlockComponentMutation, UnlockComponentMutationVariables>;
export const AdminSetComponentApprovalStatusDocument = gql`
    mutation AdminSetComponentApprovalStatus($status: ComponentApprovalStatus!, $id: ID!) {
  adminSetComponentApprovalStatus(status: $status, id: $id) {
    id
    approvalStatus
  }
}
    `;
export type AdminSetComponentApprovalStatusMutationFn = Apollo.MutationFunction<AdminSetComponentApprovalStatusMutation, AdminSetComponentApprovalStatusMutationVariables>;

/**
 * __useAdminSetComponentApprovalStatusMutation__
 *
 * To run a mutation, you first call `useAdminSetComponentApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSetComponentApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSetComponentApprovalStatusMutation, { data, loading, error }] = useAdminSetComponentApprovalStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminSetComponentApprovalStatusMutation(baseOptions?: Apollo.MutationHookOptions<AdminSetComponentApprovalStatusMutation, AdminSetComponentApprovalStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminSetComponentApprovalStatusMutation, AdminSetComponentApprovalStatusMutationVariables>(AdminSetComponentApprovalStatusDocument, options);
      }
export type AdminSetComponentApprovalStatusMutationHookResult = ReturnType<typeof useAdminSetComponentApprovalStatusMutation>;
export type AdminSetComponentApprovalStatusMutationResult = Apollo.MutationResult<AdminSetComponentApprovalStatusMutation>;
export type AdminSetComponentApprovalStatusMutationOptions = Apollo.BaseMutationOptions<AdminSetComponentApprovalStatusMutation, AdminSetComponentApprovalStatusMutationVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
    mutation CreateStripeCheckoutSession($data: PurchaseComponentInput!) {
  createStripeCheckoutSession(data: $data)
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const GetRepoDownloadLinkDocument = gql`
    query GetRepoDownloadLink($componentId: String!) {
  repoZipLink(componentId: $componentId)
}
    `;

/**
 * __useGetRepoDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetRepoDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepoDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepoDownloadLinkQuery({
 *   variables: {
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useGetRepoDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<GetRepoDownloadLinkQuery, GetRepoDownloadLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRepoDownloadLinkQuery, GetRepoDownloadLinkQueryVariables>(GetRepoDownloadLinkDocument, options);
      }
export function useGetRepoDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepoDownloadLinkQuery, GetRepoDownloadLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRepoDownloadLinkQuery, GetRepoDownloadLinkQueryVariables>(GetRepoDownloadLinkDocument, options);
        }
export type GetRepoDownloadLinkQueryHookResult = ReturnType<typeof useGetRepoDownloadLinkQuery>;
export type GetRepoDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetRepoDownloadLinkLazyQuery>;
export type GetRepoDownloadLinkQueryResult = Apollo.QueryResult<GetRepoDownloadLinkQuery, GetRepoDownloadLinkQueryVariables>;
export const DeveloperComponentsDocument = gql`
    query DeveloperComponents {
  components: developerComponents {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;

/**
 * __useDeveloperComponentsQuery__
 *
 * To run a query within a React component, call `useDeveloperComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeveloperComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeveloperComponentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeveloperComponentsQuery(baseOptions?: Apollo.QueryHookOptions<DeveloperComponentsQuery, DeveloperComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeveloperComponentsQuery, DeveloperComponentsQueryVariables>(DeveloperComponentsDocument, options);
      }
export function useDeveloperComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeveloperComponentsQuery, DeveloperComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeveloperComponentsQuery, DeveloperComponentsQueryVariables>(DeveloperComponentsDocument, options);
        }
export type DeveloperComponentsQueryHookResult = ReturnType<typeof useDeveloperComponentsQuery>;
export type DeveloperComponentsLazyQueryHookResult = ReturnType<typeof useDeveloperComponentsLazyQuery>;
export type DeveloperComponentsQueryResult = Apollo.QueryResult<DeveloperComponentsQuery, DeveloperComponentsQueryVariables>;
export const PurchasedComponentsDocument = gql`
    query PurchasedComponents {
  components: purchasedComponents {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;

/**
 * __usePurchasedComponentsQuery__
 *
 * To run a query within a React component, call `usePurchasedComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasedComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasedComponentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchasedComponentsQuery(baseOptions?: Apollo.QueryHookOptions<PurchasedComponentsQuery, PurchasedComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchasedComponentsQuery, PurchasedComponentsQueryVariables>(PurchasedComponentsDocument, options);
      }
export function usePurchasedComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasedComponentsQuery, PurchasedComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchasedComponentsQuery, PurchasedComponentsQueryVariables>(PurchasedComponentsDocument, options);
        }
export type PurchasedComponentsQueryHookResult = ReturnType<typeof usePurchasedComponentsQuery>;
export type PurchasedComponentsLazyQueryHookResult = ReturnType<typeof usePurchasedComponentsLazyQuery>;
export type PurchasedComponentsQueryResult = Apollo.QueryResult<PurchasedComponentsQuery, PurchasedComponentsQueryVariables>;
export const RateComponentDocument = gql`
    mutation RateComponent($componentId: ID!, $rating: Float!) {
  rateComponent(componentId: $componentId, rating: $rating) {
    id
    rating
  }
}
    `;
export type RateComponentMutationFn = Apollo.MutationFunction<RateComponentMutation, RateComponentMutationVariables>;

/**
 * __useRateComponentMutation__
 *
 * To run a mutation, you first call `useRateComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateComponentMutation, { data, loading, error }] = useRateComponentMutation({
 *   variables: {
 *      componentId: // value for 'componentId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useRateComponentMutation(baseOptions?: Apollo.MutationHookOptions<RateComponentMutation, RateComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RateComponentMutation, RateComponentMutationVariables>(RateComponentDocument, options);
      }
export type RateComponentMutationHookResult = ReturnType<typeof useRateComponentMutation>;
export type RateComponentMutationResult = Apollo.MutationResult<RateComponentMutation>;
export type RateComponentMutationOptions = Apollo.BaseMutationOptions<RateComponentMutation, RateComponentMutationVariables>;
export const CreateComponentDocument = gql`
    mutation CreateComponent($data: CreateComponentInput!) {
  createComponent(data: $data) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;
export type CreateComponentMutationFn = Apollo.MutationFunction<CreateComponentMutation, CreateComponentMutationVariables>;

/**
 * __useCreateComponentMutation__
 *
 * To run a mutation, you first call `useCreateComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComponentMutation, { data, loading, error }] = useCreateComponentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateComponentMutation(baseOptions?: Apollo.MutationHookOptions<CreateComponentMutation, CreateComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComponentMutation, CreateComponentMutationVariables>(CreateComponentDocument, options);
      }
export type CreateComponentMutationHookResult = ReturnType<typeof useCreateComponentMutation>;
export type CreateComponentMutationResult = Apollo.MutationResult<CreateComponentMutation>;
export type CreateComponentMutationOptions = Apollo.BaseMutationOptions<CreateComponentMutation, CreateComponentMutationVariables>;
export const EditComponentDocument = gql`
    mutation EditComponent($data: EditComponentInput!) {
  editComponent(data: $data) {
    ...Component
  }
}
    ${ComponentFragmentDoc}`;
export type EditComponentMutationFn = Apollo.MutationFunction<EditComponentMutation, EditComponentMutationVariables>;

/**
 * __useEditComponentMutation__
 *
 * To run a mutation, you first call `useEditComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editComponentMutation, { data, loading, error }] = useEditComponentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditComponentMutation(baseOptions?: Apollo.MutationHookOptions<EditComponentMutation, EditComponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditComponentMutation, EditComponentMutationVariables>(EditComponentDocument, options);
      }
export type EditComponentMutationHookResult = ReturnType<typeof useEditComponentMutation>;
export type EditComponentMutationResult = Apollo.MutationResult<EditComponentMutation>;
export type EditComponentMutationOptions = Apollo.BaseMutationOptions<EditComponentMutation, EditComponentMutationVariables>;
export const GitHubReposDocument = gql`
    query GitHubRepos {
  repos {
    repositories {
      full_name
    }
    totalCount
  }
}
    `;

/**
 * __useGitHubReposQuery__
 *
 * To run a query within a React component, call `useGitHubReposQuery` and pass it any options that fit your needs.
 * When your component renders, `useGitHubReposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGitHubReposQuery({
 *   variables: {
 *   },
 * });
 */
export function useGitHubReposQuery(baseOptions?: Apollo.QueryHookOptions<GitHubReposQuery, GitHubReposQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GitHubReposQuery, GitHubReposQueryVariables>(GitHubReposDocument, options);
      }
export function useGitHubReposLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GitHubReposQuery, GitHubReposQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GitHubReposQuery, GitHubReposQueryVariables>(GitHubReposDocument, options);
        }
export type GitHubReposQueryHookResult = ReturnType<typeof useGitHubReposQuery>;
export type GitHubReposLazyQueryHookResult = ReturnType<typeof useGitHubReposLazyQuery>;
export type GitHubReposQueryResult = Apollo.QueryResult<GitHubReposQuery, GitHubReposQueryVariables>;
export const GetRepoContentsDocument = gql`
    query GetRepoContents($fullRepoName: String!) {
  repoContents(fullRepoName: $fullRepoName) {
    path
  }
}
    `;

/**
 * __useGetRepoContentsQuery__
 *
 * To run a query within a React component, call `useGetRepoContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepoContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepoContentsQuery({
 *   variables: {
 *      fullRepoName: // value for 'fullRepoName'
 *   },
 * });
 */
export function useGetRepoContentsQuery(baseOptions: Apollo.QueryHookOptions<GetRepoContentsQuery, GetRepoContentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRepoContentsQuery, GetRepoContentsQueryVariables>(GetRepoContentsDocument, options);
      }
export function useGetRepoContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepoContentsQuery, GetRepoContentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRepoContentsQuery, GetRepoContentsQueryVariables>(GetRepoContentsDocument, options);
        }
export type GetRepoContentsQueryHookResult = ReturnType<typeof useGetRepoContentsQuery>;
export type GetRepoContentsLazyQueryHookResult = ReturnType<typeof useGetRepoContentsLazyQuery>;
export type GetRepoContentsQueryResult = Apollo.QueryResult<GetRepoContentsQuery, GetRepoContentsQueryVariables>;
export const DeveloperDashboardDocument = gql`
    query DeveloperDashboard {
  developerBids {
    ...DeveloperBid
  }
}
    ${DeveloperBidFragmentDoc}`;

/**
 * __useDeveloperDashboardQuery__
 *
 * To run a query within a React component, call `useDeveloperDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeveloperDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeveloperDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeveloperDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DeveloperDashboardQuery, DeveloperDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeveloperDashboardQuery, DeveloperDashboardQueryVariables>(DeveloperDashboardDocument, options);
      }
export function useDeveloperDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeveloperDashboardQuery, DeveloperDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeveloperDashboardQuery, DeveloperDashboardQueryVariables>(DeveloperDashboardDocument, options);
        }
export type DeveloperDashboardQueryHookResult = ReturnType<typeof useDeveloperDashboardQuery>;
export type DeveloperDashboardLazyQueryHookResult = ReturnType<typeof useDeveloperDashboardLazyQuery>;
export type DeveloperDashboardQueryResult = Apollo.QueryResult<DeveloperDashboardQuery, DeveloperDashboardQueryVariables>;
export const UserWithHeardAboutDocument = gql`
    query UserWithHeardAbout {
  getUser {
    id
    heardAbout
  }
}
    `;

/**
 * __useUserWithHeardAboutQuery__
 *
 * To run a query within a React component, call `useUserWithHeardAboutQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithHeardAboutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithHeardAboutQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithHeardAboutQuery(baseOptions?: Apollo.QueryHookOptions<UserWithHeardAboutQuery, UserWithHeardAboutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithHeardAboutQuery, UserWithHeardAboutQueryVariables>(UserWithHeardAboutDocument, options);
      }
export function useUserWithHeardAboutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithHeardAboutQuery, UserWithHeardAboutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithHeardAboutQuery, UserWithHeardAboutQueryVariables>(UserWithHeardAboutDocument, options);
        }
export type UserWithHeardAboutQueryHookResult = ReturnType<typeof useUserWithHeardAboutQuery>;
export type UserWithHeardAboutLazyQueryHookResult = ReturnType<typeof useUserWithHeardAboutLazyQuery>;
export type UserWithHeardAboutQueryResult = Apollo.QueryResult<UserWithHeardAboutQuery, UserWithHeardAboutQueryVariables>;
export const UpdateUserHeardAboutDocument = gql`
    mutation UpdateUserHeardAbout($data: UpdateUserHeardAboutInput!) {
  updateUserHeardAbout(data: $data) {
    id
    heardAbout
  }
}
    `;
export type UpdateUserHeardAboutMutationFn = Apollo.MutationFunction<UpdateUserHeardAboutMutation, UpdateUserHeardAboutMutationVariables>;

/**
 * __useUpdateUserHeardAboutMutation__
 *
 * To run a mutation, you first call `useUpdateUserHeardAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserHeardAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserHeardAboutMutation, { data, loading, error }] = useUpdateUserHeardAboutMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserHeardAboutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserHeardAboutMutation, UpdateUserHeardAboutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserHeardAboutMutation, UpdateUserHeardAboutMutationVariables>(UpdateUserHeardAboutDocument, options);
      }
export type UpdateUserHeardAboutMutationHookResult = ReturnType<typeof useUpdateUserHeardAboutMutation>;
export type UpdateUserHeardAboutMutationResult = Apollo.MutationResult<UpdateUserHeardAboutMutation>;
export type UpdateUserHeardAboutMutationOptions = Apollo.BaseMutationOptions<UpdateUserHeardAboutMutation, UpdateUserHeardAboutMutationVariables>;
export const GetDeveloperDocument = gql`
    query GetDeveloper {
  developer: getUser {
    ...Developer
  }
}
    ${DeveloperFragmentDoc}`;

/**
 * __useGetDeveloperQuery__
 *
 * To run a query within a React component, call `useGetDeveloperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeveloperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeveloperQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeveloperQuery(baseOptions?: Apollo.QueryHookOptions<GetDeveloperQuery, GetDeveloperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeveloperQuery, GetDeveloperQueryVariables>(GetDeveloperDocument, options);
      }
export function useGetDeveloperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeveloperQuery, GetDeveloperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeveloperQuery, GetDeveloperQueryVariables>(GetDeveloperDocument, options);
        }
export type GetDeveloperQueryHookResult = ReturnType<typeof useGetDeveloperQuery>;
export type GetDeveloperLazyQueryHookResult = ReturnType<typeof useGetDeveloperLazyQuery>;
export type GetDeveloperQueryResult = Apollo.QueryResult<GetDeveloperQuery, GetDeveloperQueryVariables>;
export const CreateInterviewDocument = gql`
    mutation CreateInterview {
  createInterview {
    ...Interview
  }
}
    ${InterviewFragmentDoc}`;
export type CreateInterviewMutationFn = Apollo.MutationFunction<CreateInterviewMutation, CreateInterviewMutationVariables>;

/**
 * __useCreateInterviewMutation__
 *
 * To run a mutation, you first call `useCreateInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewMutation, { data, loading, error }] = useCreateInterviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateInterviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateInterviewMutation, CreateInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInterviewMutation, CreateInterviewMutationVariables>(CreateInterviewDocument, options);
      }
export type CreateInterviewMutationHookResult = ReturnType<typeof useCreateInterviewMutation>;
export type CreateInterviewMutationResult = Apollo.MutationResult<CreateInterviewMutation>;
export type CreateInterviewMutationOptions = Apollo.BaseMutationOptions<CreateInterviewMutation, CreateInterviewMutationVariables>;
export const UpdateDeveloperExperienceDocument = gql`
    mutation UpdateDeveloperExperience($data: UpdateDeveloperExperienceInput!) {
  updateDeveloperExperience(data: $data) {
    ...Developer
  }
}
    ${DeveloperFragmentDoc}`;
export type UpdateDeveloperExperienceMutationFn = Apollo.MutationFunction<UpdateDeveloperExperienceMutation, UpdateDeveloperExperienceMutationVariables>;

/**
 * __useUpdateDeveloperExperienceMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperExperienceMutation, { data, loading, error }] = useUpdateDeveloperExperienceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeveloperExperienceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeveloperExperienceMutation, UpdateDeveloperExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeveloperExperienceMutation, UpdateDeveloperExperienceMutationVariables>(UpdateDeveloperExperienceDocument, options);
      }
export type UpdateDeveloperExperienceMutationHookResult = ReturnType<typeof useUpdateDeveloperExperienceMutation>;
export type UpdateDeveloperExperienceMutationResult = Apollo.MutationResult<UpdateDeveloperExperienceMutation>;
export type UpdateDeveloperExperienceMutationOptions = Apollo.BaseMutationOptions<UpdateDeveloperExperienceMutation, UpdateDeveloperExperienceMutationVariables>;
export const UpdateDeveloperToolsDocument = gql`
    mutation UpdateDeveloperTools($data: UpdateDeveloperToolsInput!) {
  updateDeveloperTools(data: $data) {
    id
    storybookLevel
    uiLevel
    figmaLevel
  }
}
    `;
export type UpdateDeveloperToolsMutationFn = Apollo.MutationFunction<UpdateDeveloperToolsMutation, UpdateDeveloperToolsMutationVariables>;

/**
 * __useUpdateDeveloperToolsMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperToolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperToolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperToolsMutation, { data, loading, error }] = useUpdateDeveloperToolsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeveloperToolsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeveloperToolsMutation, UpdateDeveloperToolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeveloperToolsMutation, UpdateDeveloperToolsMutationVariables>(UpdateDeveloperToolsDocument, options);
      }
export type UpdateDeveloperToolsMutationHookResult = ReturnType<typeof useUpdateDeveloperToolsMutation>;
export type UpdateDeveloperToolsMutationResult = Apollo.MutationResult<UpdateDeveloperToolsMutation>;
export type UpdateDeveloperToolsMutationOptions = Apollo.BaseMutationOptions<UpdateDeveloperToolsMutation, UpdateDeveloperToolsMutationVariables>;
export const UpdateDeveloperPersonalDocument = gql`
    mutation UpdateDeveloperPersonal($data: UpdateDeveloperPersonalInput!) {
  updateDeveloperPersonal(data: $data) {
    ...Developer
  }
}
    ${DeveloperFragmentDoc}`;
export type UpdateDeveloperPersonalMutationFn = Apollo.MutationFunction<UpdateDeveloperPersonalMutation, UpdateDeveloperPersonalMutationVariables>;

/**
 * __useUpdateDeveloperPersonalMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperPersonalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperPersonalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperPersonalMutation, { data, loading, error }] = useUpdateDeveloperPersonalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeveloperPersonalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeveloperPersonalMutation, UpdateDeveloperPersonalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeveloperPersonalMutation, UpdateDeveloperPersonalMutationVariables>(UpdateDeveloperPersonalDocument, options);
      }
export type UpdateDeveloperPersonalMutationHookResult = ReturnType<typeof useUpdateDeveloperPersonalMutation>;
export type UpdateDeveloperPersonalMutationResult = Apollo.MutationResult<UpdateDeveloperPersonalMutation>;
export type UpdateDeveloperPersonalMutationOptions = Apollo.BaseMutationOptions<UpdateDeveloperPersonalMutation, UpdateDeveloperPersonalMutationVariables>;
export const UpdateDeveloperSkillsDocument = gql`
    mutation UpdateDeveloperSkills($data: UpdateDeveloperSkillsInput!) {
  updateDeveloperSkills(data: $data) {
    ...Developer
  }
}
    ${DeveloperFragmentDoc}`;
export type UpdateDeveloperSkillsMutationFn = Apollo.MutationFunction<UpdateDeveloperSkillsMutation, UpdateDeveloperSkillsMutationVariables>;

/**
 * __useUpdateDeveloperSkillsMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperSkillsMutation, { data, loading, error }] = useUpdateDeveloperSkillsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeveloperSkillsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeveloperSkillsMutation, UpdateDeveloperSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeveloperSkillsMutation, UpdateDeveloperSkillsMutationVariables>(UpdateDeveloperSkillsDocument, options);
      }
export type UpdateDeveloperSkillsMutationHookResult = ReturnType<typeof useUpdateDeveloperSkillsMutation>;
export type UpdateDeveloperSkillsMutationResult = Apollo.MutationResult<UpdateDeveloperSkillsMutation>;
export type UpdateDeveloperSkillsMutationOptions = Apollo.BaseMutationOptions<UpdateDeveloperSkillsMutation, UpdateDeveloperSkillsMutationVariables>;
export const GetDeveloperWithPaymentDetailsDocument = gql`
    query GetDeveloperWithPaymentDetails {
  developer: getUser {
    ...DeveloperWithPaymentDetails
  }
}
    ${DeveloperWithPaymentDetailsFragmentDoc}`;

/**
 * __useGetDeveloperWithPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetDeveloperWithPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeveloperWithPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeveloperWithPaymentDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeveloperWithPaymentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetDeveloperWithPaymentDetailsQuery, GetDeveloperWithPaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeveloperWithPaymentDetailsQuery, GetDeveloperWithPaymentDetailsQueryVariables>(GetDeveloperWithPaymentDetailsDocument, options);
      }
export function useGetDeveloperWithPaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeveloperWithPaymentDetailsQuery, GetDeveloperWithPaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeveloperWithPaymentDetailsQuery, GetDeveloperWithPaymentDetailsQueryVariables>(GetDeveloperWithPaymentDetailsDocument, options);
        }
export type GetDeveloperWithPaymentDetailsQueryHookResult = ReturnType<typeof useGetDeveloperWithPaymentDetailsQuery>;
export type GetDeveloperWithPaymentDetailsLazyQueryHookResult = ReturnType<typeof useGetDeveloperWithPaymentDetailsLazyQuery>;
export type GetDeveloperWithPaymentDetailsQueryResult = Apollo.QueryResult<GetDeveloperWithPaymentDetailsQuery, GetDeveloperWithPaymentDetailsQueryVariables>;
export const UpdateDeveloperPaymentDetailsDocument = gql`
    mutation UpdateDeveloperPaymentDetails($data: UpdateDeveloperPaymentDetailsInput!) {
  updateDeveloperPaymentDetails(data: $data) {
    ...DeveloperWithPaymentDetails
  }
}
    ${DeveloperWithPaymentDetailsFragmentDoc}`;
export type UpdateDeveloperPaymentDetailsMutationFn = Apollo.MutationFunction<UpdateDeveloperPaymentDetailsMutation, UpdateDeveloperPaymentDetailsMutationVariables>;

/**
 * __useUpdateDeveloperPaymentDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateDeveloperPaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeveloperPaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeveloperPaymentDetailsMutation, { data, loading, error }] = useUpdateDeveloperPaymentDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeveloperPaymentDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeveloperPaymentDetailsMutation, UpdateDeveloperPaymentDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeveloperPaymentDetailsMutation, UpdateDeveloperPaymentDetailsMutationVariables>(UpdateDeveloperPaymentDetailsDocument, options);
      }
export type UpdateDeveloperPaymentDetailsMutationHookResult = ReturnType<typeof useUpdateDeveloperPaymentDetailsMutation>;
export type UpdateDeveloperPaymentDetailsMutationResult = Apollo.MutationResult<UpdateDeveloperPaymentDetailsMutation>;
export type UpdateDeveloperPaymentDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateDeveloperPaymentDetailsMutation, UpdateDeveloperPaymentDetailsMutationVariables>;
export const VendorDetailsDocument = gql`
    query VendorDetails {
  vendorDetails {
    vendorId
    accountStatus
    processingStatus
    payoutStatus
    lastPayoutDate
    payoutCurrency
    lastPayoutAmount
    accountBalance
  }
}
    `;

/**
 * __useVendorDetailsQuery__
 *
 * To run a query within a React component, call `useVendorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorDetailsQuery(baseOptions?: Apollo.QueryHookOptions<VendorDetailsQuery, VendorDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorDetailsQuery, VendorDetailsQueryVariables>(VendorDetailsDocument, options);
      }
export function useVendorDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorDetailsQuery, VendorDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorDetailsQuery, VendorDetailsQueryVariables>(VendorDetailsDocument, options);
        }
export type VendorDetailsQueryHookResult = ReturnType<typeof useVendorDetailsQuery>;
export type VendorDetailsLazyQueryHookResult = ReturnType<typeof useVendorDetailsLazyQuery>;
export type VendorDetailsQueryResult = Apollo.QueryResult<VendorDetailsQuery, VendorDetailsQueryVariables>;
export const DeveloperProfileDocument = gql`
    query DeveloperProfile($id: ID!) {
  developer(id: $id) {
    id
    username
    programmingLanguages
    frontendFrameworks
    stylingLibraries
    portfolio
    yearsOfExperience
    yearsOfExperienceReact
    yearsOfExperienceReactNative
    yearsOfExperienceVue
    yearsOfExperienceAngular
    yearsOfExperienceSvelte
    yearsOfExperienceWordpress
    yearsOfExperienceJavascript
    yearsOfExperienceTypescript
    yearsOfExperienceFlow
    reviews {
      ...Review
    }
    interviewDeployedProject {
      id
      netlifyUrl
      githubUrl
    }
  }
}
    ${ReviewFragmentDoc}`;

/**
 * __useDeveloperProfileQuery__
 *
 * To run a query within a React component, call `useDeveloperProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeveloperProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeveloperProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeveloperProfileQuery(baseOptions: Apollo.QueryHookOptions<DeveloperProfileQuery, DeveloperProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeveloperProfileQuery, DeveloperProfileQueryVariables>(DeveloperProfileDocument, options);
      }
export function useDeveloperProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeveloperProfileQuery, DeveloperProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeveloperProfileQuery, DeveloperProfileQueryVariables>(DeveloperProfileDocument, options);
        }
export type DeveloperProfileQueryHookResult = ReturnType<typeof useDeveloperProfileQuery>;
export type DeveloperProfileLazyQueryHookResult = ReturnType<typeof useDeveloperProfileLazyQuery>;
export type DeveloperProfileQueryResult = Apollo.QueryResult<DeveloperProfileQuery, DeveloperProfileQueryVariables>;
export const GradeInterviewDocument = gql`
    mutation GradeInterview($data: GradeInterviewInput!) {
  gradeInterview(data: $data) {
    id
    interviewStatus
  }
}
    `;
export type GradeInterviewMutationFn = Apollo.MutationFunction<GradeInterviewMutation, GradeInterviewMutationVariables>;

/**
 * __useGradeInterviewMutation__
 *
 * To run a mutation, you first call `useGradeInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeInterviewMutation, { data, loading, error }] = useGradeInterviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGradeInterviewMutation(baseOptions?: Apollo.MutationHookOptions<GradeInterviewMutation, GradeInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GradeInterviewMutation, GradeInterviewMutationVariables>(GradeInterviewDocument, options);
      }
export type GradeInterviewMutationHookResult = ReturnType<typeof useGradeInterviewMutation>;
export type GradeInterviewMutationResult = Apollo.MutationResult<GradeInterviewMutation>;
export type GradeInterviewMutationOptions = Apollo.BaseMutationOptions<GradeInterviewMutation, GradeInterviewMutationVariables>;
export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($userId: ID!) {
  adminDeleteUser(userId: $userId)
}
    `;
export type AdminDeleteUserMutationFn = Apollo.MutationFunction<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, options);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const UserWithPortfolioDocument = gql`
    query UserWithPortfolio {
  getUser {
    id
    portfolio
  }
}
    `;

/**
 * __useUserWithPortfolioQuery__
 *
 * To run a query within a React component, call `useUserWithPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithPortfolioQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithPortfolioQuery(baseOptions?: Apollo.QueryHookOptions<UserWithPortfolioQuery, UserWithPortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithPortfolioQuery, UserWithPortfolioQueryVariables>(UserWithPortfolioDocument, options);
      }
export function useUserWithPortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithPortfolioQuery, UserWithPortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithPortfolioQuery, UserWithPortfolioQueryVariables>(UserWithPortfolioDocument, options);
        }
export type UserWithPortfolioQueryHookResult = ReturnType<typeof useUserWithPortfolioQuery>;
export type UserWithPortfolioLazyQueryHookResult = ReturnType<typeof useUserWithPortfolioLazyQuery>;
export type UserWithPortfolioQueryResult = Apollo.QueryResult<UserWithPortfolioQuery, UserWithPortfolioQueryVariables>;
export const UpdatePortfolioDocument = gql`
    mutation UpdatePortfolio($data: UpdatePortfolioInput!) {
  updatePortfolio(data: $data) {
    id
    portfolio
  }
}
    `;
export type UpdatePortfolioMutationFn = Apollo.MutationFunction<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;

/**
 * __useUpdatePortfolioMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioMutation, { data, loading, error }] = useUpdatePortfolioMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>(UpdatePortfolioDocument, options);
      }
export type UpdatePortfolioMutationHookResult = ReturnType<typeof useUpdatePortfolioMutation>;
export type UpdatePortfolioMutationResult = Apollo.MutationResult<UpdatePortfolioMutation>;
export type UpdatePortfolioMutationOptions = Apollo.BaseMutationOptions<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>;
export const UserWithHourlyRateDocument = gql`
    query UserWithHourlyRate {
  getUser {
    id
    hourlyRateUsd
  }
}
    `;

/**
 * __useUserWithHourlyRateQuery__
 *
 * To run a query within a React component, call `useUserWithHourlyRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithHourlyRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithHourlyRateQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithHourlyRateQuery(baseOptions?: Apollo.QueryHookOptions<UserWithHourlyRateQuery, UserWithHourlyRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithHourlyRateQuery, UserWithHourlyRateQueryVariables>(UserWithHourlyRateDocument, options);
      }
export function useUserWithHourlyRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithHourlyRateQuery, UserWithHourlyRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithHourlyRateQuery, UserWithHourlyRateQueryVariables>(UserWithHourlyRateDocument, options);
        }
export type UserWithHourlyRateQueryHookResult = ReturnType<typeof useUserWithHourlyRateQuery>;
export type UserWithHourlyRateLazyQueryHookResult = ReturnType<typeof useUserWithHourlyRateLazyQuery>;
export type UserWithHourlyRateQueryResult = Apollo.QueryResult<UserWithHourlyRateQuery, UserWithHourlyRateQueryVariables>;
export const UpdateHourlyRateDocument = gql`
    mutation UpdateHourlyRate($data: UpdateHourlyRateInput!) {
  updateHourlyRate(data: $data) {
    id
    hourlyRateUsd
  }
}
    `;
export type UpdateHourlyRateMutationFn = Apollo.MutationFunction<UpdateHourlyRateMutation, UpdateHourlyRateMutationVariables>;

/**
 * __useUpdateHourlyRateMutation__
 *
 * To run a mutation, you first call `useUpdateHourlyRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHourlyRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHourlyRateMutation, { data, loading, error }] = useUpdateHourlyRateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateHourlyRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHourlyRateMutation, UpdateHourlyRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHourlyRateMutation, UpdateHourlyRateMutationVariables>(UpdateHourlyRateDocument, options);
      }
export type UpdateHourlyRateMutationHookResult = ReturnType<typeof useUpdateHourlyRateMutation>;
export type UpdateHourlyRateMutationResult = Apollo.MutationResult<UpdateHourlyRateMutation>;
export type UpdateHourlyRateMutationOptions = Apollo.BaseMutationOptions<UpdateHourlyRateMutation, UpdateHourlyRateMutationVariables>;
export const UserWithIntroVideoDocument = gql`
    query UserWithIntroVideo {
  getUser {
    id
    introVideoUrl
  }
}
    `;

/**
 * __useUserWithIntroVideoQuery__
 *
 * To run a query within a React component, call `useUserWithIntroVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithIntroVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithIntroVideoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithIntroVideoQuery(baseOptions?: Apollo.QueryHookOptions<UserWithIntroVideoQuery, UserWithIntroVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithIntroVideoQuery, UserWithIntroVideoQueryVariables>(UserWithIntroVideoDocument, options);
      }
export function useUserWithIntroVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithIntroVideoQuery, UserWithIntroVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithIntroVideoQuery, UserWithIntroVideoQueryVariables>(UserWithIntroVideoDocument, options);
        }
export type UserWithIntroVideoQueryHookResult = ReturnType<typeof useUserWithIntroVideoQuery>;
export type UserWithIntroVideoLazyQueryHookResult = ReturnType<typeof useUserWithIntroVideoLazyQuery>;
export type UserWithIntroVideoQueryResult = Apollo.QueryResult<UserWithIntroVideoQuery, UserWithIntroVideoQueryVariables>;
export const UpdateIntroVideoDocument = gql`
    mutation UpdateIntroVideo($data: UpdateIntroVideoInput!) {
  updateIntroVideo(data: $data) {
    id
    introVideoUrl
  }
}
    `;
export type UpdateIntroVideoMutationFn = Apollo.MutationFunction<UpdateIntroVideoMutation, UpdateIntroVideoMutationVariables>;

/**
 * __useUpdateIntroVideoMutation__
 *
 * To run a mutation, you first call `useUpdateIntroVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntroVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntroVideoMutation, { data, loading, error }] = useUpdateIntroVideoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateIntroVideoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntroVideoMutation, UpdateIntroVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntroVideoMutation, UpdateIntroVideoMutationVariables>(UpdateIntroVideoDocument, options);
      }
export type UpdateIntroVideoMutationHookResult = ReturnType<typeof useUpdateIntroVideoMutation>;
export type UpdateIntroVideoMutationResult = Apollo.MutationResult<UpdateIntroVideoMutation>;
export type UpdateIntroVideoMutationOptions = Apollo.BaseMutationOptions<UpdateIntroVideoMutation, UpdateIntroVideoMutationVariables>;
export const AdminDeveloperProfileDocument = gql`
    query AdminDeveloperProfile($id: ID!) {
  adminUser(id: $id) {
    ...AdminUser
  }
}
    ${AdminUserFragmentDoc}`;

/**
 * __useAdminDeveloperProfileQuery__
 *
 * To run a query within a React component, call `useAdminDeveloperProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDeveloperProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDeveloperProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeveloperProfileQuery(baseOptions: Apollo.QueryHookOptions<AdminDeveloperProfileQuery, AdminDeveloperProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDeveloperProfileQuery, AdminDeveloperProfileQueryVariables>(AdminDeveloperProfileDocument, options);
      }
export function useAdminDeveloperProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDeveloperProfileQuery, AdminDeveloperProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDeveloperProfileQuery, AdminDeveloperProfileQueryVariables>(AdminDeveloperProfileDocument, options);
        }
export type AdminDeveloperProfileQueryHookResult = ReturnType<typeof useAdminDeveloperProfileQuery>;
export type AdminDeveloperProfileLazyQueryHookResult = ReturnType<typeof useAdminDeveloperProfileLazyQuery>;
export type AdminDeveloperProfileQueryResult = Apollo.QueryResult<AdminDeveloperProfileQuery, AdminDeveloperProfileQueryVariables>;
export const OpenProjectsDocument = gql`
    query OpenProjects {
  openProjects {
    id
    name
    programmingLanguage
    frontendFramework
    styling
    deadline
    bid {
      id
      status
      amount
      deliveryDate
      deleted
    }
  }
}
    `;

/**
 * __useOpenProjectsQuery__
 *
 * To run a query within a React component, call `useOpenProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenProjectsQuery(baseOptions?: Apollo.QueryHookOptions<OpenProjectsQuery, OpenProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenProjectsQuery, OpenProjectsQueryVariables>(OpenProjectsDocument, options);
      }
export function useOpenProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenProjectsQuery, OpenProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenProjectsQuery, OpenProjectsQueryVariables>(OpenProjectsDocument, options);
        }
export type OpenProjectsQueryHookResult = ReturnType<typeof useOpenProjectsQuery>;
export type OpenProjectsLazyQueryHookResult = ReturnType<typeof useOpenProjectsLazyQuery>;
export type OpenProjectsQueryResult = Apollo.QueryResult<OpenProjectsQuery, OpenProjectsQueryVariables>;
export const DeveloperWithQuickApplyDocument = gql`
    query DeveloperWithQuickApply {
  getUser {
    ...DeveloperQuickApply
  }
}
    ${DeveloperQuickApplyFragmentDoc}`;

/**
 * __useDeveloperWithQuickApplyQuery__
 *
 * To run a query within a React component, call `useDeveloperWithQuickApplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeveloperWithQuickApplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeveloperWithQuickApplyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeveloperWithQuickApplyQuery(baseOptions?: Apollo.QueryHookOptions<DeveloperWithQuickApplyQuery, DeveloperWithQuickApplyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeveloperWithQuickApplyQuery, DeveloperWithQuickApplyQueryVariables>(DeveloperWithQuickApplyDocument, options);
      }
export function useDeveloperWithQuickApplyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeveloperWithQuickApplyQuery, DeveloperWithQuickApplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeveloperWithQuickApplyQuery, DeveloperWithQuickApplyQueryVariables>(DeveloperWithQuickApplyDocument, options);
        }
export type DeveloperWithQuickApplyQueryHookResult = ReturnType<typeof useDeveloperWithQuickApplyQuery>;
export type DeveloperWithQuickApplyLazyQueryHookResult = ReturnType<typeof useDeveloperWithQuickApplyLazyQuery>;
export type DeveloperWithQuickApplyQueryResult = Apollo.QueryResult<DeveloperWithQuickApplyQuery, DeveloperWithQuickApplyQueryVariables>;
export const SubmitQuickApplyDocument = gql`
    mutation SubmitQuickApply {
  submitQuickApply {
    ...DeveloperQuickApply
  }
}
    ${DeveloperQuickApplyFragmentDoc}`;
export type SubmitQuickApplyMutationFn = Apollo.MutationFunction<SubmitQuickApplyMutation, SubmitQuickApplyMutationVariables>;

/**
 * __useSubmitQuickApplyMutation__
 *
 * To run a mutation, you first call `useSubmitQuickApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuickApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuickApplyMutation, { data, loading, error }] = useSubmitQuickApplyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitQuickApplyMutation(baseOptions?: Apollo.MutationHookOptions<SubmitQuickApplyMutation, SubmitQuickApplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitQuickApplyMutation, SubmitQuickApplyMutationVariables>(SubmitQuickApplyDocument, options);
      }
export type SubmitQuickApplyMutationHookResult = ReturnType<typeof useSubmitQuickApplyMutation>;
export type SubmitQuickApplyMutationResult = Apollo.MutationResult<SubmitQuickApplyMutation>;
export type SubmitQuickApplyMutationOptions = Apollo.BaseMutationOptions<SubmitQuickApplyMutation, SubmitQuickApplyMutationVariables>;
export const UserWithHeardAboutAndSkillsDocument = gql`
    query UserWithHeardAboutAndSkills {
  getUser {
    id
    heardAbout
    programmingLanguages
    frontendFrameworks
    stylingLibraries
  }
}
    `;

/**
 * __useUserWithHeardAboutAndSkillsQuery__
 *
 * To run a query within a React component, call `useUserWithHeardAboutAndSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithHeardAboutAndSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithHeardAboutAndSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithHeardAboutAndSkillsQuery(baseOptions?: Apollo.QueryHookOptions<UserWithHeardAboutAndSkillsQuery, UserWithHeardAboutAndSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithHeardAboutAndSkillsQuery, UserWithHeardAboutAndSkillsQueryVariables>(UserWithHeardAboutAndSkillsDocument, options);
      }
export function useUserWithHeardAboutAndSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithHeardAboutAndSkillsQuery, UserWithHeardAboutAndSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithHeardAboutAndSkillsQuery, UserWithHeardAboutAndSkillsQueryVariables>(UserWithHeardAboutAndSkillsDocument, options);
        }
export type UserWithHeardAboutAndSkillsQueryHookResult = ReturnType<typeof useUserWithHeardAboutAndSkillsQuery>;
export type UserWithHeardAboutAndSkillsLazyQueryHookResult = ReturnType<typeof useUserWithHeardAboutAndSkillsLazyQuery>;
export type UserWithHeardAboutAndSkillsQueryResult = Apollo.QueryResult<UserWithHeardAboutAndSkillsQuery, UserWithHeardAboutAndSkillsQueryVariables>;
export const GetInterviewDocument = gql`
    query GetInterview {
  interview {
    ...Interview
  }
}
    ${InterviewFragmentDoc}`;

/**
 * __useGetInterviewQuery__
 *
 * To run a query within a React component, call `useGetInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInterviewQuery(baseOptions?: Apollo.QueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterviewQuery, GetInterviewQueryVariables>(GetInterviewDocument, options);
      }
export function useGetInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterviewQuery, GetInterviewQueryVariables>(GetInterviewDocument, options);
        }
export type GetInterviewQueryHookResult = ReturnType<typeof useGetInterviewQuery>;
export type GetInterviewLazyQueryHookResult = ReturnType<typeof useGetInterviewLazyQuery>;
export type GetInterviewQueryResult = Apollo.QueryResult<GetInterviewQuery, GetInterviewQueryVariables>;
export const InterviewDevProjectDocument = gql`
    query InterviewDevProject {
  interviewDevProject {
    id
    deployedProject {
      ...DeployedProject
    }
  }
}
    ${DeployedProjectFragmentDoc}`;

/**
 * __useInterviewDevProjectQuery__
 *
 * To run a query within a React component, call `useInterviewDevProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewDevProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewDevProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewDevProjectQuery(baseOptions?: Apollo.QueryHookOptions<InterviewDevProjectQuery, InterviewDevProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewDevProjectQuery, InterviewDevProjectQueryVariables>(InterviewDevProjectDocument, options);
      }
export function useInterviewDevProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewDevProjectQuery, InterviewDevProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewDevProjectQuery, InterviewDevProjectQueryVariables>(InterviewDevProjectDocument, options);
        }
export type InterviewDevProjectQueryHookResult = ReturnType<typeof useInterviewDevProjectQuery>;
export type InterviewDevProjectLazyQueryHookResult = ReturnType<typeof useInterviewDevProjectLazyQuery>;
export type InterviewDevProjectQueryResult = Apollo.QueryResult<InterviewDevProjectQuery, InterviewDevProjectQueryVariables>;
export const CreateInterviewDeployedProjectDocument = gql`
    mutation CreateInterviewDeployedProject($data: CreateInterviewInput!) {
  createInterviewDeployedProject(data: $data) {
    ...DeployedProject
  }
}
    ${DeployedProjectFragmentDoc}`;
export type CreateInterviewDeployedProjectMutationFn = Apollo.MutationFunction<CreateInterviewDeployedProjectMutation, CreateInterviewDeployedProjectMutationVariables>;

/**
 * __useCreateInterviewDeployedProjectMutation__
 *
 * To run a mutation, you first call `useCreateInterviewDeployedProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewDeployedProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewDeployedProjectMutation, { data, loading, error }] = useCreateInterviewDeployedProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInterviewDeployedProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateInterviewDeployedProjectMutation, CreateInterviewDeployedProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInterviewDeployedProjectMutation, CreateInterviewDeployedProjectMutationVariables>(CreateInterviewDeployedProjectDocument, options);
      }
export type CreateInterviewDeployedProjectMutationHookResult = ReturnType<typeof useCreateInterviewDeployedProjectMutation>;
export type CreateInterviewDeployedProjectMutationResult = Apollo.MutationResult<CreateInterviewDeployedProjectMutation>;
export type CreateInterviewDeployedProjectMutationOptions = Apollo.BaseMutationOptions<CreateInterviewDeployedProjectMutation, CreateInterviewDeployedProjectMutationVariables>;
export const DeleteDeployedProjectDocument = gql`
    mutation DeleteDeployedProject($deployedProjectId: String!) {
  deleteDeployedProject(deployedProjectId: $deployedProjectId)
}
    `;
export type DeleteDeployedProjectMutationFn = Apollo.MutationFunction<DeleteDeployedProjectMutation, DeleteDeployedProjectMutationVariables>;

/**
 * __useDeleteDeployedProjectMutation__
 *
 * To run a mutation, you first call `useDeleteDeployedProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeployedProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeployedProjectMutation, { data, loading, error }] = useDeleteDeployedProjectMutation({
 *   variables: {
 *      deployedProjectId: // value for 'deployedProjectId'
 *   },
 * });
 */
export function useDeleteDeployedProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeployedProjectMutation, DeleteDeployedProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeployedProjectMutation, DeleteDeployedProjectMutationVariables>(DeleteDeployedProjectDocument, options);
      }
export type DeleteDeployedProjectMutationHookResult = ReturnType<typeof useDeleteDeployedProjectMutation>;
export type DeleteDeployedProjectMutationResult = Apollo.MutationResult<DeleteDeployedProjectMutation>;
export type DeleteDeployedProjectMutationOptions = Apollo.BaseMutationOptions<DeleteDeployedProjectMutation, DeleteDeployedProjectMutationVariables>;
export const SubmitInterviewDocument = gql`
    mutation SubmitInterview {
  submitInterview {
    id
    devProjectStatus
    videoResponseStatus
  }
}
    `;
export type SubmitInterviewMutationFn = Apollo.MutationFunction<SubmitInterviewMutation, SubmitInterviewMutationVariables>;

/**
 * __useSubmitInterviewMutation__
 *
 * To run a mutation, you first call `useSubmitInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInterviewMutation, { data, loading, error }] = useSubmitInterviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitInterviewMutation(baseOptions?: Apollo.MutationHookOptions<SubmitInterviewMutation, SubmitInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitInterviewMutation, SubmitInterviewMutationVariables>(SubmitInterviewDocument, options);
      }
export type SubmitInterviewMutationHookResult = ReturnType<typeof useSubmitInterviewMutation>;
export type SubmitInterviewMutationResult = Apollo.MutationResult<SubmitInterviewMutation>;
export type SubmitInterviewMutationOptions = Apollo.BaseMutationOptions<SubmitInterviewMutation, SubmitInterviewMutationVariables>;
export const AdminDeleteRemoteProjectDocument = gql`
    mutation AdminDeleteRemoteProject($projectType: String!, $interview: Boolean!, $projectName: String) {
  adminDeleteRemoteProject(
    projectType: $projectType
    interview: $interview
    projectName: $projectName
  )
}
    `;
export type AdminDeleteRemoteProjectMutationFn = Apollo.MutationFunction<AdminDeleteRemoteProjectMutation, AdminDeleteRemoteProjectMutationVariables>;

/**
 * __useAdminDeleteRemoteProjectMutation__
 *
 * To run a mutation, you first call `useAdminDeleteRemoteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteRemoteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteRemoteProjectMutation, { data, loading, error }] = useAdminDeleteRemoteProjectMutation({
 *   variables: {
 *      projectType: // value for 'projectType'
 *      interview: // value for 'interview'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useAdminDeleteRemoteProjectMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteRemoteProjectMutation, AdminDeleteRemoteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteRemoteProjectMutation, AdminDeleteRemoteProjectMutationVariables>(AdminDeleteRemoteProjectDocument, options);
      }
export type AdminDeleteRemoteProjectMutationHookResult = ReturnType<typeof useAdminDeleteRemoteProjectMutation>;
export type AdminDeleteRemoteProjectMutationResult = Apollo.MutationResult<AdminDeleteRemoteProjectMutation>;
export type AdminDeleteRemoteProjectMutationOptions = Apollo.BaseMutationOptions<AdminDeleteRemoteProjectMutation, AdminDeleteRemoteProjectMutationVariables>;
export const CompleteVideoInterviewDocument = gql`
    mutation CompleteVideoInterview {
  completeVideoInterview {
    ...Interview
  }
}
    ${InterviewFragmentDoc}`;
export type CompleteVideoInterviewMutationFn = Apollo.MutationFunction<CompleteVideoInterviewMutation, CompleteVideoInterviewMutationVariables>;

/**
 * __useCompleteVideoInterviewMutation__
 *
 * To run a mutation, you first call `useCompleteVideoInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteVideoInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeVideoInterviewMutation, { data, loading, error }] = useCompleteVideoInterviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteVideoInterviewMutation(baseOptions?: Apollo.MutationHookOptions<CompleteVideoInterviewMutation, CompleteVideoInterviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteVideoInterviewMutation, CompleteVideoInterviewMutationVariables>(CompleteVideoInterviewDocument, options);
      }
export type CompleteVideoInterviewMutationHookResult = ReturnType<typeof useCompleteVideoInterviewMutation>;
export type CompleteVideoInterviewMutationResult = Apollo.MutationResult<CompleteVideoInterviewMutation>;
export type CompleteVideoInterviewMutationOptions = Apollo.BaseMutationOptions<CompleteVideoInterviewMutation, CompleteVideoInterviewMutationVariables>;
export const CreateProjectAdvancedDocument = gql`
    mutation CreateProjectAdvanced($projectId: ID!, $data: CreateProjectAdvancedInput!) {
  createProjectAdvanced(projectId: $projectId, data: $data) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type CreateProjectAdvancedMutationFn = Apollo.MutationFunction<CreateProjectAdvancedMutation, CreateProjectAdvancedMutationVariables>;

/**
 * __useCreateProjectAdvancedMutation__
 *
 * To run a mutation, you first call `useCreateProjectAdvancedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectAdvancedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectAdvancedMutation, { data, loading, error }] = useCreateProjectAdvancedMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectAdvancedMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectAdvancedMutation, CreateProjectAdvancedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectAdvancedMutation, CreateProjectAdvancedMutationVariables>(CreateProjectAdvancedDocument, options);
      }
export type CreateProjectAdvancedMutationHookResult = ReturnType<typeof useCreateProjectAdvancedMutation>;
export type CreateProjectAdvancedMutationResult = Apollo.MutationResult<CreateProjectAdvancedMutation>;
export type CreateProjectAdvancedMutationOptions = Apollo.BaseMutationOptions<CreateProjectAdvancedMutation, CreateProjectAdvancedMutationVariables>;
export const CreateProjectFigmaDocument = gql`
    mutation CreateProjectFigma($projectId: ID!, $data: CreateProjectFigmaInput!) {
  createProjectFigma(projectId: $projectId, data: $data) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type CreateProjectFigmaMutationFn = Apollo.MutationFunction<CreateProjectFigmaMutation, CreateProjectFigmaMutationVariables>;

/**
 * __useCreateProjectFigmaMutation__
 *
 * To run a mutation, you first call `useCreateProjectFigmaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectFigmaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectFigmaMutation, { data, loading, error }] = useCreateProjectFigmaMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectFigmaMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectFigmaMutation, CreateProjectFigmaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectFigmaMutation, CreateProjectFigmaMutationVariables>(CreateProjectFigmaDocument, options);
      }
export type CreateProjectFigmaMutationHookResult = ReturnType<typeof useCreateProjectFigmaMutation>;
export type CreateProjectFigmaMutationResult = Apollo.MutationResult<CreateProjectFigmaMutation>;
export type CreateProjectFigmaMutationOptions = Apollo.BaseMutationOptions<CreateProjectFigmaMutation, CreateProjectFigmaMutationVariables>;
export const CreateProjectImageDocument = gql`
    mutation createProjectImage($data: CreateProjectImageInput!, $projectId: ID!) {
  createProjectImage(data: $data, projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type CreateProjectImageMutationFn = Apollo.MutationFunction<CreateProjectImageMutation, CreateProjectImageMutationVariables>;

/**
 * __useCreateProjectImageMutation__
 *
 * To run a mutation, you first call `useCreateProjectImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectImageMutation, { data, loading, error }] = useCreateProjectImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateProjectImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectImageMutation, CreateProjectImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectImageMutation, CreateProjectImageMutationVariables>(CreateProjectImageDocument, options);
      }
export type CreateProjectImageMutationHookResult = ReturnType<typeof useCreateProjectImageMutation>;
export type CreateProjectImageMutationResult = Apollo.MutationResult<CreateProjectImageMutation>;
export type CreateProjectImageMutationOptions = Apollo.BaseMutationOptions<CreateProjectImageMutation, CreateProjectImageMutationVariables>;
export const CreateProjectOverviewDocument = gql`
    mutation CreateProjectOverview($projectId: ID, $data: CreateProjectOverviewInput!) {
  createProjectOverview(projectId: $projectId, data: $data) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type CreateProjectOverviewMutationFn = Apollo.MutationFunction<CreateProjectOverviewMutation, CreateProjectOverviewMutationVariables>;

/**
 * __useCreateProjectOverviewMutation__
 *
 * To run a mutation, you first call `useCreateProjectOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectOverviewMutation, { data, loading, error }] = useCreateProjectOverviewMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectOverviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectOverviewMutation, CreateProjectOverviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectOverviewMutation, CreateProjectOverviewMutationVariables>(CreateProjectOverviewDocument, options);
      }
export type CreateProjectOverviewMutationHookResult = ReturnType<typeof useCreateProjectOverviewMutation>;
export type CreateProjectOverviewMutationResult = Apollo.MutationResult<CreateProjectOverviewMutation>;
export type CreateProjectOverviewMutationOptions = Apollo.BaseMutationOptions<CreateProjectOverviewMutation, CreateProjectOverviewMutationVariables>;
export const CreateProjectZeplinDocument = gql`
    mutation CreateProjectZeplin($projectId: ID!, $data: CreateProjectZeplinInput!) {
  createProjectZeplin(projectId: $projectId, data: $data) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type CreateProjectZeplinMutationFn = Apollo.MutationFunction<CreateProjectZeplinMutation, CreateProjectZeplinMutationVariables>;

/**
 * __useCreateProjectZeplinMutation__
 *
 * To run a mutation, you first call `useCreateProjectZeplinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectZeplinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectZeplinMutation, { data, loading, error }] = useCreateProjectZeplinMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectZeplinMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectZeplinMutation, CreateProjectZeplinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectZeplinMutation, CreateProjectZeplinMutationVariables>(CreateProjectZeplinDocument, options);
      }
export type CreateProjectZeplinMutationHookResult = ReturnType<typeof useCreateProjectZeplinMutation>;
export type CreateProjectZeplinMutationResult = Apollo.MutationResult<CreateProjectZeplinMutation>;
export type CreateProjectZeplinMutationOptions = Apollo.BaseMutationOptions<CreateProjectZeplinMutation, CreateProjectZeplinMutationVariables>;
export const BidPaymentDocument = gql`
    query BidPayment($bidId: String!) {
  bidPayment(bidId: $bidId)
}
    `;

/**
 * __useBidPaymentQuery__
 *
 * To run a query within a React component, call `useBidPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidPaymentQuery({
 *   variables: {
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useBidPaymentQuery(baseOptions: Apollo.QueryHookOptions<BidPaymentQuery, BidPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BidPaymentQuery, BidPaymentQueryVariables>(BidPaymentDocument, options);
      }
export function useBidPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BidPaymentQuery, BidPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BidPaymentQuery, BidPaymentQueryVariables>(BidPaymentDocument, options);
        }
export type BidPaymentQueryHookResult = ReturnType<typeof useBidPaymentQuery>;
export type BidPaymentLazyQueryHookResult = ReturnType<typeof useBidPaymentLazyQuery>;
export type BidPaymentQueryResult = Apollo.QueryResult<BidPaymentQuery, BidPaymentQueryVariables>;
export const PastPaymentsDocument = gql`
    query pastPayments {
  pastPayments {
    ...Payment
  }
}
    ${PaymentFragmentDoc}`;

/**
 * __usePastPaymentsQuery__
 *
 * To run a query within a React component, call `usePastPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePastPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePastPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePastPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<PastPaymentsQuery, PastPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PastPaymentsQuery, PastPaymentsQueryVariables>(PastPaymentsDocument, options);
      }
export function usePastPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PastPaymentsQuery, PastPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PastPaymentsQuery, PastPaymentsQueryVariables>(PastPaymentsDocument, options);
        }
export type PastPaymentsQueryHookResult = ReturnType<typeof usePastPaymentsQuery>;
export type PastPaymentsLazyQueryHookResult = ReturnType<typeof usePastPaymentsLazyQuery>;
export type PastPaymentsQueryResult = Apollo.QueryResult<PastPaymentsQuery, PastPaymentsQueryVariables>;
export const RespondToBidDocument = gql`
    mutation RespondToBid($data: RespondToBidInput!) {
  respondToBid(data: $data) {
    ...Bid
  }
}
    ${BidFragmentDoc}`;
export type RespondToBidMutationFn = Apollo.MutationFunction<RespondToBidMutation, RespondToBidMutationVariables>;

/**
 * __useRespondToBidMutation__
 *
 * To run a mutation, you first call `useRespondToBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToBidMutation, { data, loading, error }] = useRespondToBidMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRespondToBidMutation(baseOptions?: Apollo.MutationHookOptions<RespondToBidMutation, RespondToBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RespondToBidMutation, RespondToBidMutationVariables>(RespondToBidDocument, options);
      }
export type RespondToBidMutationHookResult = ReturnType<typeof useRespondToBidMutation>;
export type RespondToBidMutationResult = Apollo.MutationResult<RespondToBidMutation>;
export type RespondToBidMutationOptions = Apollo.BaseMutationOptions<RespondToBidMutation, RespondToBidMutationVariables>;
export const CreateBidDocument = gql`
    mutation CreateBid($data: CreateBidInput!) {
  createBid(data: $data) {
    ...Bid
  }
}
    ${BidFragmentDoc}`;
export type CreateBidMutationFn = Apollo.MutationFunction<CreateBidMutation, CreateBidMutationVariables>;

/**
 * __useCreateBidMutation__
 *
 * To run a mutation, you first call `useCreateBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBidMutation, { data, loading, error }] = useCreateBidMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBidMutation(baseOptions?: Apollo.MutationHookOptions<CreateBidMutation, CreateBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBidMutation, CreateBidMutationVariables>(CreateBidDocument, options);
      }
export type CreateBidMutationHookResult = ReturnType<typeof useCreateBidMutation>;
export type CreateBidMutationResult = Apollo.MutationResult<CreateBidMutation>;
export type CreateBidMutationOptions = Apollo.BaseMutationOptions<CreateBidMutation, CreateBidMutationVariables>;
export const EditBidDocument = gql`
    mutation EditBid($data: EditBidInput!) {
  editBid(data: $data) {
    ...Bid
  }
}
    ${BidFragmentDoc}`;
export type EditBidMutationFn = Apollo.MutationFunction<EditBidMutation, EditBidMutationVariables>;

/**
 * __useEditBidMutation__
 *
 * To run a mutation, you first call `useEditBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBidMutation, { data, loading, error }] = useEditBidMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditBidMutation(baseOptions?: Apollo.MutationHookOptions<EditBidMutation, EditBidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBidMutation, EditBidMutationVariables>(EditBidDocument, options);
      }
export type EditBidMutationHookResult = ReturnType<typeof useEditBidMutation>;
export type EditBidMutationResult = Apollo.MutationResult<EditBidMutation>;
export type EditBidMutationOptions = Apollo.BaseMutationOptions<EditBidMutation, EditBidMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($projectId: ID!, $data: UpdateProjectInput!) {
  updateProject(projectId: $projectId, data: $data) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const SubmitPaypalPaymentDocument = gql`
    mutation SubmitPaypalPayment($data: SubmitPaypalPayment!) {
  submitPaypalPayment(data: $data) {
    id
    paypalId
    paypalPayerId
    paypalPayerCountryCode
    paypalStatus
    amount
    currency
  }
}
    `;
export type SubmitPaypalPaymentMutationFn = Apollo.MutationFunction<SubmitPaypalPaymentMutation, SubmitPaypalPaymentMutationVariables>;

/**
 * __useSubmitPaypalPaymentMutation__
 *
 * To run a mutation, you first call `useSubmitPaypalPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPaypalPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPaypalPaymentMutation, { data, loading, error }] = useSubmitPaypalPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitPaypalPaymentMutation(baseOptions?: Apollo.MutationHookOptions<SubmitPaypalPaymentMutation, SubmitPaypalPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitPaypalPaymentMutation, SubmitPaypalPaymentMutationVariables>(SubmitPaypalPaymentDocument, options);
      }
export type SubmitPaypalPaymentMutationHookResult = ReturnType<typeof useSubmitPaypalPaymentMutation>;
export type SubmitPaypalPaymentMutationResult = Apollo.MutationResult<SubmitPaypalPaymentMutation>;
export type SubmitPaypalPaymentMutationOptions = Apollo.BaseMutationOptions<SubmitPaypalPaymentMutation, SubmitPaypalPaymentMutationVariables>;
export const ProjectDocument = gql`
    query Project($id: ID!) {
  project(id: $id) {
    ...GetProject
  }
}
    ${GetProjectFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const PublishProjectDocument = gql`
    mutation PublishProject($projectId: ID!) {
  publishProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type PublishProjectMutationFn = Apollo.MutationFunction<PublishProjectMutation, PublishProjectMutationVariables>;

/**
 * __usePublishProjectMutation__
 *
 * To run a mutation, you first call `usePublishProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishProjectMutation, { data, loading, error }] = usePublishProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePublishProjectMutation(baseOptions?: Apollo.MutationHookOptions<PublishProjectMutation, PublishProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishProjectMutation, PublishProjectMutationVariables>(PublishProjectDocument, options);
      }
export type PublishProjectMutationHookResult = ReturnType<typeof usePublishProjectMutation>;
export type PublishProjectMutationResult = Apollo.MutationResult<PublishProjectMutation>;
export type PublishProjectMutationOptions = Apollo.BaseMutationOptions<PublishProjectMutation, PublishProjectMutationVariables>;
export const UnpublishProjectDocument = gql`
    mutation UnpublishProject($projectId: ID!) {
  unpublishProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type UnpublishProjectMutationFn = Apollo.MutationFunction<UnpublishProjectMutation, UnpublishProjectMutationVariables>;

/**
 * __useUnpublishProjectMutation__
 *
 * To run a mutation, you first call `useUnpublishProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishProjectMutation, { data, loading, error }] = useUnpublishProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnpublishProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishProjectMutation, UnpublishProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishProjectMutation, UnpublishProjectMutationVariables>(UnpublishProjectDocument, options);
      }
export type UnpublishProjectMutationHookResult = ReturnType<typeof useUnpublishProjectMutation>;
export type UnpublishProjectMutationResult = Apollo.MutationResult<UnpublishProjectMutation>;
export type UnpublishProjectMutationOptions = Apollo.BaseMutationOptions<UnpublishProjectMutation, UnpublishProjectMutationVariables>;
export const DeleteGitHubProjectDocument = gql`
    mutation DeleteGitHubProject($deployedProjectId: ID!) {
  deleteGitHubProject(deployedProjectId: $deployedProjectId) {
    ...DeployedProject
  }
}
    ${DeployedProjectFragmentDoc}`;
export type DeleteGitHubProjectMutationFn = Apollo.MutationFunction<DeleteGitHubProjectMutation, DeleteGitHubProjectMutationVariables>;

/**
 * __useDeleteGitHubProjectMutation__
 *
 * To run a mutation, you first call `useDeleteGitHubProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGitHubProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGitHubProjectMutation, { data, loading, error }] = useDeleteGitHubProjectMutation({
 *   variables: {
 *      deployedProjectId: // value for 'deployedProjectId'
 *   },
 * });
 */
export function useDeleteGitHubProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGitHubProjectMutation, DeleteGitHubProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGitHubProjectMutation, DeleteGitHubProjectMutationVariables>(DeleteGitHubProjectDocument, options);
      }
export type DeleteGitHubProjectMutationHookResult = ReturnType<typeof useDeleteGitHubProjectMutation>;
export type DeleteGitHubProjectMutationResult = Apollo.MutationResult<DeleteGitHubProjectMutation>;
export type DeleteGitHubProjectMutationOptions = Apollo.BaseMutationOptions<DeleteGitHubProjectMutation, DeleteGitHubProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($projectId: ID!) {
  deleteProject(projectId: $projectId)
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const AdminDeleteProjectDocument = gql`
    mutation AdminDeleteProject($projectId: ID!) {
  adminDeleteProject(projectId: $projectId)
}
    `;
export type AdminDeleteProjectMutationFn = Apollo.MutationFunction<AdminDeleteProjectMutation, AdminDeleteProjectMutationVariables>;

/**
 * __useAdminDeleteProjectMutation__
 *
 * To run a mutation, you first call `useAdminDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteProjectMutation, { data, loading, error }] = useAdminDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAdminDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteProjectMutation, AdminDeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteProjectMutation, AdminDeleteProjectMutationVariables>(AdminDeleteProjectDocument, options);
      }
export type AdminDeleteProjectMutationHookResult = ReturnType<typeof useAdminDeleteProjectMutation>;
export type AdminDeleteProjectMutationResult = Apollo.MutationResult<AdminDeleteProjectMutation>;
export type AdminDeleteProjectMutationOptions = Apollo.BaseMutationOptions<AdminDeleteProjectMutation, AdminDeleteProjectMutationVariables>;
export const UnstartProjectDocument = gql`
    mutation UnstartProject($projectId: ID!) {
  unstartProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type UnstartProjectMutationFn = Apollo.MutationFunction<UnstartProjectMutation, UnstartProjectMutationVariables>;

/**
 * __useUnstartProjectMutation__
 *
 * To run a mutation, you first call `useUnstartProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstartProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstartProjectMutation, { data, loading, error }] = useUnstartProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnstartProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnstartProjectMutation, UnstartProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnstartProjectMutation, UnstartProjectMutationVariables>(UnstartProjectDocument, options);
      }
export type UnstartProjectMutationHookResult = ReturnType<typeof useUnstartProjectMutation>;
export type UnstartProjectMutationResult = Apollo.MutationResult<UnstartProjectMutation>;
export type UnstartProjectMutationOptions = Apollo.BaseMutationOptions<UnstartProjectMutation, UnstartProjectMutationVariables>;
export const CompleteProjectDocument = gql`
    mutation CompleteProject($data: CompleteProjectInput!) {
  completeProject(data: $data) {
    id
    status
    projects {
      id
      clientReview
      clientPublicReview
      clientCommunicationRating
      clientRecommendRating
      clientUiRating
      clientCodeRating
    }
  }
}
    `;
export type CompleteProjectMutationFn = Apollo.MutationFunction<CompleteProjectMutation, CompleteProjectMutationVariables>;

/**
 * __useCompleteProjectMutation__
 *
 * To run a mutation, you first call `useCompleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProjectMutation, { data, loading, error }] = useCompleteProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<CompleteProjectMutation, CompleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteProjectMutation, CompleteProjectMutationVariables>(CompleteProjectDocument, options);
      }
export type CompleteProjectMutationHookResult = ReturnType<typeof useCompleteProjectMutation>;
export type CompleteProjectMutationResult = Apollo.MutationResult<CompleteProjectMutation>;
export type CompleteProjectMutationOptions = Apollo.BaseMutationOptions<CompleteProjectMutation, CompleteProjectMutationVariables>;
export const GetProjectsDocument = gql`
    query GetProjects {
  projects {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const CreateStripeAccountDocument = gql`
    mutation CreateStripeAccount {
  link: createStripeAccount
}
    `;
export type CreateStripeAccountMutationFn = Apollo.MutationFunction<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>;

/**
 * __useCreateStripeAccountMutation__
 *
 * To run a mutation, you first call `useCreateStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeAccountMutation, { data, loading, error }] = useCreateStripeAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>(CreateStripeAccountDocument, options);
      }
export type CreateStripeAccountMutationHookResult = ReturnType<typeof useCreateStripeAccountMutation>;
export type CreateStripeAccountMutationResult = Apollo.MutationResult<CreateStripeAccountMutation>;
export type CreateStripeAccountMutationOptions = Apollo.BaseMutationOptions<CreateStripeAccountMutation, CreateStripeAccountMutationVariables>;
export const CreateStripeDashboardLinkDocument = gql`
    mutation CreateStripeDashboardLink {
  link: createStripeDashboardLink
}
    `;
export type CreateStripeDashboardLinkMutationFn = Apollo.MutationFunction<CreateStripeDashboardLinkMutation, CreateStripeDashboardLinkMutationVariables>;

/**
 * __useCreateStripeDashboardLinkMutation__
 *
 * To run a mutation, you first call `useCreateStripeDashboardLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeDashboardLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeDashboardLinkMutation, { data, loading, error }] = useCreateStripeDashboardLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeDashboardLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeDashboardLinkMutation, CreateStripeDashboardLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeDashboardLinkMutation, CreateStripeDashboardLinkMutationVariables>(CreateStripeDashboardLinkDocument, options);
      }
export type CreateStripeDashboardLinkMutationHookResult = ReturnType<typeof useCreateStripeDashboardLinkMutation>;
export type CreateStripeDashboardLinkMutationResult = Apollo.MutationResult<CreateStripeDashboardLinkMutation>;
export type CreateStripeDashboardLinkMutationOptions = Apollo.BaseMutationOptions<CreateStripeDashboardLinkMutation, CreateStripeDashboardLinkMutationVariables>;
export const CreateCheckoutSubscriptionSessionDocument = gql`
    mutation CreateCheckoutSubscriptionSession($plan: SubscriptionPlan!) {
  createCheckoutSubscriptionSession(plan: $plan)
}
    `;
export type CreateCheckoutSubscriptionSessionMutationFn = Apollo.MutationFunction<CreateCheckoutSubscriptionSessionMutation, CreateCheckoutSubscriptionSessionMutationVariables>;

/**
 * __useCreateCheckoutSubscriptionSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSubscriptionSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSubscriptionSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSubscriptionSessionMutation, { data, loading, error }] = useCreateCheckoutSubscriptionSessionMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useCreateCheckoutSubscriptionSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutSubscriptionSessionMutation, CreateCheckoutSubscriptionSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutSubscriptionSessionMutation, CreateCheckoutSubscriptionSessionMutationVariables>(CreateCheckoutSubscriptionSessionDocument, options);
      }
export type CreateCheckoutSubscriptionSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSubscriptionSessionMutation>;
export type CreateCheckoutSubscriptionSessionMutationResult = Apollo.MutationResult<CreateCheckoutSubscriptionSessionMutation>;
export type CreateCheckoutSubscriptionSessionMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutSubscriptionSessionMutation, CreateCheckoutSubscriptionSessionMutationVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    ...Country
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const UpdateGithubUsernameDocument = gql`
    mutation UpdateGithubUsername($githubAccessToken: String!) {
  updateGithubUsername(githubAccessToken: $githubAccessToken) {
    id
    githubUsername
    githubAppUsername
  }
}
    `;
export type UpdateGithubUsernameMutationFn = Apollo.MutationFunction<UpdateGithubUsernameMutation, UpdateGithubUsernameMutationVariables>;

/**
 * __useUpdateGithubUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateGithubUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGithubUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGithubUsernameMutation, { data, loading, error }] = useUpdateGithubUsernameMutation({
 *   variables: {
 *      githubAccessToken: // value for 'githubAccessToken'
 *   },
 * });
 */
export function useUpdateGithubUsernameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGithubUsernameMutation, UpdateGithubUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGithubUsernameMutation, UpdateGithubUsernameMutationVariables>(UpdateGithubUsernameDocument, options);
      }
export type UpdateGithubUsernameMutationHookResult = ReturnType<typeof useUpdateGithubUsernameMutation>;
export type UpdateGithubUsernameMutationResult = Apollo.MutationResult<UpdateGithubUsernameMutation>;
export type UpdateGithubUsernameMutationOptions = Apollo.BaseMutationOptions<UpdateGithubUsernameMutation, UpdateGithubUsernameMutationVariables>;
export const UpdateGithubAppUsernameDocument = gql`
    mutation UpdateGithubAppUsername($githubAccessToken: String!, $githubInstallationId: Int!) {
  updateGithubAppUsername(
    githubAccessToken: $githubAccessToken
    githubInstallationId: $githubInstallationId
  ) {
    id
    githubUsername
    githubAppUsername
  }
}
    `;
export type UpdateGithubAppUsernameMutationFn = Apollo.MutationFunction<UpdateGithubAppUsernameMutation, UpdateGithubAppUsernameMutationVariables>;

/**
 * __useUpdateGithubAppUsernameMutation__
 *
 * To run a mutation, you first call `useUpdateGithubAppUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGithubAppUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGithubAppUsernameMutation, { data, loading, error }] = useUpdateGithubAppUsernameMutation({
 *   variables: {
 *      githubAccessToken: // value for 'githubAccessToken'
 *      githubInstallationId: // value for 'githubInstallationId'
 *   },
 * });
 */
export function useUpdateGithubAppUsernameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGithubAppUsernameMutation, UpdateGithubAppUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGithubAppUsernameMutation, UpdateGithubAppUsernameMutationVariables>(UpdateGithubAppUsernameDocument, options);
      }
export type UpdateGithubAppUsernameMutationHookResult = ReturnType<typeof useUpdateGithubAppUsernameMutation>;
export type UpdateGithubAppUsernameMutationResult = Apollo.MutationResult<UpdateGithubAppUsernameMutation>;
export type UpdateGithubAppUsernameMutationOptions = Apollo.BaseMutationOptions<UpdateGithubAppUsernameMutation, UpdateGithubAppUsernameMutationVariables>;
export const UnsetGithubUsernameDocument = gql`
    mutation UnsetGithubUsername {
  unsetGithubUsername {
    id
    githubUsername
  }
}
    `;
export type UnsetGithubUsernameMutationFn = Apollo.MutationFunction<UnsetGithubUsernameMutation, UnsetGithubUsernameMutationVariables>;

/**
 * __useUnsetGithubUsernameMutation__
 *
 * To run a mutation, you first call `useUnsetGithubUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetGithubUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetGithubUsernameMutation, { data, loading, error }] = useUnsetGithubUsernameMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsetGithubUsernameMutation(baseOptions?: Apollo.MutationHookOptions<UnsetGithubUsernameMutation, UnsetGithubUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetGithubUsernameMutation, UnsetGithubUsernameMutationVariables>(UnsetGithubUsernameDocument, options);
      }
export type UnsetGithubUsernameMutationHookResult = ReturnType<typeof useUnsetGithubUsernameMutation>;
export type UnsetGithubUsernameMutationResult = Apollo.MutationResult<UnsetGithubUsernameMutation>;
export type UnsetGithubUsernameMutationOptions = Apollo.BaseMutationOptions<UnsetGithubUsernameMutation, UnsetGithubUsernameMutationVariables>;
export const UnsetGithubAppUsernameDocument = gql`
    mutation UnsetGithubAppUsername {
  unsetGithubAppUsername {
    id
    githubAppUsername
  }
}
    `;
export type UnsetGithubAppUsernameMutationFn = Apollo.MutationFunction<UnsetGithubAppUsernameMutation, UnsetGithubAppUsernameMutationVariables>;

/**
 * __useUnsetGithubAppUsernameMutation__
 *
 * To run a mutation, you first call `useUnsetGithubAppUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetGithubAppUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetGithubAppUsernameMutation, { data, loading, error }] = useUnsetGithubAppUsernameMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsetGithubAppUsernameMutation(baseOptions?: Apollo.MutationHookOptions<UnsetGithubAppUsernameMutation, UnsetGithubAppUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetGithubAppUsernameMutation, UnsetGithubAppUsernameMutationVariables>(UnsetGithubAppUsernameDocument, options);
      }
export type UnsetGithubAppUsernameMutationHookResult = ReturnType<typeof useUnsetGithubAppUsernameMutation>;
export type UnsetGithubAppUsernameMutationResult = Apollo.MutationResult<UnsetGithubAppUsernameMutation>;
export type UnsetGithubAppUsernameMutationOptions = Apollo.BaseMutationOptions<UnsetGithubAppUsernameMutation, UnsetGithubAppUsernameMutationVariables>;