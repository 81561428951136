import React from 'react'
import { Label } from './Label'

interface SelectProps {
  label: string
  options: Array<{ value: string; label: string }>
}

export const Select: React.FC<
  SelectProps &
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
> = props => {
  const { label, options, ...otherProps } = props

  return (
    <div>
      <Label htmlFor={props.name}>{label}</Label>
      <select
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        id={props.name}
        {...otherProps}
      >
        {options.map(option => (
          <option key={option.value ?? 'empty'} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
