import React from 'react'
import { PageHeader } from './PageHeader'
import { PageHeaderCompact } from './PageHeaderCompact'
import { ContainerBasic } from './ContainerBasic'

interface PageLayoutProps {
  title: string
  description?: React.ReactNode
  headerDark?: boolean
  headerLarge?: boolean
  header?: React.ReactNode
  alert?: React.ReactNode
}

export const PageLayout: React.FC<PageLayoutProps> = props => {
  return (
    <div className="flex flex-col h-full">
      {props.header ? (
        props.header
      ) : props.headerLarge ? (
        <PageHeader title={props.title} description={props.description} dark={props.headerDark} />
      ) : (
        <PageHeaderCompact title={props.title} description={props.description} />
      )}
      {props.alert}
      <ContainerBasic>{props.children}</ContainerBasic>
    </div>
  )
}
