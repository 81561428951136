import React from 'react'
import { useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { PageLayout } from '../components/PageLayout'
import { Button, ButtonProps } from '../components/Button'
import { Loading } from '../components/Loading'
import {
  getGithubToken,
  getLocalStorageGitHubState,
  clearLocalStorageGitHubState,
  useGithubAuth,
  connectToGitHub,
} from '../utils/github'
import { useUserContext } from '../auth/userContext'
import { trackEvent } from '../utils/analytics'
import { EditSelected } from '../components/EditSelected'

interface GitHubProps {}

export const GitHub: React.FC<GitHubProps> = props => {
  const { user, loading } = useUserContext()
  const query = useSearchQuery()
  const code = query.get('code')
  const state = query.get('state')

  const gitHubConnected = React.useCallback(() => {
    const opener: any = window.opener
    opener?.onGitHubConnected?.(code)
  }, [code])

  React.useEffect(() => {
    if (code) {
      const stateLocal = getLocalStorageGitHubState()
      if (state === stateLocal) {
        getGithubToken(code).then(() => {
          // window.close()
          gitHubConnected()
        })
        clearLocalStorageGitHubState()
      } else {
        console.error('State is wrong')
      }
    }
  }, [code, gitHubConnected, state])

  return (
    <PageLayout title="GitHub" headerDark headerLarge>
      <Card title="Connected">
        {loading ? (
          <Loading />
        ) : code ? (
          <Button
            styleType="primary"
            onClick={() => {
              gitHubConnected()
              window.close()
            }}
          >
            Continue
          </Button>
        ) : (
          <div>There was an error connecting.</div>
        )}
      </Card>
    </PageLayout>
  )
}

export const ConnectToGitHubButton: React.FC<{
  buttonType?: ButtonProps['styleType']
}> = React.memo(props => {
  useGithubAuth()

  return (
    <Button
      styleType={props.buttonType || 'primary'}
      onClick={() => {
        trackEvent('Click connect to GitHub')
        connectToGitHub()
      }}
    >
      Connect to GitHub
    </Button>
  )
})

export const GitHubConnected: React.FC<{
  githubUsername?: string | null
  connectButton?: React.ReactNode
  editText?: string
  unsetUsername: () => void
}> = props => {
  return props.githubUsername ? (
    <EditSelected
      selected={
        <>
          Connected to GitHub as <strong>{props.githubUsername}</strong>
        </>
      }
      editText={props.editText || 'Switch GitHub Account'}
      onEdit={() => props.unsetUsername?.()}
    />
  ) : (
    <>{props.connectButton || <ConnectToGitHubButton />}</>
  )
}
