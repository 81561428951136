import React from 'react'
import { useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { PageLayout } from '../components/PageLayout'
import { getGithubAppToken } from '../utils/github'

export const GitHubApp: React.FC<{}> = props => {
  const query = useSearchQuery()
  const code = query.get('code')
  const installationId = query.get('installation_id')

  React.useEffect(() => {
    if (code) {
      getGithubAppToken(code).then(async accessToken => {
        const opener: any = window.opener
        opener?.onGitHubConnected?.(accessToken, installationId)
      })
    }
  }, [code, installationId])

  return (
    <PageLayout title="GitHub" headerDark headerLarge>
      <Card title="Connected"></Card>
    </PageLayout>
  )
}
