import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { Loading } from './Loading'
import { Button } from './Button'
import { Modal } from './Modal'
import { ScreenEdit } from './ScreenEdit'
import { Paragraph } from './Typography'
import { Screen } from '../generated/graphql'
import { ReactComponent as AnnotateIcon } from '../icons/annotation.svg'
import { ReactComponent as ExternalLinkIcon } from '../icons/external-link.svg'
import { useSearchQuery } from '../utils/location'

export const ScreensGrid = styled.div.attrs({
  className: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5',
})``

export const ScreensGridScreen: React.FC<{
  screen: Pick<Screen, 'id' | 'name' | 'notes'> & { imageUrl?: string; commentsCount?: number }
  projectCreatorId?: string
  active: boolean
  externalUrl: string
  readonly: boolean
  showNotes?: boolean
  onClick?: () => void
}> = props => {
  const { screen, active, externalUrl, readonly, showNotes, onClick } = props
  const query = useSearchQuery()
  const queryScreenId = query.get('screenId')
  const [imageExpanded, setImageExpanded] = React.useState(queryScreenId === props.screen.id)

  const { listen } = useHistory()

  React.useEffect(() => {
    const unlisten = listen(location => {
      const search = new URLSearchParams(location.search)
      const queryScreenId = search.get('screenId')

      if (queryScreenId) setImageExpanded(queryScreenId === props.screen.id)
    })

    return unlisten
  }, [listen, props.screen.id])

  return (
    <>
      <div
        className={classNames(
          'group flex flex-col rounded shadow overflow-hidden hover:shadow-xl transition-shadow duration-200 ease-in-out border-4 border-transparent',
          { 'border-indigo-500': active, 'cursor-pointer focus:ring-indigo': !!onClick }
        )}
        // don't remember why this was added but looks worse with it in and isn't helping for accessibility with tabs either
        // tabIndex={onClick ? -1 : undefined}
        onClick={onClick}
      >
        <div className="flex-1">
          {screen.imageUrl ? (
            <div className="relative">
              <div className="absolute top-0 right-0 m-2 invisible group-hover:visible">
                <Button
                  type="button"
                  size="icon"
                  onClick={e => {
                    e.stopPropagation()
                    window.open(externalUrl)
                  }}
                >
                  <ExternalLinkIcon width={20} height={20} />
                </Button>
                <span className="ml-1">
                  <Button
                    type="button"
                    size="icon"
                    onClick={e => {
                      e.stopPropagation()
                      setImageExpanded(true)
                    }}
                  >
                    <div className="flex">
                      <AnnotateIcon className="font-gray-300" width={20} height={20} />
                      {!!screen.commentsCount && (
                        <div className="pl-2 text-sm font-gray-300">{screen.commentsCount}</div>
                      )}
                    </div>
                  </Button>
                </span>
              </div>
              <div className="overflow-y-auto" style={{ maxHeight: '40rem' }}>
                <img className="w-full" src={screen.imageUrl} alt={screen.name} />
              </div>
            </div>
          ) : (
            <Loading small slow />
          )}
        </div>
        <div className="p-4">
          <div className="text-base font-medium leading-6 flex items-center">
            <div className="flex-1">{screen.name}</div>
            {typeof screen.commentsCount === 'number' && (
              <div
                className="cursor-pointer flex items-center justify-center"
                onClick={() => {
                  setImageExpanded(true)
                }}
              >
                <div className="pr-2 text-sm font-gray-300">{screen.commentsCount}</div>
                <AnnotateIcon className="font-gray-300" width={20} height={20} />
              </div>
            )}
          </div>
          {screen.notes && (
            <div className="mt-1">
              <Paragraph>{screen.notes}</Paragraph>
            </div>
          )}
        </div>
      </div>
      {imageExpanded && (
        <Modal hideModal={() => setImageExpanded(false)} fullWidth>
          <ScreenEdit
            screen={screen}
            projectCreatorId={props.projectCreatorId}
            close={() => setImageExpanded(false)}
            readonly={readonly}
            showNotes={showNotes}
          />
        </Modal>
      )}
    </>
  )
}
