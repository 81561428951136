import { Field } from 'formik'
import React from 'react'
import { useCountriesQuery } from '../generated/graphql'
import { CustomSelectComponent } from './FormikCustom'

interface CountrySelectProps {
  name?: string
}

/**
 * Expected to be used in a Formik form
 */
export const CountrySelectField: React.FC<CountrySelectProps> = props => {
  const { data, loading } = useCountriesQuery()
  const countries = data?.countries || []

  if (loading) return null

  return (
    <Field
      name={props.name || 'country'}
      label="Country"
      options={[
        { value: undefined, label: '' },
        ...countries.map(country => ({
          value: country.enum,
          label: country.name,
        })),
      ]}
      component={CustomSelectComponent}
    />
  )
}
