import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSteps, useSearchQuery } from '../utils/location'
import { PageLayout } from '../components/PageLayout'
import { NewProjectZeplin } from './NewProjectZeplin'
import { NewProjectAdvanced } from './NewProjectAdvanced'
import { NewProjectOverview } from './NewProjectOverview'
import { useProjectLazyQuery, DesignApp } from '../generated/graphql'
import { NewProjectFigma } from './NewProjectFigma'
import { NewProjectOther } from './NewProjectOther'

export const NewProject: React.FC<{}> = props => {
  const { step, increaseStep, decreaseStep } = useSteps(5)
  const history = useHistory()
  const query = useSearchQuery()
  const projectId = query.get('projectId')
  const [fetchProject, { data, loading, error }] = useProjectLazyQuery()

  React.useEffect(() => {
    if (projectId) fetchProject({ variables: { id: projectId } })
  }, [projectId, fetchProject])

  const steps = [
    () => (
      <NewProjectOverview
        project={data?.project}
        increaseStep={project => {
          if (project?.designApp === DesignApp.Figma) increaseStep(1)
          else if (project?.designApp === DesignApp.Zeplin) increaseStep(2)
          else increaseStep(3)
        }}
      />
    ),
    () => (
      <NewProjectFigma
        project={data?.project}
        increaseStep={() => increaseStep(3)}
        decreaseStep={decreaseStep}
      />
    ),
    () => (
      <NewProjectZeplin
        project={data?.project}
        increaseStep={() => increaseStep(2)}
        decreaseStep={() => decreaseStep(2)}
      />
    ),
    () => (
      <NewProjectOther
        project={data?.project}
        increaseStep={increaseStep}
        decreaseStep={() => decreaseStep(3)}
      />
    ),
    () => (
      <NewProjectAdvanced
        project={data?.project}
        increaseStep={() => {
          increaseStep()
          history.push(`/project/${projectId}`)
        }}
        decreaseStep={() => {
          if (data?.project?.designApp === DesignApp.Zeplin) decreaseStep(2)
          if (data?.project?.designApp === DesignApp.Figma) decreaseStep(3)
          else decreaseStep()
        }}
      />
    ),
  ]

  const Step = steps[step]

  return (
    <PageLayout title={data?.project ? data.project.name : 'New Project'} headerDark headerLarge>
      <Step />
    </PageLayout>
  )
}
