import React from 'react'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'

import { ErrorPage } from '../components/ErrorPage'
import { Card, CardsWrapper, CardTitleWithRightText } from '../components/Card'
import { ImageWrapperSmall } from '../components/ImageWrapper'
import { LayoutPlain } from '../components/LayoutPlain'
import { CustomLink, Paragraph } from '../components/Typography'
import { useUserWithHeardAboutAndSkillsQuery } from '../generated/graphql'
import { ReactComponent as ReviewImage } from '../images/undraw_review_fkgn.svg'
import { EnglishAssessment, HourlyRate, Portfolio } from './DeveloperProfile'
import { Loading } from '../components/Loading'

gql`
  query UserWithHeardAboutAndSkills {
    getUser {
      id
      heardAbout
      programmingLanguages
      frontendFrameworks
      stylingLibraries
    }
  }
`

interface DeveloperWaitlistProps {}

export const DeveloperWaitlist: React.FC<DeveloperWaitlistProps> = props => {
  const { data, loading, error } = useUserWithHeardAboutAndSkillsQuery()

  if (loading) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  return (
    <LayoutPlain>
      <div className="flex justify-center max-w-3xl mx-auto">
        <CardsWrapper>
          <Card title="Thank you!">
            <Paragraph>
              Your interview is being processed. If you pass, you will be eligible to work on client
              projects!
            </Paragraph>

            {loading || data?.getUser.heardAbout ? null : (
              <Paragraph>
                In the meantime we'd love to know
                <span className="font-semibold">
                  <CustomLink to="/developer/heard-about">
                    {' '}
                    how you heard about FrontWork
                  </CustomLink>
                  ?
                </span>{' '}
              </Paragraph>
            )}

            <ImageWrapperSmall>
              <ReviewImage height="100%" width="100%" />
            </ImageWrapperSmall>
          </Card>

          <Card
            titleComponent={<CardTitleWithRightText title="Intro Video" rightItem={'Optional'} />}
            description={<EnglishAssessment />}
          />

          <Card
            titleComponent={<CardTitleWithRightText title="Portfolio" rightItem={'Optional'} />}
            description={<Portfolio />}
          />

          {/* <Card
            titleComponent={<CardTitleWithRightText title="Experience" rightItem={'Optional'} />}
            description={
              <Experience
                developer={{
                  programmingLanguages: data.getUser.programmingLanguages,
                  frontendFrameworks: data.getUser.frontendFrameworks,
                }}
              />
            }
          /> */}

          <Card
            titleComponent={
              <CardTitleWithRightText title="Hourly Projects" rightItem={'Optional'} />
            }
            description={
              <>
                <div className="mt-4 mb-2">
                  <Paragraph>
                    For clients looking to work on an hourly basis, how much would you like to
                    charge per hour?
                  </Paragraph>
                </div>
                <HourlyRate />
              </>
            }
          />
        </CardsWrapper>
      </div>
    </LayoutPlain>
  )
}
