import React from 'react'
import { ContainerBasic } from './ContainerBasic'

interface LayoutPlainProps {}

export const LayoutPlain: React.FC<LayoutPlainProps> = props => {
  return (
    <div className="flex h-full items-center justify-center">
      <ContainerBasic>{props.children}</ContainerBasic>
    </div>
  )
}
