import { ApolloError } from '@apollo/client'
import React from 'react'
import { Card } from './Card'
import { PageLayout } from './PageLayout'
import { DEV_MODE } from '../utils/config'
import { ImageWrapper } from './ImageWrapper'
import { ReactComponent as ErrorImage } from '../images/undraw_cancel_u1it.svg'

interface ErrorProps {
  title?: string
  message?: string
  error?: ApolloError
}

export const ErrorCard: React.FC<ErrorProps> = props => {
  const { title, message = 'There was an error loading this page.' } = props
  if (props.error) console.error(props.error)

  return (
    <Card title={title}>
      {message}
      {/* TODO Only for DEV */}
      {DEV_MODE && <p className="py-4 whitespace-pre-wrap">{JSON.stringify(props.error, null, 0)}</p>}

      <ImageWrapper>
        <ErrorImage height='100%' width='100%' />
      </ImageWrapper>
    </Card>
  )
}

export const ErrorPage: React.FC<ErrorProps> = props => {
  return (
    <PageLayout title={'Error'} headerDark headerLarge>
      <ErrorCard {...props} message={props.message || props.error?.message} />
    </PageLayout>
  )
}
