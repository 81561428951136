import { gql } from '@apollo/client'
import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import { ModalDualButtons, ModalHeader } from '../../components/Modal'
import { useNotification } from '../../components/Notification'
import { useCompleteProjectMutation, CompleteProjectInput } from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import { formatGraphQLError } from '../../utils/error'
import { FormItem } from '../../components/FormItem'
import { CustomRatingComponent, CustomTextAreaComponent } from '../../components/FormikCustom'
import { Button } from '../../components/Button'

gql`
  mutation CompleteProject($data: CompleteProjectInput!) {
    completeProject(data: $data) {
      id
      status
      projects {
        id
        clientReview
        clientPublicReview
        clientCommunicationRating
        clientRecommendRating
        clientUiRating
        clientCodeRating
      }
    }
  }
`
const CompleteProjectSchema = Yup.object().shape<Partial<CompleteProjectInput>>({
  clientRecommendRating: Yup.number().positive('Field is required').required('Field is required'),
  clientCommunicationRating: Yup.number()
    .positive('Field is required')
    .required('Field is required'),
})

interface ReviewModalContentProps {
  deployedProjectId?: string
  projectId: string
  hideModal: () => void
}

export const ReviewModalContent: React.FC<ReviewModalContentProps> = props => {
  const { projectId, deployedProjectId } = props
  const [completeProject] = useCompleteProjectMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <div>
      <ModalHeader title="Review" />
      <div className="mt-2">
        <Formik<CompleteProjectInput>
          validationSchema={CompleteProjectSchema}
          initialValues={{
            deployedProjectId,
            projectId,
            clientPublicReview: true,
            clientRecommendRating: -1,
            clientCommunicationRating: -1,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('onSubmit', values)

            try {
              await completeProject({ variables: { data: values }, refetchQueries: [] })
              trackEvent('Mark project as complete', values)

              props.hideModal()
              showSuccessNotification({ description: 'Successfully submitted' })
            } catch (error) {
              console.error(error)
              showErrorNotification({ description: formatGraphQLError(error) })
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting, handleSubmit, handleReset, values, touched }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <div className="grid md:grid-cols-2 items-center">
                <FormItem>
                  <Field
                    name="clientCommunicationRating"
                    label="Communication"
                    component={CustomRatingComponent}
                  />
                </FormItem>
                <FormItem>
                  <Field
                    name="clientRecommendRating"
                    label="Recommend to others"
                    component={CustomRatingComponent}
                  />
                </FormItem>
                <FormItem>
                  <Field
                    name="clientUiRating"
                    label="UI Quality"
                    component={CustomRatingComponent}
                  />
                </FormItem>
                <FormItem>
                  <Field
                    name="clientCodeRating"
                    label="Code Quality"
                    component={CustomRatingComponent}
                  />
                </FormItem>
              </div>
              <FormItem>
                <Field name="clientReview" label="Review" component={CustomTextAreaComponent} />
              </FormItem>

              <FormItem>
                <ModalDualButtons
                  button1={
                    <Button styleType="primary" block type="submit" disabled={isSubmitting}>
                      Complete
                    </Button>
                  }
                  button2={
                    <Button styleType="white" block onClick={() => props.hideModal()}>
                      Cancel
                    </Button>
                  }
                />
              </FormItem>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
