import React from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import { useUserContext } from '../auth/userContext'
import { Button } from './Button'

export interface PricingProps {
  tiers: {
    plan: string
    name: string
    priceMonthly: number
    description: string
    features: string[]
  }[]
  selectedPlan?: string
  showDeveloperSection: boolean
  onSubscribeClick: (plan: string) => void
}

export const Pricing: React.FC<PricingProps> = props => {
  const { tiers, selectedPlan, showDeveloperSection, onSubscribeClick } = props

  return (
    <div className="bg-gray-800">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              Pricing
            </h2>
            <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              Access to the FrontWork component library
            </p>
            <p className="text-xl text-gray-300">
              Components for your every need with new items added weekly
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-100 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-800" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map(tier => (
                <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                      ${tier.priceMonthly}
                      <span className="ml-1 text-2xl font-medium text-gray-500">/mo</span>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {tier.features.map(feature => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                    <Button
                      size="xl"
                      styleType="black"
                      disabled={tier.plan === selectedPlan}
                      onClick={() => onSubscribeClick(tier.plan)}
                    >
                      {tier.plan === selectedPlan ? 'Subscribed' : 'Subscribe'}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {showDeveloperSection && (
          <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
            <div className="max-w-md mx-auto lg:max-w-5xl">
              <div className="rounded-lg bg-white px-6 py-8 sm:p-10 lg:flex lg:items-center">
                <div className="flex-1">
                  <div>
                    <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-gray-100 text-gray-800">
                      Developers
                    </h3>
                  </div>
                  <div className="mt-4 text-lg text-gray-600">
                    Get one month free access for every component you submit to the marketplace.
                  </div>
                </div>
                <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                  <Link
                    to="/upload-component"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                  >
                    Submit Component
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
