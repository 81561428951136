import React from 'react'
import { gql } from '@apollo/client'
import { Formik, Field } from 'formik'
import classNames from 'classnames'
import { IAnnotation } from 'react-image-annotation'
import {
  useCommentsQuery,
  useEditScreenMutation,
  useCreateCommentMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  AnnotationType,
  Screen,
  CommentFragment,
  EditCommentInput,
} from '../generated/graphql'
import { CustomInputComponent, CustomTextAreaComponent } from './FormikCustom'
import { FormItem } from './FormItem'
import { Button } from './Button'
import { Paragraph } from './Typography'
import { trackEvent } from '../utils/analytics'
import { ImageAnnotate } from './ImageAnnotate'
import { useNotification } from './Notification'
import { formatGraphQLError } from '../utils/error'
import { formatDateMedium } from '../utils/date'
import { useUserContext } from '../auth/userContext'
import { ReactComponent as PencilIcon } from '../icons/pencil.svg'
import { ReactComponent as TrashIcon } from '../icons/trash.svg'
import { ReactComponent as ReplyIcon } from '../icons/reply.svg'

const COMMENT_FRAGMENT = gql`
  fragment Comment on Comment {
    id
    createdAt
    updatedAt
    text
    userId
    screenId
    parentCommentId
    x
    y
    width
    height
    annotationType
    creatorUsername
    isCreator
  }
`

const COMMENTS_QUERY = gql`
  query Comments($screenId: ID!) {
    comments(screenId: $screenId) {
      ...Comment
    }
  }

  ${COMMENT_FRAGMENT}
`

gql`
  mutation EditScreen($data: EditScreenInput!) {
    editScreen(data: $data) {
      id
      name
      notes
    }
  }
`

gql`
  mutation CreateComment($data: CreateCommentInput!) {
    createComment(data: $data) {
      ...Comment
    }
  }

  ${COMMENT_FRAGMENT}
`

gql`
  mutation EditComment($data: EditCommentInput!) {
    editComment(data: $data) {
      ...Comment
    }
  }

  ${COMMENT_FRAGMENT}
`

gql`
  mutation DeleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId)
  }

  ${COMMENT_FRAGMENT}
`

interface ScreenEditProps {
  screen: Pick<Screen, 'id' | 'name' | 'notes'> & { imageUrl?: string }
  projectCreatorId?: string
  readonly: boolean
  showNotes?: boolean
  close: () => void
}

export const ScreenEdit: React.FC<ScreenEditProps> = props => {
  const { screen, readonly, showNotes = true, close } = props
  const { data, loading, error } = useCommentsQuery({ variables: { screenId: screen.id } })
  const { user } = useUserContext()
  const [editScreen] = useEditScreenMutation()
  const [createComment] = useCreateCommentMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const [hoveredComment, setHoveredComment] = React.useState<IAnnotation>()

  return (
    <div className="flex">
      <div className="flex items-center max-w-full overflow-y-auto" style={{ maxHeight: '80vh' }}>
        <div className="max-h-full max-w-full pb-10">
          {/* <img src={screen.imageUrl} alt={screen.name} /> */}
          {screen.imageUrl && (
            <ImageAnnotate
              image={screen.imageUrl}
              annotation={hoveredComment}
              annotations={
                data?.comments.map(comment => {
                  return {
                    data: {
                      id: comment.id,
                      text: comment.text,
                    },
                    geometry: {
                      width: comment.width || 0,
                      height: comment.height || 0,
                      x: comment.x || 0,
                      y: comment.y || 0,
                      type: comment.annotationType || AnnotationType.Rectangle,
                    },
                  }
                }) || []
              }
              onSubmit={async annotation => {
                const {
                  data: { text },
                  geometry: { x, y, height, width, type },
                } = annotation

                try {
                  const res = await createComment({
                    variables: {
                      data: {
                        screenId: screen.id,
                        text,
                        x,
                        y,
                        height,
                        width,
                        annotationType: type as AnnotationType,
                        otherUserId:
                          props.projectCreatorId !== user?.id ? props.projectCreatorId : null,
                      },
                    },
                    refetchQueries: [{ query: COMMENTS_QUERY, variables: { screenId: screen.id } }],
                  })
                  trackEvent('Add comment to screen', { ...res.data?.createComment })
                  showSuccessNotification({ description: 'Successfully added comment' })
                } catch (error) {
                  console.error(error)
                  showErrorNotification({ description: formatGraphQLError(error) })
                }
              }}
            />
          )}
        </div>
      </div>
      {showNotes && (
        <div className="w-64 ml-2 flex-shrink-0">
          <div className="ml-2">
            {readonly ? (
              <>
                <div className="text-lg leading-6">{screen.name}</div>
                <div className="mt-2">
                  <Paragraph>{screen.notes || 'There are no notes for this component.'}</Paragraph>
                </div>
              </>
            ) : (
              <Formik<{ name: string; notes?: string }>
                enableReinitialize
                initialValues={{
                  name: screen.name,
                  notes: screen.notes ?? undefined,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  console.log('values', values)
                  const data = { id: screen.id, ...values }
                  await editScreen({ variables: { data } })
                  trackEvent('Edit screen', data)
                  setSubmitting(false)
                }}
              >
                {({ isSubmitting, handleSubmit, handleReset }) => (
                  <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Field name="name" label="Name" component={CustomInputComponent} />
                    <FormItem>
                      <Field name="notes" label="Overview" component={CustomTextAreaComponent} />
                    </FormItem>

                    <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense mt-2">
                      <span className="flex w-full md:col-start-2">
                        <Button
                          styleType="primary"
                          block
                          type="submit"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                        >
                          Save
                        </Button>
                      </span>
                      <span className="mt-3 w-full sm:mt-0 md:col-start-1">
                        <Button styleType="white" block onClick={close}>
                          Cancel
                        </Button>
                      </span>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>

          <div className="ml-2">
            <div className="text-base text-semibold leading-5 mt-6 text-gray-900">Notes</div>
            {!data?.comments.length && (
              <div className="mt-2">
                <Paragraph>
                  You can add {user?.developer ? 'questions or remarks' : 'notes'} by clicking and
                  dragging on the image.
                </Paragraph>
              </div>
            )}
          </div>

          {/* 40vh height may not be the best way to handle overflow here */}
          <ul className="overflow-y-auto" style={{ maxHeight: '40vh' }}>
            {data?.comments
              .filter(comment => !comment.parentCommentId)
              .map(comment => {
                return (
                  <>
                    <Comment
                      key={comment.id}
                      comment={comment}
                      setHoveredComment={setHoveredComment}
                    />

                    {data?.comments
                      .filter(c => c.parentCommentId === comment.id)
                      .map(comment => {
                        return (
                          <Comment
                            key={comment.id}
                            comment={comment}
                            setHoveredComment={setHoveredComment}
                          />
                        )
                      })}
                  </>
                )
              })}
          </ul>
        </div>
      )}
    </div>
  )
}

interface CommentProps {
  comment: CommentFragment
  setHoveredComment: React.Dispatch<React.SetStateAction<IAnnotation | undefined>>
}

export const Comment: React.FC<CommentProps> = props => {
  const { comment, setHoveredComment } = props
  const [isEditing, setIsEditing] = React.useState(false)
  const [replyingTo, setReplyingTo] = React.useState<string>()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const refetchQueries = [{ query: COMMENTS_QUERY, variables: { screenId: comment.screenId } }]
  const [createComment] = useCreateCommentMutation()
  const [editComment] = useEditCommentMutation()
  const [deleteComment] = useDeleteCommentMutation()

  return (
    <li key={comment.id} className={classNames('py-2 group', { 'pl-4': comment.parentCommentId })}>
      <div className="flex">
        {/* <img
          className="h-6 w-6 rounded-full"
          src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
          alt=""
        /> */}
        <div
          className="flex-1 p-2 pr-4 rounded-md hover:bg-gray-100 cursor-pointer"
          onMouseEnter={() => {
            setHoveredComment({
              data: {
                id: comment.id,
                text: comment.text,
              },
              geometry: {
                height: comment.height || undefined,
                width: comment.width || undefined,
                type: comment.annotationType!,
                x: comment.x || undefined,
                y: comment.y || undefined,
              },
            })
          }}
          onMouseLeave={() => {
            setHoveredComment(undefined)
          }}
        >
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium leading-5">
              {comment.isCreator ? 'You' : comment.creatorUsername}
            </h3>
            {/* <p className="text-sm leading-5 text-gray-500">1h</p> */}
            <p className="text-sm leading-5 text-gray-400">
              {formatDateMedium(new Date(comment.createdAt))}
            </p>
          </div>
          {isEditing ? (
            <div className="my-2">
              <EditComment
                comment={comment}
                onSubmit={async values => {
                  const data = { ...values, id: comment.id }
                  try {
                    await editComment({ variables: { data } })
                    trackEvent('Update comment', data)
                    showSuccessNotification({ description: 'Successfully updated comment' })
                  } catch (error) {
                    console.error(error)
                    showErrorNotification({ description: formatGraphQLError(error) })
                  }
                  setIsEditing(false)
                }}
                onCancel={() => setIsEditing(false)}
                submitText="Save"
              />
            </div>
          ) : (
            <p className="text-sm leading-5 text-gray-500 whitespace-pre-wrap">{comment.text}</p>
          )}
          {!isEditing && (
            <div className="text-gray-400 justify-end mt-1 hidden group-hover:flex">
              <div
                className={classNames('hover:text-gray-900', {
                  'text-gray-900': replyingTo === comment.id,
                })}
              >
                <ReplyIcon
                  width={20}
                  height={20}
                  onClick={() => {
                    setReplyingTo(comment.parentCommentId || comment.id)
                  }}
                />
              </div>

              {comment.isCreator ? (
                <>
                  <div
                    className={classNames('hover:text-gray-900 ml-2', {
                      'text-gray-900': isEditing,
                    })}
                  >
                    <PencilIcon
                      width={20}
                      height={20}
                      onClick={() => {
                        setIsEditing(editing => !editing)
                      }}
                    />
                  </div>
                  <div className="hover:text-gray-900 ml-2">
                    <TrashIcon
                      width={20}
                      height={20}
                      onClick={async () => {
                        // eslint-disable-next-line no-restricted-globals
                        const yes = confirm(`Are you sure you'd like to delete this comment?`)

                        if (!yes) return

                        try {
                          await deleteComment({
                            variables: { commentId: comment.id },
                            refetchQueries: [
                              {
                                query: COMMENTS_QUERY,
                                variables: { screenId: comment.screenId },
                              },
                            ],
                          })
                          trackEvent('Delete comment', comment)
                          showSuccessNotification({ description: 'Successfully deleted comment' })
                        } catch (error) {
                          console.error(error)
                          showErrorNotification({ description: formatGraphQLError(error) })
                        }
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
          )}
          {replyingTo && (
            <div className="my-2">
              <EditComment
                comment={{ id: '', text: '' }}
                onSubmit={async values => {
                  const data = {
                    x: comment.x,
                    y: comment.y,
                    height: comment.height,
                    width: comment.width,
                    annotationType: comment.annotationType,

                    text: values.text,
                    parentCommentId: replyingTo,
                    screenId: comment.screenId,
                  }
                  try {
                    await createComment({ variables: { data }, refetchQueries })
                    trackEvent('Reply to comment', data)
                    showSuccessNotification({ description: 'Successfully replied' })
                  } catch (error) {
                    console.error(error)
                    showErrorNotification({ description: formatGraphQLError(error) })
                  }
                  setReplyingTo(undefined)
                }}
                onCancel={() => setReplyingTo(undefined)}
                submitText="Reply"
              />
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

interface EditCommentProps {
  comment: Pick<CommentFragment, 'id' | 'text'>
  onSubmit: (values: Pick<CommentFragment, 'text'>) => void
  onCancel: () => void
  submitText: string
}

export const EditComment: React.FC<EditCommentProps> = props => {
  const { comment, onSubmit } = props

  return (
    <Formik<EditCommentInput>
      initialValues={{
        id: comment.id,
        text: comment.text,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Field name="text" component={CustomTextAreaComponent} />
          <div className="flex justify-end mt-1 space-x-2">
            <button
              type="button"
              className="text-sm text-gray-400 hover:text-gray-900"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="text-sm text-gray-400 hover:text-gray-900"
              disabled={isSubmitting}
            >
              {props.submitText}
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}
