import React from 'react'

// Based off of:
// https://medium.com/better-programming/4-ways-of-adding-external-js-files-in-reactjs-823f85de3668
export const useScript = (resourceUrl: string, onLoad?: () => void) => {
  const ref = React.useRef(false)

  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = resourceUrl
    script.async = true
    script.id = resourceUrl
    script.onload = () => onLoad?.()

    if (!ref.current) {
      ref.current = true
      document.body.appendChild(script)
    }

    return () => {
      try {
        document.body.removeChild(script)
      } catch (error) {
        // seems to happen in development with hot reload
      }
    }
  }, [onLoad, resourceUrl])
}
