import React from 'react'
import ReactDOM from 'react-dom'
import { hotjar } from 'react-hotjar'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import TagManager from 'react-gtm-module'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import {
  HOTJAR_SITE_ID,
  HOTJAR_SNIPPET_VERSION,
  GTM_ID,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
} from './utils/config'
import './tailwind.css'
import './main.css'

if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID })
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: SENTRY_ENVIRONMENT === 'production' ? 0.1 : 1.0,
})
if (HOTJAR_SITE_ID && HOTJAR_SNIPPET_VERSION)
  hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
