import { gql } from '@apollo/client'
import { Formik, Field } from 'formik'
import React from 'react'
import { Button } from '../../components/Button'
import { Card } from '../../components/Card'
import { CustomRatingComponent } from '../../components/FormikCustom'
import { FormItem } from '../../components/FormItem'
import { useNotification } from '../../components/Notification'
import { useRateComponentMutation } from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import { formatGraphQLError } from '../../utils/error'

gql`
  mutation RateComponent($componentId: ID!, $rating: Float!) {
    rateComponent(componentId: $componentId, rating: $rating) {
      id
      rating
    }
  }
`

interface RateComponentProps {
  component: { id: string; rating?: number | null }
}

export const RateComponent: React.FC<RateComponentProps> = props => {
  const { component } = props
  const [rateComponent] = useRateComponentMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <Card title="Rating (Admin Only)">
      <Formik<{ rating?: number | null }>
        initialValues={{ rating: component.rating }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)

          try {
            const { rating } = values
            if (!rating) {
              console.warn('No rating')
              return
            }

            await rateComponent({
              variables: { componentId: component.id, rating },
              refetchQueries: [],
            })
            trackEvent('Rate component', values)

            showSuccessNotification({ description: 'Successfully submitted' })
          } catch (error) {
            console.error(error)
            showErrorNotification({ description: formatGraphQLError(error) })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values, touched }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <FormItem>
              <Field name="rating" label="Rating" component={CustomRatingComponent} />
            </FormItem>

            <FormItem>
              <Button styleType="primary" type="submit" disabled={isSubmitting}>
                Rate
              </Button>
            </FormItem>
          </form>
        )}
      </Formik>
    </Card>
  )
}
