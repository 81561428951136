import React from 'react'
import { Card } from '../../components/Card'
import { Tag } from '../../components/Tag'
import { Paragraph } from '../../components/Typography'
import { formatDateMedium } from '../../utils/date'
import { BidStatus, BidWithDeveloperFragment, GetProjectFragment } from '../../generated/graphql'
import { formatBidAmount } from '../../utils/bid'

export interface BidsProps {
  project: Pick<GetProjectFragment, 'bids' | 'status' | 'interviewBidProject'>
  setAcceptModalBid: (bid: BidWithDeveloperFragment) => void
}

export const Bids: React.FC<BidsProps> = props => {
  const { project, setAcceptModalBid } = props

  if (!project.bids?.length) {
    return (
      <Card title="Bids">
        <Paragraph>There are no bids yet.</Paragraph>
      </Card>
    )
  }

  return (
    <Card title="Bids" noContentPadding noBottomPadding>
      <ul className="bg-white overflow-hidden sm:rounded-b-md divide-y divide-gray-200">
        {project.bids?.map(bid => {
          return (
            <li key={bid.id} data-cy="bid-item">
              <div
                className="block hover:bg-gray-50 cursor-pointer"
                onClick={() => setAcceptModalBid(bid)}
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="flex text-sm font-medium text-indigo-600 truncate">
                        <p>{formatBidAmount(bid)}</p>
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm text-gray-500">
                          By {bid.developer.username || bid.developer.id}.{' '}
                          {bid.deliveryDate ? (
                            <span className="ml-1">
                              Delivery by{' '}
                              <time dateTime={new Date(bid.deliveryDate).toISOString()}>
                                {formatDateMedium(new Date(bid.deliveryDate))}
                              </time>
                            </span>
                          ) : (
                            <>No delivery date set</>
                          )}
                        </div>
                      </div>
                    </div>

                    {(bid.status === BidStatus.Accepted || bid.status === BidStatus.Rejected) && (
                      <div className="ml-2 flex-shrink-0 flex">
                        {bid.status === BidStatus.Accepted ? (
                          <Tag color="green">Accepted</Tag>
                        ) : (
                          <Tag color="red">Rejected</Tag>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}
