import React from 'react'
import classNames from 'classnames'
import { Link, NavLink, useHistory } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import { gql } from '@apollo/client'
import { Transition } from '@headlessui/react'
import { useUserContext } from '../auth/userContext'
import { userInitials } from '../utils/user'
import {
  useNotificationsQuery,
  useChangeAdminStatusMutation,
  useChangeAccountStatusMutation,
  useChangeInterviewStatusMutation,
  useMarkNotificationAsReadMutation,
  UserInfoFragment,
  InterviewStatus,
  AccountStatus,
  SubscriptionPlan,
} from '../generated/graphql'
import { trackEvent } from '../utils/analytics'
import { DEV_MODE, NEXT_APP_URL } from '../utils/config'
import { ReactComponent as LogoDesktop } from '../images/logo.svg'
import { ReactComponent as LogoIcon } from '../images/logo-icon.svg'
import { ReactComponent as BellIcon } from '../icons/bell.svg'
import { ReactComponent as PlusIcon } from '../icons/plus.svg'
import { ReactComponent as MenuHamburgerIcon } from '../icons/menu-hamburger.svg'
import { ReactComponent as MenuCloseIcon } from '../icons/menu-close.svg'
import { ButtonLink } from './Button'

const NOTIFICATION_FRAGMENT = gql`
  fragment Notification on Notification {
    id
    read
    message
    bidId
    projectId
    queryString
  }
`

const GET_NOTIFICATIONS = gql`
  ${NOTIFICATION_FRAGMENT}

  query Notifications {
    notifications {
      ...Notification
    }
  }
`

gql`
  ${NOTIFICATION_FRAGMENT}

  mutation MarkNotificationAsRead($notificationId: ID!) {
    markNotificationAsRead(notificationId: $notificationId) {
      ...Notification
    }
  }
`

gql`
  mutation ChangeAdminStatus($admin: Boolean!) {
    changeAdminStatus(admin: $admin) {
      id
      admin
    }
  }
`

gql`
  mutation ChangeAccountStatus($accountStatus: Boolean!, $code: String) {
    changeAccountStatus(accountStatus: $accountStatus, code: $code) {
      id
      accountStatus
    }
  }
`

gql`
  mutation ChangeInterviewStatus($passed: Boolean!) {
    changeInterviewStatus(passed: $passed) {
      id
      interviewStatus
    }
  }
`

type NavLinkItem = { title: string; to: string; external?: boolean }

const DEVELOPER_NAV_LINKS: NavLinkItem[] = [
  { title: 'Dashboard', to: '/developer/dashboard' },
  { title: 'Projects', to: '/developer/projects' },
  { title: 'Component Marketplace', to: '/components/published' },
]

const CLIENT_NAV_LINKS: NavLinkItem[] = [
  { title: 'Projects', to: '/' },
  { title: 'Component Marketplace', to: '/components' },
]

interface NavBarProps {}

export const NavBar: React.FC<NavBarProps> = props => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [userMenuOpen, setUserMenuOpen] = React.useState(false)
  const [notificationsOpen, setNotificationsOpen] = React.useState(false)
  const { user, logOut } = useUserContext()
  const history = useHistory()
  const userMenuRef = React.useRef(null)
  const notificationMenuRef = React.useRef(null)
  const [markAsRead] = useMarkNotificationAsReadMutation()
  const { data, error } = useNotificationsQuery()
  const [changeAdminStatus] = useChangeAdminStatusMutation()
  const [changeAccountStatus] = useChangeAccountStatusMutation()
  const [changeInterviewStatus] = useChangeInterviewStatusMutation()

  if (error) console.error(error)

  const closeUserMenu = () => setUserMenuOpen(false)
  const closeNotificationMenu = () => setNotificationsOpen(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  const toggleUserMenu = () => setUserMenuOpen(!userMenuOpen)
  const toggleNotificationMenu = () => setNotificationsOpen(!notificationsOpen)
  const closeMenus = () => {
    closeUserMenu()
    setMenuOpen(false)
  }

  useOnClickOutside(userMenuRef, closeUserMenu)
  useOnClickOutside(notificationMenuRef, closeNotificationMenu)

  const profileLinks: ProfileMenuProps['links'] = []

  if (user?.developer) {
    profileLinks.push({
      title: 'Your Profile',
      to: `/profile`,
      onClick: closeMenus,
    })
    profileLinks.push({
      title: 'Payments',
      to: `/developer/payments`,
      onClick: closeMenus,
    })
    profileLinks.push({
      title: 'Plan',
      to: `/subscribe`,
      onClick: closeMenus,
    })
    // if (user.interviewStatus === InterviewStatus.Passed) {
    //   profileLinks.push({
    //     title: 'Bank Information',
    //     to: `/developer/payment-details`,
    //     onClick: closeMenus,
    //   })
    // }
  } else {
    profileLinks.push({
      title: 'Profile',
      to: `/profile`,
      onClick: closeMenus,
    })
    profileLinks.push({
      title: 'Plan',
      to: `/subscribe`,
      onClick: closeMenus,
    })
    profileLinks.push({
      title: 'Payment History',
      to: `/payment-history`,
      onClick: closeMenus,
    })
  }

  if (DEV_MODE) {
    profileLinks.push({
      title: `${user?.admin ? 'Remove Admin Privleges' : 'Become Admin User'} (Dev Mode Only)`,
      to: `#`,
      onClick: () => {
        changeAdminStatus({ variables: { admin: !user?.admin } })
        closeMenus()
      },
    })

    if (user?.developer) {
      const passed = user?.interviewStatus === InterviewStatus.Passed

      profileLinks.push({
        title: `${passed ? 'Start Interviewing' : 'Pass Interview'} (Dev Mode Only)`,
        to: `#`,
        onClick: () => {
          changeInterviewStatus({
            variables: { passed: !passed },
          })
          closeMenus()
        },
      })
    } else {
      profileLinks.push({
        title: `${
          user?.accountStatus !== AccountStatus.Enabled ? 'Enable Account' : 'Disable Account'
        } (Dev Mode Only)`,
        to: `#`,
        onClick: () => {
          changeAccountStatus({
            variables: { accountStatus: user?.accountStatus !== AccountStatus.Enabled },
          })
          closeMenus()
        },
      })
    }
  }

  if (user?.admin) {
    profileLinks.push({
      title: 'New Interview Bid Project',
      to: `/new-project-type#interview-bid`,
      onClick: closeMenus,
    })

    profileLinks.push({
      title: 'New Interview Dev Project',
      to: `/new-project-type#interview-dev`,
      onClick: closeMenus,
    })
  }

  profileLinks.push({
    title: 'Sign out',
    to: '#',
    onClick: () => {
      logOut()
      closeMenus()
    },
  })

  const links = user?.developer ? DEVELOPER_NAV_LINKS : CLIENT_NAV_LINKS

  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="-ml-2 mr-2 flex items-center md:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition"
                onClick={toggleMenu}
              >
                <MenuCloseIcon className={`${menuOpen ? 'hidden' : 'block'} h-6 w-6`} />
                <MenuHamburgerIcon className={`${menuOpen ? 'block' : 'hidden'} h-6 w-6`} />
              </button>
            </div>
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <LogoIcon className="block lg:hidden h-8 w-auto text-indigo-500" />
                <LogoDesktop className="hidden lg:block h-8 w-auto" />
              </Link>
            </div>
            <div className="hidden md:ml-6 md:flex md:items-center space-x-4">
              {links.map((link, i) => (
                <NavBarLink
                  key={link.title}
                  title={link.title}
                  to={link.to}
                  exact
                  external={link.external}
                />
              ))}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {user?.componentSubscriptionPlan ? (
              <div className="text-white sm:mr-4">
                Credits:{' '}
                {user.componentSubscriptionPlan === SubscriptionPlan.Unlimited
                  ? 'Unlimited'
                  : user?.componentCredits || 0}
              </div>
            ) : (
              <div className="flex-shrink-0">
                <ButtonLink styleType="linkWhite" to="/subscribe">
                  Subscribe
                </ButtonLink>
              </div>
            )}

            {user?.developer ? (
              <div className="flex-shrink-0">
                <span className="rounded-md shadow-sm">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-indigo focus:border-indigo-600 active:bg-indigo-600 transition"
                    onClick={() => history.push('/upload-component')}
                  >
                    Sell Component
                  </button>
                </span>
              </div>
            ) : (
              <>
                <div className="flex-shrink-0">
                  <span className="rounded-md shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-indigo focus:border-indigo-600 active:bg-indigo-600 transition"
                      onClick={() => history.push('/new-project-onboarding')}
                    >
                      <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
                      <span>New Project</span>
                    </button>
                  </span>
                </div>
              </>
            )}

            <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
              <div className="relative inline-block text-left" ref={notificationMenuRef}>
                <div>
                  <button
                    className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-300 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition"
                    onClick={toggleNotificationMenu}
                  >
                    <BellIcon />
                    {!!data?.notifications.filter(n => !n.read).length && (
                      <span className="absolute top-0 right-0 block h-1.5 w-1.5 rounded-full text-white shadow-solid bg-green-400"></span>
                    )}
                  </button>
                </div>

                <Transition
                  show={notificationsOpen}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
                >
                  <div className="rounded-md bg-white focus:ringring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {data?.notifications.length ? (
                        data?.notifications.map(notification => {
                          const path = notification.projectId
                            ? `/project/${notification.projectId}`
                            : ''
                          const to = `${path}?${notification.queryString || ''}`

                          return (
                            <Link
                              key={notification.id}
                              to={to}
                              className={classNames(
                                `block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`,
                                {
                                  'bg-gray-100': !notification.read,
                                }
                              )}
                              role="menuitem"
                              onClick={() => {
                                const variables = { notificationId: notification.id }
                                markAsRead({
                                  variables,
                                  refetchQueries: [{ query: GET_NOTIFICATIONS }],
                                })
                                trackEvent('Mark notification as read', variables)
                                closeNotificationMenu()
                              }}
                            >
                              {notification.message}
                            </Link>
                          )
                        })
                      ) : (
                        <div className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
                          You don't have any notifications.
                        </div>
                      )}
                    </div>
                  </div>
                </Transition>
              </div>

              <div className="ml-3 relative" ref={userMenuRef}>
                <div>
                  <button
                    className="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition"
                    data-cy="nav-avatar"
                    onClick={toggleUserMenu}
                  >
                    <NavBarAvatar user={user} />
                  </button>
                </div>
                <Transition
                  show={userMenuOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <ProfileMenu links={profileLinks} />
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${menuOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 sm:px-3 space-y-1">
          {links.map((link, i) => (
            <NavBarLinkMobile
              key={link.title}
              title={link.title}
              to={link.to}
              exact
              external={link.external}
            />
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-5 sm:px-6">
            <div className="flex-shrink-0">
              <NavBarAvatar user={user} />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-6 text-white">
                {user?.firstName} {user?.lastName}
              </div>
              <div className="text-sm font-medium leading-5 text-gray-400">{user?.email}</div>
            </div>
          </div>
          <ProfileMenuMobile links={profileLinks} />
        </div>
      </div>
    </nav>
  )
}

interface NavBarLinkProps {
  title: string
  to: string
  exact?: boolean
  external?: boolean
}

const NavBarLink: React.FC<NavBarLinkProps> = props => {
  return (
    <span className="text-gray-300 hover:text-white">
      <NavLink
        to={props.to}
        className="px-3 py-2 rounded-md text-sm font-medium leading-5 focus:outline-none focus:text-white focus:bg-gray-700 transition hover:bg-gray-700"
        activeClassName="text-white bg-gray-700"
        exact={props.exact}
        onClick={e => {
          if (props.external) {
            e.preventDefault()
            window.open(props.to)
          }
        }}
      >
        {props.title}
      </NavLink>
    </span>
  )
}

const NavBarLinkMobile: React.FC<NavBarLinkProps> = props => (
  <NavLink
    to={props.to}
    className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition"
    activeClassName="text-white bg-gray-900"
    exact={props.exact}
    onClick={e => {
      if (props.external) {
        e.preventDefault()
        window.open(props.to)
      }
    }}
  >
    {props.title}
  </NavLink>
)

interface ProfileMenuProps {
  links: Array<{
    title: string
    to: string
    onClick?: () => void
  }>
}

const ProfileMenu: React.FC<ProfileMenuProps> = props => {
  return (
    <div className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
      <div className="py-1 rounded-md bg-white focus:ringring-1 ring-black ring-opacity-5">
        {props.links.map(link => (
          <Link
            key={link.title}
            to={link.to}
            className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition"
            onClick={link.onClick}
          >
            {link.title}
          </Link>
        ))}
      </div>
    </div>
  )
}

const ProfileMenuMobile: React.FC<ProfileMenuProps> = props => (
  <div className="mt-3 px-2 sm:px-3">
    {props.links.map((link, i) => (
      <Link
        key={link.title}
        to={link.to}
        className={`${
          i > 0 ? 'mt-1' : ''
        } block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition`}
        onClick={link.onClick}
      >
        {link.title}
      </Link>
    ))}
  </div>
)

interface AvatarProps {
  user?: UserInfoFragment
}

const NavBarAvatar: React.FC<AvatarProps> = props => {
  return (
    <div
      className="h-8 w-8 rounded-full bg-indigo-500 flex items-center justify-center text-white"
      // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
      // alt=""
    >
      {userInitials(props.user).toUpperCase()}
    </div>
  )
}
