import React from 'react'
import { gql } from '@apollo/client'
import {
  useUpdateGithubUsernameMutation,
  useUnsetGithubUsernameMutation,
  GetUserDocument,
} from '../generated/graphql'
import { GITHUB_CLIENT_ID, GITHUB_REDIRECT_URI, SERVER_URL } from './config'
import { useStorage } from './useStorage'

gql`
  mutation UpdateGithubUsername($githubAccessToken: String!) {
    updateGithubUsername(githubAccessToken: $githubAccessToken) {
      id
      githubUsername
      githubAppUsername
    }
  }
`

gql`
  mutation UpdateGithubAppUsername($githubAccessToken: String!, $githubInstallationId: Int!) {
    updateGithubAppUsername(
      githubAccessToken: $githubAccessToken
      githubInstallationId: $githubInstallationId
    ) {
      id
      githubUsername
      githubAppUsername
    }
  }
`

gql`
  mutation UnsetGithubUsername {
    unsetGithubUsername {
      id
      githubUsername
    }
  }
`

gql`
  mutation UnsetGithubAppUsername {
    unsetGithubAppUsername {
      id
      githubAppUsername
    }
  }
`

const ACCESS_TOKEN_KEY = 'GITHUB_ACCESS_TOKEN_KEY'
const STATE_KEY = 'GITHUB_STATE_KEY'

const getGitHubLocalStorage = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

const setGitHubLocalStorage = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}

const unsetGitHubLocalStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
}

const createLocalStorageGitHubState = () => {
  const state = Math.random().toString()
  localStorage.setItem(STATE_KEY, state)
  return state
}
export const getLocalStorageGitHubState = () => localStorage.getItem(STATE_KEY)
export const clearLocalStorageGitHubState = () => localStorage.removeItem(STATE_KEY)

export const connectToGitHub = () => {
  const state = createLocalStorageGitHubState()
  const url = `https://www.github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&redirect_uri=${GITHUB_REDIRECT_URI}&state=${state}&response_type=code`
  const popup = window.open(url, 'Connect GitHub', 'resizable,scrollbars,status')

  ;(window as any).onGitHubConnected = (accessToken: string) => {
    // TODO reload user. we use a more complicated flow to accomplish this instead right now
    popup?.close()
  }
}

export const getGithubToken = async (code: string) => {
  unsetGitHubLocalStorage()

  const url = `${SERVER_URL}/github/authorize/${code}`

  const resString = await fetch(url, {
    method: 'get',
    headers: { accept: 'application/json' },
  })

  const res = await resString.json()

  setGitHubLocalStorage(res.access_token)

  return res.access_token
}

export const getGithubAppToken = async (code: string) => {
  const url = `${SERVER_URL}/github/authorize-app/${code}`

  const resString = await fetch(url, {
    method: 'get',
    headers: { accept: 'application/json' },
  })

  const res = await resString.json()

  return res.access_token
}

export const useGithubAuth = () => {
  const [updateGithubUsername] = useUpdateGithubUsernameMutation()

  const callback = React.useCallback(() => {
    const token = getGitHubLocalStorage()

    if (token) {
      updateGithubUsername({ variables: { githubAccessToken: token } })
    }
  }, [updateGithubUsername])

  useStorage(callback)
}

export const useUnsetGithubUsername = (): ReturnType<typeof useUnsetGithubUsernameMutation> => {
  const [unsetMutation, result] = useUnsetGithubUsernameMutation({
    refetchQueries: [{ query: GetUserDocument }],
  })

  const unset = () => {
    unsetGitHubLocalStorage()
    return unsetMutation()
  }

  return [unset, result]
}
