import { UserInfoFragment } from '../generated/graphql'

export const userInitials = (user?: UserInfoFragment): string => {
  if (!user) return 'PR'
  if (user.firstName && user.lastName) return user.firstName[0] + user.lastName[0]
  if (user.firstName) return user.firstName[0] + user.firstName[1]
  if (user.lastName) return user.lastName[0] + user.lastName[1]
  if (user.email) return user.email[0] + user.email[1]
  return 'PR'
}
