import React from 'react'
import { Transition } from '@headlessui/react'
import { createNotificationContext } from 'react-notification-provider'
import { InPortal } from './InPortal'
import { ReactComponent as XIcon } from '../icons/x.svg'
import { ReactComponent as CheckCircleIcon } from '../icons/check-circle.svg'
import { ReactComponent as XCircleIcon } from '../icons/x-circle.svg'

const DURATION = 4_000

export interface NotificationProps {
  message?: string
  description: string | React.ReactNode
  level: 'success' | 'error'
}

const { NotificationProvider, useNotificationQueue } = createNotificationContext<
  NotificationProps
>()
export { NotificationProvider }

export const useNotification = () => {
  const { add, remove } = useNotificationQueue()

  const showNotification = React.useCallback(
    ({ level, message, description }: NotificationProps) => {
      const id = `${message}_${description}`
      add(id, { level, message, description })
      setTimeout(() => remove(id), DURATION + 500)
    },
    [add, remove]
  )

  const showSuccessNotification = React.useCallback(
    ({ message, description }: Pick<NotificationProps, 'message' | 'description'>) => {
      showNotification({
        level: 'success',
        message: message || 'Success',
        description,
      })
    },
    [showNotification]
  )

  const showErrorNotification = React.useCallback(
    ({ message, description }: Pick<NotificationProps, 'message' | 'description'>) => {
      showNotification({
        level: 'error',
        message: message || 'Error',
        description,
      })
    },
    [showNotification]
  )

  return { showSuccessNotification, showErrorNotification }
}

export const Notification: React.FC<NotificationProps> = props => {
  const [isOpen, setIsOpen] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(false)
    }, DURATION)
  }, [])

  return (
    <InPortal id="notification-root">
      <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
        <Transition
          show={isOpen}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto"
        >
          <div className="rounded-lg focus:ringring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {props.level === 'success' ? (
                    <CheckCircleIcon className="h-6 w-6 text-green-400" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 text-red-400" />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm leading-5 font-medium text-gray-900">{props.message}</p>
                  <p className="mt-1 text-sm leading-5 text-gray-500">{props.description}</p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition"
                    onClick={() => setIsOpen(false)}
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </InPortal>
  )
}

export const NotificationList: React.FC<{}> = props => {
  const queue = useNotificationQueue()

  return (
    <div>
      {queue.entries.map(({ id, data }) => (
        <Notification
          key={id}
          message={data.message}
          description={data.description}
          level={data.level}
        />
      ))}
    </div>
  )
}
