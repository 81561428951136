import React from 'react'
import ReactDOM from 'react-dom'
import { Loading } from '../../components/Loading'
import { useScript } from '../../utils/use-script'
import { serverAmountToPayPalAmount } from '../../utils/currency'
import { PAYPAL_CLIENT_ID } from '../../utils/config'
import { gql } from '@apollo/client'
import { ProjectDocument, useSubmitPaypalPaymentMutation } from '../../generated/graphql'

gql`
  mutation SubmitPaypalPayment($data: SubmitPaypalPayment!) {
    submitPaypalPayment(data: $data) {
      id
      paypalId
      paypalPayerId
      paypalPayerCountryCode
      paypalStatus
      amount
      currency
    }
  }
`

interface PaymentPayPalProps {
  amount: number
  projectId: string
  bidId: string
  onSuccess: () => void
}

export const PaymentPayPal: React.FC<PaymentPayPalProps> = props => {
  const [ready, setReady] = React.useState(false)

  useScript(`https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}`, () => setReady(true))

  const [submitPaypalPayment] = useSubmitPaypalPaymentMutation()

  if (!ready) return <Loading />

  // @ts-ignore
  const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })

  function createOrder(data: any, actions: any) {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: serverAmountToPayPalAmount(props.amount),
          },
        },
      ],
    })
  }

  async function onApprove(data: { orderID: string }, actions: any) {
    const res = actions.order.capture()

    await submitPaypalPayment({
      variables: { data: { paypalOrderId: data.orderID, bidId: props.bidId } },
      refetchQueries: [{ query: ProjectDocument, variables: { id: props.projectId } }],
    })

    props.onSuccess()

    return res
  }

  return <PayPalButton createOrder={createOrder} onApprove={onApprove} />
}
