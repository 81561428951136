import classNames from 'classnames'
import React from 'react'

export interface ToggleProps {
  enabled?: boolean
  onChange?: () => void
  rightText?: string
}

export const Toggle: React.FC<ToggleProps> = props => {
  return (
    <div className="flex items-center">
      <button
        type="button"
        className={classNames(
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          { 'bg-indigo-600': props.enabled, 'bg-gray-200': !props.enabled }
        )}
        aria-pressed="false"
        onClick={props.onChange}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            { 'translate-x-5': props.enabled, 'translate-x-0': !props.enabled }
          )}
        ></span>
      </button>

      {props.rightText ? (
        <span className="ml-3" id="annual-billing-label">
          <span className="text-sm font-medium text-gray-900">{props.rightText}</span>
        </span>
      ) : null}
    </div>
  )
}
