import { gql } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  LogInInput,
  SendMagicLogInInput,
  SignUpInput,
  useGetUserQuery,
  useLogInMutation,
  useLogOutMutation,
  UserInfoFragment,
  useSignUpMutation,
  useSendMagicLogInMutation,
  useMagicLogInMutation,
} from '../generated/graphql'
import { trackEvent, useTrackEvents } from '../utils/analytics'
import { client } from '../utils/apollo'

interface Context {
  user?: UserInfoFragment
  loading: boolean
  logIn: (input: LogInInput) => Promise<any>
  signUp: (input: SignUpInput) => Promise<any>
  logOut: (redirectToHome?: boolean) => void
  sendMagicLogIn: (input: SendMagicLogInInput) => Promise<any>
}

const defaultContextValue: Context = {
  user: undefined,
  loading: false,
  logIn: async () => null,
  signUp: async () => null,
  logOut: () => null,
  sendMagicLogIn: async () => null,
}

export const UserContext = React.createContext<Context>(defaultContextValue)

export const useUserContext = () => React.useContext(UserContext)

export const UserProvider: React.FC<{}> = ({ children }) => {
  const [state, setState] = React.useState<{ user?: UserInfoFragment; loading: boolean }>({
    user: undefined,
    loading: true,
  })
  const history = useHistory()
  const { data, loading } = useGetUserQuery()
  const [logInAction] = useLogInMutation()
  const [signUpAction] = useSignUpMutation()
  const [logOutAction] = useLogOutMutation()
  const [sendMagicLogInAction] = useSendMagicLogInMutation()
  const [magicLogInAction] = useMagicLogInMutation()
  const { trackSignUp } = useTrackEvents()

  const user = data?.user

  const search = new URLSearchParams(history.location.search)
  const token = search.get('token')

  React.useEffect(() => {
    setState({ user, loading })
  }, [user, loading])

  React.useEffect(() => {
    async function completeMagicLogIn() {
      if (token) {
        const res = await magicLogInAction({ variables: { data: { token } } })
        trackEvent('Complete magic log in', { email: res.data?.user.email })
        setState({ user: res.data?.user, loading: false })
      }
    }

    completeMagicLogIn()
  }, [magicLogInAction, token])

  const SEARCH_QUERY_KEY = 'SEARCH_QUERY'

  React.useEffect(() => {
    if (history.location.search && !localStorage.getItem(SEARCH_QUERY_KEY))
      localStorage.setItem(SEARCH_QUERY_KEY, history.location.search.substring(1))
  }, [history.location.search])

  async function logIn(data: LogInInput) {
    setState({ loading: true })
    try {
      const res = await logInAction({
        variables: {
          data,
        },
      })
      trackEvent('Log in', { email: res.data?.user.email })
      setState({ user: res.data?.user, loading: false })
    } catch (error) {
      setState({ loading: false })
      throw error
    }
  }

  async function signUp(data: SignUpInput) {
    setState({ loading: true })
    try {
      const variables = { data }

      const searchQuery = localStorage.getItem(SEARCH_QUERY_KEY)
      if (searchQuery) variables.data.signUpParams = searchQuery

      const res = await signUpAction({ variables })
      trackSignUp({ email: res.data?.user.email, developer: data.developer })
      setState({ user: res.data?.user, loading: false })
    } catch (error) {
      setState({ loading: false })
      throw error
    }
  }

  function logOut(redirectToHome = true) {
    logOutAction()
    trackEvent('Log out', { email: user?.email })
    setState({ user: undefined, loading: false })
    client.clearStore()
    if (redirectToHome) history.push('/')
  }

  async function sendMagicLogIn(data: SendMagicLogInInput) {
    trackEvent('Send magic log in', { email: data.email })
    sendMagicLogInAction({ variables: { data } })
  }

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        logIn,
        signUp,
        logOut,
        sendMagicLogIn,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const userInfoFragment = gql`
  fragment UserInfo on User {
    id
    email
    firstName
    lastName
    developer
    interviewStatus
    developerProgress
    githubUsername
    githubAppUsername
    addressSet
    accountStatus
    manualPaymentClient
    admin
    stripeConnectAccountId

    componentCredits
    componentCreditsPerMonth
    componentSubscriptionPlan
  }
`

gql`
  query GetUser {
    user: getUser {
      ...UserInfo
    }
  }

  ${userInfoFragment}
`

gql`
  mutation LogIn($data: LogInInput!) {
    user: logIn(logInInput: $data) {
      ...UserInfo
    }
  }

  ${userInfoFragment}
`

gql`
  mutation SignUp($data: SignUpInput!) {
    user: signUp(signUpInput: $data) {
      ...UserInfo
    }
  }

  ${userInfoFragment}
`

gql`
  mutation LogOut {
    logOut
  }
`

gql`
  mutation SendMagicLogIn($data: SendMagicLogInInput!) {
    sendMagicLogIn(data: $data)
  }
`

gql`
  mutation MagicLogIn($data: MagicLogInInput!) {
    user: magicLogIn(data: $data) {
      ...UserInfo
    }
  }

  ${userInfoFragment}
`
