import React from 'react'
import produce from 'immer'
import { gql } from '@apollo/client'
import { StepComponentProps } from '../utils/location'
import { ProjectFragment, useCreateProjectImageMutation } from '../generated/graphql'
import { Card } from '../components/Card'
import { Pagination } from '../components/Pagination'
import { Paragraph } from '../components/Typography'
import { formatDesignApp } from '../utils/designApp'
import { Button } from '../components/Button'
import { SelectComponents } from '../components/SelectComponents'
import { PROJECT_FRAGMENT } from './Projects'
import { trackEvent } from '../utils/analytics'
import { useCloudinary } from '../utils/cloudinary'

gql`
  mutation createProjectImage($data: CreateProjectImageInput!, $projectId: ID!) {
    createProjectImage(data: $data, projectId: $projectId) {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`
interface ScreenImage {
  url: string
  filename: string
  selected: boolean
}

interface NewProjectOtherProps extends StepComponentProps {
  project?: ProjectFragment
}

export const NewProjectOther: React.FC<NewProjectOtherProps> = props => {
  const { openUploadWidget } = useCloudinary()

  const existingImages: { [key: string]: ScreenImage } = {}
  props.project?.imageScreens?.forEach(screen => {
    if (!screen.cloudinaryUrl) return

    existingImages[screen.cloudinaryUrl] = {
      filename: screen.name,
      url: screen.cloudinaryUrl,
      selected: true,
    }
  })
  const [imagesMap, setImagesMap] = React.useState<{ [key: string]: ScreenImage }>(existingImages)
  const imagesArray = Object.values(imagesMap)
  const selectedScreens: { [key: string]: boolean } = {}
  let canSubmit = false
  imagesArray.forEach(image => {
    selectedScreens[image.url] = image.selected
    if (image.selected) canSubmit = true
  })

  const [createProjectImage] = useCreateProjectImageMutation()

  const setAll = (selected: boolean) => {
    setImagesMap(
      produce(imagesMap, imagesMap => {
        Object.keys(imagesMap).forEach(key => {
          imagesMap[key].selected = selected
        })
        return imagesMap
      })
    )
  }

  const selectAll = () => setAll(true)
  const unselectAll = () => setAll(false)
  const onClickComponent = (screenId: string) => {
    setImagesMap(
      produce(imagesMap, imagesMap => {
        imagesMap[screenId].selected = !imagesMap[screenId].selected
        return imagesMap
      })
    )
  }

  const onNextClick = props.lastStep
    ? undefined
    : async () => {
        const projectId = props.project?.id!

        const variables = {
          projectId,
          data: {
            screens: imagesArray
              .filter(screen => screen.selected)
              .map(screen => {
                return {
                  id: screen.url,
                  name: screen.filename,
                  url: screen.url,
                }
              }),
          },
        }

        await createProjectImage({ variables })
        trackEvent(`Create project - Image`, variables)

        props.increaseStep?.()
      }

  if (!props.project) return null

  return (
    <Card title={formatDesignApp(props.project.designApp)}>
      <div className="mb-4">
        <Paragraph>Please upload a pngs or jpegs of your designs.</Paragraph>
      </div>

      <div className="mt-8 mb-16 flex items-center justify-center">
        <Button
          styleType="primary"
          onClick={() => {
            openUploadWidget(({ error, event, url, filename }) => {
              if (!error && event === 'success') {
                setImagesMap(imagesMap => {
                  return {
                    ...imagesMap,
                    [url]: {
                      url,
                      filename,
                      selected: true,
                    },
                  }
                })
              }
            })
          }}
        >
          Upload images
        </Button>
      </div>

      <SelectComponents
        label="Which screens would you like to be developed?"
        screens={imagesArray.map((screen: ScreenImage) => {
          return {
            id: screen.url,
            name: screen.filename,
            image: { original_url: screen.url },
          }
        })}
        getImageUrl={screen => imagesMap[screen.id].url || ''}
        selectAll={selectAll}
        unselectAll={unselectAll}
        selectedScreens={selectedScreens}
        onClickComponent={onClickComponent}
        getExternalUrl={screen => screen.image.original_url}
        readonly={false}
        showNotes={false}
      />

      <Pagination
        isSubmitting={false}
        onNextClick={onNextClick}
        onPreviousClick={props.firstStep ? undefined : props.decreaseStep}
        disabled={!canSubmit}
      />
    </Card>
  )
}
