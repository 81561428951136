import React, { SetStateAction, Dispatch } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useSearchQuery() {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export function useSetSearchQuery(): [URLSearchParams, (key: string, value: string) => void] {
  const history = useHistory()
  const query = useSearchQuery()

  const setSearchQuery = React.useCallback(
    (key: string, value: string) => {
      query.set(key, value)
      const search = query.toString()

      history.replace({ pathname: history.location.pathname, search })
    },
    [history, query]
  )

  return [query, setSearchQuery]
}

export function useTab<T extends string | number = string>(
  initialValue: T,
  tabKey = 'tab',
  transitionType: 'push' | 'replace' = 'push'
): [T, Dispatch<SetStateAction<T>>] {
  const query = useSearchQuery()
  const history = useHistory()

  const tab = query.get(tabKey)

  const t = typeof initialValue === 'number' ? parseInt(tab || '0') : tab || ''
  const [selectedTab, setSelectedTab] = React.useState<T>((t as T) || initialValue)
  query.set(tabKey, selectedTab.toString())
  const search = query.toString()

  React.useEffect(() => {
    if (transitionType === 'push')
      history.push({ pathname: history.location.pathname, search, hash: history.location.hash })
    else
      history.replace({ pathname: history.location.pathname, search, hash: history.location.hash })
  }, [history, search, transitionType])

  return [selectedTab, setSelectedTab]
}

export function useSteps(numberOfSteps: number) {
  const [step, setStep] = useTab<number>(0, 'step')

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const increaseStep = (increment = 1) =>
    setStep(step => Math.min(step + increment, numberOfSteps - 1))
  const decreaseStep = (increment = 1) => setStep(step => step - increment)
  const firstStep = !step
  const lastStep = step >= numberOfSteps - 1

  return { step, increaseStep, decreaseStep, firstStep, lastStep }
}

export interface StepComponentProps {
  decreaseStep?: () => void
  increaseStep?: () => void
  firstStep?: boolean
  lastStep?: boolean
}
