import React from 'react'
import Select, { ValueType, ActionMeta } from 'react-select'

type Option = { value: string; label: string }
export interface SelectMultiProps {
  name?: string
  options: Array<Option>
  value?: Option
  defaultValue?: Option
  onChange?: (value: ValueType<Option, true>, actionMeta: ActionMeta<Option>) => void
}

export const SelectMulti: React.FC<SelectMultiProps> = props => {
  return (
    <Select
      isMulti
      name={props.name}
      defaultValue={props.defaultValue}
      options={props.options}
      onChange={props.onChange}
      value={props.value}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  )
}
