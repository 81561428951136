import React from 'react'

interface PageHeaderCompactProps {
  title: string
  description?: React.ReactNode
}

export const PageHeaderCompact: React.FC<PageHeaderCompactProps> = props => {
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <h1 className="text-lg leading-6 font-semibold text-gray-900">{props.title}</h1>
        {props.description ? (
          <div className="pt-2 text-sm leading-5 text-gray-500">{props.description}</div>
        ) : null}
      </div>
    </header>
  )
}
