import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card } from '../components/Card'
import { PageLayout } from '../components/PageLayout'
import { PublicProfile } from './ClientProfile'

interface DeveloperCongratulationsProps {}

export const DeveloperCongratulations: React.FC<DeveloperCongratulationsProps> = props => {
  const history = useHistory()

  return (
    <PageLayout title="Congratulations!" headerDark headerLarge>
      <Card title="Projects">
        <p>You've passed the interview. You will now be able to bid on client projects!</p>
        <p className="mt-1">Please choose a username to get started.</p>

        <div className="mt-4">
          <PublicProfile
            submitButtonText="Get Started!"
            postSubmit={() => {
              history.push('/developer/projects')
            }}
          />
        </div>
      </Card>
    </PageLayout>
  )
}
