import React from 'react'
import { Formik, Field } from 'formik'
import gql from 'graphql-tag'
import { FormSection } from '../../components/FormSection'
import {
  useUpdateDeveloperSkillsMutation,
  UpdateDeveloperSkillsInput,
  FrontendFramework,
  StylingLibrary,
  ProgrammingLanguage,
  DeveloperProfileDocument,
} from '../../generated/graphql'
import { Pagination } from '../../components/Pagination'
import { CustomChooseCardComponent } from '../../components/FormikCustom'
import { Card } from '../../components/Card'
import { trackEvent } from '../../utils/analytics'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../../utils/skills'
import { DEVELOPER_FRAGMENT, StepProps } from './common'

gql`
  mutation UpdateDeveloperSkills($data: UpdateDeveloperSkillsInput!) {
    updateDeveloperSkills(data: $data) {
      ...Developer
    }
  }

  ${DEVELOPER_FRAGMENT}
`

interface SkillSetProps extends StepProps {
  submitButton?: (options: { isSubmitting: boolean }) => React.ReactNode
}

export const SkillSet: React.FC<SkillSetProps> = props => {
  const { developer } = props
  const [updateSkills] = useUpdateDeveloperSkillsMutation()
  // const history = useHistory()

  return (
    <Card
      title="Skill Set"
      description="What projects you're interested in taking on? Only languages you are highly skilled at."
    >
      <Formik<UpdateDeveloperSkillsInput>
        initialValues={{
          programmingLanguages: developer.programmingLanguages || [],
          frontendFrameworks: developer.frontendFrameworks || [],
          stylingLibraries: developer.stylingLibraries || [],
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)
          await updateSkills({
            variables: { data: values },
            refetchQueries: [{ query: DeveloperProfileDocument, variables: { id: developer.id } }],
          })
          trackEvent('Update profile - skills', values)
          setSubmitting(false)

          if (values.programmingLanguages) props.increaseStep?.()
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => {
          return (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <div>
                <FormSection
                  title="Languages"
                  // description="Which of the following do you know well?"
                  fullWidth
                >
                  <Field
                    name="programmingLanguages"
                    component={CustomChooseCardComponent}
                    options={Object.values(ProgrammingLanguage).map(language => ({
                      value: language,
                      label: formatProgrammingLanguage(language),
                    }))}
                    multiselect
                    maxColumns={3}
                  />
                </FormSection>

                <FormSection
                  title="Frameworks"
                  // description="Which of the following do you know well?"
                  fullWidth
                >
                  <Field
                    name="frontendFrameworks"
                    component={CustomChooseCardComponent}
                    options={Object.values(FrontendFramework).map(framework => ({
                      value: framework,
                      label: formatFrontendFramework(framework),
                    }))}
                    multiselect
                    maxColumns={3}
                  />
                </FormSection>

                <FormSection
                  title="Styling"
                  // description="Which of the following do you know well?"
                  fullWidth
                >
                  <Field
                    name="stylingLibraries"
                    component={CustomChooseCardComponent}
                    options={Object.values(StylingLibrary).map(styling => ({
                      value: styling,
                      label: formatStylingLibrary(styling),
                    }))}
                    multiselect
                    maxColumns={3}
                  />
                </FormSection>
              </div>

              <div className="mt-8 border-t border-gray-200">
                {props.submitButton ? (
                  props.submitButton({ isSubmitting })
                ) : (
                  <Pagination
                    isSubmitting={isSubmitting}
                    disabled={!values.programmingLanguages.length}
                    onNextClick={props.increaseStep && (() => handleSubmit())}
                    onPreviousClick={props.decreaseStep}
                  />
                )}
              </div>
            </form>
          )
        }}
      </Formik>
    </Card>
  )
}
