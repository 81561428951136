import React from 'react'
import { useLocation } from 'react-router-dom'
import { LayoutPlain } from '../components/LayoutPlain'
import { ProjectType } from '../generated/graphql'
import { HeadingLarge } from '../components/Typography'
import { ReactComponent as StorybookSvg } from '../images/undraw_work_together_h63l.svg'
import { ReactComponent as LandingPageSvg } from '../images/undraw_experience_design_eq3j.svg'
import { ReactComponent as ComponentSvg } from '../images/undraw_grid_design_obmd.svg'
import { ReactComponent as AppUISvg } from '../images/undraw_web_developer_p3e5.svg'
import { CardButton, CardButtonGrid } from '../components/CardButton'

interface NewProjectTypeProps {}

export const NewProjectType: React.FC<NewProjectTypeProps> = props => {
  const location = useLocation()

  return (
    <LayoutPlain>
      <div className="h-full flex flex-col items-center justify-center">
        <div className="mb-12">
          <HeadingLarge>What sort of project would you like developed?</HeadingLarge>
        </div>
        <CardButtonGrid items={4}>
          <CardButton
            title="Storybook"
            description="Get a component library for your app."
            image={<StorybookSvg height={'100%'} />}
            to={`/new-project?project-type=${ProjectType.Storybook}${location.hash}`}
          />
          <CardButton
            title="Component"
            description="Develop specfic components."
            image={<ComponentSvg height={'100%'} />}
            to={`/new-project?project-type=${ProjectType.Component}${location.hash}`}
          />
          <CardButton
            title="Landing Page"
            description="Create a high-quality landing page."
            image={<LandingPageSvg height={'100%'} />}
            to={`/new-project?project-type=${ProjectType.LandingPage}${location.hash}`}
          />
          <CardButton
            title="App UI"
            description="Build the full UI for your app."
            image={<AppUISvg height={'100%'} />}
            to={`/new-project?project-type=${ProjectType.AppUi}${location.hash}`}
          />
        </CardButtonGrid>
      </div>
    </LayoutPlain>
  )
}
