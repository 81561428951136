import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../components/Button'
import { HeadingLarge, TextHeader } from '../components/Typography'

interface DeveloperFreelanceWelcomeProps {}

export const DeveloperFreelanceWelcome: React.FC<DeveloperFreelanceWelcomeProps> = props => {
  const history = useHistory()

  const continueToSkills = React.useCallback(() => history.push('/developer/skills?step=0'), [
    history,
  ])

  return (
    <main className="bg-gray-100 h-full">
      <div className="flex items-center justify-center h-full">
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8 rounded-lg">
          {/* <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"> */}
          {/* <div className="absolute inset-0"> */}
          {/* <div className="bg-white h-1/3 sm:h-2/3 rounded-lg "></div> */}
          {/* <div className="h-1/3 sm:h-2/3"></div> */}
          {/* </div> */}
          <div className="relative max-w-7xl mx-auto">
            <div className="my-10">
              <TextHeader title="Freelance Projects" subtitle="Getting Started" />
            </div>
            <div className="grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
              <Step
                number={1}
                title="Take our Interview"
                description="We're looking for frontend and UI experts"
                imageUrl="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"
                onClick={continueToSkills}
              />
              <Step
                number={2}
                title="Bid on Projects"
                description="Once you pass you can bid on client projects"
                imageUrl="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
              />
              <Step
                number={3}
                title="Code Amazing UIs"
                description="After a client accepts your bid you get to work on amazing projects"
                imageUrl="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80"
              />
            </div>

            <div className="mt-12 text-center">
              <Button size="xl" styleType="primary" onClick={continueToSkills}>
                Get started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

interface StepProps {
  number: number
  title: string
  description: string
  imageUrl: string
  onClick?: () => void
}

export const Step: React.FC<StepProps> = props => {
  return (
    <div
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
      onClick={() => props.onClick?.()}
    >
      <div className="flex-shrink-0">
        <img className="h-48 w-full object-cover" src={props.imageUrl} alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-indigo-600">
            <a href="#" className="hover:underline">
              Step {props.number}
            </a>
          </p>
          <div className="block">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">{props.title}</h3>
            <p className="mt-3 text-base leading-6 text-gray-500">{props.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
