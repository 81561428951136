import React from 'react'
import { PencilIcon } from '@heroicons/react/solid'
import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import { Button } from '../../components/Button'
import { Card, CardTitleWithRightIcon } from '../../components/Card'
import { CustomTextAreaComponent } from '../../components/FormikCustom'
import { MarkdownLazy } from '../../components/MarkdownLazy'
import { useNotification } from '../../components/Notification'
import { trackEvent } from '../../utils/analytics'
import { formatGraphQLError } from '../../utils/error'
import { PROJECT_FRAGMENT } from '../Projects'
import { useUpdateProjectMutation, UpdateProjectInput } from '../../generated/graphql'

gql`
  mutation UpdateProject($projectId: ID!, $data: UpdateProjectInput!) {
    updateProject(projectId: $projectId, data: $data) {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`

interface OverviewProps {
  projectId: string
  overview?: string | null
  canEdit: boolean
}

export const Overview: React.FC<OverviewProps> = props => {
  const [isEditing, setIsEditing] = React.useState(false)

  return (
    <Card
      titleComponent={
        <CardTitleWithRightIcon
          title={isEditing ? `Overview (supports Markdown)` : `Overview`}
          icon={props.canEdit ? <PencilIcon /> : null}
          onClick={() => {
            setIsEditing(!isEditing)
          }}
        />
      }
    >
      {isEditing ? (
        <EditOverview {...props} onSuccess={() => setIsEditing(false)} />
      ) : (
        <div className="whitespace-pre-wrap">
          <MarkdownLazy text={props.overview || `This project doesn't have an overview.`} />
        </div>
      )}
    </Card>
  )
}

interface EditOverviewProps extends OverviewProps {
  onSuccess: () => void
}

export const EditOverview: React.FC<EditOverviewProps> = props => {
  const [updateProject] = useUpdateProjectMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <Formik<UpdateProjectInput>
      enableReinitialize
      initialValues={{
        overview: props.overview,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)
        try {
          await updateProject({
            variables: { projectId: props.projectId, data: values },
          })
          trackEvent('Update overview', values)
          showSuccessNotification({
            description: 'Successfully updated!',
          })
          props.onSuccess()
        } catch (error) {
          console.error(error)
          showErrorNotification({ description: formatGraphQLError(error) })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Field
            name="overview"
            component={CustomTextAreaComponent}
            placeholder="Overview (supports markdown)"
            rows={12}
          />

          <div className="py-2">
            <MarkdownLazy text={values.overview || ''} />
          </div>

          <Button styleType="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
            Update
          </Button>
        </form>
      )}
    </Formik>
  )
}
