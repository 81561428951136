import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { PrivateRoute } from './auth/PrivateRoute'
import { UserProvider, useUserContext } from './auth/userContext'
import { NavBar } from './components/NavBar'
import { DeveloperProgress, UserInfoFragment } from './generated/graphql'
import { ClientProfile } from './routes/ClientProfile'
import { DeveloperDashboard } from './routes/DeveloperDashboard'
import { DeveloperFailed } from './routes/DeveloperFailed'
import { DeveloperOnboarding } from './routes/DeveloperOnboarding/DeveloperOnboarding'
import { DeveloperPaymentDetails } from './routes/DeveloperPaymentDetails'
import { DeveloperProfile } from './routes/DeveloperProfile'
import { DeveloperProjects } from './routes/DeveloperProjects'
import { DeveloperWaitlist } from './routes/DeveloperWaitlist'
import { DeveloperWelcome } from './routes/DeveloperWelcome'
import { DeveloperFreelanceWelcome } from './routes/DeveloperFreelanceWelcome'
import { Figma } from './routes/Figma'
import { GitHub } from './routes/GitHub'
import { GitHubApp } from './routes/GitHubApp'
import { InterviewProject } from './routes/InterviewProject'
import { InterviewVideo } from './routes/InterviewVideo'
import { LogIn, SignUp } from './routes/LogInSignUp'
import { NewProject } from './routes/NewProject'
import { NewProjectType } from './routes/NewProjectType'
import { NewProjectOnboarding } from './routes/NewProjectOnboarding'
import { Project } from './routes/Project/Project'
import { Projects } from './routes/Projects'
import { Zeplin } from './routes/Zeplin'
import { identifyUser, useTracking } from './utils/analytics'
import { client } from './utils/apollo'
import { FigmaProvider } from './utils/figma'
import { ZeplinProvider } from './utils/zeplin'
import { NotificationList, NotificationProvider } from './components/Notification'
import { FooterSimple } from './components/Footer'
import { ImageCompare } from './components/ImageCompare'
import { DeveloperCongratulations } from './routes/DeveloperCongratulations'
import { DeveloperSource } from './routes/DeveloperHeardAbout'
import { PaymentHistory } from './routes/PaymentHistory'
import { MessageProvider } from './utils/messagesContext'
import { DeveloperQuickApply } from './routes/DeveloperQuickApply'
import { ForgotPassword } from './routes/LogInSignUp'
import { useSupportChat } from './utils/support-chat'
import { EditUploadComponent, UploadComponent } from './routes/ComponentMarket/UploadComponent'
import { Components } from './routes/ComponentMarket/Components'
import { Component } from './routes/ComponentMarket/Component'
import { DeveloperPaymentsStripe } from './routes/Stripe/DeveloperPaymentsStripe'
import { StripeReturn } from './routes/Stripe/StripeReturn'
import { StripePaymentFailure, StripePaymentSuccess } from './routes/Stripe/StripePaymentResult'
import { Subscribe } from './routes/Stripe/Subscribe'
import { AllComponents } from './routes/ComponentMarket/AllComponents'

const Developer: React.FC<{ user: UserInfoFragment }> = props => {
  return (
    <Switch>
      <PrivateRoute path="/developer/welcome">
        <DeveloperWelcome />
      </PrivateRoute>
      <PrivateRoute path="/developer/freelance-welcome">
        <DeveloperFreelanceWelcome />
      </PrivateRoute>
      <PrivateRoute path="/developer/skills">
        <DeveloperOnboarding />
      </PrivateRoute>
      <PrivateRoute path="/developer/interview-project">
        {/* these redirects were causing improper redirect to welcome instead of jumping to interview project */}
        {/* {props.user.developerProgress === DeveloperProgress.Welcome ? (
          <Redirect to="/developer/welcome" />
        ) : props.user.developerProgress === DeveloperProgress.SkillSet ? (
          <Redirect to="/developer/skills" />
        ) : ( */}
        <InterviewProject />
        {/* )} */}
      </PrivateRoute>
      <PrivateRoute path="/developer/interview-video">
        <InterviewVideo />
      </PrivateRoute>
      <PrivateRoute path="/developer/projects">
        <DeveloperProjects />
      </PrivateRoute>
      <PrivateRoute path="/developer/waitlist">
        <DeveloperWaitlist />
      </PrivateRoute>
      <PrivateRoute path="/developer/failed">
        <DeveloperFailed />
      </PrivateRoute>
      <PrivateRoute path="/developer/dashboard">
        <DeveloperDashboard />
      </PrivateRoute>
      <PrivateRoute path="/developer/payment-details">
        <DeveloperPaymentDetails />
      </PrivateRoute>
      <PrivateRoute path="/developer/profile/:userId">
        <DeveloperProfile />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <DeveloperProfile currentUser />
      </PrivateRoute>
      <PrivateRoute path="/developer/congratulations">
        <DeveloperCongratulations />
      </PrivateRoute>
      <PrivateRoute path="/developer/heard-about">
        <DeveloperSource />
      </PrivateRoute>
      <PrivateRoute path="/quick-apply">
        <DeveloperQuickApply />
      </PrivateRoute>
      {/* TODO just for testing */}
      <PrivateRoute path="/image-compare">
        <ImageCompare />
      </PrivateRoute>
      <PrivateRoute path="/project/:projectId">
        <Project />
      </PrivateRoute>
      <PrivateRoute path="/github">
        <GitHub />
      </PrivateRoute>
      <PrivateRoute path="/github-app">
        <GitHubApp />
      </PrivateRoute>
      <PrivateRoute path="/developer/payments">
        <DeveloperPaymentsStripe />
      </PrivateRoute>
      <PrivateRoute path="/subscribe">
        <Subscribe />
      </PrivateRoute>
      <PrivateRoute path="/stripe-connected-success">
        <StripeReturn />
      </PrivateRoute>
      <PrivateRoute path="/stripe-payment-success">
        <StripePaymentSuccess />
      </PrivateRoute>
      <PrivateRoute path="/stripe-payment-failure">
        <StripePaymentFailure />
      </PrivateRoute>
      {/* Components */}
      <PrivateRoute path="/upload-component/:componentId">
        <EditUploadComponent />
      </PrivateRoute>
      <PrivateRoute path="/upload-component">
        <UploadComponent />
      </PrivateRoute>
      <PrivateRoute path="/components">
        <Components />
      </PrivateRoute>

      {/* Common */}
      <PrivateRoute path="/stripe-payment-success">
        <StripePaymentSuccess />
      </PrivateRoute>
      <PrivateRoute path="/stripe-payment-failure">
        <StripePaymentFailure />
      </PrivateRoute>
      <PrivateRoute path="/component/:componentId">
        <Component />
      </PrivateRoute>
      <PrivateRoute path="/all-components">
        <AllComponents />
      </PrivateRoute>

      <PrivateRoute path="*">
        {props.user.developerProgress === DeveloperProgress.Welcome ? (
          <Redirect to="/developer/welcome" />
        ) : props.user.developerProgress === DeveloperProgress.SkillSet ? (
          <Redirect to="/developer/skills" />
        ) : props.user.developerProgress === DeveloperProgress.Project ? (
          <Redirect to="/developer/interview-project" />
        ) : props.user.developerProgress === DeveloperProgress.Assessment ? (
          <Redirect to="/developer/waitlist" />
        ) : props.user.developerProgress === DeveloperProgress.Failed ? (
          <Redirect to="/developer/failed" />
        ) : props.user.developerProgress === DeveloperProgress.Congratulations ? (
          <Redirect to="/developer/congratulations" />
        ) : (
          <Redirect to="/developer/dashboard" />
        )}
      </PrivateRoute>
    </Switch>
  )
}

const Client: React.FC<{ user: UserInfoFragment }> = props => {
  // allow all users to publish projects
  // if (props.user?.accountStatus !== AccountStatus.Enabled) return <ClientAccountPending />

  return (
    <Switch>
      <PrivateRoute path="/" exact>
        <Projects />
      </PrivateRoute>
      <PrivateRoute path="/projects">
        <Projects />
      </PrivateRoute>
      <PrivateRoute path="/project/:projectId">
        <Project />
      </PrivateRoute>
      <PrivateRoute path="/new-project-type">
        <NewProjectType />
      </PrivateRoute>
      <PrivateRoute path="/new-project-onboarding">
        <NewProjectOnboarding />
      </PrivateRoute>
      <PrivateRoute path="/new-project">
        <NewProject />
      </PrivateRoute>
      <PrivateRoute path="/zeplin">
        <Zeplin />
      </PrivateRoute>
      <PrivateRoute path="/figma">
        <Figma />
      </PrivateRoute>
      <PrivateRoute path="/developer/profile/:userId">
        <DeveloperProfile />
      </PrivateRoute>
      <PrivateRoute path="/profile">
        <ClientProfile />
      </PrivateRoute>
      <PrivateRoute path="/payment-history">
        <PaymentHistory />
      </PrivateRoute>
      {/* <PrivateRoute path="/waitlist">
        <ClientAccountPending />
      </PrivateRoute> */}

      <PrivateRoute path="/components">
        <Components />
      </PrivateRoute>
      <PrivateRoute path="/upload-component/:componentId">
        <EditUploadComponent />
      </PrivateRoute>
      <PrivateRoute path="/upload-component">
        <UploadComponent />
      </PrivateRoute>

      {/* Common */}
      <PrivateRoute path="/subscribe">
        <Subscribe />
      </PrivateRoute>
      <PrivateRoute path="/stripe-payment-success">
        <StripePaymentSuccess />
      </PrivateRoute>
      <PrivateRoute path="/stripe-payment-failure">
        <StripePaymentFailure />
      </PrivateRoute>
      <PrivateRoute path="/component/:componentId">
        <Component />
      </PrivateRoute>
      <PrivateRoute path="/all-components">
        <AllComponents />
      </PrivateRoute>

      <PrivateRoute path="*">
        <Redirect to="/" />
      </PrivateRoute>
    </Switch>
  )
}

const LoggedOut: React.FC<{}> = props => {
  return (
    <Switch>
      <Route path="/log-in">
        <LogIn />
      </Route>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/subscribe">
        <Subscribe />
      </Route>
      <Route path="/*">
        <SignUp />
      </Route>
    </Switch>
  )
}

const Content: React.FC<{}> = props => {
  const { user, loading } = useUserContext()

  React.useEffect(() => {
    if (user) identifyUser(user)
  }, [user])

  useTracking()

  if (loading) return null

  return (
    <>
      {user ? (
        <div className="flex flex-col h-full">
          <NavBar />

          <div className="flex-1">
            {user.developer ? <Developer user={user} /> : <Client user={user} />}
          </div>

          <FooterSimple />
        </div>
      ) : (
        <LoggedOut />
      )}
    </>
  )
}

export const App = () => {
  useSupportChat()

  return (
    <Router>
      <ApolloProvider client={client}>
        <UserProvider>
          <MessageProvider>
            <FigmaProvider>
              <ZeplinProvider>
                <NotificationProvider>
                  <div className="bg-gray-100">
                    <div className="h-screen">
                      <Content />
                    </div>
                  </div>
                  <NotificationList />
                </NotificationProvider>
              </ZeplinProvider>
            </FigmaProvider>
          </MessageProvider>
        </UserProvider>
      </ApolloProvider>
    </Router>
  )
}
