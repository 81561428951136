import React from 'react'
import { useParams } from 'react-router-dom'
import { gql } from '@apollo/client'
import { Field, Formik } from 'formik'

import * as Sentry from '@sentry/browser'
import { PageLayout } from '../components/PageLayout'
import { Card, CardSideTitle, CardsWrapper, CardTitleExtra } from '../components/Card'
import {
  FrontendFrameworkTag,
  ProgrammingLanguageTag,
  StylingLibraryTag,
  Tag,
} from '../components/Tag'
import {
  useDeveloperProfileQuery,
  useGetDeveloperQuery,
  useAdminDeveloperProfileQuery,
  useGradeInterviewMutation,
  useUserWithPortfolioQuery,
  useUpdatePortfolioMutation,
  UpdatePortfolioInput,
  UserWithPortfolioDocument,
  useUserWithHourlyRateQuery,
  useUpdateHourlyRateMutation,
  UpdateHourlyRateInput,
  UserWithHourlyRateDocument,
  InterviewStatus,
  DeveloperProfileDocument,
  useUserWithIntroVideoQuery,
  useUpdateIntroVideoMutation,
  UpdateIntroVideoInput,
  UserWithIntroVideoDocument,
  ReviewFragment,
  useAdminDeleteUserMutation,
} from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { CustomLinkExternal, Paragraph } from '../components/Typography'
import { ChangePassword, PersonalProfile, PublicProfile } from './ClientProfile'
import { useUserContext } from '../auth/userContext'
import { Button, ButtonLink } from '../components/Button'
import { FormItem } from '../components/FormItem'
import { HeaderButton, PageHeaderExtra } from '../components/PageHeader'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../utils/skills'
import { trackEvent } from '../utils/analytics'
import { formatGraphQLError } from '../utils/error'
import {
  CustomInputComponent,
  CustomInputCurrencyComponent,
  CustomTextAreaComponent,
} from '../components/FormikCustom'
import { useNotification } from '../components/Notification'
import { amountToServerAmount, serverAmountToAmount, serverAmountToUSD } from '../utils/currency'
import { Rating } from '../components/Rating'
import { formatDate } from '../utils/date'
import { MarkdownLazy } from '../components/MarkdownLazy'
import { SkillSet } from './DeveloperOnboarding/DeveloperOnboardingSkillSet'
import { Experience } from './DeveloperOnboarding/DeveloperOnboardingExperience'
import { DEVELOPER_FRAGMENT } from './DeveloperOnboarding/common'

gql`
  fragment Review on Review {
    id
    frontendFramework
    programmingLanguage
    styling
    clientRating
    clientReview
    completedDate
  }

  query DeveloperProfile($id: ID!) {
    developer(id: $id) {
      id
      username
      programmingLanguages
      frontendFrameworks
      stylingLibraries
      portfolio

      yearsOfExperience
      yearsOfExperienceReact
      yearsOfExperienceReactNative
      yearsOfExperienceVue
      yearsOfExperienceAngular
      yearsOfExperienceSvelte
      yearsOfExperienceWordpress
      yearsOfExperienceJavascript
      yearsOfExperienceTypescript
      yearsOfExperienceFlow

      reviews {
        ...Review
      }
      interviewDeployedProject {
        id
        netlifyUrl
        githubUrl
      }
    }
  }

  ${DEVELOPER_FRAGMENT}
`

gql`
  mutation GradeInterview($data: GradeInterviewInput!) {
    gradeInterview(data: $data) {
      id
      interviewStatus
    }
  }
`

gql`
  mutation AdminDeleteUser($userId: ID!) {
    adminDeleteUser(userId: $userId)
  }
`

gql`
  query UserWithPortfolio {
    getUser {
      id
      portfolio
    }
  }
`

gql`
  mutation UpdatePortfolio($data: UpdatePortfolioInput!) {
    updatePortfolio(data: $data) {
      id
      portfolio
    }
  }
`

gql`
  query UserWithHourlyRate {
    getUser {
      id
      hourlyRateUsd
    }
  }
`

gql`
  mutation UpdateHourlyRate($data: UpdateHourlyRateInput!) {
    updateHourlyRate(data: $data) {
      id
      hourlyRateUsd
    }
  }
`

gql`
  query UserWithIntroVideo {
    getUser {
      id
      introVideoUrl
    }
  }
`

gql`
  mutation UpdateIntroVideo($data: UpdateIntroVideoInput!) {
    updateIntroVideo(data: $data) {
      id
      introVideoUrl
    }
  }
`

const GET_ADMIN_DEVELOPER_PROFILE = gql`
  fragment AdminUser on User {
    id
    email
    firstName
    lastName
    githubUsername
    country
    interviewStatus
    storybookLevel
    uiLevel
    figmaLevel
    heardAbout
    introVideoUrl
    hourlyRateUsd
    yearsOfExperience
  }

  query AdminDeveloperProfile($id: ID!) {
    adminUser(id: $id) {
      ...AdminUser
    }
  }
`

interface DeveloperProfileProps {
  currentUser?: boolean
}

export const DeveloperProfile: React.FC<DeveloperProfileProps> = props => {
  const params = useParams<{ userId: string }>()
  const { user } = useUserContext()
  const userId = props.currentUser ? user!.id : params.userId
  const { data, loading, error } = useDeveloperProfileQuery({ variables: { id: userId } })
  const [iframeLoading, setIframeLoading] = React.useState(true)
  const [isPublicView, setIsPublicView] = React.useState(false)

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const publicViewButton: HeaderButton = {
    text: isPublicView ? 'Edit Profile' : 'View Public Profile',
    type: 'secondary',
    hideOnMobile: false,
    onClick: () => {
      setIsPublicView(state => !state)
    },
  }

  const isDeveloper = user?.id === userId
  const showPrivateInfo = isDeveloper && !isPublicView

  const interviewProjectUrl = data.developer.interviewDeployedProject?.netlifyUrl?.replace(
    'http://',
    'https://'
  )

  return (
    <PageLayout
      title={data.developer.username || 'Developer'}
      header={
        <PageHeaderExtra
          title={data.developer.username || 'Developer'}
          dark
          buttons={isDeveloper ? [publicViewButton] : []}
          description={
            <div className={`text-sm leading-5 text-white`}>
              <div className="flex flex-wrap">
                {data.developer.frontendFrameworks?.map(language => {
                  return (
                    <div className="mr-1 mt-2" key={language}>
                      <Tag color="green">{formatFrontendFramework(language)}</Tag>
                    </div>
                  )
                })}
                {data.developer.programmingLanguages?.map(language => {
                  return (
                    <div className="mr-1 mt-2" key={language}>
                      <Tag color="blue">{formatProgrammingLanguage(language)}</Tag>
                    </div>
                  )
                })}
                {data.developer.stylingLibraries?.map(language => {
                  return (
                    <div className="mr-1 mt-2" key={language}>
                      <Tag color="orange">{formatStylingLibrary(language)}</Tag>
                    </div>
                  )
                })}
              </div>
            </div>
          }
        />
      }
    >
      <CardsWrapper>
        {isPublicView || !showPrivateInfo ? (
          !!data.developer.portfolio && (
            <Card title="Background">
              <PortfolioMarkdown portfolio={data.developer.portfolio} />
            </Card>
          )
        ) : (
          <Card title="Portfolio">
            <Portfolio />
          </Card>
        )}

        <Card
          titleComponent={
            <CardTitleExtra
              title="Interview Project"
              extra={
                interviewProjectUrl ? (
                  <>
                    {user?.admin && (
                      <div className="mr-6">
                        <CustomLinkExternal
                          href={data.developer.interviewDeployedProject?.githubUrl}
                          target="_blank"
                        >
                          View on GitHub
                        </CustomLinkExternal>
                      </div>
                    )}
                    <CustomLinkExternal href={interviewProjectUrl} target="_blank">
                      Open in new window
                    </CustomLinkExternal>
                  </>
                ) : null
              }
            />
          }
        >
          <div className="mt-4">
            {interviewProjectUrl ? (
              <>
                {iframeLoading && <Loading slow />}
                <iframe
                  title="Storybook"
                  src={interviewProjectUrl}
                  frameBorder="0"
                  className="w-full"
                  style={{ height: 800 }}
                  onLoad={() => setIframeLoading(false)}
                />
              </>
            ) : (
              <Paragraph>This developer does not have an interview project.</Paragraph>
            )}
          </div>
        </Card>

        <Card title="Reviews">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {data.developer.reviews.map(review => {
              return <Review key={review.id} review={review} />
            })}
          </div>
          {!data.developer.reviews.length && (
            <Paragraph>This developer doesn't have any reviews yet.</Paragraph>
          )}
        </Card>

        {showPrivateInfo && (
          <>
            <EditSkillSet />

            <Experience
              developer={data.developer}
              submitButton={({ isSubmitting }) => (
                <div className="text-right">
                  <FormItem>
                    <Button
                      styleType="primary"
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Save
                    </Button>
                  </FormItem>
                </div>
              )}
            />

            <Card title="Video Introduction">
              <EnglishAssessment />
            </Card>

            <CardSideTitle
              title="Public Profile"
              description="This information will be displayed to clients."
            >
              <PublicProfile />
            </CardSideTitle>

            <CardSideTitle
              title="Hourly rate"
              description="For clients looking to work on an hourly basis how much would you like to charge?"
            >
              <HourlyRate />
            </CardSideTitle>

            <CardSideTitle
              title="Personal Information"
              description="This information is not shared with clients."
            >
              <PersonalProfile />
            </CardSideTitle>

            <CardSideTitle title="Change Password">
              <ChangePassword />
            </CardSideTitle>
          </>
        )}

        {user?.admin && !isPublicView && <DeveloperAdminProfile developerId={data.developer.id} />}
      </CardsWrapper>
    </PageLayout>
  )
}

interface EditSkillSetProps {}

export const EditSkillSet: React.FC<EditSkillSetProps> = props => {
  const { data, loading, error } = useGetDeveloperQuery()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { developer } = data

  return (
    <div>
      <SkillSet
        developer={developer}
        submitButton={({ isSubmitting }) => (
          <div className="text-right">
            <FormItem>
              <Button
                styleType="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </Button>
            </FormItem>
          </div>
        )}
      />
    </div>
  )
}

interface DeveloperAdminProfileProps {
  developerId: string
}

export const DeveloperAdminProfile: React.FC<DeveloperAdminProfileProps> = props => {
  const { data, loading, error } = useAdminDeveloperProfileQuery({
    variables: { id: props.developerId },
  })
  const [deleteUser] = useAdminDeleteUserMutation({ variables: { userId: props.developerId } })
  const [gradeInterview] = useGradeInterviewMutation()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const developer = data.adminUser

  const submitGradeInterview = async (result: InterviewStatus) => {
    await gradeInterview({
      variables: { data: { developerId: developer.id, result } },
      refetchQueries: [{ query: GET_ADMIN_DEVELOPER_PROFILE, variables: { id: developer.id } }],
    })
  }

  return (
    <Card title="Admin">
      <p>
        <strong>Interview Status:</strong> {developer.interviewStatus}
      </p>

      {developer.interviewStatus === InterviewStatus.Completed && (
        <div className="mt-4 space-x-2">
          <Button onClick={() => submitGradeInterview(InterviewStatus.Passed)}>
            Pass Developer
          </Button>
          <Button styleType="danger" onClick={() => submitGradeInterview(InterviewStatus.Failed)}>
            Fail Developer
          </Button>
        </div>
      )}

      <div className="space-x-2 mt-2">
        <ButtonLink styleType="white" to={`/developer/profile/${props.developerId}`}>
          View Profile
        </ButtonLink>

        <Button
          styleType="danger"
          onClick={async () => {
            // eslint-disable-next-line no-restricted-globals
            const yes = confirm('Are you sure you want to delete this component?')

            if (yes) {
              await deleteUser()
            }
          }}
        >
          Delete User
        </Button>
      </div>

      <div className="mt-4">
        <UserDetails user={developer} />
      </div>
    </Card>
  )
}

const UserDetails: React.FC<{ user: any }> = props => {
  const { user } = props

  return (
    <div>
      <ul>
        <li>
          Email:{' '}
          <CustomLinkExternal href={`mailto://${user.email}`}>{user.email}</CustomLinkExternal>
        </li>
        <li>
          GitHub:{' '}
          {!!user.githubUsername && (
            <CustomLinkExternal href={`https://github.com/${user.githubUsername}`}>
              {user.githubUsername}
            </CustomLinkExternal>
          )}
        </li>
        <li>Country: {user.country}</li>
        <li>First Name: {user.firstName}</li>
        <li>Last Name: {user.lastName}</li>
        <li>Heard about FrontWork: {user.heardAbout}</li>
        <li>
          Intro Video Url:{' '}
          {!!user.introVideoUrl && (
            <CustomLinkExternal href={user.introVideoUrl}>{user.introVideoUrl}</CustomLinkExternal>
          )}
        </li>
        <li>Rate: {serverAmountToUSD(user.hourlyRateUsd)} per hour</li>
        <li>Experience: {user.yearsOfExperience} years</li>
      </ul>
      <strong>Self assessments (1-3. 3 is the best):</strong>
      <ul>
        <li>Storybook Level: {user.storybookLevel}</li>
        <li>UI Level: {user.uiLevel}</li>
        <li>Figma Level: {user.figmaLevel}</li>
      </ul>
    </div>
  )
}

export const Portfolio: React.FC<{}> = props => {
  const { data } = useUserWithPortfolioQuery()
  const [updatePortfolio] = useUpdatePortfolioMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <div className="space-y-2">
      <Paragraph>
        Tell us some more about your background. It will also appear on your public profile for
        clients to view. Items worth mentioning include:
        <ul className="list-disc list-inside">
          <li>Short intro</li>
          <li>Past job experience</li>
          <li>Past projects (links & GitHub repos where possible)</li>
          <li>LinkedIn</li>
          <li>GitHub</li>
          <li>Blog</li>
          <li>Anything you think could be of interest and helps sell you in a good light</li>
        </ul>
      </Paragraph>

      <div className="pt-2">
        <Formik<UpdatePortfolioInput>
          enableReinitialize
          initialValues={{
            text: data?.getUser.portfolio || '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('onSubmit', values)
            try {
              await updatePortfolio({
                variables: { data: values },
                refetchQueries: [
                  { query: UserWithPortfolioDocument },
                  { query: DeveloperProfileDocument, variables: { id: data?.getUser.id } },
                ],
              })
              trackEvent('Submit portfolio', values)
              showSuccessNotification({
                description: 'Successfully submitted!',
              })
            } catch (error) {
              console.error(error)
              showErrorNotification({ description: formatGraphQLError(error) })
            }
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, handleSubmit, handleReset, values }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <Field
                name="text"
                component={CustomTextAreaComponent}
                placeholder="Portfolio (supports markdown)"
                rows={12}
              />

              <PortfolioMarkdown portfolio={values.text} />

              <div className="text-right">
                <Button
                  styleType="primary"
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

interface PortfolioMarkdownProps {
  portfolio: string
}

const PortfolioMarkdown: React.FC<PortfolioMarkdownProps> = props => {
  return (
    <div className="my-2">
      <MarkdownLazy text={props.portfolio} />
    </div>
  )
}

interface HourlyRateProps {}

export const HourlyRate: React.FC<HourlyRateProps> = props => {
  const { data, loading, error } = useUserWithHourlyRateQuery()
  const [updateHourlyRate] = useUpdateHourlyRateMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  return (
    <Formik<UpdateHourlyRateInput>
      initialValues={{
        hourlyRateUsd: serverAmountToAmount(data.getUser.hourlyRateUsd || 0),
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)
        try {
          await updateHourlyRate({
            variables: { data: { hourlyRateUsd: amountToServerAmount(values.hourlyRateUsd) } },
            refetchQueries: [{ query: UserWithHourlyRateDocument }],
          })
          trackEvent('Update hourly rate', values)
          showSuccessNotification({
            description: 'Successfully updated hourly rate',
          })
        } catch (error) {
          console.error(error)
          showErrorNotification({ description: formatGraphQLError(error) })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Field
            name="hourlyRateUsd"
            component={CustomInputCurrencyComponent}
            type="number"
            min="0"
            max="500"
            step="any"
          />

          <div className="text-right">
            <FormItem>
              <Button
                styleType="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </Button>
            </FormItem>
          </div>
        </form>
      )}
    </Formik>
  )
}

export const EnglishAssessment: React.FC<{ content?: React.ReactNode }> = props => {
  const { data } = useUserWithIntroVideoQuery()
  const [updateIntroVideo] = useUpdateIntroVideoMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <div>
      {props.content || (
        <div className="space-y-2">
          <Paragraph>
            Certain projects require an English assessment to be eligible to take them on. These are
            typically long term projects where spoken English communication is important to the
            team. If you'd like to be eligible to take on these types of projects please submit a
            short video describing your background as a developer.
          </Paragraph>

          <Paragraph>
            An easy way to create this video is to use{' '}
            <span className="font-semibold">
              <CustomLinkExternal
                href="https://loom.com"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackEvent('Clicked Loom link')}
              >
                Loom
              </CustomLinkExternal>{' '}
            </span>
            in full-screen mode.
          </Paragraph>

          <Paragraph>
            The video should be around 2 minutes long (anywhere between 1 and 5 minutes is fine
            though).
          </Paragraph>
        </div>
      )}
      <div className="pt-4">
        <Formik<UpdateIntroVideoInput>
          enableReinitialize
          initialValues={{
            url: data?.getUser.introVideoUrl || '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('onSubmit', values)
            try {
              await updateIntroVideo({
                variables: { data: values },
                refetchQueries: [{ query: UserWithIntroVideoDocument }],
              })
              trackEvent('Submit English assessment', values)
              showSuccessNotification({
                description: 'Successfully submitted!',
              })
            } catch (error) {
              console.error(error)
              showErrorNotification({ description: formatGraphQLError(error) })
            }
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, handleSubmit, handleReset, values }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <div className="flex align-bottom justify-end">
                <div className="flex-1 mr-2">
                  <Field
                    name="url"
                    component={CustomInputComponent}
                    type="url"
                    placeholder="Video URL"
                  />
                </div>
                <Button
                  styleType="primary"
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export interface ReviewProps {
  review: ReviewFragment
}

export const Review: React.FC<ReviewProps> = ({ review }) => {
  return (
    <Card>
      <div className="space-y-2">
        <div className="flex flex-wrap">
          {review.frontendFramework && (
            <div className="m-1">
              <FrontendFrameworkTag>
                {formatFrontendFramework(review.frontendFramework)}
              </FrontendFrameworkTag>
            </div>
          )}
          {review.programmingLanguage && (
            <div className="m-1">
              <ProgrammingLanguageTag>
                {formatProgrammingLanguage(review.programmingLanguage)}
              </ProgrammingLanguageTag>
            </div>
          )}
          {review.styling && (
            <div className="m-1">
              <StylingLibraryTag>{formatStylingLibrary(review.styling)}</StylingLibraryTag>
            </div>
          )}
        </div>
        <Paragraph>{review.clientReview}</Paragraph>
        <Rating initialRating={review.clientRating!} readonly />
        <div className="flex justify-end text-gray-500">
          {formatDate(new Date(review.completedDate!))}
        </div>
      </div>
    </Card>
  )
}
