import React from 'react'
import { CardButton, CardButtonGrid } from '../components/CardButton'
import { HeadingLarge, TextHeader } from '../components/Typography'
import { ReactComponent as FreelanceSvg } from '../images/undraw_Freelancer_re_irh4.svg'
import { ReactComponent as ComponentMarketplaceSvg } from '../images/undraw_asset_selection_ix3v.svg'

interface DeveloperWelcomeProps {}

export const DeveloperWelcome: React.FC<DeveloperWelcomeProps> = props => {
  return (
    <main className="bg-gray-100 h-full">
      <div className="flex items-center justify-center h-full flex-col">
        <div className="my-10">
          <TextHeader
            title="Welcome to FrontWork"
            subtitle="What are you looking to get out of FrontWork?"
          />
        </div>

        <div className="max-w-3xl">
          <CardButtonGrid>
            <CardButton
              title="Freelance Projects"
              description="Pass our interview to take on freelance projects and develop custom components for clients."
              image={<FreelanceSvg height={'100%'} />}
              to="/developer/freelance-welcome"
            />
            <CardButton
              title="Sell Components"
              description="Publish your components to be sold on the FrontWork marketplace."
              image={<ComponentMarketplaceSvg height={'100%'} />}
              to="/components"
            />
          </CardButtonGrid>
        </div>
      </div>
    </main>
  )
}
