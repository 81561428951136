import React from 'react'
import { Formik, Field } from 'formik'
import * as Sentry from '@sentry/browser'
import { gql } from '@apollo/client'
import { sentenceCase } from 'change-case'
import { CardSideTitle } from '../components/Card'
import { PageLayout } from '../components/PageLayout'
import {
  BankAccountType,
  EntityType,
  Currency,
  UpdateDeveloperPaymentDetailsInput,
  useUpdateDeveloperPaymentDetailsMutation,
  useGetDeveloperWithPaymentDetailsQuery,
} from '../generated/graphql'
import { CustomInputComponent, CustomSelectComponent } from '../components/FormikCustom'
import { CountrySelectField } from '../components/CountrySelectField'
import { FormItem } from '../components/FormItem'
import { trackEvent } from '../utils/analytics'
import { Button } from '../components/Button'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { PersonalProfile } from './ClientProfile'
import { formatGraphQLError } from '../utils/error'
import { useNotification } from '../components/Notification'

const DEVELOPER_WITH_PAYMENT_DETAILS_FRAGMENT = gql`
  fragment DeveloperWithPaymentDetails on User {
    id
    firstName
    lastName
    country
    street
    city
    state
    zip
    entityType
    businessName
    taxId
    vatId
    phone
    vendorPrincipalDateOfBirth
    vendorPrincipalPersonalIdentificationNumber
    vendorDriverLicenseNumber
    vendorPassportNumber
    vendorAgreementCommissionPercent
    payoutType
    baseCurrency
    bankName
    bankAddress
    bankCountry
    bankState
    bankCity
    bankZip
    bankNameOnAccount
    # bankAccountClass
    bankAccountType
    bankId
    swiftBic
    bankAccountId
    iban
  }
`

gql`
  query GetDeveloperWithPaymentDetails {
    developer: getUser {
      ...DeveloperWithPaymentDetails
    }
  }

  ${DEVELOPER_WITH_PAYMENT_DETAILS_FRAGMENT}
`

gql`
  mutation UpdateDeveloperPaymentDetails($data: UpdateDeveloperPaymentDetailsInput!) {
    updateDeveloperPaymentDetails(data: $data) {
      ...DeveloperWithPaymentDetails
    }
  }

  ${DEVELOPER_WITH_PAYMENT_DETAILS_FRAGMENT}
`

interface DeveloperPaymentDetailsProps {}

export const DeveloperPaymentDetails: React.FC<DeveloperPaymentDetailsProps> = props => {
  const { data, loading, error } = useGetDeveloperWithPaymentDetailsQuery()
  const [updatePaymentDetails] = useUpdateDeveloperPaymentDetailsMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const {
    entityType,
    businessName,
    taxId,
    vatId,
    phone,
    vendorPrincipalDateOfBirth,
    vendorPrincipalPersonalIdentificationNumber,
    vendorDriverLicenseNumber,
    vendorPassportNumber,
    baseCurrency,
    bankName,
    bankAddress,
    bankCountry,
    bankState,
    bankCity,
    bankZip,
    bankNameOnAccount,
    bankAccountType,
    bankId,
    swiftBic,
    bankAccountId,
    iban,
  } = data.developer

  return (
    <PageLayout title={'Account Details'} headerDark headerLarge>
      <CardSideTitle title="Personal Information">
        <PersonalProfile />
      </CardSideTitle>

      <Formik<UpdateDeveloperPaymentDetailsInput>
        initialValues={{
          entityType,
          businessName,
          taxId,
          vatId,
          phone,
          vendorPrincipalDateOfBirth,
          vendorPrincipalPersonalIdentificationNumber,
          vendorDriverLicenseNumber,
          vendorPassportNumber,
          baseCurrency,
          bankName,
          bankAddress,
          bankCountry,
          bankState,
          bankCity,
          bankZip,
          bankNameOnAccount,
          bankAccountType,
          bankId,
          swiftBic,
          bankAccountId,
          iban,
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log('onSubmit', values)
          try {
            updatePaymentDetails({ variables: { data: values } })
            trackEvent('Update developer payment details', values)
            showSuccessNotification({
              description: 'Successfully updated information',
            })
          } catch (error) {
            console.error(error)
            showErrorNotification({ description: formatGraphQLError(error) })
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="mt-4">
              <CardSideTitle title="Entity">
                <FormItem>
                  <Field
                    name="entityType"
                    label="Entity Type"
                    component={CustomSelectComponent}
                    options={Object.values(EntityType).map(type => ({
                      value: type,
                      label: sentenceCase(type),
                    }))}
                  />
                </FormItem>

                {values.entityType === EntityType.Business && (
                  <>
                    <FormItem>
                      <Field
                        name="businessName"
                        label="Business Name"
                        component={CustomInputComponent}
                      />
                    </FormItem>

                    <FormItem>
                      <Field name="taxId" label="Tax Id" component={CustomInputComponent} />
                    </FormItem>

                    <FormItem>
                      <Field name="vatId" label="Vat Id" component={CustomInputComponent} />
                    </FormItem>
                  </>
                )}

                <FormItem>
                  <Field name="phone" label="Phone" component={CustomInputComponent} />
                </FormItem>

                <FormItem>
                  <Field
                    name="vendorPrincipalDateOfBirth"
                    label="Date of birth"
                    component={CustomInputComponent}
                    type="date"
                    placeholder="yyyy-mm-dd"
                  />
                </FormItem>

                <FormItem>
                  <Field
                    name="vendorPrincipalPersonalIdentificationNumber"
                    label="Personal Identification Number"
                    component={CustomInputComponent}
                  />
                </FormItem>

                <FormItem>
                  <Field
                    name="vendorDriverLicenseNumber"
                    label="Driver License Number"
                    component={CustomInputComponent}
                  />
                </FormItem>

                <FormItem>
                  <Field
                    name="vendorPassportNumber"
                    label="Passport Number"
                    component={CustomInputComponent}
                  />
                </FormItem>
              </CardSideTitle>
            </div>

            <div className="mt-4">
              <CardSideTitle title="Bank Details">
                <FormItem>
                  <Field
                    name="payoutType"
                    label="Payout Type"
                    value="Wire"
                    disabled
                    component={CustomInputComponent}
                  />
                </FormItem>

                <FormItem>
                  <Field
                    name="baseCurrency"
                    label="Currency"
                    component={CustomSelectComponent}
                    defaultValue={Currency.Usd}
                    options={Object.values(Currency).map(currency => ({
                      value: currency,
                      label: currency,
                    }))}
                  />
                </FormItem>

                <FormItem>
                  <Field name="bankName" label="Name" component={CustomInputComponent} />
                </FormItem>

                <FormItem>
                  <Field name="bankAddress" label="Address" component={CustomInputComponent} />
                </FormItem>

                <FormItem>
                  <CountrySelectField name="bankCountry" />
                </FormItem>

                <FormItem>
                  <Field name="bankState" label="State" component={CustomInputComponent} />
                </FormItem>

                <FormItem>
                  <Field name="bankCity" label="City" component={CustomInputComponent} />
                </FormItem>

                <FormItem>
                  <Field name="bankZip" label="Zip" component={CustomInputComponent} />
                </FormItem>

                <FormItem>
                  <Field
                    name="bankNameOnAccount"
                    label="Name On Account"
                    component={CustomInputComponent}
                  />
                </FormItem>

                {/* <FormItem>
                <Field
                  name="bankAccountClass"
                  label="Bank Account Class"
                  component={CustomInputComponent}
                />
              </FormItem> */}

                <FormItem>
                  <Field
                    name="bankAccountType"
                    label="Bank Account Type"
                    component={CustomSelectComponent}
                    options={Object.values(BankAccountType).map(type => ({
                      value: type,
                      label: sentenceCase(type),
                    }))}
                  />
                </FormItem>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem>
                      <Field name="bankId" label="Bank ID" component={CustomInputComponent} />
                    </FormItem>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <FormItem>
                      <Field name="swiftBic" label="Swift BIC" component={CustomInputComponent} />
                    </FormItem>
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <FormItem>
                      <Field
                        name="bankAccountId"
                        label="Bank Account ID"
                        component={CustomInputComponent}
                      />
                    </FormItem>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <FormItem>
                      <Field name="iban" label="IBAN" component={CustomInputComponent} />
                    </FormItem>
                  </div>
                </div>

                <div className="text-right">
                  <FormItem>
                    <Button
                      styleType="primary"
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Save
                    </Button>
                  </FormItem>
                </div>
              </CardSideTitle>
            </div>
          </form>
        )}
      </Formik>
    </PageLayout>
  )
}
