import { gql } from '@apollo/client'
import { Field } from 'formik'
import React from 'react'
import { useComponentCategoriesQuery } from '../generated/graphql'
import { CustomMultiSelectComponent } from './FormikCustom'

gql`
  fragment ComponentCategory on ComponentCategory {
    name
  }

  query ComponentCategories {
    componentCategories {
      ...ComponentCategory
    }
  }
`

interface ComponentCategoryProps {
  name?: string
}

/**
 * Expected to be used in a Formik form
 */
export const ComponentCategoryField: React.FC<ComponentCategoryProps> = props => {
  const { data, loading } = useComponentCategoriesQuery()
  const componentCategories = data?.componentCategories || []

  if (loading) return null

  const options = componentCategories.map(category => ({
    value: category.name,
    label: category.name,
  }))

  return (
    <Field
      name={'componentCategories'}
      label="Tags"
      options={options}
      component={CustomMultiSelectComponent}
    />
  )
}
