import React from 'react'
import { Link } from 'react-router-dom'
import { openPopupWidget, closePopupWidget, CalendlyEventListener } from 'react-calendly'

import { useUserContext } from '../auth/userContext'

import { LayoutPlain } from '../components/LayoutPlain'
import { CardContainer, CardTitle, CardDescription } from '../components/Card'
import { HeadingLarge } from '../components/Typography'

import { ReactComponent as BoardSvg } from '../images/undraw_board.svg'
import { ReactComponent as ProjectSvg } from '../images/undraw_project.svg'
import { ReactComponent as MeetingSvg } from '../images/undraw_meeting.svg'

interface NewProjectOnboardingProps {}

export const NewProjectOnboarding: React.FC<NewProjectOnboardingProps> = () => {
  const { user } = useUserContext()
  const [isEventScheduled, setIsEventScheduled] = React.useState(false)

  const openCalendly = () => {
    openPopupWidget({
      url: 'https://calendly.com/frontwork/onboarding',
      prefill: {
        email: user?.email,
      },
    })
  }

  const handleEventScheduled = () => {
    const modalCloseDelay = 5_000
    setTimeout(closePopupWidget, modalCloseDelay)

    setIsEventScheduled(true)
  }

  return (
    <LayoutPlain>
      <div className="h-full flex flex-col items-center justify-center">
        {isEventScheduled ? (
          <PostScheduleEvent />
        ) : (
          <>
            <div className="mb-12 mt-8">
              <HeadingLarge>How would you like to start your project?</HeadingLarge>
            </div>
            <div className="grid lg:grid-cols-2 gap-12 md:w-3/4">
              <Link to="/new-project-type">
                <CardContainer className="pt-5 px-4 sm:px-6 flex flex-col" hoverable>
                  <CardTitle>Self Publish</CardTitle>
                  <CardDescription>
                    Connect your Figma or design tool of choice and publish the designs.
                  </CardDescription>
                  <div className="flex flex-1 py-16 p-4">
                    <ProjectSvg className="h-64 w-64 mx-auto" />
                  </div>
                </CardContainer>
              </Link>
              <CardContainer
                className="pt-5 px-4 sm:px-6 flex flex-col cursor-pointer"
                hoverable
                onClick={openCalendly}
              >
                <CardTitle>Book a Call</CardTitle>
                <CardDescription>
                  Book a call with a FrontWork expert to help you find the best developer for your
                  project.
                </CardDescription>
                <div className="flex flex-1 py-16 p-4">
                  <BoardSvg className="h-64 w-64 mx-auto" />
                </div>
              </CardContainer>
            </div>
          </>
        )}
      </div>
      <CalendlyEventListener onEventScheduled={handleEventScheduled} />
    </LayoutPlain>
  )
}

const PostScheduleEvent = () => {
  return (
    <CardContainer className="pt-5 px-4 sm:px-6 flex flex-col md:w-1/2">
      <CardTitle>See you then</CardTitle>
      <CardDescription>Our team can't wait to meet you</CardDescription>
      <div className="flex flex-1 py-16 p-4">
        <MeetingSvg className="md:h-86 md:w-86 h-full w-full mx-auto" />
      </div>
    </CardContainer>
  )
}
