import {
  FrontendFramework,
  FrontendFrameworkProject,
  ProgrammingLanguage,
  ProgrammingLanguageProject,
  StylingLibrary,
  StylingLibraryProject,
  User,
} from '../generated/graphql'

export const programmingLanguagesProject = [
  { value: ProgrammingLanguageProject.Javascript, label: 'JavaScript' },
  { value: ProgrammingLanguageProject.Typescript, label: 'TypeScript' },
  { value: ProgrammingLanguageProject.Flow, label: 'Flow' },
  { value: ProgrammingLanguageProject.VanillaHtml, label: 'Vanilla HTML' },
  { value: ProgrammingLanguageProject.DevelopersChoice, label: `Developer's choice` },
]

export const stylingLibrariesProject = [
  { value: StylingLibraryProject.Css, label: 'CSS' },
  { value: StylingLibraryProject.Sass, label: 'Sass' },
  { value: StylingLibraryProject.Less, label: 'Less' },
  { value: StylingLibraryProject.StyledComponents, label: 'Styled Components' },
  { value: StylingLibraryProject.Emotion, label: 'Emotion' },
  { value: StylingLibraryProject.Tailwind, label: 'Tailwind' },
  { value: StylingLibraryProject.Other, label: 'Other' },
  { value: StylingLibraryProject.DevelopersChoice, label: `Developer's choice` },
]

export const frontendFrameworksProject = [
  { value: FrontendFrameworkProject.React, label: 'React' },
  { value: FrontendFrameworkProject.Angular, label: 'Angular' },
  { value: FrontendFrameworkProject.Vue, label: 'Vue' },
  { value: FrontendFrameworkProject.Svelte, label: 'Svelte' },
  { value: FrontendFrameworkProject.VanillaHtml, label: 'Vanilla HTML' },
  { value: FrontendFrameworkProject.ReactNative, label: 'React Native' },
  { value: FrontendFrameworkProject.ReactNativeWeb, label: 'React Native Web' },
  { value: FrontendFrameworkProject.Wordpress, label: 'WordPress' },
  { value: StylingLibraryProject.Other, label: 'Other' },
  { value: StylingLibraryProject.DevelopersChoice, label: `Developer's choice` },
]

export const formatProgrammingLanguage = (
  language?: ProgrammingLanguageProject | ProgrammingLanguage | null
) => {
  if (!language) return ''

  const l = programmingLanguagesProject.find(l => l.value === language)

  return l?.label || ''
}

export const formatStylingLibrary = (library?: StylingLibraryProject | StylingLibrary | null) => {
  if (!library) return ''

  const s = stylingLibrariesProject.find(s => s.value === library)

  return s?.label || ''
}

export const formatFrontendFramework = (
  framework?: FrontendFrameworkProject | FrontendFramework | null
) => {
  if (!framework) return ''

  const f = frontendFrameworksProject.find(f => f.value === framework)

  return f?.label || ''
}

export const formatSkill = (
  skill: FrontendFramework | StylingLibrary | ProgrammingLanguage | string | null | undefined
) => {
  if (!skill) return ''

  return (
    formatFrontendFramework(skill as FrontendFramework) ||
    formatStylingLibrary(skill as StylingLibrary) ||
    formatProgrammingLanguage(skill as ProgrammingLanguage)
  )
}

export const SKILL_TO_EXPERIENCE: { [key: string]: string } = {
  [FrontendFramework.React]: 'yearsOfExperienceReact',
  [FrontendFramework.ReactNative]: 'yearsOfExperienceReactNative',
  [FrontendFramework.Vue]: 'yearsOfExperienceVue',
  [FrontendFramework.Angular]: 'yearsOfExperienceAngular',
  [FrontendFramework.Svelte]: 'yearsOfExperienceSvelte',
  [FrontendFramework.Wordpress]: 'yearsOfExperienceWordpress',
  [ProgrammingLanguage.Javascript]: 'yearsOfExperienceJavascript',
  [ProgrammingLanguage.Typescript]: 'yearsOfExperienceTypescript',
  [ProgrammingLanguage.Flow]: 'yearsOfExperienceFlow',
}

export const findSkillForExperience = (experience: string): string | undefined => {
  const skillExperience = Object.entries(SKILL_TO_EXPERIENCE).find(
    ([key, value]) => value === experience
  )

  return skillExperience?.[0]
}
