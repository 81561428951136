import React from 'react'
import { serverAmountToUSD } from '../utils/currency'
import { formatDate } from '../utils/date'
import { ReactComponent as CashIcon } from '../icons/cash.svg'
import { PaymentFragment } from '../generated/graphql'
import { Paragraph } from './Typography'

export interface PaymentsListProps {
  payments: PaymentFragment[]
}

export const PaymentsList: React.FC<PaymentsListProps> = props => {
  const { payments } = props

  if (!payments.length) {
    return (
      <div className="mt-4">
        {' '}
        <Paragraph>You have not made any previous payments.</Paragraph>
      </div>
    )
  }
  
  return (
    <>
      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-cool-gray-200 overflow-hidden shadow sm:hidden">
          {payments.map(payment => {
            return (
              <li key={payment.id}>
                <div className="block px-4 py-4 bg-white hover:bg-cool-gray-50">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 flex space-x-2 truncate">
                      <CashIcon className="flex-shrink-0 h-5 w-5 text-cool-gray-400" />
                      <div className="text-cool-gray-500 text-sm truncate">
                        <p className="truncate">{payment.bid.project.name}</p>
                        <p>
                          <span className="text-cool-gray-900 font-medium">
                            {serverAmountToUSD(parseInt(payment.amount) * 100)}
                          </span>{' '}
                          USD
                        </p>
                        <p>{formatDate(new Date(payment.createdAt))}</p>
                      </div>
                    </div>
                    {/* <div>
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-cool-gray-400" />
                    </div> */}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        {/* <PaginationMobile payments={payments} /> */}
      </div>

      <div className="hidden sm:block">
        <div className="">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-cool-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                      Transaction
                    </th>
                    <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th className="hidden px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider md:block">
                      Status
                    </th>
                    <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-cool-gray-200">
                  {payments.map(payment => {
                    return (
                      <tr key={payment.id} className="bg-white">
                        <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm leading-5 text-cool-gray-900">
                          <div className="flex">
                            <div className="group inline-flex space-x-2 truncate text-sm leading-5">
                              <CashIcon className="flex-shrink-0 h-5 w-5 text-cool-gray-400 group-hover:text-cool-gray-500 transition" />
                              <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition">
                                {payment.bid.project.name}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm leading-5 text-cool-gray-500">
                          <span className="text-cool-gray-900 font-medium">
                            {serverAmountToUSD(parseInt(payment.amount) * 100)}{' '}
                          </span>
                          USD
                        </td>
                        <td className="hidden px-6 py-4 whitespace-nowrap text-sm leading-5 text-cool-gray-500 md:block">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 capitalize">
                            {payment.paypalStatus}
                          </span>
                        </td>
                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm leading-5 text-cool-gray-500">
                          {formatDate(new Date(payment.createdAt))}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {/* <Pagination payments={payments} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface PaginationProps {
  payments: PaymentFragment[]
}

export const Pagination: React.FC<PaginationProps> = props => {
  const { payments } = props

  return (
    <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200 sm:px-6">
      <div className="hidden sm:block">
        <p className="text-sm leading-5 text-cool-gray-700">
          Showing <span className="font-medium">1</span> to{' '}
          <span className="font-medium">{Math.min(10, payments.length)}</span> of{' '}
          <span className="font-medium">{payments.length}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition"
        >
          Next
        </a>
      </div>
    </nav>
  )
}

interface PaginationMobileProps extends PaginationProps {}

export const PaginationMobile: React.FC<PaginationMobileProps> = props => {
  return (
    <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200">
      <div className="flex-1 flex justify-between">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition"
        >
          Next
        </a>
      </div>
    </nav>
  )
}
