import React from 'react'
import classNames from 'classnames'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useParams, useHistory } from 'react-router-dom'
import { PencilIcon, CheckIcon, LightningBoltIcon } from '@heroicons/react/solid'
import { ChatIcon, SparklesIcon } from '@heroicons/react/outline'
import { PageLayout } from '../../components/PageLayout'
import { Card, CardWrapper } from '../../components/Card'
import {
  useProjectQuery,
  usePublishProjectMutation,
  useUnpublishProjectMutation,
  useDeleteGitHubProjectMutation,
  useDeleteProjectMutation,
  useAdminDeleteProjectMutation,
  useUnstartProjectMutation,
  ProjectStatus,
  Screen,
  BidWithDeveloperFragment,
  ComponentType,
} from '../../generated/graphql'
import { Loading } from '../../components/Loading'
import { Paragraph } from '../../components/Typography'
import {
  FrontendFrameworkTag,
  ProgrammingLanguageTag,
  StylingLibraryTag,
  Tag,
} from '../../components/Tag'
import { PROJECT_FRAGMENT } from '../Projects'
import { PageHeaderExtra, HeaderButton } from '../../components/PageHeader'
import { ScreensGrid, ScreensGridScreen } from '../../components/ScreensGrid'
import { Modal, ModalDualButtons, ModalHeader } from '../../components/Modal'
import { Button } from '../../components/Button'
import { formatDateLong } from '../../utils/date'
import { useUserContext } from '../../auth/userContext'
import { serverAmountToUSD } from '../../utils/currency'
import { ErrorPage } from '../../components/ErrorPage'
import { trackEvent } from '../../utils/analytics'
import { DEPLOYED_PROJECT_FRAGMENT } from '../InterviewProject'
import { useNotification } from '../../components/Notification'
import { formatGraphQLError } from '../../utils/error'
import { PersonalProfile } from '../ClientProfile'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../../utils/skills'
import { StartProjectModalContent } from '../InterviewProject'
import { ScheduleSvg } from '../../icons/ScheduleIcon'
import { Bids } from './Bids'
import { AcceptBidModalContent } from './AcceptBidModal'
import { BidModalContent, BID_FRAGMENT } from './BidModal'
import { ReviewModalContent } from './ReviewModal'
import { useMessageContext } from '../../utils/messagesContext'
import { Overview } from './Overview'

gql`
  ${PROJECT_FRAGMENT}
  ${BID_FRAGMENT}

  fragment BidWithDeveloper on Bid {
    ...Bid
    developer {
      id
      username
    }
  }

  fragment GetProject on Project {
    ...Project
    bid {
      ...Bid
    }
    bids {
      ...BidWithDeveloper
    }
    deployedProject {
      ...DeployedProject
    }
  }

  query Project($id: ID!) {
    project(id: $id) {
      ...GetProject
    }
  }

  ${DEPLOYED_PROJECT_FRAGMENT}
`

gql`
  ${PROJECT_FRAGMENT}

  mutation PublishProject($projectId: ID!) {
    publishProject(projectId: $projectId) {
      ...Project
    }
  }
`

gql`
  ${PROJECT_FRAGMENT}

  mutation UnpublishProject($projectId: ID!) {
    unpublishProject(projectId: $projectId) {
      ...Project
    }
  }
`

gql`
  mutation DeleteGitHubProject($deployedProjectId: ID!) {
    deleteGitHubProject(deployedProjectId: $deployedProjectId) {
      ...DeployedProject
    }
  }

  ${DEPLOYED_PROJECT_FRAGMENT}
`

gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`

gql`
  mutation AdminDeleteProject($projectId: ID!) {
    adminDeleteProject(projectId: $projectId)
  }
`

gql`
  ${PROJECT_FRAGMENT}

  mutation UnstartProject($projectId: ID!) {
    unstartProject(projectId: $projectId) {
      ...Project
    }
  }
`

interface ProjectProps {}

export const Project: React.FC<ProjectProps> = props => {
  const { projectId } = useParams<{ projectId: string }>()
  const [showBidModal, setShowBidModal] = React.useState(false)
  const [showPublishModal, setShowPublishModal] = React.useState(false)
  const [showStartProjectModal, setShowStartProjectModal] = React.useState(false)
  const [showReviewModal, setShowReviewModal] = React.useState(false)
  const { setOpen } = useMessageContext()
  const { user } = useUserContext()
  const history = useHistory()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  const { data, loading, error, refetch } = useProjectQuery({ variables: { id: projectId } })
  const [publishProject] = usePublishProjectMutation({ variables: { projectId } })
  const [unpublishProject] = useUnpublishProjectMutation({ variables: { projectId } })
  const [deleteGitHubProject] = useDeleteGitHubProjectMutation()
  const [deleteProject, { loading: loadingDeleteProject }] = useDeleteProjectMutation({
    variables: { projectId },
  })
  const [
    adminDeleteProject,
    { loading: loadingAdminDeleteProject },
  ] = useAdminDeleteProjectMutation({
    variables: { projectId },
  })
  const [unstartProject] = useUnstartProjectMutation({ variables: { projectId } })

  const [acceptModalBid, setAcceptModalBid] = React.useState<BidWithDeveloperFragment>()
  const closeAcceptModal = React.useCallback(() => {
    setAcceptModalBid(undefined)

    // remove bidId from url
    const search = new URLSearchParams(history.location.search)
    if (search.has('bidId')) {
      search.delete('bidId')
      history.replace({ pathname: history.location.pathname, search: search.toString() })
    }
  }, [history])

  const { listen, location } = useHistory()
  const bids = data?.project.bids

  const openModalFromQueryString = React.useCallback(
    (location: { search: string }) => {
      const search = new URLSearchParams(location.search)
      const queryBidId = search.get('bidId')

      if (queryBidId) {
        const bid = bids?.find(b => b.id === queryBidId)
        if (bid) setAcceptModalBid(bid)
      }
    },
    [bids]
  )

  React.useEffect(() => {
    openModalFromQueryString(location)

    const unlisten = listen(location => {
      openModalFromQueryString(location)
    })

    return unlisten
  }, [listen, location, openModalFromQueryString])

  if (!user) return null
  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { project } = data
  const { deployedProject, zeplinProjectId, figmaFileId } = project
  const { githubUrl, netlifyUrl } = deployedProject || {}

  const editButton: HeaderButton = {
    text: 'Edit',
    icon: (
      <span className="text-gray-300">
        <PencilIcon />
      </span>
    ),
    type: 'secondary',
    hideOnMobile: false,
    onClick: () => {
      history.push(`/new-project?projectId=${projectId}`)
    },
  }

  const publishButton: HeaderButton = {
    text: 'Publish',
    icon: <CheckIcon />,
    type: 'primary',
    hideOnMobile: false,
    onClick: () => {
      setShowPublishModal(true)
      trackEvent('Open publish project modal', { projectId })
    },
  }

  const unpublishButton: HeaderButton = {
    text: 'Unpublish',
    type: 'secondary',
    hideOnMobile: true,
    onClick: () => {
      unpublishProject()
      trackEvent('Unpublish project', { projectId })
    },
  }

  const bidButton: HeaderButton = {
    text: project.bid ? 'Edit Bid' : 'Bid',
    icon: <SparklesIcon />,
    type: 'primary',
    hideOnMobile: false,
    onClick: () => {
      setShowBidModal(true)
    },
  }

  const startProjectButton: HeaderButton = {
    text: 'Start Interview Project',
    icon: <LightningBoltIcon />,
    type: 'primary',
    hideOnMobile: false,
    onClick: () => {
      setShowStartProjectModal(true)
    },
  }

  const continueInterviewButton: HeaderButton = {
    text: 'Back to Interview',
    type: 'secondary',
    hideOnMobile: false,
    onClick: () => {
      history.push('/developer/interview-project')
    },
  }

  const messageButton: HeaderButton = {
    text: 'Message',
    icon: (
      <span className="text-gray-300">
        <ChatIcon />
      </span>
    ),
    type: 'secondary',
    hideOnMobile: false,
    onClick: () => {
      if (user.id === project.creatorId) {
        // message developer
        if (deployedProject?.developerId) setOpen(deployedProject?.developerId)
      } else {
        // message creator
        setOpen(project.creatorId)
      }
    },
  }

  function getButtons(): HeaderButton[] {
    if (user?.developer) {
      const developerButtons: HeaderButton[] = []

      if (project.interviewBidProject || project.interviewDevProject)
        developerButtons.push(continueInterviewButton)
      else developerButtons.push(messageButton)

      if (project.interviewDevProject && !githubUrl) developerButtons.push(startProjectButton)
      if (!project.interviewDevProject) developerButtons.push(bidButton)

      return developerButtons
    } else {
      const clientButtons: HeaderButton[] = [editButton]

      switch (project.status) {
        case ProjectStatus.Draft:
        case ProjectStatus.Expired:
          clientButtons.push(publishButton)
          break
        case ProjectStatus.Searching:
          clientButtons.push(unpublishButton)
          break
        case ProjectStatus.InProgress:
          clientButtons.push(messageButton)
          break
        default:
          break
      }

      return clientButtons
    }
  }

  const buttons = getButtons()
  const isOwner = project.creatorId === user.id
  const showBids = isOwner && project.status === ProjectStatus.Searching
  const canDelete =
    isOwner &&
    project.status !== ProjectStatus.InProgress &&
    project.status !== ProjectStatus.Completed
  const canAdminDelete = user?.admin
  const showOtherSection = canDelete || canAdminDelete

  const isZeplinProject = !!project.zeplinScreens?.length
  const isFigmaProject = !isZeplinProject && !!project.figmaScreens?.length

  const screens:
    | Array<
        Pick<Screen, 'id' | 'name' | 'notes' | 'cloudinaryUrl' | 'componentType'> & {
          figmaId?: string | null
          figmaPageId?: string | null
          zeplinId?: string | null
          commentsCount?: number
        }
      >
    | null
    | undefined = isZeplinProject
    ? project.zeplinScreens
    : isFigmaProject
    ? project.figmaScreens
    : project.imageScreens

  return (
    <PageLayout
      title={project.name}
      headerDark
      headerLarge
      header={
        <PageHeaderExtra
          title={project.name}
          dark
          rightContent={
            project.bid ? (
              <div className="flex flex-col justify-center pr-6 text-md text-white text-right">
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                  {project.bid.amount
                    ? serverAmountToUSD(project.bid.amount)
                    : project.bid.hourlyRate && `${serverAmountToUSD(project.bid.hourlyRate)} / hr`}
                </h2>
                {project.bid.deliveryDate && (
                  <div className="text-gray-300 flex text-sm">
                    <div className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500">
                      <ScheduleSvg />
                    </div>
                    <div>{formatDateLong(new Date(project.bid.deliveryDate))}</div>
                  </div>
                )}
              </div>
            ) : null
          }
          buttons={buttons}
          description={
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
              <div className="sm:flex sm:space-x-2 space-y-2 sm:space-y-0 mt-2">
                {project.frontendFramework && (
                  <FrontendFrameworkTag>
                    {formatFrontendFramework(project.frontendFramework)}
                  </FrontendFrameworkTag>
                )}
                {project.programmingLanguage && (
                  <ProgrammingLanguageTag>
                    {formatProgrammingLanguage(project.programmingLanguage)}
                  </ProgrammingLanguageTag>
                )}
                {project.styling && (
                  <StylingLibraryTag>{formatStylingLibrary(project.styling)}</StylingLibraryTag>
                )}
                {(project.interviewDevProject || project.interviewBidProject) && (
                  <Tag color="pink">
                    Interview {project.interviewDevProject ? 'Dev' : 'Bid'} Project
                  </Tag>
                )}
              </div>
              <div className="mt-2 flex items-center text-sm leading-5 text-gray-300 ml-1.5">
                {project.deadline ? (
                  <>
                    <div className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500">
                      <ScheduleSvg />
                    </div>
                    Deadline: {formatDateLong(new Date(project.deadline))}
                  </>
                ) : null}
              </div>
            </div>
          }
        />
      }
      alert={
        project.status === ProjectStatus.InProgress || deployedProject ? (
          <div className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 h-full">
              {!deployedProject && (
                <div className="text-lg leading-8 font-medium mb-2 text-gray-900">In Progress</div>
              )}
              <div className="space-x-2">
                {githubUrl && (
                  <Button styleType="primary" onClick={() => window.open(githubUrl!)}>
                    GitHub Repo
                  </Button>
                )}
                {netlifyUrl && (
                  <Button styleType="primary" onClick={() => window.open(netlifyUrl)}>
                    Deployed Version
                  </Button>
                )}
                {zeplinProjectId && (
                  <Button
                    styleType="primary"
                    onClick={() => window.open(`https://app.zeplin.io/project/${zeplinProjectId}`)}
                  >
                    Zeplin Project
                  </Button>
                )}
                {figmaFileId && (
                  <Button
                    styleType="primary"
                    onClick={() => window.open(`https://www.figma.com/file/${figmaFileId}`)}
                  >
                    Figma Project
                  </Button>
                )}
                {project.status === ProjectStatus.InProgress && (
                  <Button styleType="secondary" onClick={() => setShowReviewModal(true)}>
                    Complete Project
                  </Button>
                )}
                {user.admin && deployedProject && (githubUrl || netlifyUrl) && (
                  <Button
                    styleType="danger"
                    onClick={() =>
                      deleteGitHubProject({ variables: { deployedProjectId: deployedProject.id } })
                    }
                  >
                    Delete GitHub and Netlify projects
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : null
      }
    >
      <div className={classNames({ 'grid grid-cols-6 gap-4 grid-flow-row-dense': showBids })}>
        {showBids && (
          <div className="col-span-6 lg:col-span-2 lg:col-start-5">
            <Bids project={project} setAcceptModalBid={setAcceptModalBid} />
          </div>
        )}

        <div className={classNames({ 'col-span-6 lg:col-span-4 lg:col-start-1': showBids })}>
          <Overview projectId={project.id} overview={project.overview} canEdit={isOwner} />

          <CardWrapper>
            <Card title="Components">
              {screens?.length ? (
                <ScreensGrid>
                  {screens
                    .slice()
                    .sort((screenA, screenB) => {
                      return screenA.componentType.localeCompare(screenB.componentType)
                    })
                    .map(screen => {
                      const imageUrl = screen.cloudinaryUrl || ''

                      const getExternalUrl = () => {
                        if (screen.figmaId)
                          return `https://www.figma.com/file/${figmaFileId}/?node-id=${screen.figmaId}`

                        if (screen.zeplinId) {
                          return screen.componentType === ComponentType.Component
                            ? `https://app.zeplin.io/project/${zeplinProjectId}/styleguide/components?coid=${screen.zeplinId}`
                            : `https://app.zeplin.io/project/${zeplinProjectId}/screen/${screen.zeplinId}`
                        }

                        return imageUrl
                      }

                      const externalUrl = getExternalUrl()

                      return (
                        <ScreensGridScreen
                          key={screen.id}
                          screen={{
                            id: screen.id,
                            name: screen.name,
                            imageUrl,
                            notes: screen.notes || undefined,
                            commentsCount: screen.commentsCount || 0,
                          }}
                          active={false}
                          externalUrl={externalUrl}
                          readonly={!isOwner}
                          projectCreatorId={project.creatorId}
                        />
                      )
                    })}
                </ScreensGrid>
              ) : (
                <Paragraph>There are no screens for this project.</Paragraph>
              )}
            </Card>
          </CardWrapper>

          {showOtherSection && (
            <CardWrapper>
              <Card title="Other">
                {/* <p className="mb-2 font-semibold">Custom Prettier Config</p>
              {project.prettierConfig ? (
                <CodeEditor height={150} value={project.prettierConfig} />
              ) : (
                <p>Not set</p>
              )}
              <CardWrapper>
                <p className="mb-2 font-semibold">Custom ESLint Config</p>
                {project.eslintConfig ? (
                  <CodeEditor height={150} value={project.eslintConfig} />
                ) : (
                  <p>Not set</p>
                )}
              </CardWrapper> */}

                {canDelete && (
                  <div className="mt-4">
                    <Button
                      styleType="danger"
                      loading={loadingDeleteProject}
                      disabled={loadingDeleteProject}
                      onClick={async () => {
                        // eslint-disable-next-line no-restricted-globals
                        const yes = confirm(`Are you sure you'd like to delete this project?`)

                        if (yes) {
                          try {
                            const res = await deleteProject()
                            trackEvent('Delete project', { projectId })
                            showSuccessNotification({
                              description: 'Successfully deleted project',
                            })
                            history.push('/')
                          } catch (error) {
                            console.error(error)
                            showErrorNotification({ description: formatGraphQLError(error) })
                          }
                        }
                      }}
                    >
                      Delete Project
                    </Button>
                  </div>
                )}

                {canAdminDelete && (
                  <div className="mt-4 flex">
                    <div className="mr-2">
                      <Button
                        styleType="danger"
                        loading={loadingAdminDeleteProject}
                        disabled={loadingAdminDeleteProject}
                        onClick={async () => {
                          // eslint-disable-next-line no-restricted-globals
                          const yes = confirm(`Are you sure you'd like to delete this project?`)

                          if (yes) {
                            try {
                              const res = await adminDeleteProject()
                              trackEvent('Admin Delete project', { projectId })
                              showSuccessNotification({
                                description: 'Successfully deleted project',
                              })
                              history.push('/')
                            } catch (error) {
                              console.error(error)
                              showErrorNotification({ description: formatGraphQLError(error) })
                            }
                          }
                        }}
                      >
                        Admin Delete Project
                      </Button>
                    </div>
                    {project.status === ProjectStatus.InProgress && (
                      <Button
                        styleType="danger"
                        onClick={async () => {
                          // eslint-disable-next-line no-restricted-globals
                          const yes = confirm(`Are you sure you'd like to unstart this project?`)

                          if (yes) {
                            try {
                              const res = await unstartProject()
                              trackEvent('Admin unstart project', { projectId })
                              showSuccessNotification({
                                description: 'Successfully unstarted project',
                              })
                            } catch (error) {
                              console.error(error)
                              showErrorNotification({ description: formatGraphQLError(error) })
                            }
                          }
                        }}
                      >
                        Admin Unstart Project
                      </Button>
                    )}
                  </div>
                )}
              </Card>
            </CardWrapper>
          )}

          {showBidModal && (
            <Modal hideModal={() => setShowBidModal(false)}>
              <BidModalContent
                projectId={projectId}
                bid={project.bid}
                hideModal={() => setShowBidModal(false)}
                interviewProject={!!project.interviewBidProject}
                projectContractType={project.contractType}
                projectCreatorId={project.creatorId}
              />
            </Modal>
          )}

          {acceptModalBid && (
            <Modal hideModal={closeAcceptModal}>
              <AcceptBidModalContent
                projectId={projectId}
                bid={acceptModalBid}
                user={user}
                hideModal={closeAcceptModal}
              />
            </Modal>
          )}

          {showPublishModal && (
            <Modal fullWidth hideModal={() => setShowPublishModal(false)}>
              <ModalHeader title="Publish Project" />

              <div className="my-6">
                <Paragraph>Please confirm you'd like to publish the project.</Paragraph>
                <Paragraph>
                  Once published this will be shared with a set of vetted developers.
                </Paragraph>
              </div>

              <ModalDualButtons
                button1={
                  <Button
                    styleType="primary"
                    block
                    type="submit"
                    onClick={() => {
                      publishProject()
                      trackEvent('Publish project', { projectId })
                      setShowPublishModal(false)
                    }}
                    data-cy="confirm-publish"
                  >
                    Publish
                  </Button>
                }
                button2={
                  <Button styleType="white" block onClick={() => setShowPublishModal(false)}>
                    Cancel
                  </Button>
                }
              />
            </Modal>
          )}

          {showStartProjectModal && (
            <Modal fullWidth hideModal={() => setShowStartProjectModal(false)}>
              <StartProjectModalContent
                projectId={projectId}
                hideModal={() => setShowStartProjectModal(false)}
              />
            </Modal>
          )}

          {showReviewModal && (
            <Modal hideModal={() => setShowReviewModal(false)}>
              <ReviewModalContent
                deployedProjectId={deployedProject?.id}
                projectId={project.id}
                hideModal={() => setShowReviewModal(false)}
              />
            </Modal>
          )}
        </div>
      </div>
    </PageLayout>
  )
}
