import { gql } from '@apollo/client'
import React from 'react'
import { Formik, Field } from 'formik'
import { addDays } from 'date-fns'
import { useUserContext } from '../../auth/userContext'
import { ModalDualButtons, ModalHeader } from '../../components/Modal'
import { useNotification } from '../../components/Notification'
import {
  BidFragment,
  BidStatus,
  ContractType,
  CreateBidInput,
  GetInterviewDocument,
  InterviewStatus,
  ProjectDocument,
  useCreateBidMutation,
  useEditBidMutation,
} from '../../generated/graphql'
import { amountToServerAmount, serverAmountToAmount } from '../../utils/currency'
import { formatDateForCalendar, parseCalendarDate } from '../../utils/date'
import { trackEvent } from '../../utils/analytics'
import { formatGraphQLError } from '../../utils/error'
import { FormItem } from '../../components/FormItem'
import {
  CustomInputComponent,
  CustomInputCurrencyComponent,
  CustomTextAreaComponent,
} from '../../components/FormikCustom'
import { Button } from '../../components/Button'
import { Alert } from '../../components/Alert'
import { LinkButton } from '../../components/Typography'
import { useMessageContext } from '../../utils/messagesContext'
import { BidResult } from '../../components/BidResult'

export const BID_FRAGMENT = gql`
  fragment Bid on Bid {
    id
    status
    amount
    hourlyRate
    developerId
    projectId
    deliveryDate
    message
    deleted
  }
`

gql`
  ${BID_FRAGMENT}

  mutation CreateBid($data: CreateBidInput!) {
    createBid(data: $data) {
      ...Bid
    }
  }
`

gql`
  ${BID_FRAGMENT}

  mutation EditBid($data: EditBidInput!) {
    editBid(data: $data) {
      ...Bid
    }
  }
`

interface BidModalContentProps {
  projectId: string
  bid?: BidFragment | null
  hideModal: () => void
  interviewProject: boolean
  projectContractType: ContractType
  projectCreatorId: string
}

export const BidModalContent: React.FC<BidModalContentProps> = props => {
  const { bid, interviewProject } = props
  const [createBid] = useCreateBidMutation()
  const [editBid] = useEditBidMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const { user } = useUserContext()
  const { setOpen } = useMessageContext()

  return (
    <div>
      <ModalHeader title="Make Bid" />
      <div className="mt-2">
        <Formik<CreateBidInput>
          initialValues={{
            amount: serverAmountToAmount(bid?.amount || 0),
            projectId: props.projectId,
            deliveryDate: bid?.deliveryDate
              ? formatDateForCalendar(new Date(bid.deliveryDate))
              : '',
            message: bid?.message,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('onSubmit', values)

            const options = {
              variables: {
                data: {
                  amount: amountToServerAmount(values.amount),
                  projectId: props.projectId,
                  hourlyRate: values.hourlyRate
                    ? amountToServerAmount(values.hourlyRate)
                    : undefined,
                  deliveryDate: parseCalendarDate(values.deliveryDate as string),
                  message: values.message,
                },
              },
            }

            const refetchQueries = [
              { query: ProjectDocument, variables: { id: props.projectId } },
              ...(user?.interviewStatus === InterviewStatus.Passed
                ? []
                : [{ query: GetInterviewDocument }]),
            ]

            try {
              if (bid) {
                const variables = { data: { ...options.variables.data, id: bid.id } }
                await editBid({ variables, refetchQueries })
                trackEvent('Edit bid', { ...variables.data, interviewProject })
              } else {
                await createBid({
                  ...options,
                  refetchQueries,
                })
                trackEvent('Create bid', { ...options.variables.data, interviewProject })
              }

              props.hideModal()
              showSuccessNotification({ description: 'Successfully submitted bid' })
            } catch (error) {
              console.error(error)
              showErrorNotification({ description: formatGraphQLError(error) })
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting, handleSubmit, handleReset, values, touched }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              {props.projectContractType !== ContractType.Hourly && (
                <>
                  <FormItem>
                    <Field
                      name="amount"
                      label="Amount"
                      type="number"
                      min="0"
                      max="100000"
                      step="1"
                      component={CustomInputCurrencyComponent}
                    />
                  </FormItem>
                  {touched.amount && values.amount <= 100 && (
                    <div className="mt-4">
                      <Alert
                        type="warning"
                        title="Low Bid"
                        description="Are you sure you meant to bid this amount for a fixed price project? This field is not an hourly rate."
                      />
                    </div>
                  )}
                </>
              )}
              <FormItem>
                <Field
                  name="deliveryDate"
                  label="Delivery Date (Optional)"
                  type="date"
                  placeholder="yyyy-mm-dd"
                  min={formatDateForCalendar(new Date())}
                  max={formatDateForCalendar(addDays(new Date(), 365))}
                  component={CustomInputComponent}
                />
              </FormItem>
              {props.projectContractType !== ContractType.FixedPrice && (
                <FormItem>
                  <Field
                    name="hourlyRate"
                    label="Hourly Rate for future work"
                    type="number"
                    min="0"
                    max="500"
                    step="1"
                    component={CustomInputCurrencyComponent}
                  />
                </FormItem>
              )}

              <FormItem>
                <Field
                  name="message"
                  label="Message"
                  placeholder="An optional message to send with your bid"
                  component={CustomTextAreaComponent}
                />
              </FormItem>

              <div className="text-sm mt-2 flex justify-end">
                <LinkButton type="button" onClick={() => setOpen(props.projectCreatorId)}>
                  View Conversation
                </LinkButton>
              </div>

              {bid?.status !== BidStatus.Accepted && bid?.status !== BidStatus.Rejected ? (
                <FormItem>
                  <ModalDualButtons
                    button1={
                      <Button styleType="primary" block type="submit" disabled={isSubmitting}>
                        Bid
                      </Button>
                    }
                    button2={
                      <Button styleType="white" block onClick={() => props.hideModal()}>
                        Cancel
                      </Button>
                    }
                  />
                </FormItem>
              ) : (
                <BidResult status={bid?.status} />
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
