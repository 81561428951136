import React from 'react'
import gql from 'graphql-tag'
import { Field, Formik } from 'formik'
import {
  ProjectFragment,
  useCreateProjectFigmaMutation,
  FigmaScreenInput,
  CreateProjectFigmaInput,
} from '../generated/graphql'
import {
  CustomChooseCardComponent,
  CustomInputWithButtonComponent,
} from '../components/FormikCustom'
import { FormItem } from '../components/FormItem'
import { StepComponentProps, useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { Pagination } from '../components/Pagination'
import { Button } from '../components/Button'
import { useFigma, connectToFigma, figmaUrlToFileId, getPageScreens } from '../utils/figma'
import { Loading } from '../components/Loading'
import { PROJECT_FRAGMENT } from './Projects'
import { ConnectTo } from '../components/ConnectTo'
import { notUndefined } from '../utils/common'
import { useStorage } from '../utils/useStorage'
import { trackEvent } from '../utils/analytics'
import { SelectComponents } from '../components/SelectComponents'
import { DEV_MODE } from '../utils/config'

gql`
  mutation CreateProjectFigma($projectId: ID!, $data: CreateProjectFigmaInput!) {
    createProjectFigma(projectId: $projectId, data: $data) {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`

interface NewProjectFigmaProps extends StepComponentProps {
  project?: ProjectFragment
}

export const NewProjectFigma: React.FC<NewProjectFigmaProps> = props => {
  const { project } = props
  const query = useSearchQuery()
  const projectId = query.get('projectId')
  const { authenticated, loadingAuth, file, fileId, images, connect, getFile } = useFigma()

  const callback = React.useCallback(() => {
    if (!authenticated) connect()
  }, [authenticated, connect])
  useStorage(callback)

  const figmaFileId = project?.figmaFileId

  React.useEffect(() => {
    if (authenticated && figmaFileId) getFile(figmaFileId)
  }, [authenticated, getFile, figmaFileId])

  const [createProjectFigma] = useCreateProjectFigmaMutation()

  if (!projectId) return null

  const figmaScreensMap = Object.fromEntries(
    project?.figmaScreens?.map(screen => {
      return [screen.figmaId, true]
    }) || []
  )

  const canSubmit = !!((fileId && images) || DEV_MODE)

  return (
    <Card title="Figma">
      <Formik<
        Partial<CreateProjectFigmaInput> & {
          fileUrl?: string
          figmaFileId?: string
          figmaPageId?: string
          figmaScreensMap: { [key: string]: boolean }
        }
      >
        initialValues={{
          figmaFileId: project?.figmaFileId || undefined,
          figmaPageId: undefined,
          figmaScreensMap,
          fileUrl: project?.figmaFileId
            ? `https://www.figma.com/file/${project?.figmaFileId}`
            : undefined,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!canSubmit) return

          const screensArray = file?.document.children
            .map(page => {
              return page.children.map(child => ({ ...child, pageId: page.id }))
            })
            .flat()

          const variables = {
            projectId,
            data: {
              figmaFileId: figmaUrlToFileId(values.fileUrl),
              figmaScreens: Object.entries(values.figmaScreensMap)
                .filter(([screenId, value]) => value)
                .map(([screenId]) => {
                  const screen = screensArray?.find(s => s?.id === screenId)

                  if (!screen) return undefined

                  const input: FigmaScreenInput = {
                    figmaId: screen.id,
                    name: screen.name,
                    figmaOriginalUrl: images?.[screen.id] || '',
                    figmaPageId: screen.pageId,
                  }

                  if (!input.figmaOriginalUrl) return undefined

                  return input
                })
                .filter(notUndefined),
            },
          }
          await createProjectFigma({ variables })
          trackEvent('Create project - Figma', variables)
          // setSubmitting(false)
          props.increaseStep?.()
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, setFieldValue, values }) => {
          return (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              {loadingAuth ? (
                <Loading />
              ) : authenticated ? (
                <SelectFigmaFile
                  figmaFileId={figmaUrlToFileId(values.fileUrl)}
                  figmaPageId={values.figmaPageId}
                  figmaScreensMap={values.figmaScreensMap}
                  setFieldValue={setFieldValue}
                />
              ) : (
                <ConnectTo
                  name="Figma"
                  onClick={() => {
                    trackEvent('Click connect to Figma')
                    connectToFigma()
                  }}
                />
              )}
              <Pagination
                isSubmitting={isSubmitting}
                onNextClick={props.lastStep ? undefined : () => handleSubmit()}
                onPreviousClick={props.firstStep ? undefined : props.decreaseStep}
                disabled={!canSubmit}
              />
            </form>
          )
        }}
      </Formik>
    </Card>
  )
}

interface SelectFigmaFileProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  figmaFileId: string
  figmaPageId?: string
  figmaScreensMap: { [key: string]: boolean }
}

const SelectFigmaFile: React.FC<SelectFigmaFileProps> = React.memo(props => {
  const { setFieldValue, figmaFileId, figmaPageId, figmaScreensMap } = props
  const { file, getFile, getFrameScreenshots, loadingFile, disconnect, images } = useFigma()
  const screens = getPageScreens(file, figmaPageId)

  const selectAll = () => {
    setFieldValue('figmaScreensMap', {
      ...figmaScreensMap,
      ...Object.fromEntries(screens.map(screen => [screen.id, true])),
    })
  }

  const unselectAll = () => {
    setFieldValue('figmaScreensMap', {
      ...figmaScreensMap,
      ...Object.fromEntries(screens.map(screen => [screen.id, false])),
    })
  }

  const onInputChange = React.useCallback(
    (val: string) => {
      const value = val.trim()
      if (value) {
        setFieldValue('fileUrl', value)
        setFieldValue('figmaPageId', undefined)
        getFile(value)
      }
    },
    [getFile, setFieldValue]
  )

  return (
    <>
      <FormItem>
        <Field
          name="fileUrl"
          label={
            <div className="flex justify-between items-center">
              Please paste in the link or id for the Figma file you'd like to use.
              <div className="flex">
                <Button
                  styleType="link"
                  size="sm"
                  onClick={() => window.open('https://www.figma.com/files/recent')}
                >
                  Recent Figma Files
                </Button>
                <Button styleType="link" size="sm" onClick={() => disconnect()}>
                  Switch Figma Account
                </Button>
              </div>
            </div>
          }
          component={CustomInputWithButtonComponent}
          // onClickSelect={onInputChange}
          onInputChange={onInputChange}
        />
      </FormItem>

      {loadingFile && <Loading />}

      {!loadingFile && !!file && (
        <FormItem>
          <Field
            name="figmaPageId"
            label="Page"
            options={file.document.children.map(page => ({
              value: page.id,
              label: page.name,
            }))}
            component={CustomChooseCardComponent}
            onChange={(value: string) => {
              getFrameScreenshots({
                fileId: figmaFileId,
                nodeIds: getPageScreens(file, value).map(screen => screen.id) || [],
              })
            }}
          />
        </FormItem>
      )}

      <SelectComponents
        label="Which screens would you like to be developed?"
        screens={screens.map(screen => {
          return {
            ...screen,
            image: { original_url: screen.id },
            // notes: '',
          }
        })}
        getImageUrl={screen => images?.[screen.id] || ''}
        selectAll={selectAll}
        unselectAll={unselectAll}
        selectedScreens={figmaScreensMap}
        onClickComponent={screenId => {
          setFieldValue('figmaScreensMap', {
            ...figmaScreensMap,
            [screenId]: !figmaScreensMap[screenId],
          })
        }}
        getExternalUrl={screen => `https://www.figma.com/file/${figmaFileId}/?node-id=${screen.id}`}
        readonly={false}
        showNotes={false}
      />
    </>
  )
})
