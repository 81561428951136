import gql from 'graphql-tag'
import { DeveloperFragment } from '../../generated/graphql'
import { StepComponentProps } from '../../utils/location'

export interface StepProps extends StepComponentProps {
  developer: DeveloperFragment
}

export const DEVELOPER_FRAGMENT = gql`
  fragment Developer on User {
    id

    firstName
    lastName
    country
    city
    street
    state
    zip

    programmingLanguages
    frontendFrameworks
    stylingLibraries

    storybookLevel
    uiLevel
    figmaLevel

    yearsOfExperience
    yearsOfExperienceReact
    yearsOfExperienceReactNative
    yearsOfExperienceVue
    yearsOfExperienceAngular
    yearsOfExperienceSvelte
    yearsOfExperienceWordpress
    yearsOfExperienceJavascript
    yearsOfExperienceTypescript
    yearsOfExperienceFlow
  }
`
