import React from 'react'
import { gql } from '@apollo/client'
import { Formik, Field } from 'formik'
import * as Sentry from '@sentry/browser'
import { Button, ButtonProps } from '../components/Button'
import { Tag } from '../components/Tag'
import { FormItem } from '../components/FormItem'
import {
  CreateInterviewInput,
  useCreateInterviewDeployedProjectMutation,
  useDeleteDeployedProjectMutation,
  useInterviewDevProjectQuery,
  useSubmitInterviewMutation,
  useAdminDeleteRemoteProjectMutation,
  InterviewStatus,
  useGetInterviewQuery,
  DeployedProjectFragment,
  GetUserDocument,
  ProjectDocument,
  useUserWithIntroVideoQuery,
} from '../generated/graphql'
import { CustomSelectComponent } from '../components/FormikCustom'
import { Loading } from '../components/Loading'
import { Card, CardsWrapper, CardTitleWithRightText } from '../components/Card'
import { CustomLinkExternal } from '../components/Typography'
import { PageLayout } from '../components/PageLayout'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../components/Notification'
import { trackEvent } from '../utils/analytics'
import { useUserContext } from '../auth/userContext'
import { ErrorPage } from '../components/ErrorPage'
import { Alert } from '../components/Alert'
import { DEV_MODE, IS_DEVELOPMENT } from '../utils/config'
import { formatGraphQLError } from '../utils/error'
import { INTERVIEW_FRAGMENT } from './DeveloperOnboarding/DeveloperOnboarding'
import { Modal, ModalDualButtons, ModalHeader } from '../components/Modal'
import { ReactComponent as ClockIcon } from '../icons/clock.svg'
import { EnglishAssessment } from './DeveloperProfile'
import { ConnectToGitHubButton, GitHubConnected } from './GitHub'
import { useUnsetGithubUsername } from '../utils/github'

const INTERVIEW_PROJECT_TYPES = [
  'React/TypeScript',
  'React/JavaScript',
  // 'ReactNative/TypeScript',
  // 'ReactNativeWeb/JavaScript', // not fully working
  // 'Vue/TypeScript',
  // 'Angular/TypeScript',
]

export const DEPLOYED_PROJECT_FRAGMENT = gql`
  fragment DeployedProject on DeployedProject {
    id
    githubUrl
    netlifyUrl
    developerId
  }
`

gql`
  ${INTERVIEW_FRAGMENT}

  query GetInterview {
    interview {
      ...Interview
    }
  }
`

const GET_INTERVIEW = gql`
  query InterviewDevProject {
    interviewDevProject {
      id
      deployedProject {
        ...DeployedProject
      }
    }
  }

  ${DEPLOYED_PROJECT_FRAGMENT}
`

gql`
  mutation CreateInterviewDeployedProject($data: CreateInterviewInput!) {
    createInterviewDeployedProject(data: $data) {
      ...DeployedProject
    }
  }

  ${DEPLOYED_PROJECT_FRAGMENT}
`

gql`
  mutation DeleteDeployedProject($deployedProjectId: String!) {
    deleteDeployedProject(deployedProjectId: $deployedProjectId)
  }
`

gql`
  mutation SubmitInterview {
    submitInterview {
      id
      devProjectStatus
      videoResponseStatus
    }
  }
`

gql`
  mutation AdminDeleteRemoteProject(
    $projectType: String!
    $interview: Boolean!
    $projectName: String
  ) {
    adminDeleteRemoteProject(
      projectType: $projectType
      interview: $interview
      projectName: $projectName
    )
  }
`

interface InterviewProjectProps {}

export const InterviewProject: React.FC<InterviewProjectProps> = props => {
  const history = useHistory()
  const { user } = useUserContext()
  const { data: userData } = useUserWithIntroVideoQuery()
  const { data, loading, error } = useInterviewDevProjectQuery()
  const { data: dataInterview } = useGetInterviewQuery()
  const [submitInterview] = useSubmitInterviewMutation()
  const [unsetGitHubUsername] = useUnsetGithubUsername()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error(error)
    return <ErrorPage error={error} />
  }

  const { interviewDevProject } = data

  const underReview = user?.interviewStatus === InterviewStatus.Completed
  const passed = user?.interviewStatus === InterviewStatus.Passed

  const connectedGithub = !!user?.githubUsername
  const startedStorybook = !!interviewDevProject?.deployedProject?.githubUrl
  const completedBidProject = !!dataInterview?.interview?.completedBidProject

  const canSubmitInterview = IS_DEVELOPMENT
    ? completedBidProject
    : connectedGithub && startedStorybook && completedBidProject

  return (
    <PageLayout
      title="Interview Project"
      description="In order to be accepted for projects on FrontWork you must complete two interview stages. You can do them in any order you choose."
      headerDark
      headerLarge
    >
      <CardsWrapper>
        {underReview && (
          <div className="shadow-sm">
            <Alert
              type="success"
              title="Under Review"
              description="Your interview is currently under review. You will be eligible to take on client projects after passing the interview process."
            />
          </div>
        )}
        {!underReview && passed && (
          <div className="shadow-sm">
            <Alert
              type="success"
              title="Passed"
              description="Congrats! You passed the FrontWork interview process. You are now able to take on client projects!"
            />
          </div>
        )}

        <Card
          titleComponent={
            <InterviewCardTitle
              title="1. Connect to GitHub"
              time="1 minute"
              completed={connectedGithub}
            />
          }
          description={
            <>
              <div className="mb-4">
                This allows us to automatically generate a GitHub repo for you for the interview and
                add you to it.
              </div>
              <div>
                <GitHubConnected
                  githubUsername={user?.githubUsername}
                  unsetUsername={() => {
                    unsetGitHubUsername()
                  }}
                />
              </div>
            </>
          }
        />

        <Card
          titleComponent={
            <InterviewCardTitle
              title="2. Storybook Project"
              time="~90 minutes"
              completed={underReview}
            />
          }
          description={
            <div>
              Implement a{' '}
              <span className="font-semibold">
                <CustomLinkExternal
                  href="https://storybook.js.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storybook
                </CustomLinkExternal>
              </span>{' '}
              project. The repo will be automatically created and deployed once you start the
              project.
            </div>
          }
        >
          <ProjectDetails
            deployedProject={interviewDevProject?.deployedProject}
            devProjectId={dataInterview?.interview?.devProjectId || ''}
          />
        </Card>

        <Card
          titleComponent={
            <InterviewCardTitle
              title="3. Bid on a Project"
              time="~5 minutes"
              completed={completedBidProject}
            />
          }
          description="Make a bid on a demo project. This gives us a chance to understand how you interact with clients as well as help onboard you to the platform."
          action={{
            text: 'View Project',
            url: `/project/${dataInterview?.interview?.bidProjectId}`,
          }}
        />

        <Card
          titleComponent={
            <InterviewCardTitle
              title="4. Short Video Introduction (Optional)"
              time="10 minutes"
              completed={!!userData?.getUser.introVideoUrl}
            />
          }
          description={<EnglishAssessment />}
        />

        {/* Video interviews are turned off for now */}
        {/* <Card
          titleComponent={
            <InterviewCardTitle
              title="4. Short Video Interview"
              time="15 minutes"
              completed={
                dataInterview?.interview?.videoResponseStatus === VideoReponseStatus.Submitted
              }
            />
          }
          description="A few basic interview questions for us to get an understanding of your level of English, communication skills, and judgement skills. The interview is over video but is not live."
          action={{
            text: 'Video Interview',
            url: '/developer/interview-video',
          }}
        /> */}

        {!!interviewDevProject && !underReview && (
          <div className="flex justify-end">
            <Button
              styleType="primary"
              disabled={!canSubmitInterview}
              onClick={async () => {
                if (!interviewDevProject) return

                // eslint-disable-next-line no-restricted-globals
                const yes = confirm(
                  `Once you submit, this project will be checked for quality. Are you sure you'd like to submit?`
                )

                if (yes) {
                  await submitInterview({ refetchQueries: [{ query: GetUserDocument }] })
                  trackEvent('Complete interview project')

                  history.push('/developer/waitlist')
                } else {
                  trackEvent('Cancelled complete interview project')
                }
              }}
            >
              Submit Interview
            </Button>
          </div>
        )}
      </CardsWrapper>
    </PageLayout>
  )
}

interface ProjectDetailsProps {
  deployedProject?: DeployedProjectFragment | null
  devProjectId: string
}

export const ProjectDetails: React.FC<ProjectDetailsProps> = props => {
  const { user } = useUserContext()
  const history = useHistory()
  const [deleteDeployedProject] = useDeleteDeployedProjectMutation()
  const [showStartProjectModal, setShowStartProjectModal] = React.useState(false)

  const { deployedProject, devProjectId } = props

  return deployedProject?.githubUrl && deployedProject.netlifyUrl ? (
    <div>
      <div>
        <span className="text-sm font-medium leading-5 text-gray-700">GitHub repo: </span>
        <CustomLinkExternal href={deployedProject.githubUrl} target="_blank">
          {deployedProject.githubUrl}
        </CustomLinkExternal>
      </div>
      <div className="mt-1">
        <span className="text-sm font-medium leading-5 text-gray-700">Deployed version: </span>
        <CustomLinkExternal
          href={deployedProject.netlifyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {deployedProject.netlifyUrl}
        </CustomLinkExternal>
        <p className="mt-4 max-w-2xl text-sm leading-5 text-gray-500">
          The deployed version will take a few minutes to create the first time and automatically
          redeploys each time you push to master.
        </p>
      </div>

      <div className="mt-4 flex">
        <div className="mr-2">
          <Button
            styleType="secondary"
            onClick={() => {
              history.push(`/project/${devProjectId}`)
            }}
          >
            View Project
          </Button>
        </div>
        {DEV_MODE && user?.admin ? (
          <span className="mr-2">
            <Button
              styleType="danger"
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                const yes = confirm(
                  'This will delete the GitHub repo and deployed Storybook. Are you sure you want to delete this project?'
                )

                if (yes) {
                  const variables = { deployedProjectId: deployedProject.id }
                  deleteDeployedProject({
                    variables,
                    refetchQueries: [{ query: GET_INTERVIEW }],
                  })
                  trackEvent('Delete interview project', variables)
                }
              }}
            >
              Delete
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="flex">
      <div className="mr-2">
        <Button
          styleType="secondary"
          onClick={() => {
            const path = `/project/${devProjectId}`
            if (!path) {
              const message = 'devProjectId is null when clicking View Project'
              console.error(message)
              Sentry.captureException(new Error(message))
              alert('There was an error. Pleaser refresh the page and try again.')
            }
            history.push(path)
          }}
        >
          View Project
        </Button>
      </div>

      <Button styleType="primary" type="button" onClick={() => setShowStartProjectModal(true)}>
        Start Project
      </Button>

      {showStartProjectModal && (
        <Modal fullWidth hideModal={() => setShowStartProjectModal(false)}>
          <StartProjectModalContent
            projectId={devProjectId}
            hideModal={() => setShowStartProjectModal(false)}
          />
        </Modal>
      )}
    </div>
  )
}

export const InterviewCardTitle: React.FC<{
  title: string
  time?: string
  completed: boolean
}> = props => {
  return (
    <CardTitleWithRightText
      title={
        <>
          {props.title}{' '}
          {props.completed && (
            <div className="inline-block ml-1">
              <Tag color="green">Complete</Tag>
            </div>
          )}
        </>
      }
      rightItem={props.time}
      rightIcon={props.time ? <ClockIcon /> : null}
    />
  )
}

interface StartProjectModalContentProps {
  projectId: string
  hideModal: () => void
}

export const StartProjectModalContent: React.FC<StartProjectModalContentProps> = props => {
  const [createInterviewDeployedProject] = useCreateInterviewDeployedProjectMutation()
  const [deleteRemoteProject] = useAdminDeleteRemoteProjectMutation()
  const { user } = useUserContext()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <div>
      <ModalHeader title="Start Storybook Project" />

      <div className="my-6">
        <div className="prose prose-indigo text-gray-700">
          <p>
            Before you start the interview here are some important tips on how to pass. We'll judge
            you on:
          </p>

          <ol>
            <li>UI quality</li>
            <li>Code quality</li>
            <li>Reusability</li>
            <li>Sound judgment</li>
          </ol>
          <p>
            The UI should be close to pixel perfect. The code should be clear and simple. Someone
            should be able to make use of your components without having to make edits to them,
            similar to Ant Design or Bootstrap. Use props as appropriate. Use sound judgement to
            decide on appropriate responsivity and animations.
          </p>
          <p>This is the level of quality we expect on every FrontWork client project.</p>
          <p>
            Deliver your components using Storybook. It will be set up for you and automatically
            deploy to Netlify when you click start.
          </p>
        </div>
      </div>

      {!user?.githubUsername && (
        <div className="mt-4">
          <Alert
            type="danger"
            title="Connect GitHub"
            description={
              <>
                <div className="mb-2">Please connect your GitHub account to start the project.</div>
                <ConnectToGitHubButton buttonType="white" />
              </>
            }
          />
        </div>
      )}

      <Formik<CreateInterviewInput>
        initialValues={{ projectType: INTERVIEW_PROJECT_TYPES[0] }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)
          try {
            showSuccessNotification({
              message: 'Creating Project',
              description:
                'This will take a few seconds. Netlify deploy url will take a few minutes to be ready',
            })
            const res = await createInterviewDeployedProject({
              variables: { data: values },
              refetchQueries: [
                { query: GET_INTERVIEW },
                { query: ProjectDocument, variables: { id: props.projectId } },
              ],
            })
            trackEvent('Start interview dev project', values)
            console.log('res', res)
            showSuccessNotification({
              description: 'Successfully created GitHub repo and Netlify deployment',
            })
          } catch (error) {
            console.error(error)
            showErrorNotification({ description: formatGraphQLError(error) })
          } finally {
            setSubmitting(false)
            props.hideModal()
          }
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <FormItem>
              <Field
                name="projectType"
                label="Which template would you like to use?"
                options={INTERVIEW_PROJECT_TYPES.map(type => ({
                  value: type,
                  label: type,
                }))}
                component={CustomSelectComponent}
              />
            </FormItem>

            {DEV_MODE && user?.admin ? (
              <div className="mt-2">
                <Button
                  styleType="danger"
                  onClick={async () => {
                    // eslint-disable-next-line no-restricted-globals
                    const yes = confirm(
                      'This will delete the GitHub repo and deployed Netlify Storybook. Are you sure you want to continue?'
                    )

                    if (yes) {
                      const variables = { interview: true, projectType: values.projectType }
                      await deleteRemoteProject({
                        variables,
                        refetchQueries: [{ query: GET_INTERVIEW }],
                      })
                      trackEvent('Delete remote interview project', variables)
                    }
                  }}
                >
                  Delete Remote Project
                </Button>
              </div>
            ) : null}

            <div className="mt-6">
              <ModalDualButtons
                button1={
                  <Button
                    styleType="primary"
                    block
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !user?.githubUsername}
                    data-cy="confirm-start-project"
                  >
                    Start Project
                  </Button>
                }
                button2={
                  <Button styleType="white" block onClick={() => props.hideModal()}>
                    Cancel
                  </Button>
                }
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
