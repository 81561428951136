import React from 'react'
import classNames from 'classnames'

export interface AlertProps {
  title?: string
  description?: React.ReactNode
  type: 'success' | 'info' | 'warning' | 'danger' | 'indigo'
}

export const Alert: React.FC<AlertProps> = props => {
  return (
    <div
      className={classNames('rounded-md p-4', {
        'bg-green-50': props.type === 'success',
        'bg-blue-50': props.type === 'info',
        'bg-yellow-50': props.type === 'warning',
        'bg-red-50': props.type === 'danger',
        'bg-indigo-50': props.type === 'indigo',
      })}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <div
            className={classNames('h-5 w-5', {
              'text-green-400': props.type === 'success',
              'text-blue-400': props.type === 'info',
              'text-yellow-400': props.type === 'warning',
              'text-red-400': props.type === 'danger',
              'text-indigo-400': props.type === 'indigo',
            })}
          >
            {props.type === 'success' && <SuccessIcon />}
            {props.type === 'info' && <InfoIcon />}
            {props.type === 'warning' && <WarningIcon />}
            {props.type === 'danger' && <DangerIcon />}
            {props.type === 'indigo' && <InfoIcon />}
          </div>
        </div>
        <div className="ml-3">
          {props.title && (
            <h3
              className={classNames('text-sm leading-5 font-medium', {
                'text-green-800': props.type === 'success',
                'text-blue-800': props.type === 'info',
                'text-yellow-800': props.type === 'warning',
                'text-red-800': props.type === 'danger',
                'text-indigo-800': props.type === 'indigo',
              })}
            >
              {props.title}
            </h3>
          )}
          {props.description && (
            <div
              className={classNames('mt-2 text-sm leading-5', {
                'text-green-700': props.type === 'success',
                'text-blue-700': props.type === 'info',
                'text-yellow-700': props.type === 'warning',
                'text-red-700': props.type === 'danger',
                'text-indigo-700': props.type === 'indigo',
              })}
            >
              <p>{props.description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SuccessIcon: React.FC<{}> = props => {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const InfoIcon: React.FC<{}> = props => {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const WarningIcon: React.FC<{}> = props => {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const DangerIcon: React.FC<{}> = props => {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clipRule="evenodd"
      />
    </svg>
  )
}
