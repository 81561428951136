import React from 'react'
import ReactRating from 'react-rating'
import { ReactComponent as StarIcon } from '../icons/star.svg'
import { ReactComponent as StarSolidIcon } from '../icons/star-solid.svg'
import { Label } from './Label'

export interface RatingProps {
  initialRating?: number
  label?: string
  readonly?: boolean
  onChange?: (value: number) => void
}

export const Rating: React.FC<RatingProps> = props => {
  return (
    <>
      {props.label ? (
        <div className="mb-1">
          <Label>{props.label}</Label>
        </div>
      ) : null}
      <ReactRating
        initialRating={props.initialRating}
        readonly={props.readonly}
        onChange={props.onChange}
        emptySymbol={<StarIcon className="w-8 h-8 text-indigo-600" />}
        fullSymbol={<StarSolidIcon className="w-8 h-8 text-indigo-600" />}
      />
    </>
  )
}
