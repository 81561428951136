import React from 'react'
import { Link } from 'react-router-dom'
import { serverAmountToUSD } from '../utils/currency'
import { Card } from './Card'

export interface ComponentGridProps {
  components: Array<{
    id: string
    name: string
    price: number
    description?: string | null
    thumbnail?: string | null
    rating?: number | null
  }>
  showRating?: boolean
}

export const ComponentGrid: React.FC<ComponentGridProps> = props => {
  return (
    <ul className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
      {props.components.map(component => (
        <li key={component.id} className="relative">
          <Link to={`/component/${component.id}`}>
            <Card>
              <div className="group block pb-2 rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img
                  src={component.thumbnail || 'No thumbnail'}
                  alt={component.thumbnail ? '' : 'No thumbnail'}
                  className="object-cover pointer-events-none group-hover:opacity-75 rounded-md mx-auto max-h-96"
                />
                <button type="button" className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {component.name}</span>
                </button>
              </div>
              <div className="flex mt-2">
                <div className="flex-1">
                  <p className="block text-sm font-medium text-gray-900 truncate pointer-events-none">
                    {component.name}
                  </p>
                  {component.description && (
                    <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                      {component.description}
                    </p>
                  )}
                </div>
                <p className="flex items-center text-sm font-medium text-gray-500 pointer-events-none">
                  {serverAmountToUSD(component.price)}
                </p>
              </div>
              {props.showRating && (
                <div className="mt-2 flex items-center text-sm font-medium text-indigo-700 pointer-events-none">
                  Rating: {component.rating || 'No rating'}
                </div>
              )}
            </Card>
          </Link>
        </li>
      ))}
    </ul>
  )
}
