import React from 'react'

export const useStorage = (onStorage: () => void) => {
  React.useEffect(() => {
    window.addEventListener('storage', onStorage, false)

    return () => {
      window.removeEventListener('storage', onStorage, false)
    }
  }, [onStorage])
}
