import { gql } from '@apollo/client'
import React from 'react'
import * as Sentry from '@sentry/browser'
import { useHistory, useParams } from 'react-router-dom'
import { DownloadIcon, ExternalLinkIcon, TrashIcon } from '@heroicons/react/solid'
import { Loading } from '../../components/Loading'
import { PageLayout } from '../../components/PageLayout'
import {
  useComponentQuery,
  useCreateStripeCheckoutSessionMutation,
  useGetRepoDownloadLinkLazyQuery,
  useDeleteComponentMutation,
  DeveloperComponentsDocument,
  useAdminSetComponentApprovalStatusMutation,
  ComponentFragment,
  ComponentApprovalStatus,
  useUnlockComponentMutation,
} from '../../generated/graphql'
import { ErrorPage } from '../../components/ErrorPage'
import { Card, CardsWrapper, CardTitle } from '../../components/Card'
import { PencilIcon } from '@heroicons/react/solid'
import { HeaderButton, PageHeaderExtra } from '../../components/PageHeader'
import { serverAmountToUSD } from '../../utils/currency'
import { ComponentScreenshots } from '../../components/ComponentScreenshots'
import {
  FrontendFrameworkTag,
  ProgrammingLanguageTag,
  StylingLibraryTag,
  Tag,
} from '../../components/Tag'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../../utils/skills'
import { CustomLinkExternal, Paragraph } from '../../components/Typography'
import { trackEvent } from '../../utils/analytics'
import { Button, ButtonLink } from '../../components/Button'
import { useSearchQuery } from '../../utils/location'
import { useUserContext } from '../../auth/userContext'
import { DeveloperAdminProfile } from '../DeveloperProfile'
import { useNotification } from '../../components/Notification'
import { formatGraphQLError } from '../../utils/error'
import { ButtonGroup } from '../../components/ButtonGroup'
import { Alert } from '../../components/Alert'
import { RateComponent } from './RateComponent'
import { Modal, ModalHeader, ModalVerticalButtons } from '../../components/Modal'
import { STANDARD_SUBSCRIPTION_AMOUNT, STANDARD_SUBSCRIPTION_CREDITS } from '../../utils/config'

gql`
  fragment Component on Component {
    id
    programmingLanguages
    frontendFrameworks
    stylingLibraries
    name
    description
    demoUrl
    price
    categories {
      name
    }
    daysToDeliver
    componentScreenshots {
      id
      url
    }
    thumbnail
    userId
    isCreator
    deliveryUrl
    gitHubRepo
    gitHubInstallationId
    allGitHubRepoFiles
    files {
      path
      url
    }
    hasAccess
    approvalStatus
    rating
  }
`

gql`
  query Component($id: ID!) {
    component(id: $id) {
      ...Component
    }
  }
`

gql`
  mutation DeleteComponent($componentId: ID!) {
    deleteComponent(componentId: $componentId)
  }
`

gql`
  mutation UnlockComponent($componentId: ID!) {
    unlockComponent(componentId: $componentId) {
      ...Component
    }
  }
`

gql`
  mutation AdminSetComponentApprovalStatus($status: ComponentApprovalStatus!, $id: ID!) {
    adminSetComponentApprovalStatus(status: $status, id: $id) {
      id
      approvalStatus
    }
  }
`

gql`
  mutation CreateStripeCheckoutSession($data: PurchaseComponentInput!) {
    createStripeCheckoutSession(data: $data)
  }
`

gql`
  query GetRepoDownloadLink($componentId: String!) {
    repoZipLink(componentId: $componentId)
  }
`

interface ComponentProps {}

export const Component: React.FC<ComponentProps> = props => {
  const { componentId } = useParams<{ componentId: string }>()
  const { user } = useUserContext()
  const history = useHistory()
  const searchQuery = useSearchQuery()
  const immediatePurchase = searchQuery.get('buy')
  const [purchaseModalOpen, setPurchaseModalOpen] = React.useState(!!immediatePurchase)

  const { showSuccessNotification, showErrorNotification } = useNotification()

  const { data, loading, error, refetch } = useComponentQuery({ variables: { id: componentId } })
  const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation({
    variables: { data: { componentId } },
  })

  const [deleteComponent] = useDeleteComponentMutation({ variables: { componentId } })
  const onDelete = React.useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    const yes = confirm('Are you sure you want to delete this component?')

    if (yes) {
      await deleteComponent({ refetchQueries: [{ query: DeveloperComponentsDocument }] })
      history.push(`/components`)
    }
  }, [deleteComponent, history])

  const onPurchase = React.useCallback(async () => {
    const link = await createStripeCheckoutSession()
    if (link.data) {
      const popup = window.open(
        link.data.createStripeCheckoutSession,
        'Purchase Component',
        'resizable,scrollbars,status'
      )
      ;(window as any).onPurchaseCompleted = async () => {
        refetch()
        trackEvent('Component Purchase Completed')
        popup?.close()
      }
    }
  }, [createStripeCheckoutSession, refetch])

  const [unlockComponent] = useUnlockComponentMutation({ variables: { componentId } })
  const onUnlock = React.useCallback(async () => {
    try {
      await unlockComponent()

      showSuccessNotification({ description: 'Component unlocked!' })
    } catch (error) {
      console.error(error)
      showErrorNotification({ description: 'There was an error unlocking the component' })
    }
  }, [showErrorNotification, showSuccessNotification, unlockComponent])

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { component } = data

  const editButton: HeaderButton = {
    text: 'Edit',
    icon: (
      <span className="text-gray-300">
        <PencilIcon />
      </span>
    ),
    type: 'secondary',
    hideOnMobile: false,
    onClick: () => {
      history.push(`/upload-component/${componentId}`)
    },
  }

  const deleteButton: HeaderButton = {
    text: 'Delete',
    icon: (
      <span className="text-gray-300">
        <TrashIcon />
      </span>
    ),
    type: 'secondary',
    hideOnMobile: true,
    onClick: onDelete,
  }

  const purchaseButton: HeaderButton = {
    text: 'Purchase',
    icon: null,
    type: 'primary',
    hideOnMobile: false,
    onClick: () => setPurchaseModalOpen(true),
  }

  const unlockComponentButton: HeaderButton = {
    text: 'Unlock',
    icon: null,
    type: 'primary',
    hideOnMobile: false,
    onClick: onUnlock,
  }

  const purchased = !component.isCreator && component.hasAccess

  function getButtons(): HeaderButton[] {
    const creatorButtons = [editButton, deleteButton]

    if (component.isCreator) return creatorButtons
    
    if (user?.admin) return [...creatorButtons, unlockComponentButton, purchaseButton]

    // already purchased
    if (component.hasAccess) return []
    // subscriber
    if (user?.componentCredits) return [unlockComponentButton, purchaseButton]
    // not subscribed
    return [purchaseButton]
  }

  return (
    <PageLayout
      title={component.name}
      header={
        <PageHeaderExtra
          dark
          title={component.name}
          buttons={getButtons()}
          rightContent={
            // copied from bid amount on project page
            <div className="flex flex-col justify-center pr-6 text-md text-white text-right">
              <h2 className="text-2xl font-bold leading-7 sm:text-3xl sm:leading-9 sm:truncate space-x-4">
                {(purchased || user?.admin) && (
                  <DownloadComponent
                    componentId={componentId}
                    deliveryUrl={component.deliveryUrl}
                  />
                )}
                {(!purchased || user?.admin) && <span>{serverAmountToUSD(component.price)}</span>}
              </h2>
            </div>
          }
          description={
            // based off of description on project page
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
              <div className="sm:flex sm:space-x-2 space-y-2 sm:space-y-0 mt-2">
                {component.frontendFrameworks?.map(frontendFramework => {
                  return (
                    <FrontendFrameworkTag key={frontendFramework}>
                      {formatFrontendFramework(frontendFramework)}
                    </FrontendFrameworkTag>
                  )
                })}
                {component.programmingLanguages?.map(programmingLanguage => {
                  return (
                    <ProgrammingLanguageTag key={programmingLanguage}>
                      {formatProgrammingLanguage(programmingLanguage)}
                    </ProgrammingLanguageTag>
                  )
                })}
                {component.stylingLibraries?.map(stylingLibrary => {
                  return (
                    <StylingLibraryTag key={stylingLibrary}>
                      {formatStylingLibrary(stylingLibrary)}
                    </StylingLibraryTag>
                  )
                })}
              </div>
            </div>
          }
        />
      }
    >
      <CardsWrapper>
        {component.approvalStatus === ComponentApprovalStatus.Rejected && (
          <Alert
            type="danger"
            title="Rejected"
            description="This component has been rejected. Please contact support at hi@frontwork.dev to understand why and to have it reinlisted."
          />
        )}

        <Card>
          <div className="space-y-2">
            {component.description && <Paragraph>{component.description}</Paragraph>}
            <div className="flex space-x-2">
              <div>Tags:</div>
              {component.categories.map(category => {
                return (
                  <Tag key={category.name} color="green">
                    {category.name}
                  </Tag>
                )
              })}
            </div>
            {!!component.demoUrl && (
              <Paragraph>
                <div className="flex space-x-2">
                  <div>Demo link:</div>
                  <CustomLinkExternal href={component.demoUrl} target="_blank" rel="noreferrer">
                    {component.demoUrl}
                  </CustomLinkExternal>
                </div>
              </Paragraph>
            )}
            {!!component.deliveryUrl && (
              <Paragraph>
                <div className="flex space-x-2">
                  <div>Delivery url:</div>
                  <CustomLinkExternal href={component.deliveryUrl} target="_blank" rel="noreferrer">
                    {component.deliveryUrl}
                  </CustomLinkExternal>
                </div>
              </Paragraph>
            )}
          </div>
        </Card>

        <Card title="Screenshots">
          <ComponentScreenshots screenshots={component.componentScreenshots || []} />
        </Card>

        {user?.admin && <RateComponent component={component} />}
        {user?.admin && <AdminSection component={component} onDelete={onDelete} />}
      </CardsWrapper>

      {purchaseModalOpen && (
        <Modal hideModal={() => setPurchaseModalOpen(false)}>
          <ModalHeader title="Purchase Component" />
          <div className="my-6">
            <Paragraph>
              Would you like to make a one time purchase or subscribe for $
              {STANDARD_SUBSCRIPTION_AMOUNT} per month for {STANDARD_SUBSCRIPTION_CREDITS} monthly
              component unlock credits?
            </Paragraph>
          </div>
          <ModalVerticalButtons
            buttons={[
              <Button styleType="black" block type="submit" onClick={onPurchase}>
                Purchase for {serverAmountToUSD(component.price)}
              </Button>,
              <ButtonLink to="/subscribe" styleType="primary" block>
                Subscribe for ${STANDARD_SUBSCRIPTION_AMOUNT} / mo
              </ButtonLink>,
              <Button styleType="white" block onClick={() => setPurchaseModalOpen(false)}>
                Cancel
              </Button>,
            ]}
          />
        </Modal>
      )}
    </PageLayout>
  )
}

interface DownloadComponentProps {
  componentId: string
  deliveryUrl?: string | null
}

export const DownloadComponent: React.FC<DownloadComponentProps> = props => {
  const { componentId, deliveryUrl } = props

  const [getDownloadLink, { data: downloadLinkData, error }] = useGetRepoDownloadLinkLazyQuery({
    variables: { componentId },
  })

  const { showErrorNotification } = useNotification()

  React.useEffect(() => {
    if (downloadLinkData?.repoZipLink) {
      window.open(downloadLinkData.repoZipLink)
    }
  }, [downloadLinkData])

  React.useEffect(() => {
    if (error) {
      console.error(error)
      showErrorNotification({ description: formatGraphQLError(error) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <Button
      onClick={() => {
        if (deliveryUrl) {
          window.open(deliveryUrl, '_blank')
        } else {
          getDownloadLink()
        }
      }}
      icon={deliveryUrl ? <ExternalLinkIcon /> : <DownloadIcon />}
      onDark
    >
      {deliveryUrl ? 'View Code' : 'Download Code'}
    </Button>
  )
}

interface AdminSectionProps {
  component: ComponentFragment
  onDelete: () => void
}

export const AdminSection: React.FC<AdminSectionProps> = props => {
  const { component, onDelete } = props

  const [setApprovalStatus] = useAdminSetComponentApprovalStatusMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <>
      <Card title="Admin">
        <div className="space-x-2 flex">
          <Button styleType="danger" onClick={onDelete}>
            Delete Component
          </Button>

          <DownloadComponent componentId={component.id} deliveryUrl={component.deliveryUrl} />
        </div>
        <div>
          <div className="mt-6 mb-2">
            <CardTitle>Status</CardTitle>
          </div>
          <ButtonGroup
            buttons={[
              { value: ComponentApprovalStatus.Approved, label: 'Approved' },
              { value: ComponentApprovalStatus.Pending, label: 'Pending' },
              { value: ComponentApprovalStatus.Rejected, label: 'Rejected' },
            ]}
            selected={component.approvalStatus}
            onClick={async status => {
              try {
                await setApprovalStatus({
                  variables: {
                    status: status as ComponentApprovalStatus,
                    id: component.id,
                  },
                })
                showSuccessNotification({
                  description: `Successfully updated status to ${status}`,
                })
              } catch (error) {
                console.error(error)
                showErrorNotification({ description: formatGraphQLError(error) })
              }
            }}
          />
        </div>
      </Card>

      <DeveloperAdminProfile developerId={component.userId} />
    </>
  )
}
