import { Field, Formik } from 'formik'
import gql from 'graphql-tag'
import React from 'react'
import { Card } from '../../components/Card'
import { CustomInputComponent } from '../../components/FormikCustom'
import { useNotification } from '../../components/Notification'
import { Pagination } from '../../components/Pagination'
import {
  Developer,
  UpdateDeveloperExperienceInput,
  useUpdateDeveloperExperienceMutation,
} from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import { formatGraphQLError } from '../../utils/error'
import { StepComponentProps } from '../../utils/location'
import { findSkillForExperience, formatSkill, SKILL_TO_EXPERIENCE } from '../../utils/skills'
import { DEVELOPER_FRAGMENT, StepProps } from './common'

gql`
  mutation UpdateDeveloperExperience($data: UpdateDeveloperExperienceInput!) {
    updateDeveloperExperience(data: $data) {
      ...Developer
    }
  }

  ${DEVELOPER_FRAGMENT}
`

interface ExperienceProps extends StepComponentProps {
  developer: Pick<Developer, 'frontendFrameworks' | 'programmingLanguages' | 'yearsOfExperience'>
  submitButton?: (options: { isSubmitting: boolean }) => React.ReactNode
}

export const Experience: React.FC<ExperienceProps> = props => {
  const [updateExperience] = useUpdateDeveloperExperienceMutation()
  const { showErrorNotification } = useNotification()

  const { developer } = props

  const skills = [
    ...(developer.frontendFrameworks || []),
    ...(developer.programmingLanguages || []),
  ]

  // only show experience for skills the user says they have
  const experiences: string[] = skills.map(skill => SKILL_TO_EXPERIENCE[skill])

  const { yearsOfExperience } = developer

  const developerYearsOfExperiences = Object.fromEntries(
    experiences.map(experience => {
      return [experience, (developer as any)[experience] as number]
    })
  )

  return (
    <Card title="Experience" description="How many years of experience do you have?">
      <Formik<UpdateDeveloperExperienceInput>
        enableReinitialize
        initialValues={{ yearsOfExperience, ...developerYearsOfExperiences }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)
          try {
            await updateExperience({ variables: { data: values } })
            trackEvent('Submit experience', values)
            props.increaseStep?.()
          } catch (error) {
            console.error(error)
            showErrorNotification({ description: formatGraphQLError(error) })
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="space-y-6 sm:space-y-5 border-b pb-4">
              <div className="sm:grid sm:grid-cols-2 sm:items-start sm:pt-5">
                {/* TODO may be better to have this as a custom formik component for other inline forms */}
                <label
                  htmlFor="yearsOfExperience"
                  className="block text-sm font-medium sm:mt-px sm:pt-2"
                >
                  Total As a Developer
                </label>
                <div className="flex justify-end">
                  <div className="w-20">
                    <Field
                      name="yearsOfExperience"
                      component={CustomInputComponent}
                      type="number"
                      min={0}
                      max={50}
                      step={0.5}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid">
              {experiences.map(experience => (
                <div className="space-y-6 sm:space-y-5" key={experience}>
                  <div className="sm:grid sm:grid-cols-2 sm:items-start sm:pt-5">
                    <label
                      htmlFor={experience}
                      className="block text-sm font-medium sm:mt-px sm:pt-2"
                    >
                      {formatSkill(findSkillForExperience(experience))}
                    </label>
                    <div className="flex justify-end">
                      <div className="w-20">
                        <Field
                          name={experience}
                          component={CustomInputComponent}
                          type="number"
                          min={0}
                          max={50}
                          step={0.5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-8 border-t border-gray-200">
              {props.submitButton ? (
                props.submitButton({ isSubmitting })
              ) : (
                <Pagination
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting || !values.yearsOfExperience}
                  onNextClick={props.increaseStep && (() => handleSubmit())}
                  onPreviousClick={props.decreaseStep}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
    </Card>
  )
}
