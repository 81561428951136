import * as React from 'react'
import classNames from 'classnames'
import { FieldProps } from 'formik'
import styled from 'styled-components/macro'
import { Input, InputWithButton, TextArea, InputCurrency } from './Input'
import { Select } from './Select'
import { Checkbox } from './Checkbox'
import { Label } from './Label'
import { Radio } from './Radio'
import { Toggle, ToggleProps } from './Toggle'
import { Rating } from './Rating'
import { SelectMulti } from './SelectMulti'

const ErrorMessage = styled.div.attrs({
  className: 'mt-2 text-sm text-red-600',
})``

export const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
  onChange?: (value: string) => void
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <Input
        type="text"
        {...field}
        {...props}
        error={!!error}
        onChange={e => {
          // setFieldValue(field.name, value.currentTarget.value)
          props.onChange?.(e.currentTarget.value)
          return field.onChange(e)
        }}
      />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomInputWithButtonComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
  onClickSelect?: (value: string) => void
  onInputChange?: (value: string) => void
}) => {
  const error = touched[field.name] && errors[field.name]
  const { onInputChange, onClickSelect, ...otherProps } = props

  return (
    <div>
      {/* <InputWithButton */}
      <Input
        type="text"
        {...field}
        {...otherProps}
        error={!!error}
        // onClickSelect={value => {
        //   onClickSelect?.(value)
        // }}
        onChange={e => {
          field.onChange(e)
          onInputChange?.(e.currentTarget.value)
        }}
      />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomTextAreaComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <TextArea rows={4} error={!!error} {...field} {...props} />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomInputCurrencyComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  disabled?: boolean
  placeholder?: string
  active?: boolean
  containerStyle?: any
  light?: boolean
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <InputCurrency error={!!error} {...field} {...props} />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  options: Array<{ value: string; label: string }>
  style?: React.CSSProperties
  onChange?: (value: string) => void
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <Select
        {...field}
        {...props}
        onChange={value => {
          setFieldValue(field.name, value.currentTarget.value)
          props.onChange?.(value.currentTarget.value)
        }}
        value={field.value}
        options={props.options}
      />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomMultiSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label: string
  options: Array<{ value: string; label: string }>
  style?: React.CSSProperties
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      {props.label && (
        <div className="mb-2">
          <Label htmlFor={field.name}>{props.label}</Label>
        </div>
      )}
      <SelectMulti
        {...field}
        {...props}
        onChange={values => {
          setFieldValue(field.name, values)
        }}
        value={field.value}
        options={props.options}
      />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomCheckboxComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  id: string
  label: string
  disabled?: boolean
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <Checkbox {...field} {...props} />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomToggleComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & ToggleProps & { label?: string }) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <Toggle
        {...props}
        rightText={props.label}
        enabled={field.value}
        onChange={() => {
          setFieldValue(field.name, !field.value)
        }}
      />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomRadioComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  id: string
  label: string
  disabled?: boolean
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <Radio {...field} {...props} />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

export const CustomChooseCardComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  label?: string
  options: Array<{ value: string; label: string }>
  style?: React.CSSProperties
  onChange?: (value: string) => void
  multiselect?: boolean
  maxColumns?: number
}) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <>
      {props.label && (
        <div className="mb-2">
          <Label htmlFor={field.name}>{props.label}</Label>
        </div>
      )}
      <div
        className={classNames(
          `grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-${props.maxColumns || 4}`
        )}
      >
        {props.options.map(option => {
          const isSelected = props.multiselect
            ? field.value.includes(option.value)
            : option.value === field.value

          return (
            <button
              key={option.value}
              type="button"
              className={classNames(
                'text-sm rounded shadow py-8 px-2 cursor-pointer focus:ring-indigo hover:shadow-lg transition-shadow duration-200 ease-in-out border-4 border-gray-50',
                {
                  'border-indigo-500 bg-indigo-100 font-semibold': isSelected,
                }
              )}
              onClick={() => {
                if (props.multiselect) {
                  const newValues = isSelected
                    ? field.value.filter((v: string) => v !== option.value)
                    : [...field.value, option.value]
                  setFieldValue(field.name, newValues)
                } else {
                  setFieldValue(field.name, option.value)
                }

                props.onChange?.(option.value)
              }}
            >
              {option.label}
            </button>
          )
        })}
      </div>
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </>
  )
}

export const CustomRatingComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & ToggleProps) => {
  const error = touched[field.name] && errors[field.name]

  return (
    <div>
      <Rating
        {...props}
        {...field}
        initialRating={field.value}
        onChange={value => {
          setFieldValue(field.name, value)
        }}
      />
      {error && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}
