import React from 'react'
import Annotation, { IAnnotation } from 'react-image-annotation'
import {
  PointSelector,
  RectangleSelector,
  OvalSelector,
} from 'react-image-annotation/lib/selectors'
import { Button } from './Button'

interface ImageAnnotateProps {
  image: string
  onSubmit: (data: IAnnotation) => void
  annotations: IAnnotation[]
  annotation?: IAnnotation
}

export const ImageAnnotate: React.FC<ImageAnnotateProps> = props => {
  const { image, onSubmit, annotations } = props
  const [state, setState] = React.useState<{
    annotation: IAnnotation | {}
    tool: { TYPE: string }
  }>({
    annotation: {},
    tool: RectangleSelector,
  })
  const { tool } = state

  const onSubmitAnnotation = React.useCallback(
    annotation => {
      const { geometry, data } = annotation

      onSubmit({ data, geometry })
    },
    [onSubmit]
  )

  const onChange = React.useCallback(
    value => setState(state => ({ ...state, annotation: value })),
    []
  )

  const activeAnnotationComparator = React.useCallback((a: IAnnotation, b: string) => {
    return a.data.id === b
  }, [])

  const setTool = React.useCallback((tool: any) => setState(state => ({ ...state, tool })), [])

  return (
    <>
      <div className="mb-2">
        <ImageAnnotateToolbar tool={tool} setTool={setTool} />
      </div>
      <Annotation
        src={image}
        alt="Component"
        annotations={annotations}
        type={tool.TYPE}
        value={state.annotation}
        onChange={onChange}
        onSubmit={onSubmitAnnotation}
        allowTouch
        activeAnnotations={props.annotation?.data.id ? [props.annotation.data.id] : undefined}
        activeAnnotationComparator={activeAnnotationComparator}
      />
    </>
  )
}

interface ImageAnnotateToolbarProps {
  tool: any
  setTool: (tool: any) => void
}

export const ImageAnnotateToolbar: React.FC<ImageAnnotateToolbarProps> = props => {
  const { tool, setTool } = props

  return (
    <div>
      <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">Annotate</div>
      <div className="flex mt-1">
        <div className="mr-1">
          <Button
            size="xs"
            styleType={tool === RectangleSelector ? 'primary' : 'secondary'}
            onClick={() => setTool(RectangleSelector)}
          >
            {RectangleSelector.TYPE}
          </Button>
        </div>
        <div className="mr-1">
          <Button
            size="xs"
            styleType={tool === PointSelector ? 'primary' : 'secondary'}
            onClick={() => setTool(PointSelector)}
          >
            {PointSelector.TYPE}
          </Button>
        </div>
        <div className="mr-1">
          <Button
            size="xs"
            styleType={tool === OvalSelector ? 'primary' : 'secondary'}
            onClick={() => setTool(OvalSelector)}
          >
            {OvalSelector.TYPE}
          </Button>
        </div>
      </div>
    </div>
  )
}
