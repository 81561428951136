import React from 'react'

export interface PaginationListProps {
  start: number
  end: number
  total: number
  onPrevious: () => void
  onNext: () => void
}

export const PaginationList: React.FC<PaginationListProps> = props => {
  const { start, end, total } = props

  return (
    <nav className="bg-white pl-6 py-3 flex items-center justify-between" aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{start}</span> to{' '}
          <span className="font-medium">{end}</span> of <span className="font-medium">{total}</span>{' '}
          results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={props.onPrevious}
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={props.onNext}
        >
          Next
        </a>
      </div>
    </nav>
  )
}
