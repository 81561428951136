import React from 'react'
import { Button } from './Button'
import { InPortal } from './InPortal'
import { ReactComponent as CloseIcon } from '../icons/close.svg'
import { Transition } from '@headlessui/react'

export interface SlideOverProps {
  title: string
  children: React.ReactNode
  footer?: React.ReactNode
  open: boolean
  fixedHeader?: boolean
  onClose?: () => void
  onSave?: () => void
}

export const SlideOver: React.FC<SlideOverProps> = props => {
  if (!props.open) return null

  const header = (
    <header className="px-4 sm:px-6">
      <div className="flex items-start justify-between space-x-3">
        <h2 className="text-lg leading-7 font-medium text-gray-900">{props.title}</h2>
        <div className="h-7 flex items-center">
          <button
            aria-label="Close panel"
            className="text-gray-400 hover:text-gray-500 transition"
            onClick={props.onClose}
          >
            <CloseIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </header>
  )

  return (
    <InPortal id="slide-over-root">
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="fixed inset-0 transition-opacity">
            {/* bg-black looks better? */}
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={props.onClose}></div>
          </div>

          <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition
              show={props.open}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              className="w-screen max-w-lg"
            >
              <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                {props.fixedHeader && <div className="py-6">{header}</div>}

                <div className="h-0 flex flex-col flex-1 pt-6 space-y-6 overflow-y-scroll">
                  {!props.fixedHeader && header}
                  <div className="relative flex-1">{props.children}</div>
                </div>

                {props.footer}

                {props.onSave ? (
                  <div className="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                    <Button styleType="white">Cancel</Button>
                    <Button type="submit" onSubmit={props.onSave}>
                      Save
                    </Button>
                  </div>
                ) : null}
              </div>
            </Transition>
          </section>
        </div>
      </div>
    </InPortal>
  )
}
