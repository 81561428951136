import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Tag } from '../components/Tag'
import { formatDateLong, formatDateForCalendar } from '../utils/date'
import { formatBidsText } from '../utils/bid'
import { formatStatus } from '../utils/project'
import { BidStatus, ProjectFragment, ProjectStatus } from '../generated/graphql'
import { serverAmountToUSD } from '../utils/currency'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../utils/skills'
import { CodeIcon, MoonIcon, CalendarIcon } from '@heroicons/react/solid'

interface ProjectItemClientProps {
  project: Pick<
    ProjectFragment,
    | 'id'
    | 'name'
    | 'frontendFramework'
    | 'status'
    | 'programmingLanguage'
    | 'styling'
    | 'deadline'
    | 'interviewBidProject'
    | 'interviewDevProject'
  > &
    Partial<Pick<ProjectFragment, 'bids'>>
  firstItem?: boolean
  showStatus: boolean
  bidAmount?: number
  bidStatus?: BidStatus
}

export const ProjectItemClient: React.FC<ProjectItemClientProps> = props => {
  const { project, firstItem } = props

  return (
    <li key={project.id} className={classNames({ 'border-t border-gray-200': !firstItem })}>
      <Link
        to={`/project/${project.id}`}
        className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition"
      >
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <div className="text-sm leading-5 font-medium text-indigo-600 truncate flex">
              {project.name}
              {(project.interviewDevProject || project.interviewBidProject) && (
                <div className="ml-1">
                  <Tag color="pink">
                    Interview {project.interviewDevProject ? 'Dev' : 'Bid'} Project
                  </Tag>
                </div>
              )}
            </div>
            <div className="flex space-x-1">
              <Tag color="indigo">{formatFrontendFramework(project.frontendFramework)}</Tag>
              {props.showStatus && (
                <Tag color={project.status === ProjectStatus.Searching ? 'green' : 'blue'}>
                  {formatStatus(project.status)}
                </Tag>
              )}
              {props.bidAmount && <Tag color="green">{serverAmountToUSD(props.bidAmount)}</Tag>}
              {props.bidStatus === BidStatus.Rejected && <Tag color="red">Rejected</Tag>}
              {!!project.bids?.length && project.status === ProjectStatus.Searching && (
                <Tag color="orange">{formatBidsText(project.bids?.length || 0)}</Tag>
              )}
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              {project.programmingLanguage && (
                <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                  <CodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                  {formatProgrammingLanguage(project.programmingLanguage)}
                </div>
              )}
              {project.styling && (
                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                  <MoonIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                  {formatStylingLibrary(project.styling)}
                </div>
              )}
            </div>
            <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
              {project.deadline ? (
                <span>
                  Deadline:{' '}
                  <time dateTime={formatDateForCalendar(new Date(project.deadline))}>
                    {formatDateLong(new Date(project.deadline))}
                  </time>
                </span>
              ) : (
                <span>No deadline</span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}
