import React from 'react'
import styled from 'styled-components'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

export const Paragraph = styled.p.attrs({
  className: 'text-sm leading-5 text-gray-500',
})``

export const HeadingLarge = styled.h2.attrs({
  className:
    'text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10',
})``

export const LinkButton = styled.button.attrs({
  className: 'text-indigo-600 hover:text-indigo-500 transition',
})``

export const CustomLinkExternal: React.FC<any> = React.forwardRef((props, ref: React.Ref<any>) => {
  return (
    <a
      {...props}
      ref={ref}
      className="font-medium text-indigo-600 hover:text-indigo-500 transition"
    >
      {props.children}
    </a>
  )
})

export const CustomLink: React.FC<LinkProps> = props => {
  return <ReactRouterLink {...props} component={CustomLinkExternal} />
}

interface TextHeaderProps {
  title: string
  subtitle?: string
}

export const TextHeader: React.FC<TextHeaderProps> = props => {
  return (
    <div className="text-center">
      <HeadingLarge className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
        {props.title}
      </HeadingLarge>
      {props.subtitle && (
        <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
          {props.subtitle}
        </p>
      )}
    </div>
  )
}
