import React from 'react'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useUserContext } from '../auth/userContext'
import { PageLayout } from '../components/PageLayout'
import { Button } from '../components/Button'
import { Paragraph } from '../components/Typography'
import { useNotification } from '../components/Notification'
import {
  useGetInterviewQuery,
  useCompleteVideoInterviewMutation,
  VideoReponseStatus,
} from '../generated/graphql'
import { Loading } from '../components/Loading'
import { useHistory } from 'react-router-dom'
import { Card } from '../components/Card'
import { INTERVIEW_FRAGMENT } from './DeveloperOnboarding/DeveloperOnboarding'
import { ErrorPage } from '../components/ErrorPage'

gql`
  ${INTERVIEW_FRAGMENT}

  mutation CompleteVideoInterview {
    completeVideoInterview {
      ...Interview
    }
  }
`

interface InterviewVideoProps {}

export const InterviewVideo: React.FC<InterviewVideoProps> = props => {
  const { user } = useUserContext()
  const [iframeLoading, setIframeLoading] = React.useState(true)
  const { data, loading, error } = useGetInterviewQuery()
  const [
    completeVideoInterview,
    { loading: completeInterviewLoading },
  ] = useCompleteVideoInterviewMutation()
  const history = useHistory()
  const { showErrorNotification } = useNotification()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  let url = `https://www.videoask.com/fmqjoc9j0?contact_email=${user?.email.replace('+', '%2B')}`

  if (user?.firstName && user.lastName) url += `&contact_name=${user.firstName}%20${user.lastName}`

  return (
    <PageLayout title="Video Interview" headerDark headerLarge>
      {data.interview?.videoResponseStatus === VideoReponseStatus.Submitted ? (
        <Card title="Complete">
          <Paragraph>
            Thank you for submitting your video interview. We will review it shortly.
          </Paragraph>
          <div className="mt-4">
            <Button
              onClick={() => {
                history.push('/developer/interview-project')
              }}
            >
              Continue Process
            </Button>
          </div>
        </Card>
      ) : (
        <>
          <div className="mb-4">
            <Button
              onClick={async () => {
                const result = await completeVideoInterview()

                if (
                  result.data?.completeVideoInterview.videoResponseStatus !==
                  VideoReponseStatus.Submitted
                ) {
                  showErrorNotification({
                    description: `We have yet to receive your response. If you're sure you've submitted it please try again in a few seconds`,
                  })
                }
              }}
              disabled={completeInterviewLoading}
              size="lg"
              block
              loading={completeInterviewLoading}
            >
              Submit Video
            </Button>
          </div>
          {iframeLoading && <Loading />}
          <iframe
            title="Video Interview"
            src={url}
            allow="camera; microphone; autoplay; encrypted-media;"
            width="100%"
            height="600px"
            style={{ border: 'none', borderRadius: 24 }}
            onLoad={() => setIframeLoading(false)}
          ></iframe>
        </>
      )}
    </PageLayout>
  )
}
