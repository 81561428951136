import React from 'react'
import gql from 'graphql-tag'
import { Field, Formik } from 'formik'
import {
  useCreateProjectAdvancedMutation,
  CreateProjectAdvancedInput,
  ProjectFragment,
  ContractType,
} from '../generated/graphql'
import { FormItem } from '../components/FormItem'
import { StepComponentProps, useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { Pagination } from '../components/Pagination'
import { PROJECT_FRAGMENT } from './Projects'
// import { CustomCodeEditorComponent } from '../components/CodeEditor'
import { CustomChooseCardComponent } from '../components/FormikCustom'
import { trackEvent } from '../utils/analytics'
import { programmingLanguagesProject, stylingLibrariesProject } from '../utils/skills'

gql`
  mutation CreateProjectAdvanced($projectId: ID!, $data: CreateProjectAdvancedInput!) {
    createProjectAdvanced(projectId: $projectId, data: $data) {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`

interface NewProjectAdvancedProps extends StepComponentProps {
  project?: ProjectFragment
}

export const NewProjectAdvanced: React.FC<NewProjectAdvancedProps> = props => {
  // const prettierRef = React.useRef(null)
  // const esLintRef = React.useRef(null)
  const query = useSearchQuery()
  const projectId = query.get('projectId')

  const [createProjectAdvanced] = useCreateProjectAdvancedMutation()

  return (
    <Card title="Advanced">
      <Formik<CreateProjectAdvancedInput>
        initialValues={{
          programmingLanguage: props.project?.programmingLanguage,
          styling: props.project?.styling,
          contractType: props.project?.contractType,
          englishLevelRequired: props.project?.englishLevelRequired,
          // esLintConfig: props.project?.eslintConfig,
          // prettierConfig: props.project?.prettierConfig,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!projectId) return

          // const prettierValueGetter = prettierRef as any
          // const esLintValueGetter = esLintRef as any
          // const prettierConfig = prettierValueGetter.current()
          // const esLintConfig = esLintValueGetter.current()

          const variables = {
            projectId,
            data: {
              ...values,
              // prettierConfig,
              // esLintConfig,
            },
          }
          await createProjectAdvanced({ variables })
          trackEvent('Create project - advanced', variables)
          // setSubmitting(false)
          props.increaseStep?.()
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <FormItem>
              <Field
                name="programmingLanguage"
                label="Language"
                options={programmingLanguagesProject}
                component={CustomChooseCardComponent}
              />
            </FormItem>

            <FormItem>
              <Field
                name="styling"
                label="Styling"
                options={stylingLibrariesProject}
                component={CustomChooseCardComponent}
              />
            </FormItem>

            <FormItem>
              <Field
                name="englishLevelRequired"
                label="Does the developer need to have good English?"
                options={[
                  { value: 5, label: 'Strong spoken English' },
                  { value: 4, label: 'Okay spoken English' },
                  { value: 3, label: 'Only needs to be able to communicate in writing' },
                ]}
                component={CustomChooseCardComponent}
              />
            </FormItem>

            <FormItem>
              <Field
                name="contractType"
                label="Would you like the developer to work on an hourly or fixed-price basis?"
                options={[
                  { value: ContractType.FixedPrice, label: 'Fixed-price' },
                  {
                    value: ContractType.FixedPriceThenHourly,
                    label: 'Fixed-price with the option to move to hourly afterwards',
                  },
                  { value: ContractType.Hourly, label: 'Hourly' },
                ]}
                component={CustomChooseCardComponent}
              />
            </FormItem>

            {/* <FormItem>
              <Field
                name="prettier"
                label="Prettier"
                value={props.project?.prettierConfig || `{}`}
                editorRef={prettierRef}
                component={CustomCodeEditorComponent}
              />
            </FormItem>

            <FormItem>
              <Field
                name="esLint"
                label="ES Lint"
                value={props.project?.eslintConfig || `{}`}
                editorRef={esLintRef}
                component={CustomCodeEditorComponent}
              />
            </FormItem> */}

            <Pagination
              isSubmitting={isSubmitting}
              onNextClick={props.lastStep ? undefined : () => handleSubmit()}
              onPreviousClick={props.firstStep ? undefined : props.decreaseStep}
              disabled={!values.programmingLanguage || !values.styling}
            />
          </form>
        )}
      </Formik>
    </Card>
  )
}
