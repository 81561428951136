import React from 'react'

interface ContainerBasicProps {}

export const ContainerBasic: React.FC<ContainerBasicProps> = props => {
  return (
    <main
      className="bg-gray-100 flex-1 h-full"
      // style={{ minHeight: '85vh' }}
    >
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 h-full">{props.children}</div>
    </main>
  )
}
