import React from 'react'
import classNames from 'classnames'

interface FormSectionProps {
  title: string
  description?: string
  fullWidth?: boolean
}

export const FormSection: React.FC<FormSectionProps> = props => {
  return (
    <div className="mt-6 md:mt-5 md:border-t md:border-gray-200 md:pt-5">
      <fieldset>
        <div className="md:grid md:grid-cols-3 md:gap-4">
          <div>
            <legend className="text-base leading-6 font-medium text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700">
              {props.title}
            </legend>
            {props.description && (
              <p className="mt-1 text-sm leading-5 text-gray-500">{props.description}</p>
            )}
          </div>

          <div className="mt-4 md:mt-0 md:col-span-2">
            <div className={classNames({ 'max-w-lg': !props.fullWidth })}>{props.children}</div>
          </div>
        </div>
      </fieldset>
    </div>
  )
}
