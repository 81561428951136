// on the server $13.00 is expressed as 1300 to avoid dealing with decimals
// on the server we santise amounts to remove cents
export const serverAmountToAmount = (amount: number) => amount / 100
export const amountToServerAmount = (amount: number) => amount * 100
export const serverAmountToUSD = (amount: number) => {
  const clientAmount = serverAmountToAmount(amount)

  // so that 150 cents shows as $1.50 and not $1.5
  if (amount % 100) return `$${clientAmount.toFixed(2)}`

  return `$${clientAmount.toLocaleString()}`
}
export const serverAmountToPayPalAmount = (serverAmount: number) =>
  serverAmountToAmount(serverAmount).toFixed(2)
