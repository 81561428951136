import React from 'react'
import classNames from 'classnames'
import { ModalPortal } from './ModalPortal'

export interface ModalProps {
  hideModal: () => void
  fullWidth?: boolean
}

export const Modal: React.FC<ModalProps> = props => {
  return (
    <ModalPortal>
      <div className="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-6 sm:flex sm:items-center sm:justify-center h-full md:h-auto overflow-auto">
        {/* <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
        <div className="fixed inset-0 transition-opacity">
          {/* bg-black looks better? */}
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={props.hideModal}></div>
        </div>

        {/* <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  --> */}
        <div
          className={classNames(
            `bg-white rounded-lg px-4 pt-5 pb-4 mt-4 overflow-auto shadow-xl transform transition-all sm:p-6`,
            { 'sm:max-w-lg sm:w-full': !props.fullWidth }
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {props.children}
        </div>
      </div>
    </ModalPortal>
  )
}

interface ModalHeaderProps {
  title: string
}

export const ModalHeader: React.FC<ModalHeaderProps> = props => {
  return (
    <h3 className="text-center text-lg leading-6 font-medium text-gray-900" id="modal-headline">
      {props.title}
    </h3>
  )
}

interface ModalDualButtonsProps {
  button1: React.ReactNode
  button2: React.ReactNode
}

export const ModalDualButtons: React.FC<ModalDualButtonsProps> = props => {
  return (
    <div className="sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
      <span className="flex w-full sm:col-start-2">{props.button1}</span>
      <span className="mt-3 flex w-full sm:mt-0 sm:col-start-1">{props.button2}</span>
    </div>
  )
}

interface ModalTripleButtonsProps {
  button1: React.ReactNode
  button2: React.ReactNode
  button3: React.ReactNode
  vertical?: boolean
}

export const ModalTripleButtons: React.FC<ModalTripleButtonsProps> = props => {
  if (props.vertical) return <div className="grid"></div>

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
      <span className="flex w-full sm:col-start-3">{props.button1}</span>
      <span className="mt-3 flex w-full sm:mt-0 sm:col-start-2">{props.button2}</span>
      <span className="mt-3 flex w-full sm:mt-0 sm:col-start-1">{props.button3}</span>
    </div>
  )
}

interface ModalVerticalButtonsProps {
  buttons: React.ReactNode[]
}

export const ModalVerticalButtons: React.FC<ModalVerticalButtonsProps> = props => {
  return (
    <div className="grid gap-2">
      {props.buttons.map((button, i) => {
        return <div key={i}>{button}</div>
      })}
    </div>
  )
}
