import React from 'react'
import { ScreensGrid, ScreensGridScreen } from '../components/ScreensGrid'
import { Label } from '../components/Label'
import { Button } from '../components/Button'
import { FormItem } from '../components/FormItem'

interface Screen {
  id: string
  name: string
  notes?: string
  image: { original_url: string }
}

interface SelectComponentsProps {
  label: string
  screens: Screen[]
  readonly: boolean
  showNotes?: boolean
  projectCreatorId?: string
  selectedScreens: { [key: string]: boolean }
  selectAll: () => void
  unselectAll: () => void
  onClickComponent: (screenId: string) => void
  getImageUrl: (screen: Screen) => string
  getExternalUrl: (screen: Screen) => string
}

export const SelectComponents: React.FC<SelectComponentsProps> = props => {
  const {
    label,
    screens,
    selectAll,
    unselectAll,
    selectedScreens,
    onClickComponent,
    getImageUrl,
    getExternalUrl,
    readonly,
    showNotes,
    projectCreatorId,
  } = props
  const [visible, setVisible] = React.useState(true)

  const toggleVisible = () => setVisible(!visible)

  if (!screens.length) return null

  return (
    <FormItem>
      <div className="flex justify-between items-center">
        <Label>{label}</Label>
        <div className="space-x-2">
          <Button styleType="secondary" onClick={toggleVisible}>
            {visible ? 'Collapse' : 'Expand'}
          </Button>
          <Button styleType="secondary" onClick={selectAll}>
            Select All
          </Button>
          <Button styleType="secondary" onClick={unselectAll}>
            Unselect All
          </Button>
        </div>
      </div>

      {visible && (
        <div className="mt-3">
          <ScreensGrid>
            {screens.map(screen => {
              const active = selectedScreens[screen.id]
              return (
                <ScreensGridScreen
                  key={screen.id}
                  screen={{
                    id: screen.id,
                    name: screen.name,
                    imageUrl: getImageUrl(screen),
                    notes: screen.notes,
                  }}
                  active={active}
                  onClick={() => onClickComponent(screen.id)}
                  externalUrl={getExternalUrl(screen)}
                  readonly={readonly}
                  showNotes={showNotes}
                  projectCreatorId={projectCreatorId}
                />
              )
            })}
          </ScreensGrid>
        </div>
      )}
    </FormItem>
  )
}
