import React from 'react'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { PageLayout } from '../components/PageLayout'
import { Card, CardWrapper } from '../components/Card'
import {
  useDeveloperDashboardQuery,
  DeveloperBidFragment,
  ProjectStatus,
} from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { ProjectItemClient } from '../components/ProjectItemClient'
import { Paragraph } from '../components/Typography'

gql`
  fragment DeveloperBid on Bid {
    id
    updatedAt
    amount
    developerId
    projectId
    project {
      id
      name
      status
      type
      frontendFramework
      programmingLanguage
      styling
      deadline
    }
    status
    deliveryDate
    message
  }

  query DeveloperDashboard {
    developerBids {
      ...DeveloperBid
    }
  }
`

interface DeveloperDashboardProps {}

export const DeveloperDashboard: React.FC<DeveloperDashboardProps> = props => {
  const { data, loading, error } = useDeveloperDashboardQuery({ fetchPolicy: 'cache-and-network' })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  return (
    <PageLayout title="Dashboard" headerDark headerLarge>
      <ProjectSection
        title="Active Projects"
        noProjects="You don't have any active projects."
        bids={data.developerBids.filter(bid => bid.project.status === ProjectStatus.InProgress)}
      />
      <CardWrapper>
        <ProjectSection
          title="Bids"
          noProjects="You don't have any open bids."
          bids={data.developerBids.filter(bid => bid.project.status === ProjectStatus.Searching)}
        />
      </CardWrapper>
      <CardWrapper>
        <ProjectSection
          title="Past Projects"
          noProjects="You don't have any past projects."
          bids={data.developerBids.filter(
            bid =>
              bid.project.status === ProjectStatus.Completed ||
              bid.project.status === ProjectStatus.Expired ||
              bid.project.status === ProjectStatus.Cancelled
          )}
        />
      </CardWrapper>
    </PageLayout>
  )
}

interface ProjectSectionProps {
  title: string
  noProjects: string
  bids: DeveloperBidFragment[]
}

export const ProjectSection: React.FC<ProjectSectionProps> = props => {
  const hasBids = !!props.bids.length

  return (
    <Card title={props.title} noContentPadding={hasBids}>
      <ul>
        {hasBids ? (
          props.bids.map((bid, i) => {
            return (
              <ProjectItemClient
                key={bid.id}
                project={bid.project}
                firstItem={!i}
                showStatus={false}
                bidAmount={bid.amount}
                bidStatus={bid.status}
              />
            )
          })
        ) : (
          <Paragraph>{props.noProjects}</Paragraph>
        )}
      </ul>
    </Card>
  )
}
