import React from 'react'
import { Button } from './Button'

export const ConnectTo: React.FC<{
  name: string
  onClick: () => void
}> = props => {
  return (
    <div className="flex h-64 items-center justify-center">
      <div>
        <Button styleType="secondary" onClick={props.onClick}>
          Connect to {props.name}
        </Button>
      </div>
    </div>
  )
}
