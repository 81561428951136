import React from 'react'
import * as Sentry from '@sentry/browser'
import { Link } from 'react-router-dom'
import { Button } from './Button'
import { formatDateAgo } from '../utils/date'
import {
  ConversationDocument,
  CreateCommentInput,
  useConversationQuery,
  useCreateCommentMutation,
  MessageFragment,
  BidMessageFragment,
} from '../generated/graphql'
import { gql } from '@apollo/client'
import { Field, Formik } from 'formik'
import classNames from 'classnames'
import { trackEvent } from '../utils/analytics'
import { CustomTextAreaComponent } from './FormikCustom'
import { Loading } from './Loading'
import { ErrorPage } from './ErrorPage'
import { Avatar } from './Avatar'
import { formatBidAmount } from '../utils/bid'
import { useUserContext } from '../auth/userContext'
import { SlideOver } from './SlideOver'

gql`
  fragment Message on Comment {
    id
    text
    createdAt
    creatorUsername
    isCreator
  }

  fragment BidMessage on Bid {
    id
    text: message
    createdAt
    amount
    hourlyRate
    projectId
    developer {
      id
      username
    }
  }

  query Conversation($otherUserId: ID!) {
    messages(otherUserId: $otherUserId) {
      ...Message
    }
    conversationBids(otherUserId: $otherUserId) {
      ...BidMessage
    }
  }
`

export interface MessagesProps {
  otherUserId: string
  userProfilePicture?: string
  pollInterval?: number
  onBidClick?: () => void
}

export const Messages: React.FC<MessagesProps> = props => {
  const { user } = useUserContext()
  const { data, loading, error } = useConversationQuery({
    variables: { otherUserId: props.otherUserId },
    pollInterval: props.pollInterval ?? 10_000,
  })
  const bottomRef = React.useRef<any>(null)

  const messagesCount = data?.messages.length

  React.useEffect(() => {
    if (bottomRef.current) bottomRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [messagesCount])

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  // TODO can be optimised (messages and bids come back ordered correctly)
  const messagesAndBidMessages: Array<MessageFragment | BidMessageFragment> = [
    ...data.messages,
    ...data.conversationBids,
  ].sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt))

  const totalMessages = messagesAndBidMessages.length

  return (
    <section aria-labelledby="notes-title" className="h-full">
      <div className="bg-white sm:overflow-hidden flex flex-col h-full">
        <div className="divide-y divide-gray-200 flex-1">
          <div className="px-4 pb-6 sm:px-6">
            <ul className="space-y-8">
              {messagesAndBidMessages.map((message, i) => {
                const creatorUsername =
                  'developer' in message ? message.developer.username : message.creatorUsername
                const isCreator =
                  'developer' in message ? message.developer.id === user?.id : message.isCreator
                const username = (isCreator ? 'You' : creatorUsername) || ''

                return (
                  <li
                    key={message.id}
                    ref={i === totalMessages - 1 ? bottomRef : undefined}
                    className={classNames({
                      'bg-gray-100 py-4 px-4 -mx-4 sm:px-6 sm:-mx-6': 'developer' in message,
                    })}
                  >
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <Avatar
                          letters={username.substr(0, 2)}
                          color={isCreator ? 'bg-blue-500' : 'bg-green-500'}
                        />
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-900">{username}</div>
                        <p className="mt-1 text-sm text-gray-700 whitespace-pre-wrap">
                          {'developer' in message && (
                            // not sure why CustomLink doesn't work nicely here
                            <Link
                              to={`/project/${message.projectId}?bidId=${message.id}`}
                              className="font-medium text-indigo-600 hover:text-indigo-500 transition"
                              onClick={props.onBidClick}
                            >
                              <div className="font-semibold mb-1">
                                {formatBidAmount(message)} bid:{' '}
                              </div>
                            </Link>
                          )}
                          {message.text}
                        </p>
                        <div className="mt-2 text-sm space-x-2">
                          <span className="text-gray-500 font-medium">
                            {formatDateAgo(new Date(message.createdAt))}
                          </span>
                          {/* <span className="text-gray-500 font-medium">&middot;</span>
                          <button type="button" className="text-gray-900 font-medium">
                            Reply
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export interface MessagesSendProps extends MessagesProps {}

export const MessagesSend: React.FC<MessagesSendProps> = props => {
  const [createComment] = useCreateCommentMutation()

  return (
    <div className="flex-shrink-0 justify-end bg-gray-50 px-4 py-6 sm:px-6">
      <div className="flex space-x-3">
        {props.userProfilePicture ? (
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={props.userProfilePicture} alt="" />
          </div>
        ) : null}
        <div className="min-w-0 flex-1">
          <Formik<CreateCommentInput>
            initialValues={{ text: '', otherUserId: props.otherUserId }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              await createComment({
                variables: { data: values },
                refetchQueries: [
                  {
                    query: ConversationDocument,
                    variables: { otherUserId: props.otherUserId },
                  },
                ],
              })
              trackEvent('Send message', values)
              setSubmitting(false)
              resetForm()
            }}
          >
            {({ isSubmitting, handleSubmit, handleReset }) => (
              <form onSubmit={handleSubmit} onReset={handleReset}>
                <Field name="text" component={CustomTextAreaComponent} rows={4} />
                <div className="mt-3 flex items-center justify-between">
                  <div className="flex-1"></div>
                  {/* <a
                    href="#"
                    className="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                  >
                    <QuestionMarkIcon className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    <span>Markdown is supported.</span>
                  </a> */}
                  <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
                    Send
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

interface MessagesSlideOverProps {
  open: boolean
  otherUserId: string
  onClose: () => void
}

export const MessagesSlideOver: React.FC<MessagesSlideOverProps> = props => {
  return (
    <SlideOver
      title="Messages"
      footer={<MessagesSend otherUserId={props.otherUserId} />}
      fixedHeader
      open={props.open}
      onClose={props.onClose}
    >
      <Messages otherUserId={props.otherUserId} onBidClick={props.onClose} />
    </SlideOver>
  )
}
