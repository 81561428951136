import React from 'react'
import { gql } from '@apollo/client'
import { PageLayout } from '../../components/PageLayout'
import {
  useCreateStripeAccountMutation,
  useCreateStripeDashboardLinkMutation,
} from '../../generated/graphql'
import { Button } from '../../components/Button'
import { useUserContext } from '../../auth/userContext'

gql`
  mutation CreateStripeAccount {
    link: createStripeAccount
  }
`

gql`
  mutation CreateStripeDashboardLink {
    link: createStripeDashboardLink
  }
`

export const DeveloperPaymentsStripe: React.FC<{}> = props => {
  const { user } = useUserContext()

  return (
    <PageLayout title={'Payments'} headerDark headerLarge>
      {user?.stripeConnectAccountId ? (
        <ConnectDashboardLink stripeConnectAccountId={user.stripeConnectAccountId} />
      ) : (
        <ConnectStripe />
      )}
    </PageLayout>
  )
}

const ConnectStripe: React.FC<{}> = props => {
  const [createStripeAccount, { data, loading, error }] = useCreateStripeAccountMutation()

  return (
    <Button
      loading={loading}
      onClick={async () => {
        const res = await createStripeAccount()

        if (res.data?.link) window.location.href = res.data.link
      }}
    >
      Connect with Stripe
    </Button>
  )
}

const ConnectDashboardLink: React.FC<{ stripeConnectAccountId: string }> = props => {
  const [
    createStripeDashboardLink,
    { data, loading, error },
  ] = useCreateStripeDashboardLinkMutation()

  return (
    <Button
      loading={loading}
      onClick={async () => {
        const res = await createStripeDashboardLink()

        if (res.data?.link) window.location.href = res.data.link
      }}
    >
      View Stripe Dashboard
    </Button>
  )
}
