import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Loading } from '../components/Loading'
import { useUserContext } from './userContext'

interface PrivateRouteProps {
  path: string
  exact?: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, path, exact }) => {
  const { user, loading } = useUserContext()

  if (loading) return <Loading />

  // TODO redirectToReferrer: https://tylermcginnis.com/react-router-protected-routes-authentication/
  if (!user) return <Redirect to="/log-in" />

  return <Route path={path} exact={exact} render={() => (user ? children : null)} />
}
