import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import classNames from 'classnames'
import { Button } from './Button'
import { CustomLink } from './Typography'

interface CardContainerProps {
  hoverable?: boolean
}

export const CardContainer = styled.div.attrs<CardContainerProps>(props => ({
  className: classNames('card bg-white shadow sm:rounded-lg', {
    'hover:shadow-xl transition-shadow duration-200 ease-in-out': props.hoverable,
  }),
}))<CardContainerProps>``

export const CardTitle = styled.h3.attrs({
  className: `text-lg leading-6 font-medium text-gray-900`,
})``

export const CardDescription = styled.p.attrs({
  className: `mt-2 max-w-2xl text-sm leading-5 text-gray-500`,
})``

interface CardTitleExtraProps {
  title: string
  extra: React.ReactNode
}

export const CardTitleExtra: React.FC<CardTitleExtraProps> = props => {
  return (
    <div className="flex">
      <div className="mr-4 flex-1">
        <CardTitle>{props.title}</CardTitle>
      </div>
      {props.extra}
    </div>
  )
}

export interface CardProps {
  title?: string
  titleComponent?: React.ReactNode
  description?: React.ReactNode
  link?: {
    text: string
    url: string
  }
  action?: {
    text: string
    url: string
  }
  hoverable?: boolean
  noContentPadding?: boolean
  noBottomPadding?: boolean
  children?: React.ReactNode
}

export const Card: React.FC<CardProps> = props => {
  return (
    <CardContainer hoverable={props.hoverable}>
      <div
        className={classNames('px-4 py-5 sm:p-6', {
          'pb-0 sm:pb-0': props.noBottomPadding,
        })}
      >
        {props.title ? <CardTitle>{props.title}</CardTitle> : null}
        {props.titleComponent}
        {props.description ? <CardDescription>{props.description}</CardDescription> : null}
        {props.children ? (
          <div
            className={classNames('text-sm leading-5 text-gray-500', {
              'mt-5': props.title || props.titleComponent || props.description,
              '-mx-4 sm:-mx-6': props.noContentPadding,
            })}
          >
            {props.children}
          </div>
        ) : null}
        {props.link ? (
          <div className="mt-3 text-sm leading-5">
            <CustomLink to={props.link.url}>{props.link.text} &rarr;</CustomLink>
          </div>
        ) : null}
        {props.action ? (
          <div className="mt-5">
            <Link to={props.action.url}>
              <Button styleType="secondary">{props.action.text}</Button>
            </Link>
          </div>
        ) : null}
      </div>
    </CardContainer>
  )
}

// prefer `CardsWrapper`
export const CardWrapper = styled.div.attrs({
  className: `mt-4`,
})``

export const CardsWrapper = styled.div.attrs({
  className: `space-y-4`,
})``

interface CardSideTitleProps {
  title: string
  description?: string
}

export const CardSideTitle: React.FC<CardSideTitleProps> = props => {
  return (
    <Card>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">{props.title}</h3>
          {props.description && (
            <p className="mt-1 text-sm leading-5 text-gray-500">{props.description}</p>
          )}
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">{props.children}</div>
      </div>
    </Card>
  )
}

export const CardTitleWithRightText: React.FC<{
  title: React.ReactNode
  rightItem: React.ReactNode
  rightIcon?: React.ReactNode
}> = props => {
  return (
    <CardTitle>
      <div className="flex justify-between">
        <div className="flex items-center">{props.title}</div>
        <div className="flex items-center text-base">
          {props.rightIcon && <div className="h-4 w-4 mr-1">{props.rightIcon}</div>}
          {props.rightItem}
        </div>
      </div>
    </CardTitle>
  )
}

export const CardTitleWithRightIcon: React.FC<{
  title: React.ReactNode
  icon: React.ReactNode
  onClick: () => void
}> = props => {
  return (
    <CardTitle>
      <div className="flex justify-between">
        {props.title}
        {props.icon && (
          <button className="h-6 w-6 hover:text-gray-500" onClick={props.onClick}>
            {props.icon}
          </button>
        )}
      </div>
    </CardTitle>
  )
}
