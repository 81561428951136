import classNames from 'classnames'
import React from 'react'
import { Button } from './Button'
import { Modal } from './Modal'
import { Tag } from './Tag'

export interface ComponentScreenshotsProps {
  thumbnail?: string
  screenshots: Array<{ url: string }>
  onScreenshotClick?: (url: string) => void
  onScreenshotDelete?: (url: string) => void
}

export const ComponentScreenshots: React.FC<ComponentScreenshotsProps> = props => {
  return (
    <ul className="grid sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {Object.values(props.screenshots).map(screenshot => (
        <ComponentScreenshot
          key={screenshot.url}
          url={screenshot.url}
          isThumbnail={screenshot.url === props.thumbnail}
          onScreenshotClick={props.onScreenshotClick}
          onScreenshotDelete={props.onScreenshotDelete}
        />
      ))}
    </ul>
  )
}

const ComponentScreenshot: React.FC<{
  url: string
  isThumbnail?: boolean
  onScreenshotClick?: (url: string) => void
  onScreenshotDelete?: (id: string) => void
}> = props => {
  const { onScreenshotClick, onScreenshotDelete } = props

  const [modalOpen, setModalOpen] = React.useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <li
      className={classNames('relative group border-4 rounded-lg border-transparent', {
        'border-indigo-500': props.isThumbnail,
      })}
    >
      {props.isThumbnail && (
        <div className="absolute top-2 right-2">
          <Tag color="indigo">Thumbnail</Tag>
        </div>
      )}
      {onScreenshotDelete && (
        <div className="absolute bottom-2 right-2 hidden group-hover:block">
          <Button
            styleType="danger"
            size="sm"
            onClick={() => {
              onScreenshotDelete(props.url)
            }}
          >
            Delete
          </Button>
        </div>
      )}
      <div
        className="block w-full rounded-md focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden cursor-pointer"
        onClick={onScreenshotClick ? () => onScreenshotClick(props.url) : openModal}
      >
        <img src={props.url} alt="" className="object-cover pointer-events-none" />
      </div>

      {modalOpen && (
        <Modal fullWidth hideModal={closeModal}>
          <div className="max-w-5xl">
            <img src={props.url} alt="" />
          </div>
        </Modal>
      )}
    </li>
  )
}
