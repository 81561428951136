import React from 'react'
import * as Sentry from '@sentry/browser'
import { gql } from '@apollo/client'
import { Button } from '../components/Button'
import { Card, CardsWrapper } from '../components/Card'
import { ErrorPage } from '../components/ErrorPage'
import { Loading } from '../components/Loading'
import { PageLayout } from '../components/PageLayout'
import { CustomLinkExternal, Paragraph } from '../components/Typography'
import { useDeveloperWithQuickApplyQuery, useSubmitQuickApplyMutation } from '../generated/graphql'
import { trackEvent } from '../utils/analytics'
import { EnglishAssessment, HourlyRate, Portfolio } from './DeveloperProfile'
import { InterviewCardTitle } from './InterviewProject'
import { useHistory } from 'react-router-dom'

const DEVELOPER_QUICK_APPLY_FRAGMENT = gql`
  fragment DeveloperQuickApply on User {
    id
    hourlyRateUsd
    portfolio
    introVideoUrl
    submittedQuickApply
  }
`

gql`
  query DeveloperWithQuickApply {
    getUser {
      ...DeveloperQuickApply
    }
  }

  ${DEVELOPER_QUICK_APPLY_FRAGMENT}
`

gql`
  mutation SubmitQuickApply {
    submitQuickApply {
      ...DeveloperQuickApply
    }
  }

  ${DEVELOPER_QUICK_APPLY_FRAGMENT}
`

interface DeveloperQuickApplyProps {}

export const DeveloperQuickApply: React.FC<DeveloperQuickApplyProps> = props => {
  const { data, loading, error } = useDeveloperWithQuickApplyQuery({
    variables: {},
  })
  const [submitQuickApply] = useSubmitQuickApplyMutation()
  const history = useHistory()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const canSubmitInterview = !!(
    data.getUser.introVideoUrl &&
    data.getUser.portfolio &&
    data.getUser.hourlyRateUsd
  )

  return (
    <PageLayout
      title="Quick Apply"
      description="Apply without completing the interview project. We may ask you to complete the interview project at a later date."
      headerDark
      headerLarge
    >
      <div className="flex justify-center max-w-3xl mx-auto">
        <CardsWrapper>
          <Card
            titleComponent={
              <InterviewCardTitle title="Intro Video" completed={!!data.getUser.introVideoUrl} />
            }
            description={
              <EnglishAssessment
                content={
                  <div className="space-y-2">
                    <Paragraph>
                      Please submit a video describing your experience as a developer. The video
                      should be around 2 minutes long (anywhere between 1 and 5 minutes is fine
                      though).
                    </Paragraph>

                    <Paragraph>
                      An easy way to create this video is to use{' '}
                      <span className="font-semibold">
                        <CustomLinkExternal
                          href="https://loom.com"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => trackEvent('Clicked Loom link')}
                        >
                          Loom
                        </CustomLinkExternal>{' '}
                      </span>
                      in full-screen mode.
                    </Paragraph>
                  </div>
                }
              />
            }
          />
          <Card
            titleComponent={
              <InterviewCardTitle title="Portfolio" completed={!!data.getUser.portfolio} />
            }
            description={<Portfolio />}
          />
          <Card
            titleComponent={
              <InterviewCardTitle
                title="Hourly Projects"
                completed={!!data.getUser.hourlyRateUsd}
              />
            }
            description={
              <>
                <div className="mt-4 mb-2">
                  <Paragraph>
                    For clients looking to work on an hourly basis, how much would you like to
                    charge per hour?
                  </Paragraph>
                </div>
                <HourlyRate />
              </>
            }
          />

          {!data.getUser.submittedQuickApply && (
            <div className="flex justify-end">
              <Button
                styleType="primary"
                disabled={!canSubmitInterview}
                onClick={async () => {
                  submitQuickApply()

                  trackEvent('Submit quick apply')

                  history.push('/developer/waitlist')
                }}
              >
                Submit for Review
              </Button>
            </div>
          )}
        </CardsWrapper>
      </div>
    </PageLayout>
  )
}
