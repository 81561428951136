import React from 'react'
import { isArray } from 'lodash'
import { SERVER_URL } from './config'
import { ZEPLIN_REDIRECT_URI, ZEPLIN_CLIENT_ID } from '../utils/config'

interface ZeplinAuth {
  accessToken: string
  refreshToken: string
  // expires_in?: number
  // refresh_expires_in?: number
  // token_type?: 'bearer'
}

export interface ZeplinProject {
  id: string
  name: string
  thumbnail: string
  platform: string
  status: 'active' | 'archived'
  scene_url: string
  created: number
  updated: number
  number_of_screens: number
  number_of_components: number
  number_of_text_styles: number
  number_of_colors: number
  number_of_members: number
  description?: string
}

export interface ZeplinScreen {
  id: string
  name: string
  image: ZeplinImage
  created: number
  updated: number
  number_of_versions?: number
  number_of_notes?: number
  tags?: any[]
}

export interface ZeplinImage {
  width: number
  height: number
  original_url: string
}

const ZeplinContext = React.createContext<{
  getZeplinToken: (code: string) => Promise<void>
  connect: () => void
  disconnect: () => void
  getProjects: () => any
  getProjectScreens: (projectId: string) => any
  getProjectComponents: (projectId: string) => any
  projects?: ZeplinProject[]
  selectedProjectScreens?: ZeplinScreen[]
  selectedProjectComponents?: ZeplinScreen[]
  authenticated: boolean
  loadingAuth: boolean
  loadingProjects: boolean
  // loadingScreens: boolean
}>({} as any)

export const useZeplin = () => React.useContext(ZeplinContext)

const ACCESS_TOKEN_KEY = 'ZEPLIN_ACCESS_TOKEN_KEY'
const REFRESH_TOKEN_KEY = 'ZEPLIN_REFRESH_TOKEN_KEY'
const STATE_KEY = 'ZEPLIN_STATE_KEY'

const setLocalStorage = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

const unsetLocalStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

const getLocalStorageTokens = (): ZeplinAuth | undefined => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? undefined
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? undefined

  if (!accessToken || !refreshToken) return

  return {
    accessToken,
    refreshToken,
  }
}

const createLocalStorageZeplinState = () => {
  const state = Math.random().toString()
  localStorage.setItem(STATE_KEY, state)
  return state
}
export const getLocalStorageZeplinState = () => localStorage.getItem(STATE_KEY)
export const clearLocalStorageZeplinState = () => localStorage.removeItem(STATE_KEY)

export const connectToZeplin = () => {
  const state = createLocalStorageZeplinState()
  const url = `https://app.zeplin.io/oauth/authorize?response_type=code&client_id=${ZEPLIN_CLIENT_ID}&redirect_uri=${ZEPLIN_REDIRECT_URI}&state=${state}`
  window.open(url, 'Connect Zeplin', 'resizable,scrollbars,status')
}

export const ZeplinProvider: React.FC = props => {
  const [authState, setAuthState] = React.useState<{ tokens?: ZeplinAuth; loading: boolean }>()
  const [projectsState, setProjectsState] = React.useState<{
    projects?: ZeplinProject[]
    loading: boolean
  }>()
  const [selectedProjectScreens, setSelectedProjectScreens] = React.useState<
    ZeplinScreen[] | undefined
  >()
  const [selectedProjectComponents, setSelectedProjectComponents] = React.useState<
    ZeplinScreen[] | undefined
  >()

  const connect = React.useCallback(() => {
    const tokens = getLocalStorageTokens()
    if (tokens) setAuthState({ tokens, loading: false })
  }, [])

  React.useEffect(() => {
    connect()
  }, [connect])

  const getZeplinToken = React.useCallback(async (code: string) => {
    setAuthState({ tokens: undefined, loading: true })
    const resString = await fetch(`${SERVER_URL}/zeplin/authorize/${code}`, {
      method: 'get',
      headers: { accept: 'application/json' },
    })
    const res = await resString.json()
    setAuthState({ tokens: res, loading: false })
    setLocalStorage(res.access_token, res.refresh_token)

    return res
  }, [])

  const refreshZeplinToken = React.useCallback(async () => {
    const tokens = getLocalStorageTokens()

    if (!tokens?.refreshToken) return

    setAuthState(state => ({ ...state, loading: true }))
    const resString = await fetch(`${SERVER_URL}/zeplin/refresh-token/${tokens.refreshToken}`, {
      method: 'get',
      headers: { accept: 'application/json' },
    })
    const res = await resString.json()
    setAuthState({ tokens: res, loading: false })
    setLocalStorage(res.access_token, res.refresh_token)

    return res
  }, [])

  const disconnect = React.useCallback(async () => {
    setAuthState({ tokens: undefined, loading: false })
    unsetLocalStorage()
  }, [])

  const accessToken = authState?.tokens?.accessToken

  const fetchZeplin = React.useCallback(
    async path => {
      const resOrig = await fetch(`https://api.zeplin.dev/v1/${path}`, {
        method: 'get',
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })

      if (resOrig.status === 401) {
        await refreshZeplinToken()
        // TODO this can lead to infinite refresh loop
        // but something like this is needed for the fetch to work
        // fetchZeplin(path)
      }

      const res = await resOrig.json()

      return res
    },
    [accessToken, refreshZeplinToken]
  )

  const getProjects = React.useCallback(async () => {
    if (!accessToken) {
      console.error('No Zeplin access')
      return
    }

    setProjectsState(state => ({ ...state, loading: true }))

    // TODO update limit/offset. max limit is 100
    const res: ZeplinProject[] | { message: string } = await fetchZeplin(
      'projects?limit=100&offset=0'
    )

    if (res && !('message' in res)) {
      setProjectsState({ projects: res, loading: false })
    } else {
      console.error('Unable to fetch file', res)
      disconnect()
      setProjectsState({ projects: undefined, loading: false })
    }

    return res
  }, [accessToken, disconnect, fetchZeplin])

  const getProjectComponents = React.useCallback(
    async (projectId: string) => {
      if (!accessToken) {
        console.error('No Zeplin access')
        return
      }

      // TODO update limit/offset. max limit is 100
      const res: ZeplinScreen[] | { message: string } = await fetchZeplin(
        `projects/${projectId}/components?limit=100&offset=0`
      )

      if (isArray(res)) setSelectedProjectComponents(res)
    },
    [accessToken, fetchZeplin]
  )

  const getProjectScreens = React.useCallback(
    async (projectId: string) => {
      if (!accessToken) {
        console.error('No Zeplin access')
        return
      }

      // TODO update limit/offset. max limit is 100
      const res: ZeplinScreen[] | { message: string } = await fetchZeplin(
        `projects/${projectId}/screens?limit=100&offset=0`
      )

      if (isArray(res)) setSelectedProjectScreens(res)
    },
    [accessToken, fetchZeplin]
  )

  return (
    <ZeplinContext.Provider
      value={{
        getZeplinToken,
        connect,
        disconnect,
        getProjects,
        getProjectScreens,
        getProjectComponents,
        projects: projectsState?.projects,
        loadingProjects: !!projectsState?.loading,
        loadingAuth: !!authState?.loading,
        authenticated: !!authState?.tokens,
        selectedProjectScreens,
        selectedProjectComponents,
      }}
    >
      {props.children}
    </ZeplinContext.Provider>
  )
}
