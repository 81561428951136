import React from 'react'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { useGetProjectsQuery, ProjectFragment, ProjectStatus } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { Card } from '../components/Card'
import { ButtonLink } from '../components/Button'
import { ProjectItemClient } from '../components/ProjectItemClient'

export const PROJECT_FRAGMENT = gql`
  fragment Project on Project {
    id
    creatorId
    name
    overview
    status
    type
    designApp
    zeplinProjectId
    zeplinScreens {
      id
      name
      notes
      cloudinaryUrl
      componentType
      commentsCount
      zeplinId
      zeplinCreated
      zeplinUpdated
      zeplinNumberOfVersions
      zeplinNumberOfNotes
      zeplinOriginalUrl
      width
      height
    }
    figmaFileId
    figmaScreens {
      id
      name
      notes
      cloudinaryUrl
      componentType
      commentsCount
      figmaId
      figmaPageId
      figmaOriginalUrl
    }
    imageScreens {
      id
      name
      notes
      cloudinaryUrl
      componentType
      commentsCount
    }
    designUrl
    frontendFramework
    programmingLanguage
    styling
    deadline
    contractType
    englishLevelRequired
    prettierConfig
    eslintConfig
    interviewBidProject
    interviewDevProject
    bids {
      id
    }
  }
`

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`

interface ProjectsProps {}

export const Projects: React.FC<ProjectsProps> = props => {
  const { data, loading, error } = useGetProjectsQuery({ fetchPolicy: 'cache-and-network' })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  return (
    <div className="bg-gray-100">
      <div className="bg-gray-800 pb-32">
        <header className="py-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl leading-9 font-bold text-white">Projects</h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="max-w-7xl mx-auto pb-12 sm:px-6 lg:px-8">
          {data.projects.length ? (
            <List projects={data.projects} />
          ) : (
            <Card>
              <StartProjectButton />
            </Card>
          )}
        </div>
      </main>
    </div>
  )
}

interface ListProps {
  projects: ProjectFragment[]
}

const List: React.FC<ListProps> = props => {
  const sortPriority = {
    [ProjectStatus.InProgress]: 1,
    [ProjectStatus.Searching]: 2,
    [ProjectStatus.Draft]: 3,
    [ProjectStatus.Completed]: 4,
    [ProjectStatus.Cancelled]: 5,
    [ProjectStatus.Expired]: 6,
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul>
        {props.projects
          .slice()
          .sort((a, b) => {
            const priority = sortPriority[a.status] - sortPriority[b.status]

            if (priority === 0) {
              if (!a.deadline) return 1
              if (!b.deadline) return -1

              return +new Date(a.deadline) - +new Date(b.deadline)
            }

            return priority
          })
          .map((project, i) => (
            <ProjectItemClient key={project.id} project={project} firstItem={!i} showStatus />
          ))}
      </ul>
      {/* Because it doesn't look right on the page if we only have one project in the list */}
      {props.projects.length === 1 && <StartProjectButton />}
    </div>
  )
}

const StartProjectButton: React.FC<{}> = props => {
  return (
    <div className="flex align-center justify-center my-16">
      <ButtonLink styleType="primary" size="xl" to="/new-project-onboarding">
        Start a Project
      </ButtonLink>
    </div>
  )
}
