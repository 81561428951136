import React from 'react'
import { Card } from '../components/Card'
import { LayoutPlain } from '../components/LayoutPlain'

interface DeveloperFailedProps {}

export const DeveloperFailed: React.FC<DeveloperFailedProps> = props => {
  return (
    <LayoutPlain>
      <div className="flex justify-center">
        <Card title="Failed Interview">
          <p className="mb-2">Unfortunately you failed the FrontWork interview.</p>
          <p className="mb-2">
            Don't let this discourage you. Interviewers often miss out on hidden talent. We've
            failed many interviews ourselves and it sucks!
          </p>
          <p className="mb-2">You can apply again in 3 months from now.</p>
        </Card>
      </div>
    </LayoutPlain>
  )
}
