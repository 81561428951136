import styled from 'styled-components'

// prefer `FormItemsWrapper`
export const FormItem = styled.div.attrs({
  className: `mt-6`,
})``

export const FormItemsWrapper = styled.div.attrs({
  className: `space-y-6`,
})``
