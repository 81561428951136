import React from 'react'
import { Color } from '../utils/color'

interface TagProps {
  color: Color
}

export const Tag: React.FC<TagProps> = props => {
  const { color } = props

  return (
    <div className="flex flex-shrink-0">
      <span
        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-${color}-100 text-${color}-800`}
      >
        {props.children}
      </span>
    </div>
  )
}

export const FrontendFrameworkTag: React.FC<{}> = props => {
  return <Tag color="green" {...props} />
}

export const ProgrammingLanguageTag: React.FC<{}> = props => {
  return <Tag color="blue" {...props} />
}

export const StylingLibraryTag: React.FC<{}> = props => {
  return <Tag color="orange" {...props} />
}
