import React from 'react'
import { CRISP_WEBSITE_ID } from './config'

function loadCrispChat() {
  if (!CRISP_WEBSITE_ID) return
  
  // Include the Crisp code here, without the <script></script> tags
  (window as any).$crisp = []
  ;(window as any).CRISP_WEBSITE_ID = CRISP_WEBSITE_ID

  ;(function () {
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = true
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}

export const useSupportChat = () => {
  React.useEffect(() => {
    loadCrispChat()
  }, [])
}
