import React from 'react'
import { Button } from './Button'

interface PaginationProps {
  isSubmitting: boolean
  disabled?: boolean
  onNextClick?: () => void
  onPreviousClick?: () => void
}

export const Pagination: React.FC<PaginationProps> = props => {
  return (
    <div className="pt-5 flex justify-between">
      {props.onPreviousClick ? (
        <Button styleType="primary" onClick={() => props.onPreviousClick?.()}>
          Previous
        </Button>
      ) : (
        <div />
      )}
      {props.onNextClick ? (
        <Button
          type="submit"
          disabled={props.isSubmitting || props.disabled}
          styleType="primary"
          onClick={() => props.onNextClick?.()}
        >
          Next
        </Button>
      ) : (
        <div />
      )}
    </div>
  )
}
