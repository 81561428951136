import React from 'react'
import { Card } from '../../components/Card'
import { PageLayout } from '../../components/PageLayout'

interface StripeReturnProps {}

export const StripeReturn: React.FC<StripeReturnProps> = props => {
  return (
    <PageLayout title={'Connected'} headerDark headerLarge>
      <Card>You have successfully connected your Stripe account to receive payments.</Card>
    </PageLayout>
  )
}
