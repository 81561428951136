import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { Paragraph } from './Typography'

// based on: https://loading.io/css/
const LoadingSpinner = styled.div<{ size?: number; thickness?: number; color?: string }>`
  display: inline-block;
  position: relative;
  width: ${props => props.size || 48}px;
  height: ${props => props.size || 48}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => props.size || 48}px;
    height: ${props => props.size || 48}px;
    /* margin: 8px; */
    border: ${props => (props.thickness ? props.thickness : 4)}px solid
      ${props => props.color || '#6875f5'};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color || '#6875f5'} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

interface LoadingProps {
  small?: boolean
  color?: string
  slow?: boolean
}

export const Loading: React.FC<LoadingProps> = props => {
  return (
    <>
      <div
        className={classNames('flex flex-col items-center justify-center px-4 py-16', {
          'py-4': props.small,
        })}
        role="progressbar"
      >
        <LoadingSpinner color={props.color}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LoadingSpinner>
        {props.slow && (
          <div className="mt-4">
            {' '}
            <Paragraph>This can take some time</Paragraph>
          </div>
        )}
      </div>
    </>
  )
}

export const LoadingSmall: React.FC<LoadingProps> = props => {
  return (
    <div className={classNames('flex items-center justify-center')}>
      <LoadingSpinner size={18} thickness={2} color={props.color}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingSpinner>
    </div>
  )
}
