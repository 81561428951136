import React from 'react'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { PageLayout } from '../components/PageLayout'
import { useOpenProjectsQuery, InterviewStatus } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { ProjectItem } from '../components/ProjectItem'
import { useUserContext } from '../auth/userContext'
import { CustomLink, Paragraph } from '../components/Typography'
import {
  formatFrontendFramework,
  formatProgrammingLanguage,
  formatStylingLibrary,
} from '../utils/skills'
import { ReactComponent as ProjectsImage } from '../images/undraw_design_feedback_dexe.svg'
import { ImageWrapper } from '../components/ImageWrapper'

gql`
  query OpenProjects {
    openProjects {
      id
      name
      programmingLanguage
      frontendFramework
      styling
      deadline
      bid {
        id
        status
        amount
        deliveryDate
        deleted
      }
    }
  }
`

interface DeveloperProjectsProps {}

export const DeveloperProjects: React.FC<DeveloperProjectsProps> = props => {
  const { user } = useUserContext()
  const { data, loading, error } = useOpenProjectsQuery({ fetchPolicy: 'cache-and-network' })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const content = data.openProjects.length ? (
    <ul>
      {data.openProjects.map(project => {
        return (
          <ProjectItem
            key={project.id}
            projectId={project.id}
            name={project.name}
            technologies={[
              ...(project.frontendFramework
                ? [formatFrontendFramework(project.frontendFramework)]
                : []),
              ...(project.programmingLanguage
                ? [formatProgrammingLanguage(project.programmingLanguage)]
                : []),
              ...(project.styling ? [formatStylingLibrary(project.styling)] : []),
            ]}
            projectDeadline={project.deadline ? new Date(project.deadline) : undefined}
            bidAmount={project.bid?.amount}
            bidDeliveryDate={
              project.bid?.deliveryDate ? new Date(project.bid?.deliveryDate) : undefined
            }
            // imageUrl={figma or zeplin image url}
            firstItem={true}
          />
        )
      })}
    </ul>
  ) : (
    <div className="p-6">
      {user?.interviewStatus === InterviewStatus.Passed ? (
        <Paragraph>
          There are no projects open for you to take on at this time.
          <ImageWrapper>
            <ProjectsImage height="100%" width="100%" />
          </ImageWrapper>
        </Paragraph>
      ) : (
        <Paragraph>
          Once you pass the{' '}
          <span className="font-semibold">
            <CustomLink to="/developer/interview-project">interview process</CustomLink>
          </span>{' '}
          open projects will appear here.
          <ImageWrapper>
            <ProjectsImage height="100%" width="100%" />
          </ImageWrapper>
        </Paragraph>
      )}
    </div>
  )

  return (
    <PageLayout title="Open Projects" headerDark headerLarge>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">{content}</div>
    </PageLayout>
  )
}
