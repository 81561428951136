import React from 'react'
import ReactDOM from 'react-dom'

const modalRoot = document.getElementById('modal-root')

// same thing can be done with `InPortal` component
export class ModalPortal extends React.Component {
  el: any

  constructor(props: {}) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    if (modalRoot) modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    if (modalRoot) modalRoot.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
