import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import classNames from 'classnames'
import React from 'react'
import { useUserContext } from '../../auth/userContext'
import { Button } from '../../components/Button'
import { EditSelected } from '../../components/EditSelected'
import { ErrorCard } from '../../components/ErrorPage'
import { FormItemsWrapper } from '../../components/FormItem'
import { Label } from '../../components/Label'
import { Loading } from '../../components/Loading'
import { PaginationList } from '../../components/PaginationList'
import { Toggle } from '../../components/Toggle'
import {
  useGetRepoContentsQuery,
  useGitHubReposQuery,
  useUpdateGithubAppUsernameMutation,
  useUnsetGithubAppUsernameMutation,
} from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import { GITHUB_APP_INSTALL_URL } from '../../utils/config'
import { GitHubConnected } from '../GitHub'

gql`
  query GitHubRepos {
    repos {
      repositories {
        full_name
      }
      totalCount
    }
  }
`

gql`
  query GetRepoContents($fullRepoName: String!) {
    repoContents(fullRepoName: $fullRepoName) {
      path
    }
  }
`

interface UploadComponentGitHubProps {
  selectedRepo?: string
  setSelectedRepo: (repo?: string) => void
  selectedFiles: { [key: string]: boolean }
  toggleSelectedFile: (file: { path: string }) => void
  deliverAllFiles: boolean
  toggleDeliverAllFiles: () => void
}

export const UploadComponentGitHub: React.FC<UploadComponentGitHubProps> = props => {
  const { user } = useUserContext()
  const [updateGithubAppUsername] = useUpdateGithubAppUsernameMutation()
  const [unsetGitHubAppUsername] = useUnsetGithubAppUsernameMutation()

  const onClick = React.useCallback(() => {
    trackEvent('Click connect to GitHub app')

    const popup = window.open(
      GITHUB_APP_INSTALL_URL,
      'Install FrontWork GitHub App',
      'resizable,scrollbars,status'
    )

    ;(window as any).onGitHubConnected = async (accessToken: string, installationId: string) => {
      await updateGithubAppUsername({
        variables: {
          githubAccessToken: accessToken,
          githubInstallationId: parseInt(installationId),
        },
      })

      trackEvent('Installed GitHub App')

      popup?.close()
    }
  }, [updateGithubAppUsername])

  if (!user) return null

  return (
    <FormItemsWrapper>
      <div>
        <div className="mb-2">
          <Label>GitHub</Label>
        </div>
        <GitHubConnected
          githubUsername={user.githubAppUsername}
          connectButton={
            <Button styleType="primary" onClick={onClick}>
              Install GitHub App
            </Button>
          }
          editText="Reconfigure GitHub"
          unsetUsername={() => unsetGitHubAppUsername()}
        />
      </div>
      {user.githubAppUsername ? <GitHubRepos {...props} /> : null}
    </FormItemsWrapper>
  )
}

interface GitHubReposProps extends UploadComponentGitHubProps {}

const GitHubRepos: React.FC<GitHubReposProps> = props => {
  const { data, loading, error } = useGitHubReposQuery()

  if (loading) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorCard error={error} />
  }

  return (
    <>
      <div className="space-y-2">
        <Label>Repo</Label>
        {/* <Input placeholder="Search..." label="Select Repo" /> */}
        {props.selectedRepo ? (
          <EditSelected
            selected={props.selectedRepo}
            editText="Change Repo"
            onEdit={() => props.setSelectedRepo(undefined)}
          />
        ) : (
          <>
            <ul className="bg-white shadow overflow-hidden rounded-md">
              {data.repos.repositories.map(repo => (
                <li
                  key={repo.full_name}
                  className={classNames(
                    'border-t border-gray-200 px-4 py-4 sm:px-6 text-sm leading-5 font-medium text-indigo-600 truncate block hover:bg-indigo-50 focus:outline-none focus:bg-gray-50 transition cursor-pointer',
                    { 'bg-indigo-100': repo.full_name === props.selectedRepo }
                  )}
                  onClick={() => props.setSelectedRepo(repo.full_name)}
                >
                  {repo.full_name}
                </li>
              ))}
            </ul>
            <PaginationList
              start={data.repos.totalCount ? 1 : 0}
              end={data.repos.repositories.length}
              total={data.repos.totalCount}
              onPrevious={() => null}
              onNext={() => null}
            />
          </>
        )}
      </div>
      {props.selectedRepo && (
        <>
          <div className="mb-4">
            <Toggle
              rightText={'Deliver all files on purchase'}
              enabled={props.deliverAllFiles}
              onChange={props.toggleDeliverAllFiles}
            />
          </div>
          {!props.deliverAllFiles && <FileList {...props} selectedRepo={props.selectedRepo} />}
        </>
      )}
    </>
  )
}

interface FileList extends GitHubReposProps {
  selectedRepo: string
}

const FileList: React.FC<FileList> = props => {
  const { data, loading, error } = useGetRepoContentsQuery({
    variables: { fullRepoName: props.selectedRepo },
  })

  if (loading) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorCard error={error} />
  }

  // type Node = {}
  // type Folder = { [key: string]: Folder | string[] }
  // const tree: Folder = {}
  // let root: string[] = []
  // data.repoContents.forEach(file => {
  //   const parts = file.path.split('/')
  //   const filename = parts.pop()

  //   if (!filename) return

  //   if (!parts.length) {
  //     root = [...root, filename]
  //     return
  //   }

  //   const folder = get(tree, parts)

  //   if (folder) set(tree, parts, [...folder, filename])
  //   else set(tree, parts, [filename])
  // })

  return (
    <div className="space-y-2">
      <Label>Files</Label>
      {/* <Input placeholder="Search..." /> */}
      <ul className="bg-white shadow overflow-hidden rounded-md">
        {data.repoContents.map(repo => (
          // {[...Object.keys(tree), ...root].map(repo => (
          <li
            key={repo.path}
            className={classNames(
              'border-t border-gray-200 px-4 py-4 sm:px-6 text-sm leading-5 font-medium text-indigo-600 truncate block hover:bg-indigo-50 focus:outline-none focus:bg-gray-50 transition cursor-pointer',
              { 'bg-indigo-100': props.selectedFiles[repo.path] }
            )}
            onClick={() => props.toggleSelectedFile(repo)}
          >
            {repo.path}
          </li>
        ))}
      </ul>
    </div>
  )
}
