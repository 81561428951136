import classNames from 'classnames'
import React from 'react'

export interface ButtonGroupProps {
  buttons: Array<{ label: string; value: string }>
  selected?: string | null
  onClick: (button: string) => void
}

export const ButtonGroup: React.FC<ButtonGroupProps> = props => {
  const { buttons, selected, onClick } = props
  const [firstButton, ...endButtons] = buttons
  const lastButton = endButtons.pop()

  // edge case to handle single button, although not sure you'd want this
  if (!lastButton) {
    return (
      <ButtonGroupButton
        text={firstButton.label}
        selected={firstButton.value === selected}
        onClick={() => onClick(firstButton.value)}
      />
    )
  }

  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <ButtonGroupButton
        text={firstButton.label}
        selected={firstButton.value === selected}
        onClick={() => onClick(firstButton.value)}
        first
      />
      {endButtons.map(button => {
        return (
          <ButtonGroupButton
            key={button.value}
            text={button.label}
            selected={button.value === selected}
            onClick={() => onClick(button.value)}
          />
        )
      })}
      <ButtonGroupButton
        text={lastButton.label}
        selected={lastButton.value === selected}
        onClick={() => onClick(lastButton.value)}
        last
      />
    </span>
  )
}

const ButtonGroupButton: React.FC<{
  text: string
  selected: boolean
  first?: boolean
  last?: boolean
  onClick: () => void
}> = props => {
  return (
    <button
      type="button"
      className={classNames(
        '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500',
        {
          'bg-indigo-500 text-white hover:bg-indigo-400': props.selected,
          'bg-white text-gray-700 hover:bg-gray-50': !props.selected,
          'rounded-l-md': props.first,
          'rounded-r-md': props.last,
        }
      )}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  )
}
