import React from 'react'
import { gql } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import {
  useGetDeveloperQuery,
  useCreateInterviewMutation,
  GetUserDocument,
} from '../../generated/graphql'
import { Loading } from '../../components/Loading'
import { PageLayout } from '../../components/PageLayout'
import { useSteps } from '../../utils/location'
import { ErrorPage } from '../../components/ErrorPage'
import { Experience } from './DeveloperOnboardingExperience'
import { InterviewPreparation } from './DeveloperOnboardingInterviewPreparation'
import { SkillSet } from './DeveloperOnboardingSkillSet'
import { DEVELOPER_FRAGMENT } from './common'

gql`
  query GetDeveloper {
    developer: getUser {
      ...Developer
    }
  }

  ${DEVELOPER_FRAGMENT}
`

export const INTERVIEW_FRAGMENT = gql`
  fragment Interview on Interview {
    id
    videoResponseStatus
    bidProjectId
    devProjectId
    devProjectStatus
    videoResponseStatus

    completedBidProject
  }
`

gql`
  ${INTERVIEW_FRAGMENT}

  mutation CreateInterview {
    createInterview {
      ...Interview
    }
  }
`

export const DeveloperOnboarding: React.FC<{}> = () => {
  const { data, loading, error } = useGetDeveloperQuery()
  const [createInterview] = useCreateInterviewMutation()
  const { step, increaseStep, decreaseStep } = useSteps(3)
  const history = useHistory()

  const finalStep = React.useCallback(async () => {
    await createInterview({ refetchQueries: [{ query: GetUserDocument }] })
    history.push('/developer/interview-project')
  }, [createInterview, history])

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { developer } = data

  const steps = [
    () => <SkillSet developer={developer} increaseStep={increaseStep} />,
    () => (
      <Experience developer={developer} increaseStep={increaseStep} decreaseStep={decreaseStep} />
    ),
    () => (
      <InterviewPreparation
        developer={developer}
        decreaseStep={decreaseStep}
        increaseStep={finalStep}
      />
    ),
    // () => (
    //   <Personal
    //     developer={developer}
    //     decreaseStep={decreaseStep}
    //     increaseStep={finalStep}
    //   />
    // ),
  ]
  const Step = steps[step]

  return (
    <PageLayout title="Onboarding" headerDark headerLarge>
      <Step />
    </PageLayout>
  )
}
