import React from 'react'
import {
  useZeplin,
  getLocalStorageZeplinState,
  clearLocalStorageZeplinState,
} from '../utils/zeplin'
import { useSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { PageLayout } from '../components/PageLayout'
import { Button } from '../components/Button'
import { Loading } from '../components/Loading'

interface ZeplinProps { }

export const Zeplin: React.FC<ZeplinProps> = props => {
  const { getZeplinToken, authenticated, loadingAuth } = useZeplin()
  const query = useSearchQuery()
  const code = query.get('code')
  const state = query.get('state')

  React.useEffect(() => {
    if (code) {
      const stateLocal = getLocalStorageZeplinState()
      if (state === stateLocal) {
        getZeplinToken(code).then(() => {
          window.close()
        })
        clearLocalStorageZeplinState()
      } else {
        console.error('State is wrong')
      }
    }
  }, [code, state, getZeplinToken])

  return (
    <PageLayout title="Zeplin" headerDark headerLarge>
      <Card title="Connected">
        {loadingAuth ? (
          <Loading />
        ) : authenticated ? (
          <Button
            styleType="primary"
            onClick={() => {
              window.close()
            }}
          >
            Continue
          </Button>
        ) : (
              <div>There was an error connecting.</div>
            )}
      </Card>
    </PageLayout>
  )
}
