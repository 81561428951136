import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { CardContainer, CardDescription, CardTitle } from './Card'

export interface CardButtonProps {
  title: string
  description: string
  image: React.ReactNode
  to: string
}

export const CardButton: React.FC<CardButtonProps> = props => {
  const { title, description, image, to } = props

  return (
    <Link to={to}>
      <CardContainer className="h-full pt-5 px-4 sm:px-6 flex flex-col" hoverable>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
        <div className="flex flex-1 py-16 p-4 text-indigo-500">{image}</div>
      </CardContainer>
    </Link>
  )
}

export const CardButtonGrid = styled.div.attrs<{ items?: number }>(props => ({
  className: classNames('grid grid-cols-1 sm:grid-cols-2 gap-4', {
    'lg:grid-cols-4': props.items && props.items >= 4,
  }),
}))<{ items?: number }>``
