import React from 'react'
import { Card } from '../../components/Card'
import { DEVELOPER_FRAGMENT, StepProps } from './common'
import { RadioGroupWrapper } from '../../components/Radio'
import { Alert } from '../../components/Alert'
import { CustomLinkExternal } from '../../components/Typography'
import { CustomRadioComponent } from '../../components/FormikCustom'
import {
  useUpdateDeveloperToolsMutation,
  DeveloperProfileDocument,
  GetUserDocument,
} from '../../generated/graphql'
import { Field, Formik } from 'formik'
import { trackEvent } from '../../utils/analytics'
import { FormSection } from '../../components/FormSection'
import { Pagination } from '../../components/Pagination'
import gql from 'graphql-tag'

gql`
  mutation UpdateDeveloperTools($data: UpdateDeveloperToolsInput!) {
    updateDeveloperTools(data: $data) {
      id
      storybookLevel
      uiLevel
      figmaLevel
    }
  }

  ${DEVELOPER_FRAGMENT}
`

interface InterviewPreparationProps extends StepProps {}

export const InterviewPreparation: React.FC<InterviewPreparationProps> = props => {
  const { developer } = props
  const [updateTools] = useUpdateDeveloperToolsMutation()

  return (
    <Card
      title="Tools"
      description="Let us know how well you know each tool so we can best guide you throughout the interview process."
    >
      <Formik<{ storybookLevel?: string; uiLevel?: string; figmaLevel?: string }>
        initialValues={{
          storybookLevel: developer.storybookLevel ? `${developer.storybookLevel}` : undefined,
          uiLevel: developer.uiLevel ? `${developer.uiLevel}` : undefined,
          figmaLevel: developer.figmaLevel ? `${developer.figmaLevel}` : undefined,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)
          await updateTools({
            variables: {
              data: {
                storybookLevel: values.storybookLevel ? parseInt(values.storybookLevel) : undefined,
                uiLevel: values.uiLevel ? parseInt(values.uiLevel) : undefined,
                figmaLevel: values.figmaLevel ? parseInt(values.figmaLevel) : undefined,
              },
            },
            refetchQueries: [
              { query: DeveloperProfileDocument, variables: { id: developer.id } },
              { query: GetUserDocument },
            ],
          })
          trackEvent('Update profile - tools', values)
          setSubmitting(false)

          if (values.figmaLevel && values.storybookLevel && values.uiLevel) props.increaseStep?.()
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset, values }) => {
          return (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <div>
                <FormSection title="Storybook" description="How well do you know Storybook?">
                  {/* <legend className="text-base leading-6 font-medium text-gray-900">
                      Push Notifications
                    </legend>
                    <p className="text-sm leading-5 text-gray-500">
                      These are delivered via SMS to your mobile phone.
                    </p> */}
                  <RadioGroupWrapper>
                    {[
                      { value: '3', label: `I've used it and know it very well` },
                      { value: '2', label: `I've used it a little` },
                      { value: '1', label: `I haven't used it before` },
                    ].map(({ value, label }) => (
                      <Field
                        key={value}
                        type="radio"
                        name={'storybookLevel'}
                        label={label}
                        value={value}
                        id={`storybookLevel-${value}`}
                        component={CustomRadioComponent}
                        data-cy={`storybookLevel-${value}`}
                      />
                    ))}
                  </RadioGroupWrapper>

                  {values.storybookLevel && parseInt(values.storybookLevel) >= 3 && (
                    <StorybookHighQualityMessage />
                  )}
                  {values.storybookLevel && parseInt(values.storybookLevel) < 3 && (
                    <StorybookLowQualityMessage />
                  )}
                </FormSection>

                <FormSection title="UI Skills" description="How good are your UI skills?">
                  <RadioGroupWrapper>
                    {[
                      {
                        value: '3',
                        label: `Given a design, I'm comfortable implementing it with close to pixel perfection`,
                      },
                      {
                        value: '2',
                        label: `My UI skills are okay but I'm stronger in other areas`,
                      },
                      { value: '1', label: `My UI skills are weak` },
                    ].map(({ value, label }) => (
                      <Field
                        key={value}
                        type="radio"
                        name={'uiLevel'}
                        label={label}
                        value={value}
                        id={`uiLevel-${value}`}
                        component={CustomRadioComponent}
                        data-cy={`uiLevel-${value}`}
                      />
                    ))}
                  </RadioGroupWrapper>

                  {values.uiLevel && parseInt(values.uiLevel) >= 3 && <UIHighQualityMessage />}
                  {values.uiLevel && parseInt(values.uiLevel) < 3 && <UILowQualityMessage />}
                </FormSection>

                <FormSection
                  title="Figma"
                  description="Are you familiar with Figma as a handoff tool for developers?"
                >
                  <RadioGroupWrapper>
                    {[
                      {
                        value: '3',
                        label: `I've used it and am comfortable taking a Figma design and converting it to code`,
                      },
                      {
                        value: '2',
                        label: `I don't have a lot of experience with Figma`,
                      },
                      { value: '1', label: `I haven't used it before` },
                    ].map(({ value, label }) => (
                      <Field
                        key={value}
                        type="radio"
                        name={'figmaLevel'}
                        label={label}
                        value={value}
                        id={`figmaLevel-${value}`}
                        component={CustomRadioComponent}
                        data-cy={`figmaLevel-${value}`}
                      />
                    ))}
                  </RadioGroupWrapper>

                  {values.figmaLevel && parseInt(values.figmaLevel) >= 3 && (
                    <FigmaHighQualityMessage />
                  )}

                  {values.figmaLevel && parseInt(values.figmaLevel) < 3 && (
                    <FigmaLowQualityMessage />
                  )}
                </FormSection>
              </div>

              <div className="mt-8 border-t border-gray-200">
                <Pagination
                  isSubmitting={isSubmitting}
                  disabled={!values.uiLevel || !values.figmaLevel || !values.storybookLevel}
                  onNextClick={props.increaseStep && (() => handleSubmit())}
                  onPreviousClick={props.decreaseStep}
                />
              </div>
            </form>
          )
        }}
      </Formik>
    </Card>
  )
}

const StorybookHighQualityMessage: React.FC<{}> = props => (
  <div className="mt-4">
    <Alert
      type="success"
      title="Great!"
      description={`We use Storybook in the interview and to deliver projects to clients!`}
    />
  </div>
)

const StorybookLowQualityMessage: React.FC<{}> = props => (
  <div className="mt-4">
    <Alert
      type="indigo"
      title="Note"
      description={
        <div>
          Storybook is a tool used to display your UI nicely in isolation. The interview consists of
          a small Storybook project that we've already set up for you. You can learn more about
          Storybook{' '}
          <CustomLinkExternal
            href="https://storybook.js.org/docs/react/get-started/whats-a-story"
            target="_blank"
            rel="noreferrer"
          >
            here
          </CustomLinkExternal>
          .
        </div>
      }
    />
  </div>
)

const UIHighQualityMessage: React.FC<{}> = props => (
  <div className="mt-4">
    <Alert
      type="success"
      title="Nice!"
      description={`This is exactly what we're after at FrontWork!`}
    />
  </div>
)

const UILowQualityMessage: React.FC<{}> = props => (
  <div className="mt-4">
    <Alert
      type="indigo"
      title="Note"
      description={`Thanks for the honesty! If your UI skills aren't great, FrontWork may not be the platform for you. You'll need to implement a UI with close to pixel perfection to pass the interview. If you'd like to give it a try in any case feel free to continue with the process!`}
    />
  </div>
)

const FigmaHighQualityMessage: React.FC<{}> = props => (
  <div className="mt-4">
    <Alert
      type="success"
      title="Awesome!"
      description={
        <div>
          If you need some tips on how to get CSS from Figma you can take a look at{' '}
          <CustomLinkExternal
            href="https://www.figma.com/best-practices/tips-on-developer-handoff/an-overview-of-figma-for-developers/"
            target="_blank"
            rel="noreferrer"
          >
            using Figma as a handoff tool for developers
          </CustomLinkExternal>
          .
        </div>
      }
    />
  </div>
)

const FigmaLowQualityMessage: React.FC<{}> = props => (
  <div className="mt-4">
    <Alert
      type="indigo"
      title="Note"
      description={
        <div>
          The interview uses Figma and it will make your life easier if you're familiar with the
          features it provides for developers. You can learn more about using{' '}
          <CustomLinkExternal
            href="https://www.figma.com/best-practices/tips-on-developer-handoff/an-overview-of-figma-for-developers/"
            target="_blank"
            rel="noreferrer"
          >
            Figma as a handoff tool here
          </CustomLinkExternal>
          .
        </div>
      }
    />
  </div>
)
