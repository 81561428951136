import React from 'react'
import gql from 'graphql-tag'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useLocation } from 'react-router-dom'
import {
  useCreateProjectOverviewMutation,
  CreateProjectOverviewInput,
  ProjectType,
  DesignApp,
  ProjectFragment,
  FrontendFrameworkProject,
} from '../generated/graphql'
import {
  CustomInputComponent,
  CustomTextAreaComponent,
  CustomChooseCardComponent,
} from '../components/FormikCustom'
import { PROJECT_FRAGMENT, GET_PROJECTS } from './Projects'
import { FormItem } from '../components/FormItem'
import { StepComponentProps, useSetSearchQuery } from '../utils/location'
import { Card } from '../components/Card'
import { formatDesignApp } from '../utils/designApp'
import { Pagination } from '../components/Pagination'
import { formatDateForCalendar, addDays, parseCalendarDate } from '../utils/date'
import { trackEvent } from '../utils/analytics'
import { frontendFrameworksProject } from '../utils/skills'

gql`
  mutation CreateProjectOverview($projectId: ID, $data: CreateProjectOverviewInput!) {
    createProjectOverview(projectId: $projectId, data: $data) {
      ...Project
    }
  }

  ${PROJECT_FRAGMENT}
`

const ProjectOverviewSchema = Yup.object().shape<Partial<CreateProjectOverviewInput>>({
  name: Yup.string()
    .min(3, 'The project name must be at least 3 characters long.')
    .max(100, 'The project name can be at most 100 characters long.')
    .required('Required'),
  overview: Yup.string().max(3000, 'The project can be at most 3000 characters long.'),
})

interface NewProjectOverviewProps extends StepComponentProps {
  project?: ProjectFragment
  increaseStep?: (project?: ProjectFragment) => void
}

export const NewProjectOverview: React.FC<NewProjectOverviewProps> = props => {
  const { project } = props
  const [query, setSearchQuery] = useSetSearchQuery()
  const projectType = query.get('project-type') as ProjectType
  const location = useLocation()
  const isBidInterview = location.hash.includes('#interview-bid')
  const isDevInterview = location.hash.includes('#interview-dev')

  const [createProjectOverview] = useCreateProjectOverviewMutation({
    refetchQueries: [{ query: GET_PROJECTS }],
  })

  return (
    <Card title="Project">
      <Formik<CreateProjectOverviewInput>
        initialValues={{
          name: project?.name || '',
          overview: project?.overview || '',
          designApp: project?.designApp || DesignApp.Figma,
          frontendFramework: project?.frontendFramework || FrontendFrameworkProject.React,
          type: project?.type || projectType,
          deadline: project?.deadline ? formatDateForCalendar(new Date(project?.deadline)) : '',
          bidInterview: isBidInterview,
          devInterview: isDevInterview,
        }}
        validationSchema={ProjectOverviewSchema}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onSubmit', values)
          const deadline = parseCalendarDate(values.deadline as string)
          const updatedProject = await createProjectOverview({
            variables: {
              projectId: project?.id || undefined,
              data: { ...values, deadline },
            },
          })
          const projectId = updatedProject.data?.createProjectOverview.id
          trackEvent('Create project - Overview', { ...values, projectId })

          if (projectId) setSearchQuery('projectId', projectId)
          // setSubmitting(false)
          props.increaseStep?.(updatedProject.data?.createProjectOverview)
        }}
      >
        {({ isSubmitting, handleSubmit, handleReset }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <FormItem>
              <Field name="name" label="Name" component={CustomInputComponent} />
            </FormItem>
            <FormItem>
              <Field
                name="overview"
                label="Please provide a brief overview of your project (supports markdown)"
                type="textarea"
                component={CustomTextAreaComponent}
              />
            </FormItem>

            <FormItem>
              <Field
                name="designApp"
                label="How would you like to provide your designs to the developer?"
                component={CustomChooseCardComponent}
                options={Object.entries(DesignApp).map(app => ({
                  value: app[1],
                  label: formatDesignApp(app[1]),
                }))}
              />
            </FormItem>

            <FormItem>
              <Field
                name="frontendFramework"
                label="Which frontend framework would you like the developer to use?"
                options={frontendFrameworksProject}
                component={CustomChooseCardComponent}
              />
            </FormItem>

            <FormItem>
              <Field
                name="deadline"
                label="By when do you need the project completed?"
                component={CustomInputComponent}
                type="date"
                placeholder="yyyy-mm-dd"
                min={formatDateForCalendar(new Date())}
                max={formatDateForCalendar(addDays(new Date(), 365))}
              />
            </FormItem>

            <Pagination
              isSubmitting={isSubmitting}
              onNextClick={
                props.lastStep
                  ? undefined
                  : () => {
                      handleSubmit()
                    }
              }
              onPreviousClick={props.firstStep ? undefined : props.decreaseStep}
              disabled={false}
            />
          </form>
        )}
      </Formik>
    </Card>
  )
}
