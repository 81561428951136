import React from 'react'
import * as Sentry from '@sentry/browser'
import { gql } from '@apollo/client'
import { Card } from '../../components/Card'
import { ComponentGrid } from '../../components/ComponentGrid'
import { Loading } from '../../components/Loading'
import { ErrorPage } from '../../components/ErrorPage'
import { useAllComponentsQuery } from '../../generated/graphql'
import { PageHeaderTabBar } from '../../components/PageHeaderTabBar'
import { PageLayout } from '../../components/PageLayout'
import { Route, Switch } from 'react-router-dom'

gql`
  query AllComponents($filter: String) {
    components: allComponents(filter: $filter) {
      ...Component
    }
  }
`

export const AllComponents: React.FC<{}> = props => {
  return (
    <Switch>
      <Route path="/all-components" exact>
        <AllComponentsContent filter="all" />
      </Route>
      <Route path="/all-components/public" exact>
        <AllComponentsContent filter="public" />
      </Route>
      <Route path="/all-components/rated" exact>
        <AllComponentsContent filter="rated" />
      </Route>
      <Route path="/all-components/unrated" exact>
        <AllComponentsContent filter="unrated" />
      </Route>
      <Route path="/all-components/rejected" exact>
        <AllComponentsContent filter="rejected" />
      </Route>
    </Switch>
  )
}

export const AllComponentsContent: React.FC<{
  filter?: 'all' | 'public' | 'rated' | 'unrated' | 'rejected'
}> = props => {
  const { filter } = props
  const { data, loading, error } = useAllComponentsQuery({
    variables: { filter },
    fetchPolicy: 'cache-and-network',
  })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { components } = data

  return (
    <PageLayout
      title=""
      headerDark
      header={
        <header className="bg-gray-800 shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <PageHeaderTabBar
              tabs={[
                { label: 'All', to: '/all-components' },
                { label: 'Public', to: '/all-components/public' },
                { label: 'Rated', to: '/all-components/rated' },
                { label: 'Unrated', to: '/all-components/unrated' },
                { label: 'Rejected', to: '/all-components/rejected' },
              ]}
            />
          </div>
        </header>
      }
    >
      <ComponentGrid components={components} showRating />
    </PageLayout>
  )
}
