import React from 'react'
import * as Sentry from '@sentry/browser'
import { gql } from '@apollo/client'
import { PaymentsList } from '../components/PaymentsList'
import { usePastPaymentsQuery } from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { PageLayout } from '../components/PageLayout'

gql`
  fragment Payment on PaypalPayment {
    id
    createdAt
    paypalStatus
    amount
    currency
    bid {
      id
      project {
        id
        name
      }
    }
  }

  query pastPayments {
    pastPayments {
      ...Payment
    }
  }
`

interface PaymentHistoryProps {}

export const PaymentHistory: React.FC<PaymentHistoryProps> = props => {
  const { data, loading, error } = usePastPaymentsQuery({
    variables: {},
  })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  return (
    <PageLayout title="Payment History" headerDark headerLarge>
      <PaymentsList payments={data.pastPayments} />
    </PageLayout>
  )
}
