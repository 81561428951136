import React from 'react'
import classNames from 'classnames'
import { Label } from './Label'
import { Button } from './Button'
import { ReactComponent as AlertIcon } from '../icons/alert.svg'

export interface InputProps {
  label: string
  error?: boolean
}

type FullInputProps = InputProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Input: React.FC<FullInputProps> = props => {
  const { label, error } = props
  const id = props.id || props.name

  return (
    <div>
      {label ? (
        <div className="mb-1">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      <div className="relative">
        <BasicInput {...props} id={id} />
        {error && <ErrorSymbol />}
      </div>
    </div>
  )
}

export const InputWithButton: React.FC<
  InputProps & {
    onClickSelect: (value: string) => void
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = props => {
  const { label, error, ...otherProps } = props
  const { onClickSelect, ...inputProps } = props
  const id = props.id || props.name

  return (
    <div>
      <Label htmlFor={id}>{props.label}</Label>
      <div className="mt-1 flex">
        <div className="relative flex-1 mr-2">
          <BasicInput {...inputProps} id={id} />
          {error && <ErrorSymbol />}
        </div>
        <Button styleType="secondary" onClick={() => onClickSelect(otherProps.value as string)}>
          Select
        </Button>
      </div>
    </div>
  )
}

export const TextArea: React.FC<
  InputProps &
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
> = props => {
  const { label, error, ...otherProps } = props
  const id = props.id || props.name

  return (
    <div>
      {label ? (
        <div className="mb-1">
          <Label htmlFor={id}>{label}</Label>
        </div>
      ) : null}
      <div className="relative">
        <textarea className={getInputClassName(error)} id={id} {...otherProps} />
        {error && <ErrorSymbol />}
      </div>
    </div>
  )
}

export const InputCurrency: React.FC<
  InputProps & {
    currencySymbol?: string
    currencyAbbreviation?: string
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = props => {
  const { label, currencySymbol = '$', currencyAbbreviation = 'USD', error, ...otherProps } = props
  const id = props.id || props.name

  return (
    <div>
      <Label htmlFor={id}>{props.label}</Label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm sm:leading-5">{currencySymbol}</span>
        </div>
        <input
          type="text"
          id={props.id}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder="0"
          aria-describedby="price-currency"
          {...otherProps}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm sm:leading-5" id="price-currency">
            {currencyAbbreviation}
          </span>
        </div>
      </div>
    </div>
  )
}

const getInputClassName = (error?: boolean) =>
  classNames('shadow-sm block w-full sm:text-sm border-gray-300 rounded-md', {
    'focus:ring-indigo-500 focus:border-indigo-500': !error,
    'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red': error,
  })

const BasicInput: React.FC<FullInputProps> = props => {
  const { label, error, id, ...otherProps } = props

  return (
    <input
      type="text"
      className={getInputClassName(error)}
      id={id}
      aria-invalid={error ? 'true' : undefined}
      {...otherProps}
    />
  )
}

const ErrorSymbol: React.FC<{}> = props => {
  return (
    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <div className="h-5 w-5 text-red-500">
        <AlertIcon />
      </div>
    </div>
  )
}
