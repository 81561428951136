import React from 'react'
import { SERVER_URL } from './config'
import { FIGMA_REDIRECT_URI, FIGMA_CLIENT_ID } from './config'
import { FigmaFile, FigmaImagesResponse } from './figma.types'

interface FigmaAuth {
  accessToken: string
  refreshToken: string
}

const FigmaContext = React.createContext<{
  getFigmaToken: (code: string) => Promise<void>
  connect: () => void
  disconnect: () => void
  getFile: (fileId: string) => any
  getFrameScreenshots: (options: { fileId: string; nodeIds: string[] }) => any
  fileId?: string
  file?: FigmaFile
  images?: { [key: string]: string }
  authenticated: boolean
  loadingAuth: boolean
  loadingFile: boolean
  loadingImages: boolean
}>({} as any)

export const useFigma = () => React.useContext(FigmaContext)

const ACCESS_TOKEN_KEY = 'FIGMA_ACCESS_TOKEN_KEY'
const REFRESH_TOKEN_KEY = 'FIGMA_REFRESH_TOKEN_KEY'
const STATE_KEY = 'FIGMA_STATE_KEY'

const setLocalStorage = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
}

const unsetLocalStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

const getLocalStorageTokens = (): FigmaAuth | undefined => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) ?? undefined
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? undefined

  if (!accessToken || !refreshToken) return

  return {
    accessToken,
    refreshToken,
  }
}

const createLocalStorageFigmaState = () => {
  const state = Math.random().toString()
  localStorage.setItem(STATE_KEY, state)
  return state
}
export const getLocalStorageFigmaState = () => localStorage.getItem(STATE_KEY)
export const clearLocalStorageFigmaState = () => localStorage.removeItem(STATE_KEY)

export const connectToFigma = () => {
  const state = createLocalStorageFigmaState()
  const url = `https://www.figma.com/oauth?client_id=${FIGMA_CLIENT_ID}&redirect_uri=${FIGMA_REDIRECT_URI}&scope=file_read&state=${state}&response_type=code`
  window.open(url, 'Connect Figma', 'resizable,scrollbars,status')
}

// supports both file ids and file urls
// file url format: https://www.figma.com/file/FILE_ID/FILE_NAME
export const figmaUrlToFileId = (fileIdOrFileUrl?: string) => {
  let fileId = fileIdOrFileUrl

  if (fileIdOrFileUrl?.startsWith('http')) {
    const parts = fileIdOrFileUrl.split('/')

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]
      if (part === 'file') {
        fileId = parts[i + 1]
        break
      }
    }
  }

  return fileId || ''
}

const getPage = (file?: FigmaFile, pageId?: string) => {
  return file?.document.children.find(page => page.id === pageId)
}

export const getPageScreens = (file?: FigmaFile, pageId?: string) => {
  return getPage(file, pageId)?.children || []
}

export const FigmaProvider: React.FC = props => {
  const [authState, setAuthState] = React.useState<{ tokens?: FigmaAuth; loading: boolean }>()
  const [filesState, setFilesState] = React.useState<{
    file?: FigmaFile
    fileId?: string
    loading: boolean
    notFound?: boolean
  }>()
  const [imagesState, setImagesState] = React.useState<{
    images?: { [key: string]: string }
    loading: boolean
  }>()

  const connect = React.useCallback(() => {
    const tokens = getLocalStorageTokens()
    if (tokens) setAuthState({ tokens, loading: false })
  }, [])

  React.useEffect(() => {
    connect()
  }, [connect])

  const getFigmaToken = React.useCallback(async (code: string) => {
    setAuthState({ tokens: undefined, loading: true })
    const resString = await fetch(`${SERVER_URL}/figma/authorize/${code}`, {
      method: 'get',
      headers: { accept: 'application/json' },
    })
    const res = await resString.json()
    setAuthState({ tokens: res, loading: false })
    setLocalStorage(res.access_token, res.refresh_token)

    return res
  }, [])

  // Not super critical for Figma as:
  // `By default OAuth tokens expire after 90 days so you will need to refresh your stored tokens if your integration is long-lived. You do this using the refresh_token.`
  // const refreshFigmaToken = React.useCallback(async () => {
  //   const tokens = getLocalStorageTokens()

  //   if (!tokens?.refreshToken) return

  //   setAuthState(state => ({ ...state, loading: true }))
  //   const resString = await fetch(`${SERVER_URL}/figma/refresh-token/${tokens.refreshToken}`, {
  //     method: 'get',
  //     headers: { accept: 'application/json' },
  //   })
  //   const res = await resString.json()
  //   setAuthState(state => ({ tokens: { ...state?.tokens, ...res }, loading: false }))
  //   setLocalStorage(res.access_token, res.refresh_token)

  //   return res
  // }, [])

  const disconnect = React.useCallback(async () => {
    setAuthState({ tokens: undefined, loading: false })
    unsetLocalStorage()
  }, [])

  const accessToken = authState?.tokens?.accessToken

  const getFile = React.useCallback(
    async (fileIdOrFileUrl: string) => {
      const fileId = figmaUrlToFileId(fileIdOrFileUrl)

      if (!accessToken) {
        console.error('No Figma access')
        return
      }

      setFilesState(state => ({ ...state, loading: true }))

      const resString = await fetch(`https://api.figma.com/v1/files/${fileId}`, {
        method: 'get',
        headers: {
          accept: 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
      // TODO played with types here. need to check that this is correct type
      const res: FigmaFile & { status: number; err: string } = await resString.json()

      if (res && typeof res['err'] !== 'string') {
        setFilesState({ file: res, fileId, loading: false })
      } else {
        console.error('Unable to fetch file', res)
        if (res.err !== 'Not found') {
          unsetLocalStorage()
          setAuthState({ loading: false, tokens: undefined })
        }

        setFilesState({ file: undefined, fileId: undefined, loading: false, notFound: true })
      }

      return res
    },
    [accessToken]
  )

  const getFrameScreenshots = React.useCallback(
    async ({
      fileId,
      nodeIds,
      // may even be worth keeping it as pageSize of 1
      pageSize = 2,
    }: {
      fileId: string
      nodeIds: string[]
      pageSize?: number
    }) => {
      if (!nodeIds.length) return

      setImagesState(state => ({ ...state, loading: true }))

      async function fetchScreens(start: number, end: number) {
        const resString = await fetch(
          `https://api.figma.com/v1/images/${fileId}?ids=${nodeIds.slice(start, end).join(',')}`,
          {
            method: 'get',
            headers: {
              accept: 'application/json',
              authorization: `Bearer ${accessToken}`,
            },
          }
        )
        const res: FigmaImagesResponse = await resString.json()

        if (res.err) {
          unsetLocalStorage()
          setImagesState({ images: undefined, loading: false })
        } else {
          setImagesState(state => {
            return { images: { ...state?.images, ...res.images }, loading: false }
          })
        }
      }

      // Figma supports loading all in one go but then it takes forver for large files and never completes
      for (let i = 0; i < nodeIds.length; i += pageSize) {
        fetchScreens(i, i + pageSize)
      }
    },
    [accessToken]
  )

  return (
    <FigmaContext.Provider
      value={{
        getFigmaToken,
        connect,
        disconnect,
        getFile,
        getFrameScreenshots,
        fileId: filesState?.fileId,
        file: filesState?.file,
        images: imagesState?.images,
        loadingFile: !!filesState?.loading,
        loadingImages: !!imagesState?.loading,
        loadingAuth: !!authState?.loading,
        authenticated: !!authState?.tokens,
      }}
    >
      {props.children}
    </FigmaContext.Provider>
  )
}
