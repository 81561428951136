import { serverAmountToUSD } from './currency'

export const formatBidsText = (bids: number) => {
  const bidText = bids === 1 ? 'bid' : 'bids'

  return `${bids} ${bidText}`
}

export const formatBidAmount = (bid: {
  amount?: number | null
  hourlyRate?: number | null
}): string => {
  const { amount, hourlyRate } = bid

  if (amount && !hourlyRate) return `${serverAmountToUSD(amount)}`
  if (!amount && hourlyRate) return `${serverAmountToUSD(hourlyRate)} per hour`
  if (amount && hourlyRate)
    return `${serverAmountToUSD(amount)} plus ${serverAmountToUSD(hourlyRate)} per hour`

  return ''
}
