import React from 'react'
import amplitude from 'amplitude-js'
import Cohere from 'cohere-js'
import { useHistory } from 'react-router-dom'
import { AMPLITUDE_API_KEY, DEV_MODE, COHERE_API_KEY } from './config'
import { UserInfoFragment } from '../generated/graphql'

declare global {
  interface Window {
    dataLayer: object[]
  }
}

if (AMPLITUDE_API_KEY) amplitude.getInstance().init(AMPLITUDE_API_KEY)
if (COHERE_API_KEY) Cohere.init(COHERE_API_KEY)

export function identifyUser(user: UserInfoFragment) {
  if (AMPLITUDE_API_KEY) {
    const identify = new amplitude.Identify()
    identify.set('id', user.id)
    identify.set('email', user.email)
    identify.set('name', { firstName: user.firstName, lastName: user.lastName })
    identify.set('isDeveloper', !!user.developer)
    identify.set('interviewStatus', user.interviewStatus)
    amplitude.identify(identify)
  }

  if (COHERE_API_KEY) {
    Cohere.identify(user.id, {
      displayName: `${user.firstName || ''} ${user.lastName || ''}`,
      email: user.email,
    })
  }
}

export function trackEvent(eventType: string, eventProperties?: any) {
  if (DEV_MODE) console.log(eventType, eventProperties)
  if (!AMPLITUDE_API_KEY) return

  amplitude.getInstance().logEvent(eventType, eventProperties)
}

export const useTrackEvents = () => {
  const trackSignUp = (data: { email: string | undefined; developer: boolean }) => {
    trackEvent('Sign Up', data)
    window.dataLayer?.push({ event: 'sign_up' })
  }

  return { trackSignUp }
}

export const useTracking = () => {
  const { listen } = useHistory()

  React.useEffect(() => {
    if (!AMPLITUDE_API_KEY) {
      console.log('Not tracking. Amplitude key missing')
      return
    }

    const unlisten = listen(location => {
      const search = new URLSearchParams(location.search)
      const tab = search.get('tab')
      const options = location
      if (tab) (options as any).tab = tab
      trackEvent('View page', options)
    })

    return unlisten
  }, [listen])
}
