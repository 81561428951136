import { gql } from '@apollo/client'
import React from 'react'
import * as Sentry from '@sentry/browser'
import { ButtonLink } from '../../components/Button'
import { ComponentGrid } from '../../components/ComponentGrid'
import { ErrorCard, ErrorPage } from '../../components/ErrorPage'
import { Loading } from '../../components/Loading'
import { PageLayout } from '../../components/PageLayout'
import { useDeveloperComponentsQuery, usePurchasedComponentsQuery } from '../../generated/graphql'
import { PageHeaderTabBar, PageHeaderTabBarWrapper } from '../../components/PageHeaderTabBar'
import { Route, Switch } from 'react-router-dom'
import { NEXT_APP_URL } from '../../utils/config'
import { useUserContext } from '../../auth/userContext'

gql`
  query DeveloperComponents {
    components: developerComponents {
      ...Component
    }
  }
`

export const Components: React.FC<{}> = props => {
  const { user } = useUserContext()

  const PURCHASED = { label: 'Purchased Components', to: '/components' }
  const MARKETPLACE = { label: 'Marketplace', to: '/components/marketplace' }
  const PUBLISHED = { label: 'Your Components', to: '/components/published' }

  return (
    <PageLayout
      title=""
      headerDark
      header={
        <PageHeaderTabBarWrapper>
          <PageHeaderTabBar
            tabs={
              user?.developer
                ? [PUBLISHED, PURCHASED, MARKETPLACE]
                : [PURCHASED, PUBLISHED, MARKETPLACE]
            }
          />
        </PageHeaderTabBarWrapper>
      }
    >
      <Switch>
        <Route path="/components/published" exact>
          <PublishedComponentsContent />
        </Route>
        <Route path="/components/marketplace" exact>
          <ComponentsMarketplaceContent />
        </Route>
        <Route path="/components" exact>
          <PurchasedComponentsContent />
        </Route>
      </Switch>
    </PageLayout>
  )
}

const PublishedComponentsContent: React.FC<{}> = props => {
  const { data, loading, error } = useDeveloperComponentsQuery({})

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { components } = data

  return components.length ? (
    <>
      <ComponentGrid components={components} />
      <div className="mt-4 flex justify-end">
        <ButtonLink to="/upload-component">Publish New Component</ButtonLink>
      </div>
    </>
  ) : (
    <Centered>
      <ButtonLink to="/upload-component">Publish Your First Component</ButtonLink>
    </Centered>
  )
}

const ComponentsMarketplaceContent: React.FC<{}> = props => {
  return (
    <Centered>
      <ButtonLink to={`${NEXT_APP_URL}/component-marketplace`} external>
        Visit Component Marketplace
      </ButtonLink>
    </Centered>
  )
}

gql`
  query PurchasedComponents {
    components: purchasedComponents {
      ...Component
    }
  }
`

const PurchasedComponentsContent: React.FC<{}> = props => {
  const { data, loading, error } = usePurchasedComponentsQuery({ variables: {} })

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorCard error={error} />
  }

  const { components } = data

  return components.length ? (
    <>
      <ComponentGrid components={components} />
      <div className="mt-4 flex justify-end">
        <ButtonLink to={`${NEXT_APP_URL}/component-marketplace`} external>
          Purchase Components
        </ButtonLink>
      </div>
    </>
  ) : (
    <Centered>
      <ButtonLink to={`${NEXT_APP_URL}/component-marketplace`} external>
        Purchase Components
      </ButtonLink>
    </Centered>
  )
}

export const Centered: React.FC<{}> = props => {
  return <div className="flex justify-center py-10">{props.children}</div>
}
