import { gql } from '@apollo/client'
import React from 'react'
import { BidResult } from '../../components/BidResult'
import { Button } from '../../components/Button'
import { ModalDualButtons, ModalHeader } from '../../components/Modal'
import { useNotification } from '../../components/Notification'
import { LinkButton, CustomLink, Paragraph } from '../../components/Typography'
import {
  BidStatus,
  BidWithDeveloperFragment,
  ProjectDocument,
  useRespondToBidMutation,
  UserInfoFragment,
} from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import { serverAmountToUSD } from '../../utils/currency'
import { formatDateLong } from '../../utils/date'
import { useMessageContext } from '../../utils/messagesContext'
import { PaymentPayPal } from './PaymentPayPal'

gql`
  mutation RespondToBid($data: RespondToBidInput!) {
    respondToBid(data: $data) {
      ...Bid
    }
  }
`

export interface AcceptBidModalContentProps {
  projectId: string
  bid: BidWithDeveloperFragment
  user: UserInfoFragment
  hideModal: () => void
}

export const AcceptBidModalContent: React.FC<AcceptBidModalContentProps> = props => {
  const { projectId, bid, hideModal, user } = props
  const [respondToBid] = useRespondToBidMutation()
  const { setOpen } = useMessageContext()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  if (!bid) return null

  return (
    <div>
      <ModalHeader title="Bid" />
      <div className="my-6 space-y-4">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
          {!!bid.amount && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Amount</dt>
              <dd className="mt-1 text-sm text-gray-900">{serverAmountToUSD(bid.amount)}</dd>
            </div>
          )}
          {!!bid.hourlyRate && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Hourly Rate</dt>
              <dd className="mt-1 text-sm text-gray-900">{serverAmountToUSD(bid.hourlyRate)}</dd>
            </div>
          )}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Delivery</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {bid.deliveryDate ? formatDateLong(new Date(bid.deliveryDate)) : 'No date set'}
            </dd>
          </div>

          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Developer</dt>
            <dd className="mt-1 text-sm text-gray-900 flex">
              <div className="flex-1">{bid.developer.username || bid.developer.id}</div>
              <CustomLink to={`/developer/profile/${bid.developer.id}`}>
                View full profile →
              </CustomLink>
            </dd>
          </div>

          {bid.message ? (
            <div className="sm:col-span-2 max-h-72 overflow-y-auto">
              <p className="text-sm font-medium text-gray-500">Message</p>
              <p className="mt-1 text-sm text-gray-900 bg-gray-100 rounded-md p-4 whitespace-pre-wrap">
                {bid.message}
              </p>
            </div>
          ) : null}

          <div className="sm:col-span-2 flex justify-end">
            <div className="text-sm">
              <LinkButton onClick={() => setOpen(bid.developerId)}>Reply</LinkButton>
            </div>
          </div>
        </dl>

        {bid.status === BidStatus.Pending ? (
          <>
            <Paragraph>
              By continuing you agree to pay{' '}
              {bid.amount
                ? serverAmountToUSD(bid.amount)
                : `${serverAmountToUSD(bid.hourlyRate || 0)} per hour`}{' '}
              for the current project. Development will begin immediately.
            </Paragraph>

            {!user?.manualPaymentClient && (
              <PaymentPayPal
                amount={bid.amount}
                projectId={projectId}
                bidId={bid.id}
                onSuccess={() => hideModal()}
              />
            )}
          </>
        ) : (
          <BidResult status={bid.status} />
        )}
      </div>

      {bid.status === BidStatus.Pending && user.manualPaymentClient && (
        <ModalDualButtons
          button1={
            <Button
              block
              onClick={async () => {
                try {
                  const variables = {
                    data: {
                      id: bid.id,
                      response: BidStatus.Accepted,
                    },
                  }
                  await respondToBid({
                    variables,
                    refetchQueries: [{ query: ProjectDocument, variables: { id: projectId } }],
                  })
                  trackEvent('Respond to bid', { ...variables, projectId })
                  showSuccessNotification({ description: 'Successfully started project' })
                } catch (error) {
                  console.error('Error accepting bid', error)
                  showErrorNotification({ description: 'There was an error accepting the bid' })
                }

                hideModal()
              }}
              data-cy="confirm-accept"
            >
              Accept
            </Button>
            // <ButtonLink block to={`/payment?bidId=${bid.id}`}>
            //   Pay
            // </ButtonLink>
            // <Button
            //   block
            //   onClick={() => {
            //     setBlueSnapLoading(true)
            //     trackEvent('Click pay for project', { projectId })
            //     getCheckoutToken()
            //   }}
            //   loading={blueSnapLoading}
            // >
            //   Accept
            // </Button>
          }
          button2={
            <Button
              styleType="secondary"
              block
              onClick={async () => {
                try {
                  const variables = {
                    data: {
                      id: bid.id,
                      response: BidStatus.Rejected,
                    },
                  }
                  await respondToBid({
                    variables,
                    refetchQueries: [{ query: ProjectDocument, variables: { id: projectId } }],
                  })
                  trackEvent('Reject bid', { ...variables, projectId })
                  showSuccessNotification({ description: 'Successfully rejected bid' })
                } catch (error) {
                  console.error('Error rejecting bid', error)
                  showErrorNotification({ description: 'There was an error rejecting the bid' })
                }

                hideModal()
              }}
            >
              Reject
            </Button>
          }
        />
      )}
    </div>
  )
}
