import React from 'react'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { PageLayout } from '../components/PageLayout'
import { CardSideTitle, CardsWrapper } from '../components/Card'
import {
  usePublicProfileQuery,
  useUpdatePublicProfileMutation,
  usePersonalProfileQuery,
  useUpdatePersonalProfileMutation,
  useUpdatePasswordMutation,
  UpdatePersonalProfileInput,
  UpdatePublicProfileInput,
  DeveloperProfileDocument,
  GetUserDocument,
} from '../generated/graphql'
import { Loading } from '../components/Loading'
import { ErrorPage } from '../components/ErrorPage'
import { CountrySelectField } from '../components/CountrySelectField'
import { Field, Formik } from 'formik'
import { trackEvent } from '../utils/analytics'
import { FormItem } from '../components/FormItem'
import { CustomInputComponent } from '../components/FormikCustom'
import { Button } from '../components/Button'
import { useNotification } from '../components/Notification'
import { formatGraphQLError } from '../utils/error'

gql`
  query PublicProfile {
    user: getUser {
      id
      username
    }
  }
`

gql`
  query PersonalProfile {
    user: getUser {
      id
      firstName
      lastName
      email
      street
      city
      state
      country
      countryCode
      zip
      addressSet
    }
  }
`

gql`
  mutation UpdatePublicProfile($data: UpdatePublicProfileInput!) {
    updatePublicProfile(data: $data) {
      id
      username
    }
  }
`

gql`
  mutation UpdatePersonalProfile($data: UpdatePersonalProfileInput!) {
    updatePersonalProfile(data: $data) {
      id
      email
      firstName
      lastName
      country
      street
      city
      state
      zip
      addressSet
    }
  }
`

gql`
  mutation UpdatePassword($password: String!) {
    updatePassword(password: $password)
  }
`

export const ClientProfile: React.FC<{}> = props => {
  return (
    <PageLayout title={'Profile'} headerDark headerLarge>
      <CardsWrapper>
        <CardSideTitle
          title="Public Profile"
          description="This information will be displayed publicly."
        >
          <PublicProfile />
        </CardSideTitle>
        <CardSideTitle title="Personal/Company Information">
          <PersonalProfile />
        </CardSideTitle>
        <CardSideTitle title="Change Password">
          <ChangePassword />
        </CardSideTitle>
      </CardsWrapper>
    </PageLayout>
  )
}

export const PublicProfile: React.FC<{
  submitButtonText?: string
  postSubmit?: () => void
}> = props => {
  const { data, loading, error } = usePublicProfileQuery()
  const [updatePublicProfileMutation] = useUpdatePublicProfileMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { user } = data

  return (
    <Formik<UpdatePublicProfileInput>
      initialValues={{
        username: user.username || '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)
        try {
          await updatePublicProfileMutation({
            variables: { data: values },
            refetchQueries: [
              { query: DeveloperProfileDocument, variables: { id: user.id } },
              {
                query: GetUserDocument,
              },
            ],
          })
          trackEvent('Update public profile', values)
          showSuccessNotification({
            description: 'Successfully updated public profile information',
          })
          props.postSubmit?.()
        } catch (error) {
          console.error(error)
          showErrorNotification({ description: formatGraphQLError(error) })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Field name="username" label="Username" component={CustomInputComponent} />

          <div className="text-right">
            <FormItem>
              <Button
                styleType="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {props.submitButtonText || 'Save'}
              </Button>
            </FormItem>
          </div>
        </form>
      )}
    </Formik>
  )
}

export const PersonalProfile: React.FC<{hideEmail?: boolean}> = props => {
  const { data, loading, error } = usePersonalProfileQuery()
  const [updatePersonalProfileMutation] = useUpdatePersonalProfileMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  if (loading && !data) return <Loading />
  if (!data || error) {
    Sentry.captureException(error)
    console.error('error', error)
    return <ErrorPage error={error} />
  }

  const { user } = data

  return (
    <Formik<UpdatePersonalProfileInput>
      initialValues={{
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        country: user.country,
        street: user.street,
        city: user.city,
        state: user.state,
        zip: user.zip,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)
        try {
          await updatePersonalProfileMutation({ variables: { data: values } })
          trackEvent('Update personal profile', values)
          showSuccessNotification({
            description: 'Successfully updated personal information',
          })
        } catch (error) {
          console.error(error)
          showErrorNotification({ description: formatGraphQLError(error) })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="grid grid-cols-6 gap-6">
            {!props.hideEmail && <div className="col-span-6">
              <Field name="email" label="Email" type="email" component={CustomInputComponent} />
            </div>}

            <div className="col-span-6 sm:col-span-3">
              <Field name="firstName" label="First Name" component={CustomInputComponent} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Field name="lastName" label="Last Name" component={CustomInputComponent} />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <CountrySelectField />
            </div>

            <div className="col-span-6">
              <Field name="street" label="Street address" component={CustomInputComponent} />
            </div>

            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
              <Field name="city" label="City" component={CustomInputComponent} />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <Field name="state" label="State / Province" component={CustomInputComponent} />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <Field name="zip" label="ZIP / Postal" component={CustomInputComponent} />
            </div>
          </div>

          <div className="text-right">
            <FormItem>
              <Button
                styleType="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </Button>
            </FormItem>
          </div>
        </form>
      )}
    </Formik>
  )
}

export const ChangePassword: React.FC<{}> = props => {
  const [updatePassword] = useUpdatePasswordMutation()
  const { showSuccessNotification, showErrorNotification } = useNotification()

  return (
    <Formik<{ password: string }>
      initialValues={{ password: '' }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log('onSubmit', values)
        try {
          await updatePassword({ variables: { password: values.password } })
          trackEvent('Update password')
          showSuccessNotification({
            description: 'Successfully updated password',
          })
        } catch (error) {
          console.error(error)
          showErrorNotification({ description: formatGraphQLError(error) })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit, handleReset, values }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Field
            name="password"
            label="Password"
            type="password"
            component={CustomInputComponent}
          />

          <div className="text-right">
            <FormItem>
              <Button
                styleType="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Update
              </Button>
            </FormItem>
          </div>
        </form>
      )}
    </Formik>
  )
}
