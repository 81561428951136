import React from 'react'

export interface LabelProps {
  htmlFor?: string
}

export const Label: React.FC<LabelProps> = props => {
  return (
    <label htmlFor={props.htmlFor} className="block text-sm font-medium leading-5 text-gray-700">
      {props.children}
    </label>
  )
}
