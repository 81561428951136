import { gql } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useUserContext } from '../../auth/userContext'
import { Pricing } from '../../components/Pricing'
import {
  SubscriptionPlan,
  useCreateCheckoutSubscriptionSessionMutation,
  useGetUserLazyQuery,
} from '../../generated/graphql'
import { trackEvent } from '../../utils/analytics'
import {
  STANDARD_SUBSCRIPTION_AMOUNT,
  STANDARD_SUBSCRIPTION_CREDITS,
  UNLIMITED_SUBSCRIPTION_AMOUNT,
} from '../../utils/config'

gql`
  mutation CreateCheckoutSubscriptionSession($plan: SubscriptionPlan!) {
    createCheckoutSubscriptionSession(plan: $plan)
  }
`

interface SubscribeProps {}

export const Subscribe: React.FC<SubscribeProps> = props => {
  const { user } = useUserContext()
  const history = useHistory()

  const [createSession] = useCreateCheckoutSubscriptionSessionMutation()
  const [getUser] = useGetUserLazyQuery()

  const onSubscribeClick = React.useCallback(
    async (plan: string) => {
      if (!user) {
        history.push(`/sign-up?redirect=${history.location.pathname}#client`)
        return
      }

      const link = await createSession({ variables: { plan: plan as SubscriptionPlan } })

      if (link.data) {
        const popup = window.open(
          link.data.createCheckoutSubscriptionSession,
          'FrontWork Subscribe',
          'resizable,scrollbars,status'
        )

        ;(window as any).onSubscriptionCompleted = async (sessionId: string) => {
          trackEvent('Subscription Completed', { plan })

          getUser()

          popup?.close()
        }
      }
    },
    [createSession, getUser, history, user]
  )

  const tiers = [
    {
      plan: SubscriptionPlan.Standard,
      name: 'Standard',
      priceMonthly: STANDARD_SUBSCRIPTION_AMOUNT,
      description: `Unlock up to ${STANDARD_SUBSCRIPTION_CREDITS} components per month`,
      features: [
        `${STANDARD_SUBSCRIPTION_CREDITS} component unlocks per month`,
        '2% discount on custom development',
      ],
    },
    {
      plan: SubscriptionPlan.Unlimited,
      name: 'Unlimited',
      priceMonthly: UNLIMITED_SUBSCRIPTION_AMOUNT,
      description: 'Unlimited component unlocks every month',
      features: ['Unlimited component unlocks per month', '5% discount on custom development'],
    },
  ]

  return (
    <Pricing
      tiers={tiers}
      selectedPlan={user?.componentSubscriptionPlan || undefined}
      showDeveloperSection={user?.developer || !user}
      onSubscribeClick={onSubscribeClick}
    />
  )
}
